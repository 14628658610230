import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../LegacyMemberDashboardTypes';
import LandingPageType from '../Models/LandingPageType';

export interface ILegacyMemberDashboardApiClient {

  /** 
   * GetMemberLandingPage
   */
  getMemberLandingPage(): AxiosPromise<Model.PlainTrackedObject<LandingPageType>>;

  // Client only properties / methods
}

@injectable()
export default class LegacyMemberDashboardApiClient extends Data.ApiClientBase implements ILegacyMemberDashboardApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/legacy-member-dashboard`);
  }

  public getMemberLandingPage(): AxiosPromise<Model.PlainTrackedObject<LandingPageType>> {
    return this.axios.get(`${this.apiPath}/get-member-landing-page`);
  }

  // Client only properties / methods
}