import { Attributes, LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class SavingsClaimPartyFileDescriptorLookup extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public fileDescriptorId: number = 0;

    public fileName: string = "";

    @Attributes.Integer()
    public documentType: number = 0;

    // Client only properties / methods
}