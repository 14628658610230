import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { MemberDashboardExportedTypes } from './MemberDashboardExportedTypes';

const MemberDashboardTypes = {
  ApiClients: {
    ...MemberDashboardExportedTypes.ApiClients
  },
  Services: {
    ...MemberDashboardExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  MemberDashboard: MemberDashboardTypes
}

export { AppService, Types, MemberDashboardTypes }