import React from 'react';
import { observer } from 'mobx-react';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';

interface IProductDisabilityProps {
  benefitGroup: BenefitGroup,
  disclaimer: string,
  tableText: string
}

@observer
export default class ProductDisabilityTable extends React.Component<IProductDisabilityProps> {

  constructor(props: IProductDisabilityProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        {
          (this.props.benefitGroup.unApprovedAcceptedAmount > 0) &&
          <div>
            <div className='table-title-risk'><span className='table-title-risk bold'>{this.props.benefitGroup.benefitType}</span>
              ({this.props.disclaimer})
            </div>
            <table className='table-width-risk'>
              <tr className='table-border-risk'>
                <th className='table-border-risk table-row-header-risk'></th>
                <th className='table-border-risk padding-phone-screen'>Accepted benefit amount</th>
                <th className='table-border-risk padding-phone-screen remove-border-right-phone-risk'>Entitled <span className='space-phone-screen-risk'><br /> </span> benefit amount</th>
                <th className='table-border-risk table-column-underwriting-heading-risk'>Underwriting</th>
              </tr>
              {
                (this.props.benefitGroup.unApprovedAcceptedAmount !== 0) &&
                <tr className='table-border-risk'>
                  <td className='table-border-risk table-row-header-risk'>{this.props.tableText}</td>
                  <td className='table-border-risk table-column-content-risk'><span className='underline-green-risk'>
                    {this.props.benefitGroup.unApprovedAcceptedAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
                  </span></td>
                  <td className='table-border-risk table-column-content-risk remove-border-right-phone-risk'>
                    {this.props.benefitGroup.unApprovedEntitledAmount?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}

                  </td>
                  <td rowSpan={2} className='table-border-risk table-column-underwriting-risk remove-border-bottom-web-risk'>
                    {this.props.benefitGroup.unApprovedAcceptedAmount === this.props.benefitGroup.unApprovedEntitledAmount ? (
                      <span>No further underwriting actions required.</span>) : (
                      <span>
                        You will only be paid out your accepted benefit - please speak to the schemes' Financial Advisor to see what action must be taken.
                      </span>
                    )}
                  </td>
                </tr>
              }
            </table>
          </div>

        }
      </div>
    );
  }
}