import { List, ModelBase } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import MemberSchemeMembershipLookup from "../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup";
import { AppService, Types } from "./AppService";

class SchemeNames {
  schemeId: number | null = 0;
  schemeName: string | null = ''
}

@injectable()
export default class UserMemberSchemeService extends ModelBase {
  private hasInitialised = false;
  private taskRunner = AppService.get(Types.Neo.TaskRunner);

  public memberSchemes = new List(MemberSchemeMembershipLookup);
  public currentSchemeSelected = new MemberSchemeMembershipLookup();
  public schemeDetailsLookups: MemberSchemeMembershipLookup[] = [];
  public namesArray: SchemeNames[] = [];

  constructor(private personProfileApiClient = AppService.get(Types.Parties.ApiClients.PersonProfileApiClient)) {
    super();
    this.makeObservable();
  }

  public async getSchemes() {
    if (this.memberSchemes.length === 0) {
      var result = await this.personProfileApiClient.getActiveMemberSchemeMembershipLookups();
      this.memberSchemes.set(result.data);
      if (this.memberSchemes.length > 0) {
        this.currentSchemeSelected.mapFrom(this.memberSchemes[0]);
      }
      this.hasInitialised = true;
    }
  }

  public setCurrentSchemeMember(schemeId: number) {
    let schemeMembership = this.memberSchemes.find(s => s.schemeId === schemeId);
    if (schemeMembership) {
      this.currentSchemeSelected.mapFrom(schemeMembership, { isMap: true });
    }
  }

  public async getSchemeDetails() {
    if (this.schemeDetailsLookups.length === 0) {
      this.schemeDetailsLookups = this.memberSchemes;
      this.convertToPlainSchemes();
      if (this.schemeDetailsLookups.length > 0) {
        let scheme = this.schemeDetailsLookups[0];
        this.currentSchemeSelected.schemeName = scheme.schemeName;
        this.currentSchemeSelected.schemeId = scheme.schemeId;
        this.currentSchemeSelected.memberId = scheme.memberId;
        return scheme;
      }
    } else {
      if (this.currentSchemeSelected.schemeId != null) {
        if (this.currentSchemeSelected.schemeId > 0) {
          let scheme = this.schemeDetailsLookups.find(s => s.schemeId === this.currentSchemeSelected.schemeId);
          return scheme;
        }
      }

      let scheme = this.schemeDetailsLookups[0];
      this.currentSchemeSelected.schemeName = scheme.schemeName;
      this.currentSchemeSelected.schemeId = scheme.schemeId;
      this.currentSchemeSelected.memberId = scheme.memberId;
      return scheme;
    }
    return;
  }

  public async convertToPlainSchemes() {
    if (this.namesArray.length === 0) {
      this.schemeDetailsLookups.forEach(item => {
        let itemScheme = new SchemeNames();
        itemScheme.schemeId = item.schemeId,
          itemScheme.schemeName = item.schemeName
        this.namesArray.push(itemScheme);
      });
    }
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }
}