import { Data, Model, Validation } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import TAndCTemplate from '../../Models/TAndC/TAndCTemplate';

export interface ITAndCCommandApiClient {

  /**
   * Deletes a TAndC template.
   * @param templateId The TAndC template to delete.
   * @returns A list of TAndC templates.
   */
  deleteTAndCTemplate(templateId: number): AxiosPromise;

  /** 
   * Inserts or updates a TAndC template.
   * @param tandcTemplate The TAndC template to insert.
   * @returns The inserted template.
   */
  insertUpdateTAndCTemplate(tandcTemplate: Model.PartialPlainObject<TAndCTemplate>): AxiosPromise<Model.PlainTrackedObject<TAndCTemplate>>;

  // Client only properties / methods
}

@injectable()
export default class TAndCCommandApiClient extends Data.ApiClientBase implements ITAndCCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/tandc`);
  }

  public deleteTAndCTemplate(templateId: number): AxiosPromise {
    return this.axios.delete(`${this.apiPath}/tandc-template/${templateId}`);
  }

  public insertUpdateTAndCTemplate(tandcTemplate: Model.PartialPlainObject<TAndCTemplate>): AxiosPromise<Model.PlainTrackedObject<TAndCTemplate>> {
    return this.axios.post(`${this.apiPath}/tandc-template`, tandcTemplate);
  }

  // Client only properties / methods
}