import { Attributes, LookupBase, ModelBase, StringUtils } from '@singularsystems/neo-core';

export default class ContactUsTemplateCardLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public contactUsTemplateCardId: number = 0;

  public contactUsTemplateId: number = 0;

  public heading: string = "";

  @Attributes.Integer()
  public sequenceNo: number = 99;

  public email: string = "";

  public additionalEmail: string = "";

  public telephoneNumber: string = "";

  public additionalTelephoneNumber: string = "";

  public fax: string = "";

  public address: string = "";

  public postalBox: string = "";

  public city: string = "";

  public postalCode: string = "";

  public availableFrom: string | null = null;

  public availableTill: string | null = null;

  // Client only properties / methods

  public getFormattedTelephoneNumber(): string {
    if (this.telephoneNumber.length === 12) {
      return `${this.telephoneNumber.substring(0, 5)} ${this.telephoneNumber.substring(5, 8)} ${this.telephoneNumber.substring(8)}`;
    }
    else if (this.telephoneNumber.length >= 10) {
      return `${this.telephoneNumber.substring(0, 3)} ${this.telephoneNumber.substring(3, 6)} ${this.telephoneNumber.substring(6)}`;
    }
    else {
      return '';
    }
  }

  public getFormattedFax(): string {
    if (this.fax.length > 3) {
      return `${this.fax.substring(0, 3)} ${this.fax.substring(3, 6)} ${this.fax.substring(6)}`;
    }
    else {
      return this.fax;
    }
  }

  public getFormattedAdditionalTelNumber(): string {
    if (this.additionalTelephoneNumber.length === 12) {
      return `${this.additionalTelephoneNumber.substring(0, 5)} ${this.additionalTelephoneNumber.substring(5, 8)} ${this.additionalTelephoneNumber.substring(8)}`;
    }
    else if (this.additionalTelephoneNumber.length >= 10) {
      return `${this.additionalTelephoneNumber.substring(0, 3)} ${this.additionalTelephoneNumber.substring(3, 6)} ${this.additionalTelephoneNumber.substring(6)}`;
    }
    else {
      return '';
    }
  }

  public splitHeadingTwo: string = "";

  public splitHeading(): string {
    if (StringUtils.isNullOrWhitespace(this.heading)) {
      return this.heading;
    }
    var splitHeading = this.heading.split(" ")
    var splitHeadingOne = splitHeading[0];
    this.splitHeadingTwo = splitHeading.length > 1 ? splitHeading[1] : "";
    return splitHeadingOne;
  }
}