import React from 'react';
import { observer } from 'mobx-react';
import "../../Styles/Components/BlueRibbon.scss";
import { Neo } from '@singularsystems/neo-react';

interface IBlueRibbonProps {
  text?: string;
  // TODO: remove custom width prop when background cards are fixed
  width?: string;
  semiboldPart?: string;
}

@observer
export default class BlueRibbon extends React.Component<IBlueRibbonProps> {
  constructor(props: IBlueRibbonProps) {
    super(props);
  }

  public render() {
    const { width, text, semiboldPart } = this.props;
    const customWidth = width ? { width } : {};

    return (
      <div className='blue-ribbon'>
        <Neo.Alert
          variant="primary"
          className='display-alert-profile'
          style={customWidth}>
          <span>
            {semiboldPart && <span className="semibold-text">{semiboldPart}</span>}
            {text}
          </span>
        </Neo.Alert>
      </div>
    );
  }
}