import React from 'react';
import { observer } from 'mobx-react';
import InstructionsVM from '../InstructionsVM';
import { Neo } from '@singularsystems/neo-react';
import img_Upload from '../../assets/img/primary-upload.png';

interface IWithdrawalClaimIncompleteViewProps {
  viewModel: InstructionsVM
}

@observer
export default class InstructionWithdrawalClaimIncompleteView extends React.Component<IWithdrawalClaimIncompleteViewProps> {

  constructor(props: IWithdrawalClaimIncompleteViewProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='upload-card'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='instructon-view-message' >Please submit documents for the member.</p>
            </div>
          </div>
          <label className='mt-2 ms-2 form-label up-docs'>Upload document/s</label>
          <br />
          <label className='ms-2 form-label up-docs'>Max file size 5mb File types allowed: .pdf, .jpeg, .doc</label>
          <div className='form-group col-12 col-md-12'>
            <Neo.FileContext
              onFilesSelected={this.props.viewModel.onDocumentSelected}
              allowedExtensions={[".pdf", ".doc", ".jpeg"]} >
              <Neo.FileDropArea className='file-drop-area'>
                <i><img src={img_Upload} /></i>
                <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                <Neo.FileUploadProgress />
              </Neo.FileDropArea>
            </Neo.FileContext>
          </div>
        </div>
        <div className='container'>
          <Neo.Button text="Submit" className='center btn btn-submit btn-lg' onClick={() => this.props.viewModel.showInstructionDetails = false} />
        </div>
      </div>
    );
  }
}