import { Validation } from '@singularsystems/neo-core';
import MemberQueryLookupBase from './MemberQueryLookupBase';

export default class MemberComplimentLookup extends MemberQueryLookupBase {

  constructor() {
    super();
    this.makeObservable();
  }

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New member compliment lookup";
    } else {
      return "Member Compliment Lookup";
    }
  }
}