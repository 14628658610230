import { EnumHelper } from '@singularsystems/neo-core';

export enum ClaimTypeSubCategory {
    None = 0,
    InFundAnnuity = 1,
    LibertyRetailAnnuity = 2,
    RetirementOtherApprovedFundTransfer = 3,
    InFundNoHelpFromFA = 4,
    InFundWithHelpFromFA = 5,
    PreserveLibertyRetail = 6,
    PreserveOtherApprovedFundTransfer = 7,
    PreserveCashLibertyRetail = 8,
    PreserveAndCashOtherApprovedFundTransfer = 9,
    DeferRetirement = 10,
}

EnumHelper.decorateEnum(ClaimTypeSubCategory, decorator => {
    decorator.describe(ClaimTypeSubCategory.InFundAnnuity, "In-fund annuity");
    decorator.describe(ClaimTypeSubCategory.LibertyRetailAnnuity, "Liberty retail annuity");
    decorator.describe(ClaimTypeSubCategory.RetirementOtherApprovedFundTransfer, "Other approved fund - transfer");
    decorator.describe(ClaimTypeSubCategory.InFundNoHelpFromFA, "in-fund - No help from FA");
    decorator.describe(ClaimTypeSubCategory.InFundWithHelpFromFA, "in-fund - With help from FA");
    decorator.describe(ClaimTypeSubCategory.PreserveLibertyRetail, "Liberty retail");
    decorator.describe(ClaimTypeSubCategory.PreserveOtherApprovedFundTransfer, "Other approved fund - transfer");
    decorator.describe(ClaimTypeSubCategory.PreserveCashLibertyRetail, "Liberty retail");
    decorator.describe(ClaimTypeSubCategory.PreserveAndCashOtherApprovedFundTransfer, "Other approved fund - transfer");
    decorator.describe(ClaimTypeSubCategory.DeferRetirement, "Defer Retirement");
});