import { Attributes, LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class MemberCurrentInvestmentValuesLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public investmentFundName: string | null = null;

  @Attributes.Nullable()
  @Attributes.Float()
  public totalInvestmentFundValue: number | null = null;

  public fdDescId: string | null = null;

  // Client only properties / methods
  public url: string | null = null;
}