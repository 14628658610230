import { NeoModel, NotifyUtils, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import SchemeDetailsLookup from '../../Party/Models/LookUps/SchemeDetailsLookup';
import { AppService, Types } from '../MemberDashboardTypes';
import MemberInvestmentValuesLookup from '../../Party/Models/LookUps/MemberInvestmentValuesLookup';
import MemberDetailsLookup from '../../Party/Models/LookUps/MemberDetails/MemberDetailsLookup';
import { NotificationDuration } from '../../App/Models/NotificationDuration';
import { SchemeProductClassType } from '../../Party/Models/LookUps/SchemeProductClassType';

@NeoModel
export default class MyGoalsDetailsVM extends Views.ViewModelBase {
  public showMyGoalDetailComponent: boolean = true;
  public showMonthlyContributionSalaryPopup: boolean = false;
  public showTotalProjectedBenefitsAtRetirement: boolean = false;
  public showVoluntaryContributionPopup: boolean = false;
  public showFinancialAdviserPopup: boolean = false;
  public investmentDetails: MemberInvestmentValuesLookup = new MemberInvestmentValuesLookup();
  public memberDetails: MemberDetailsLookup = new MemberDetailsLookup();
  public schemeProductClassType: SchemeProductClassType = SchemeProductClassType.RiskAndInvestment;
  public IsPreservationScheme: boolean = false;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private memberInvestmentValuesApiClient = AppService.get(Types.Parties.ApiClients.MemberInvestmentValuesApiClient),
    private memberDetailsApiClient = AppService.get(Types.Parties.ApiClients.MemberDetailsApiClient),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService)) {

    super(taskRunner);
  }

  public async initialise() {
  }

  public async setSchemeInfo(scheme: SchemeDetailsLookup) {

  }

  public async getMemberInvestmentData() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberInvestmentValuesApiClient.getMemberInvestmentValuesLookupById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));
      if (response.data) {
        this.investmentDetails = MemberInvestmentValuesLookup.fromJSObject(response.data);
      } else {
        this.investmentDetails = new MemberInvestmentValuesLookup();
      }
    } else {
      NotifyUtils.addWarning("Issue Fetching Investment Details", "There was an issue fetching investment values.", NotificationDuration.Long);
    }
  }

  public async getMemberDetails() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      const response = await this.taskRunner.waitFor(
        this.memberDetailsApiClient.getMemberDetailsById(Number(this.userMemberSchemeService.currentSchemeSelected.memberId), this.userMemberSchemeService.currentSchemeSelected.sourceId));
      if (response.data) {
        this.memberDetails = MemberDetailsLookup.fromJSObject(response.data);
      } else {
        this.memberDetails = new MemberDetailsLookup();
      }
    } else {
      NotifyUtils.addWarning("Issue Fetching Details", "There was an issue fetching member details.", NotificationDuration.Long);
    }
  }

  public async showOrHideBenefitLinks() {
    if (this.userMemberSchemeService.currentSchemeSelected) {
      this.schemeProductClassType = this.userMemberSchemeService.currentSchemeSelected.schemeProductClassType;
      this.IsPreservationScheme = this.userMemberSchemeService.currentSchemeSelected.isPreservationScheme;
    }
  }
}