import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';

export interface IMemberBeneficiariesQueryApiClient {

  /** 
   * GetMemberBeneficiariesLookups
   * @returns List of MemberBeneficiariesLookup
   */
  getMemberBeneficiariesLookups(memberId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class MemberBeneficiariesQueryApiClient extends Data.ApiClientBase implements IMemberBeneficiariesQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-beneficiaries`);
  }

  public getMemberBeneficiariesLookups(memberId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryLookup>>> {
    return this.axios.get(`${this.apiPath}/lookups/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}