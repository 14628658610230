import { LookupBase } from '@singularsystems/neo-core';

export default class EmployerComplaintsResultsLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public firstName: string = "";

  public lastName: string = "";

  public memberNumber: string = "";

  public cellNumber: string = "";

  public emailAdress: string = "";

  public schemeName: string = "";

  public schemeNumber: string = "";

  public keyword: string = "";

  // Client only properties / methods

  public get memberNameAndID(): string {
    return this.firstName + " " + this.lastName + " - " + this.memberNumber
  }
}