import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberComplimentLookup from '../../Models/LookUps/MemberQueries/MemberComplimentLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberComplimentsQueryApiClient {

  /** 
   * Gets a member query.
   * @param memberId The member id.
   * @returns A member query.
   */
  getMemberCompliment(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberComplimentLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberComplimentsQueryApiClient extends Data.ApiClientBase implements IMemberComplimentsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-compliments`);
  }

  public getMemberCompliment(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberComplimentLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}