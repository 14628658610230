import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import EducationalInformationTemplate from '../../Domain/Models/EducationalInformation/EducationalInformationTemplate';
import { UserType } from '../../Enums/UserType.enum';
import { AppService, Types } from '../Services/AppService';
import KnowledgeAndFormsView from './KnowledgeAndFormsView';

@NeoModel
export default class EducationalMaterialVM extends Views.ViewModelBase {

  public isAdmin = false;
  public showEditTemplateModel = false;
  public showEmployerEditTemplateModel = false;
  public educationalInformationTemplates = new List(EducationalInformationTemplate);
  public educationalMaterialTemplateMember = new EducationalInformationTemplate();
  public educationalMaterialTemplateEmployer = new EducationalInformationTemplate();
  public userType = UserType.Member;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private educationalInformationCommandApiClient = AppService.get(Types.Domain.ApiClients.EducationalInformationCommandApiClient),
    private educationalInformationQueryApiClient = AppService.get(Types.Domain.ApiClients.EducationalInformationQueryApiClient)) {

    super(taskRunner);
  }

  public async initialise() {
    await this.getEducationalInformationTemplates();
    await this.refreshEducationalMaterialTemplates();
  }

  public hideTemplateModel() {
    this.refreshEducationalMaterialTemplates();
    this.showEditTemplateModel = false;
  }

  public hideTemplateEmployerModel() {
    this.refreshEducationalMaterialTemplates();
    this.showEmployerEditTemplateModel = false;
  }

  public getEducationalInformationTemplates = async () => {
    const response = await this.educationalInformationQueryApiClient.getEducationalInformationTemplates();
    this.educationalInformationTemplates.set(response.data);
  }

  public async refreshEducationalMaterialTemplates() {
    const response = await this.educationalInformationQueryApiClient.getEducationalInformationTemplate(2);
    this.educationalMaterialTemplateMember.set(response.data);
    const responseEmp = await this.educationalInformationQueryApiClient.getEducationalInformationTemplate(3);
    this.educationalMaterialTemplateEmployer.set(responseEmp.data);
  }

  public updateEducationalMaterialMemberContent = async () => {
    this.taskRunner.run(async () => {
      const converted = this.educationalMaterialTemplateMember.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false });
      let result: any = null;
      result = await this.educationalInformationCommandApiClient.updateEducationalInformationTemplate(converted);
      this.refreshEducationalMaterialTemplates();
      this.showEditTemplateModel = !this.showEditTemplateModel;
    })
  }

  public updateEducationalMaterialEmployerContent = async () => {
    this.taskRunner.run(async () => {
      const converted = this.educationalMaterialTemplateEmployer.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false });
      let result: any = null;
      result = await this.educationalInformationCommandApiClient.updateEducationalInformationTemplate(converted);
      this.refreshEducationalMaterialTemplates();
      this.showEmployerEditTemplateModel = !this.showEmployerEditTemplateModel;
    })
  }

  public homeClicked(): void {
    this.navigation.navigateToView(KnowledgeAndFormsView);
  }
}