import { AppService, Types as AppTypes } from '../App/Services/AppService';
import { MasterDataExportedTypes } from './MasterDataExportedTypes';

// Symbols specific to this module.
const MasterDataTypes = {
  ApiClients: {
    ...MasterDataExportedTypes.ApiClients,
  },
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  MasterData: MasterDataTypes,
}

export { AppService, Types, MasterDataTypes }