import React from 'react';
import { observer } from 'mobx-react';
import "../../Styles/Components/MemberIcon.scss";

interface IMemberIconProps {
  firstName: string,
  lastName: string,
  className: string
}

@observer
export default class MemberIcon extends React.Component<IMemberIconProps> {

  constructor(props: IMemberIconProps) {
    super(props);
  }

  public initials = `${this.props.firstName.charAt(0)}${this.props.lastName.charAt(0)}`;

  public render() {
    return (
      <div className={this.props.className}>
        {this.initials}
      </div>
    );
  }
}