import { Attributes, LookupBase, Model } from '@singularsystems/neo-core';
import VASTemplateLookup from './VASTemplateLookup';

export default class VASTemplateCardLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public vasTemplateCardId: number = 0;

  public vasTemplateId: number = 0;

  public title: string = "";

  public description: string = "";

  public icon: string = "";

  @Attributes.Integer()
  public order: number = 99;

  // Client only properties / methods

  public isExpanded = new Model.ObservableValue(false);
}