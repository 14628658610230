import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import EducationalInformationTemplate from '../../Models/EducationalInformation/EducationalInformationTemplate';
import EducationalInformationTemplateLookup from '../../Models/EducationalInformation/EducationalInformationTemplateLookup';

export interface IEducationalInformationQueryApiClient {

  /** 
   * Gets a EducationalInformation template.
   * @returns A EducationalInformation template.
   */
  getEducationalInformationTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<EducationalInformationTemplate>>;

  /** 
   * Gets the EducationalInformation templateLookup.
   * @returns A EducationalInformationTemplateLookup.
   */
  getEducationalInformationTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<EducationalInformationTemplateLookup>>;

  /** 
   * Gets the EducationalInformation templates.
   * @returns A list of EducationalInformation templates.
   */
  getEducationalInformationTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<EducationalInformationTemplate>>>;

  /** 
   * Gets the EducationalInformation templates.
   * @returns A list of EducationalInformation templates.
   */
  getEducationalInformationTemplateLookups(): AxiosPromise<Array<Model.PlainObject<EducationalInformationTemplateLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class EducationalInformationQueryApiClient extends Data.ApiClientBase implements IEducationalInformationQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/educational-information`);
  }

  public getEducationalInformationTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<EducationalInformationTemplate>> {
    return this.axios.get(`${this.apiPath}/educational-information-template/${templateId}`);
  }

  public getEducationalInformationTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<EducationalInformationTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/educational-information-template-lookup/${templateId}`);
  }

  public getEducationalInformationTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<EducationalInformationTemplate>>> {
    return this.axios.get(`${this.apiPath}/educational-information-templates`);
  }

  public getEducationalInformationTemplateLookups(): AxiosPromise<Array<Model.PlainObject<EducationalInformationTemplateLookup>>> {
    return this.axios.get(`${this.apiPath}/educational-information-template-lookups`);
  }

  // Client only properties / methods
}