import { Attributes, ModelBase } from '@singularsystems/neo-core';

export default class MemberEligibilityLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public memberId: number = 0;

  public isEligible: boolean = false;

  public eligibilityReason: string = "";

  @Attributes.Float()
  public sofSavingsPot: number = 0.00;

  public claimDateToBeShown: string | null = null;

  // Client only properties / methods
}