
export default class BenefitType {

  //Death
  static readonly spousePensionBenefit: string = "Spouse's pension benefit";
  static readonly constructorhildrenPensionBenefit: string = "Children's pension benefit";
  static readonly deathBenefit: string = "Death benefit";
  static readonly accidentalDeath: string = "Accidental death";

  // Critical
  static readonly livingPlan: string = "Living plan";
  static readonly progressiveLivingPlan: string = "Progressive living plan";
  static readonly progressiveLivingPlanPlus: string = "Progressive living plan plus";
  static readonly livingAssuranceWithReinstatement: string = "Living assurance with reinstatement";

  //Death of spouse
  static readonly spouseDeathBenefit: string = "Spouse's death benefit";
  static readonly spouseDisabilityBenefit: string = "Spouse's disability benefit";

  //Disability of member
  static readonly accidentalDisability: string = "Accidental disability";
  static readonly progressiveCapitalDisability: string = "Progressive capital disability";
  static readonly occupationalCapitalDisability: string = "Occupational capital disability";
  static readonly progressiveLevelIncomeProtectionBenefit: string = "Progressive level income protection benefit";
  static readonly progressiveReducingIncomeProtectionBenefit: string = "Progressive reducing income protection benefit";
  static readonly occupationalLimitedTermIncomeProtectionBenefit: string = "Occupational limited term income protection benefit";
  static readonly occupationalReducingIncomeProtectionBenefit: string = "Occupational reducing income protection benefit";
  static readonly occupationalLevelIncomeProtectionBenefit: string = "Occupational level income protection benefit";
  static readonly incomeProtectionPlusBenefit: string = "Income protection plus benefit";
  static readonly employerRetirementContributionAndPremiumWaiver: string = "Employer retirement contribution and premium waiver";
  static readonly healthPremiumWaiver: string = "Health premium waiver";

  static readonly progressiveEducatorBenefit: string = "Progressive";
  static readonly foundationEducatorBenefit: string = "Foundation";
}