import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import UpdatePersonContactDetailsCommand from '../../Models/LookUps/MemberProfileUpdates/UpdatePersonContactDetailsCommand';

export interface IPersonContactDetailsCommandApiClient {

  /** 
   * Updates PersonContactDetails
   */
  updatePersonContactDetails(updatePersonContactDetailsCommand: Model.PartialPlainObject<UpdatePersonContactDetailsCommand>): AxiosPromise<boolean>;

  // Client only properties / methods
}

@injectable()
export default class PersonContactDetailsCommandApiClient extends Data.ApiClientBase implements IPersonContactDetailsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/person-contact-details`);
  }

  public updatePersonContactDetails(updatePersonContactDetailsCommand: Model.PartialPlainObject<UpdatePersonContactDetailsCommand>): AxiosPromise<boolean> {
    return this.axios.post(`${this.apiPath}/update-person-contact-details`, updatePersonContactDetailsCommand);
  }
  // Client only properties / methods
}