import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import FundFactSheet from '../../Models/FundFactSheets/FundFactSheet';

export interface IFundFactSheetCommandApiClient {

    /** 
     * Inserts a VAFundFactSheetS.
     * @param fundFactSheet The FundFactSheet to insert.
     * @returns The inserted .
     */
    insertFundFactSheet(fundFactSheet: Model.PartialPlainObject<FundFactSheet>): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>>;

    /** 
     * Updates a FundFactSheet .
     * @param fundFactSheet The FundFactSheet to update.
     * @returns The updated .
     */
    updateFundFactSheet(fundFactSheet: Model.PartialPlainObject<FundFactSheet>): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>>;

    /** 
     * Deletes a FundFactSheet.
     * @param Id The FundFactSheet to delete.
     * @returns A list of FundFactSheet s.
     */
    deleteFundFactSheet(Id: number): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class FundFactSheetCommandApiClient extends Data.ApiClientBase implements IFundFactSheetCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/FundFactSheetCommand`);
    }

    public insertFundFactSheet(fundFactSheet: Model.PartialPlainObject<FundFactSheet>): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>> {
        return this.axios.post(`${this.apiPath}/fund-fact-sheet-insert`, fundFactSheet);
    }

    public updateFundFactSheet(fundFactSheet: Model.PartialPlainObject<FundFactSheet>): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>> {
        return this.axios.put(`${this.apiPath}/fund-fact-sheet-update`, fundFactSheet);
    }

    public deleteFundFactSheet(Id: number): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/fund-fact-sheet/${Id}`);
    }

    // Client only properties / methods
}