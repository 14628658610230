import React from 'react';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
  imageGallery
} from "suneditor/src/plugins";
import { observer } from 'mobx-react';
import SunEditor from 'suneditor-react';
import katex from "katex";
import "../../Styles/Components/RichTextEditor.scss"
import "suneditor/dist/css/suneditor.min.css";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import { AppService, Types } from '../../Services/AppService';

//See https://www.npmjs.com/package/suneditor-react?activeTab=readme

interface Props {
  viewModel?: RichTextEditorVM;
  onBlur?: () => void;
  taskRunner?: ITaskRunner;
  readOnly?: boolean;
  bind: IPropertyInstance;
  onChange?: (value: string) => void;
  hideToolbar?: boolean;
  autoFocus?: boolean;
  disable?: boolean;
  height?: string;
}

@NeoModel
export class RichTextEditorVM extends Views.ViewModelBase {

  constructor(
    public taskRunner = AppService.get(Types.Neo.TaskRunner)) {
    super(taskRunner);
  }
}

@observer
export default class RichTextEditor extends React.Component<Props> {
  private viewModel = new RichTextEditorVM();
  public editorOptions = {
    height: "500",
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
      imageGallery
    ],
    buttonList: [
      ["undo", "redo"],
      ["removeFormat"],
      ["bold", "underline", "italic", "fontSize"],
      ["font", "fontColor", "hiliteColor"],
      ["align", "horizontalRule", "list"],
      ["outdent", "indent"],
      ["table", "link", "image", "imageGallery"],
      ["showBlocks", "codeView"],
    ],
    font: [
      "NotoSans-Medium",
      "NotoSans-Regular",
      "NotoSans-SemiBold"
    ],
    colorList: [],
    katex: katex,
    imageRotation: false,
    fontSize: [12, 14, 16, 18, 20],
  };

  constructor(props: any) {
    super(props);
    this.viewModel = new RichTextEditorVM();
    this.setValue = this.setValue.bind(this);
  }

  public editorRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  public setValue = (value: any) => {
    if (this.editorRef.current)
      this.editorRef.current.innerHTML = value;
    this.props.bind.value = value;
  };

  render() {
    return (
      <div className='editor'>
        <SunEditor
          height={this.props.height}
          setDefaultStyle='font-family: NotoSans-Regular; font-size: 16px;'
          readOnly={this.props.readOnly}
          setOptions={this.editorOptions}
          hideToolbar={this.props.hideToolbar}
          lang="en"
          onChange={this.setValue}
          setContents={this.props.bind.value}
          autoFocus={this.props.autoFocus}
          disable={this.props.disable}
        />
      </div>
    );
  }
}
