import { Attributes, Misc, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class TAndCTemplate extends ModelBase {

  public tAndCTemplateId: number = 0;

  @Rules.Required()
  @Attributes.Date(Misc.TimeZoneFormat.None)
  @Attributes.Display("Effective Date")
  public effectiveDate: Date | null = null;

  @Attributes.Display("Content")
  @Rules.Required()
  public templateContent: string = "";

  public modifiedBy: number = 0;

  @Attributes.Date()
  public modifiedOn: Date = new Date();

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.templateContent) {
      return "New t and c template";
    } else {
      return this.templateContent;
    }
  }
}