import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import WalkmeUrlCommand from '../../Models/WebInterfaceOptions/WalkMeCommand';
import MemberWebInterfaceOptionLookup from '../../Models/WebInterfaceOptions/MemberWebInterfaceOptionsLookup';

export interface IMemberWebInterfaceOptionsCommandApiClient {

  /** 
   * Create member we options for Walkme.
   * @param walkMeUrlCommand Command object to set the WalkMe Url
   * @returns A list of WebInterfaceOptions
   */
  updateUsingWalkmeUrlCommand(walkMeUrlCommand: Model.PartialPlainObject<WalkmeUrlCommand>): AxiosPromise<Model.PlainTrackedObject<MemberWebInterfaceOptionLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberWebInterfaceOptionsCommandApiClient extends Data.ApiClientBase implements IMemberWebInterfaceOptionsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/MemberWebInterfaceOptionsCommand`);
  }

  public updateUsingWalkmeUrlCommand(walkMeUrlCommand: Model.PartialPlainObject<WalkmeUrlCommand>): AxiosPromise<Model.PlainTrackedObject<MemberWebInterfaceOptionLookup>> {
    return this.axios.post(`${this.apiPath}/create-or-update-webinterfaceoption`, walkMeUrlCommand);
  }

  // Client only properties / methods
}