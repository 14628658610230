import { AppServices } from '@singularsystems/neo-core';
import FADashboardApiClient from './ApiClients/FaDashboardApiClient';

export const FADashboardExportedTypes = {
  ApiClients: {
    FADashboardApiClient: new AppServices.ServiceIdentifier<FADashboardApiClient>("FADashboard.ApiClient.FADashboardApiClient"),
  },
  Services: {
    // Add Services here
  },
};