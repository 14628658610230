import { NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { observable } from 'mobx';
import WebInterfaceOptionBase from './WebInterfaceOptionsBase';

export default class MemberWebInterfaceOptionLookup extends WebInterfaceOptionBase {
  static typeName = "MemberWebInterfaceOption";

  constructor() {
    super();
    this.makeObservable();
  }

  @Rules.StringLength(220)
  public walkmeUrl: string = "";

  public walkMeHeaderEnabled: boolean = false;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.walkmeUrl) {
      return "New member web interface option";
    } else {
      return this.walkmeUrl;
    }
  }
}