import React from 'react';
import BeneficiariesVM from './BeneficiariesVM';
import { observer } from 'mobx-react';
import "../../Styles/Beneficiaries.scss";
import SchemeInformationHeader from '../../../App/Views/Components/SchemeInformationHeader';
import { Neo, Routing, Views } from '@singularsystems/neo-react';
import checked from '../../../App/assets/img/checked.png';
import unchecked from '../../../App/assets/img/unchecked.png';
import BeneficiariesApprovedDeathBenefits from '../../Components/Beneficiaries/BeneficiariesApprovedDeathBenefits';
import BeneficiariesAddKnown from '../../Components/Beneficiaries/BeneficiariesAddKnown';
import BeneficiariesUnApprovedDeathBenefits from '../../Components/Beneficiaries/BeneficiariesUnApprovedDeathBenefits';
import BeneficiariesFuneralBenefit from '../../Components/Beneficiaries/BeneficiariesFuneralBenefit';
import BeneficiaryDetails from '../../Components/Beneficiaries/BeneficiaryDetails';
import { UserType } from '../../../Enums/UserType.enum';
import BeneficiariesContactPerson from '../../Components/Beneficiaries/BeneficiariesContactPerson';
import BeneficiariesAddKnownForTrustedPerson from '../../Components/Beneficiaries/BeneficiariesAddKnownForTrustedPerson';
import valid from '../../../App/assets/img/img-valid.png';
import warningYellow from '../../../App/assets/img/warning-yellow.png';
import errorRed from '../../../App/assets/img/error-red.png';
import MatchingKnownBeneficiaries from '../../Components/Beneficiaries/MatchingKnownBeneficiaries';
import { ModalResult } from '@singularsystems/neo-core/dist/Misc';
import { ModalUtils } from '@singularsystems/neo-core';

class BeneficiariesViewParams {
  public memberName = { required: false, isQuery: true };
}

@observer
export default class BeneficiariesView extends Views.ViewBase<BeneficiariesVM, BeneficiariesViewParams> {

  constructor(props: unknown) {
    super("My Beneficiaries", BeneficiariesVM, props);
  }

  public beforeRouteChanged(props: Routing.IRouteChangedProps) {
    const viewModel = this.viewModel;
    if ((viewModel.tabValue == "Approved Death Benefits" && viewModel.approvedBeneficiaryGrouping.isDirty) ||
      (viewModel.tabValue == "Unapproved Death Benefits" && viewModel.unApprovedBeneficiaryGrouping.isDirty) ||
      (viewModel.tabValue == "Funeral Benefits" && viewModel.funeralBeneficiaryGrouping.isDirty)) {
      props.leavePrompt = new Promise<ModalResult>((resolve, reject) => {

        function onAccept() {
          resolve(ModalResult.Yes);
        }

        function onReject() {
        }

        ModalUtils.showModal("Are you sure you want to leave this page?", "Please note: These changes have not been submitted. The information captured will be retained for 30 days. Please return to this screen to complete and submit.",
          {
            onClose: () => resolve(ModalResult.No),
            showCancelButton: false,
            acceptButton: { text: "Leave", onClick: onAccept },
            closeButton: { text: "Cancel", onClick: onReject }
          });
      });
    }
  }

  public static params = new BeneficiariesViewParams();

  protected viewParamsUpdated() {
    let client = this.viewParams.memberName;
    if (client) {
      this.viewModel.memberName = client.asString();
    }
  }

  private tabCodes = [
    { Id: 1, tabCodes: "Approved Death Benefits" },
    { Id: 2, tabCodes: "Unapproved Death Benefits" },
    { Id: 3, tabCodes: "Funeral Benefits" },
    { Id: 4, tabCodes: "Trusted Contact Person" }
  ];

  public render() {
    const viewModel = this.viewModel;

    return (
      <div className='help-my'>
        {!this.viewModel.viewOnlyBeneficiary &&
          <div>
            {
              <SchemeInformationHeader schemeHeader={'Select a scheme for more information'} memberName={this.viewModel.memberName} showClaimButton={false} extraCssClasses=''
                onSchemeSelected={async () => await this.viewModel.getMemberNominationsLookups()} />
            }
          </div>
        }
        {!this.viewModel.showBeneficiariesApprovedDeathBenefit && !this.viewModel.showBeneficiariesAddKnown
          && !this.viewModel.showBeneficiariesUnApprovedDeathBenefit && !this.viewModel.showBeneficiariesFuneralBenefits &&
          <div className='beneficiaries-scss'>
            <div className='background-card margin'>
              <div className='tabs-dropdown-beneficiaries'>
                <Neo.AutoCompleteDropDown
                  bind={viewModel.meta.tabValueId}
                  bindDisplay={viewModel.meta.tabValue}
                  items={this.tabCodes} />
              </div>
              <Neo.TabContainer variant="flat" className='beneficiary-tab-container' selectedTab={this.viewModel.meta.tabValue}>
                <Neo.Tab header={
                  <div className='beneficiary-tab'>
                    Approved death benefits
                    {!this.viewModel.viewOnlyBeneficiary &&
                      <span>
                        {this.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
                          <img className='alert-error' src={errorRed} />}
                        {this.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0 && this.viewModel.approvedTotalShareValue !== 100 &&
                          <img className='alert-warning' src={warningYellow} />}
                        {this.viewModel.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0 && this.viewModel.approvedTotalShareValue === 100 &&
                          <img className='alert-approved' src={valid} />}
                      </span>
                    }
                  </div>} name="Approved Death Benefits">
                  <BeneficiariesApprovedDeathBenefits viewModel={this.viewModel} />
                </Neo.Tab>
                <Neo.Tab header={
                  <div
                    className='beneficiary-tab'>
                    Unapproved death benefits
                    {!this.viewModel.viewOnlyBeneficiary &&
                      <span>
                        {this.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
                          <img className='alert-error' src={errorRed} />}
                        {this.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0 && this.viewModel.unapprovedTotalShareValue !== 100 &&
                          <img className='alert-warning' src={warningYellow} />}
                        {this.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0 && this.viewModel.unapprovedTotalShareValue === 100 &&
                          <img className='alert-approved' src={valid} />}
                      </span>
                    }
                  </div>} name="Unapproved Death Benefits">
                  <BeneficiariesUnApprovedDeathBenefits viewModel={this.viewModel} />
                </Neo.Tab>
                <Neo.Tab header={
                  <div className='beneficiary-tab'>
                    Funeral benefits
                    {!this.viewModel.viewOnlyBeneficiary &&
                      <span>
                        {this.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
                          <img className='alert-error' src={errorRed} />}
                        {this.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 1 &&
                          <img className='alert-warning' src={warningYellow} />}
                        {this.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 2 &&
                          <img className='alert-approved' src={valid} />}
                      </span>
                    }
                  </div>} name="Funeral Benefits">
                  <BeneficiariesFuneralBenefit viewModel={this.viewModel} />
                </Neo.Tab>
                <Neo.Tab header={
                  <div className='beneficiary-tab'>
                    Trusted contact person
                    {!this.viewModel.viewOnlyBeneficiary &&
                      <span>
                        {!this.viewModel.trustedContactPersonHasData() &&
                          <img className='alert-error' src={errorRed} />}
                        {!this.viewModel.trustedContactPerson.isValidTrustedPerson() && this.viewModel.trustedContactPersonHasData() &&
                          <img className='alert-warning' src={warningYellow} />}
                        {this.viewModel.trustedContactPerson.isValidTrustedPerson() &&
                          <img className='alert-approved' src={valid} />}
                      </span>
                    }
                  </div>} name="Trusted Contact Person">
                  <BeneficiariesContactPerson viewModel={this.viewModel} currentBeneficiary={this.viewModel.trustedContactPerson} />
                </Neo.Tab>
              </Neo.TabContainer>
            </div>
          </div>
        }
        {(this.viewModel.currentUser.userType === UserType.Staff || this.viewModel.currentUser.userType === UserType.Employer || this.viewModel.currentUser.userType === UserType.FA) && !this.viewModel.viewOnlyBeneficiary &&
          <Neo.Modal title='' bind={this.viewModel.meta.showFAEmployerStaffModal}
            acceptButton={{ text: "Continue", variant: "success" }}
            className='beneficiaries-modal'>
            <div className='modal-content'>
              <h1 className='modal-heading'>Please take note</h1>
              <p className='modal-text'>
                You will need to upload and submit the Beneficiary Nomination Form once you have filled in the necessary information
                on behalf of the member.
              </p>
              <span className='grey-block-message'>
                <Neo.Button className='checkbox-table-bak sub-select' onClick={() => this.viewModel.dontShowMessageAgain = this.viewModel.dontShowMessageAgain ? false : true} >{this.viewModel.dontShowMessageAgain ? <img src={checked} /> : <img src={unchecked} />} Do not show this message again</Neo.Button>
              </span>
            </div>
          </Neo.Modal>
        }
        <Neo.Modal title='All known beneficiaries'
          className='know-beneficiaries-modal'
          size='lg'
          acceptButton=
          {this.viewModel.knownBeneficiaries.length > 0 &&
          {
            text: "Add", variant: "primary", onClick: async () => {
              await this.viewModel.addSelectedExistingBeneficiaries()
            }
          }
          }
          closeButton={{ text: "Cancel", className: "btn-no-outline-secondary" }}
          bind={this.viewModel.meta.showSelectExistingBeneficiariesModal}>
          <BeneficiariesAddKnown viewModel={this.viewModel} />
        </Neo.Modal>
        <Neo.Modal
          title='All known beneficiaries'
          className='know-beneficiaries-modal trusted-person-beneficiaries-modal'
          size='lg'
          acceptButton=
          {this.viewModel.knownBeneficiaries.length > 0 &&
            { text: "Add", variant: "primary", onClick: () => { this.viewModel.addSelectedExistingBeneficiaries(), this.viewModel.showKnowBeneficiariesModalForTrustedPerson = false } }
          }
          closeButton={{ text: "Cancel", className: "btn-no-outline-secondary" }}
          bind={this.viewModel.meta.showKnowBeneficiariesModalForTrustedPerson}>
          <span className='align-sub-title'>Please note that you may only select one existing beneficiary to be your trusted contact person.</span>
          <BeneficiariesAddKnownForTrustedPerson viewModel={this.viewModel} />
        </Neo.Modal>
        <Neo.Modal title='Add beneficiaries'
          className='know-beneficiaries-modal'
          size='lg'
          acceptButton={{
            text: "Add", variant: "primary", disabled: !this.viewModel.newBeneficiary.isValid, onClick: async () => {
              await this.viewModel.addNewBeneficiary()
            }
          }}
          closeButton={{ text: "Cancel", className: "btn-no-outline-secondary" }}
          bind={this.viewModel.meta.showAddBeneficiariesModal}>
          <BeneficiaryDetails viewModel={this.viewModel} currentBeneficiary={this.viewModel.newBeneficiary} />
        </Neo.Modal>
        <Neo.Modal title='Edit beneficiary'
          className='know-beneficiaries-modal'
          size='lg'
          acceptButton={{
            text: "Update", variant: "primary",
            disabled: !this.viewModel.selectedBeneficiaryToEdit.isValid,
            onClick: () => this.viewModel.submitUpdatedBeneficiary()
          }}
          closeButton={{ text: "Cancel", className: "btn-no-outline-secondary" }}
          bind={this.viewModel.meta.showEditBeneficiaryModal}>
          <BeneficiaryDetails viewModel={this.viewModel} currentBeneficiary={this.viewModel.selectedBeneficiaryToEdit} />
        </Neo.Modal>
        <Neo.Modal
          showCancelButton={false}
          bind={this.viewModel.meta.showConfirmSaveChangesModal}
          title='Are you sure you want to cancel?'
          acceptButton={{
            text: "Yes", variant: "primary", onClick: async () => {
              await this.viewModel.deleteInterimNomination(),
                this.viewModel.showConfirmSaveChangesModal = false
            }
          }}
          closeButton={{
            text: "No", className: "btn-no-outline-secondary", onClick: () => {
              this.viewModel.showConfirmSaveChangesModal = false
            }
          }}>
          <div>By cancelling, the changes that you have made on this tab will be lost.</div>
        </Neo.Modal>
        <Neo.Modal title='We have detected similarities'
          className='know-beneficiaries-modal'
          size='lg'
          acceptButton=
          {this.viewModel.knownBeneficiaries.length > 0 &&
            { text: "Add", variant: "primary", onClick: () => { this.viewModel.addSelectedMatchingBeneficiary(), this.viewModel.showMatchingKnownBeneficiariesModal = false } }
          }
          closeButton={{ text: "Cancel", className: "btn-no-outline-secondary" }}
          bind={this.viewModel.meta.showMatchingKnownBeneficiariesModal}>
          <div className='align-sub-title'>Please select the preferred linked beneficiary, alternatively you can create a new trusted contact person. By selecting an existing linked beneficiary their information will be updated according to what you have filled in the fields.</div>
          <MatchingKnownBeneficiaries viewModel={this.viewModel} />
        </Neo.Modal>
      </div >
    );
  }
}