import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import { NumberUtils, Validation } from '@singularsystems/neo-core';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import { AccountOwnershipType } from '../../Models/MemberClaims/Enums/AccountOwnershipType.enum';
import { ConfirmYesNo } from '../../Models/MemberClaims/Enums/ConfirmYesNo.enum';

interface ISelectionProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class Selection extends React.Component<ISelectionProps> {

  constructor(props: ISelectionProps) {
    super(props);
  }
  componentDidMount() {
    if (this.props.viewModel.savingsWithdrawalClaimCapture.accountHolderName == null || this.props.viewModel.savingsWithdrawalClaimCapture.accountHolderName == "") {
      this.props.viewModel.savingsWithdrawalClaimCapture.accountHolderName = this.props.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.lastName;
    }
  }
  private branchCode = [
    { Id: 1, BranchCode: "17784" },
    { Id: 2, BranchCode: "1133" },
    { Id: 3, BranchCode: "9585" },
    { Id: 4, BranchCode: "55655" },
  ];

  public render() {
    return (
      <div className='preservation-and-transfer'>
        <div className='preservation-card'>
          <div className='preservation-card-heading'>
            Savings component value: <span className='amount'>
              {NumberUtils.format(this.props.viewModel.eligibilityData.sofSavingsPot, "R # ##0.00")}</span>
          </div>
          <div className='preservation-card-sub-heading'>
            *Subject to further contributions, market fluctuations and fees.
          </div>
        </div>
        <div className='preservation-heading'>
          View funds as: <span className='display-asterisk'>*</span>
        </div>
        <div className="col-md-6 col-12 m-4">
          <Neo.RadioList className='align-radio withdrawal-details'
            bind={this.props.viewModel.meta.isPercentage}
            radioList={{ items: [{ id: false, text: "Rand value" }, { id: true, text: "Percentage" }], inline: true }} />
        </div>
        <div className='row'>
          <div className='col-md-6 withdrawal-details-input-field-left col-12 withdrawal-details-padding-on-mobile'>
            {!this.props.viewModel.isPercentage &&
              <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                <div>
                  <label className='savings-withdrawal-amount-heading'>Savings withdrawal amount:</label>
                  <Neo.FormGroup className='withdrawal-details-input-field'
                    numProps={{ formatString: "R # ##0.00" }}
                    bind={this.props.viewModel.meta.savingWithdrawalAmount}
                    onBlur={() => this.props.viewModel.savingWithdrawalPercentageChange()} />
                  <span className='sub-text-savings-withdrawal-amount'><span className='display-asterisk'>* </span> Please note that marginal tax rates will apply</span>
                </div>
              </Neo.FormContext>
            }
            {this.props.viewModel.isPercentage &&
              <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                <div>
                  <label className='savings-withdrawal-amount-heading'>Savings withdrawal percentage:</label>
                  <Neo.FormGroup className='withdrawal-details-input-field'
                    numProps={{ formatString: "#.##%" }}
                    bind={this.props.viewModel.meta.savingWithdrawalPercentage}
                    onBlur={() => this.props.viewModel.savingWithdrawalPercentageChange()}
                  />
                  <span className='sub-text-savings-withdrawal-amount'><span className='display-asterisk'>* </span> Please note that marginal tax rates will apply</span>
                </div>
              </Neo.FormContext>
            }
          </div>
        </div>
        <div className='m-4'>
          <div className='separating-line ' />
        </div>
        <div className='deductions-heading mb-3'>
          Please fill out your banking details so we can transfer your cash lump sum.
        </div>
        <div className='p-2 p-sm-0'>
          <BlueRibbon
            width='97%'
            text='The cash savings withdrawal will be paid into this account. The details will be verified at the time of processing.'
          />
        </div>
        <div className='preservation-heading semi-bold mt-3'>
          Account <span className='display-asterisk'>*</span>
        </div>
        <div className="col-md-6 col-12 m-3 m-md-4">
          <Neo.RadioList className='align-radio withdrawal-details'
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.isSouthAfricanBankAccount}
            radioList={{
              items: [
                { id: ConfirmYesNo.Yes, text: "South African bank account" },
                { id: ConfirmYesNo.No, text: "Foreign bank account" },
              ],
              inline: true
            }} />
        </div>
        <div className='preservation-heading semi-bold'>
          Account ownership <span className='display-asterisk'>*</span>
        </div>
        <div className="col-md-6 col-12 m-3 m-md-4">
          <Neo.RadioList className='align-radio withdrawal-details'
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.accountOwnershipType}
            radioList={{
              items: this.props.viewModel.accountOwnershipTypesList,
              inline: true,
              valueMember: "id"
            }} />
        </div>
        <div className='preservation-heading semi-bold'>
          Type of account <span className='display-asterisk'>*</span>
        </div>
        <div className="col-md-6 col-12 m-3 m-md-4">
          <Neo.RadioList className='align-radio withdrawal-details'
            bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.typeOfAccount}
            radioList={{
              items: this.props.viewModel.bankAccountTypesList,
              inline: true,
              valueMember: "id"
            }} />
        </div>
        <Neo.GridLayout>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text change-color'
              label={<span>Account holder surname <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.accountHolderName}
              placeholder="Please enter"
              onBlur={() => this.props.viewModel.saveSavingsClaimData()}
              required
            />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text right-side change-color'
              label={<span>Account number <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.accountNumber}
              placeholder="Please enter"
              onBlur={() => this.props.viewModel.saveSavingsClaimData()}
              required
            />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            {this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount != ConfirmYesNo.No &&
              <div className="form-group form-text">
                <label className='mt-0'>Name of bank <span className='display-asterisk'>*</span></label>
                <Neo.AutoCompleteDropDown
                  className='auto-complete-dropdown mt-0 mb-1'
                  bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.bankId}
                  displayMember="name"
                  onBlur={() => this.props.viewModel.saveSavingsClaimData()}
                  items={this.props.viewModel.masterDataService.banks}
                  onItemSelected={() => this.props.viewModel.populateBankBranch(this.props.viewModel.savingsWithdrawalClaimCapture.bankId)}
                  placeholder="Please select option"
                  required />
              </div>
            }
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            {this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount != ConfirmYesNo.No &&
              <Neo.FormGroup className='form-text right-side change-color'
                label={<span>Branch code <span className='display-asterisk'>*</span></span>}
                bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.branchCode}
                placeholder="Please enter"
                onBlur={() => this.props.viewModel.saveSavingsClaimData()}
                readOnly={this.props.viewModel.savingsWithdrawalClaimCapture.nameOfBank == 'Ithala Bank' ? false : true}
                required
              />
              //TODO: We are waiting on the API call for the branchCode dropdown this will need to be implemented then 
              // <div className="align-communication-method-withdrawal col-md-12 dropdown-phone-padding">
              //   <label className='align-province-label mt-0'> Branch code </label>
              //   <Neo.AutoCompleteDropDown className='mt-0 mb-1'
              //     bind={item.meta.branchId}
              //     items={this.branchCode}
              //     onBlur={() => this.props.viewModel.saveSavingsClaimData()}
              //     placeholder="Please enter option" />
              // </div>
            }
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text change-color'
              label={<span>Name of bank <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.nameOfBank}
              hidden={this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes || this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.NotSelected}
              placeholder="Please enter bank name"
              required
              onBlur={() => this.props.viewModel.saveSavingsClaimData()} />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text right-side change-color'
              label={<span>Name of branch <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.nameOfBranch}
              hidden={this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes || this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.NotSelected}
              placeholder="Please enter branch name"
              required
              onBlur={() => this.props.viewModel.saveSavingsClaimData()} />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text change-color'
              label={<span>Branch code <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.branchCode}
              hidden={this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes || this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.NotSelected}
              placeholder="Please enter branch code"
              required
              onBlur={() => this.props.viewModel.saveSavingsClaimData()} />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text right-side change-color'
              label={<span>Swift code <span className='display-asterisk'>*</span></span>}
              bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.swiftCode}
              hidden={this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.Yes || this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.NotSelected}
              placeholder="Please enter swift code"
              required
              onBlur={() => this.props.viewModel.saveSavingsClaimData()}
              onChange={() => {
                if (this.props.viewModel.savingsWithdrawalClaimCapture.meta.swiftCode.value) {
                  this.props.viewModel.savingsWithdrawalClaimCapture.meta.swiftCode.value = this.props.viewModel.savingsWithdrawalClaimCapture.meta.swiftCode.value.toUpperCase();
                }
              }} />
          </Neo.FormContext>
          <br />
        </Neo.GridLayout>
      </div>
    );
  }
}