import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../MemberDashboard/MemberDashboardTypes';
import StaffDashboardSearchResultsLookup from '../Models/Lookups/StaffDashboardSearchResultsLookup';
import StaffSearchCriteria from '../Models/Lookups/StaffSearchCriteria';

export interface IStaffDashboardApiClient {

  /** 
   * Returns the StaffDashboardSearchResultsLookupListPaged .
   * @returns Paged List Of StaffDashboardSearchResults.
   */
  getStaffDashboardSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<StaffSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<StaffDashboardSearchResultsLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class StaffDashboardApiClient extends Data.ApiClientBase implements IStaffDashboardApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/StaffDashboard`);
  }

  public getStaffDashboardSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<StaffSearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<StaffDashboardSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/staff-dashboard-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}