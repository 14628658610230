import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import edit from '../../../App/assets/img/edit.png';
import bin from '../../../App/assets/img/bin.png';
import valid from '../../../App/assets/img/img-valid.png';
import warningYellow from '../../../App/assets/img/warning-yellow.png';
import group from '../../../App/assets/img/group.png';
import { FormGroupInline } from '@singularsystems/neo-react/dist/ReactComponents/_Exports';
import NoBeneficiaries from './NoBeneficiaries';
import "../../Styles/Beneficiaries.scss";
import information_icon from '../../../App/assets/img/information-small.png';
import MemberIcon from '../../../App/Views/Components/MemberIcon';
import FileUploadBeneficiaries from './FileUploadBeneficiaries';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';

interface IBeneficiariesUnApprovedDeathBenefitsProps {
  viewModel: BeneficiariesVM
}

@observer
export default class BeneficiariesUnApprovedDeathBenefits extends React.Component<IBeneficiariesUnApprovedDeathBenefitsProps> {

  constructor(props: IBeneficiariesUnApprovedDeathBenefitsProps) {
    super(props);
  }

  public render() {
    return (
      <div className='beneficiaries-approved-death-benefits'>
        <div className='adding-margin-to-the-sides'>
          <div className='row alert-margin'>
            <div className='col-md-6'>
              <div className='benefit-info-link' onClick={() => this.props.viewModel.showUnapprovedDeathBenefitInfoModal = true}>
                <img className='alert-approved-badb' src={information_icon} /> What is unapproved death benefits?
              </div>
            </div>
            {this.props.viewModel.unapprovedTotalShareValue === 100 &&
              <div className='col-md-3'>
                <div className='details-outstanding green-status-text'>
                  <img className='alert-approved-badb' src={valid} /> Up to date
                </div>
              </div>
            }
            {this.props.viewModel.unapprovedTotalShareValue !== 100 &&
              <div className='col-md-3'>
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Total share value must equal 100%
                </div>
              </div>
            }
            <div className='col-md-3'>
              <div className={this.props.viewModel.unapprovedTotalShareValue === 100 ? 'total-share-value total-share-value-border' : 'total-share-value'} >
                <span className='total-share-value-text'>Total share value: {' '}
                  <span className={this.props.viewModel.unapprovedTotalShareValue === 100 ? 'green-total-percentage' : 'yellow-total-percentage'} >
                    {this.props.viewModel.unapprovedTotalShareValue} %
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
          <NoBeneficiaries viewModel={this.props.viewModel} />
        }
        {!this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='adding-margin-to-the-sides'>
              {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
                <span>
                  <div className='show-on-web-screen'>
                    <div className='row table-heading-badb'>
                      <span className='col-3 card-heading-text '>Full name</span>
                      <span className='col-2 card-heading-text '>ID/Passport Number</span>
                      <span className='col-2 card-heading-text '>Relationship</span>
                      <span className='col-2 card-heading-text '>Share %</span>
                      <span className='col-3 card-heading-text' />
                    </div>
                    {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                      <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                        <div className='row'>
                          <span className='col-3'>
                            <MemberIcon firstName={item.firstName} lastName={item.lastName} className='icon-cu-beneficiaries-table'></MemberIcon>
                            <span className='table-card-name'>{item.firstName} {item.lastName}</span>
                          </span>
                          <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                          <span className='col-2 card-items-padding relationship'>{item.relationshipType}</span>
                          <div className='col-2'>
                            <FormGroupInline bind={item.meta.nominationPercentage}
                              onBlur={() => {
                                this.props.viewModel.updateInterimNomination(item);
                              }}
                              suppressLabel
                              className='share-input-field'
                              type='number'>
                            </FormGroupInline>
                          </div>
                          <span className='col-1 card-items-padding'>
                            <img src={edit} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => this.props.viewModel.editBeneficiary(item)}>
                              Edit
                            </Neo.Link>
                          </span>
                          <span className='col-2 card-items-padding delete'>
                            <img src={bin} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                var unApprovedBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                if (unApprovedBeneficiary) {
                                  this.props.viewModel.selectedUnapprovedBeneficiary = unApprovedBeneficiary;
                                  this.props.viewModel.showDeleteModal = true;
                                }
                              }}>
                              Remove
                            </Neo.Link>
                          </span>
                        </div>
                      </Neo.Card>
                    ))}
                  </div>
                  <div className='show-on-phone-screen'>
                    {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                      <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                        <div className='row'>
                          <span className='col-5 card-text-phone-screen'>Fullname </span><span className='col-7 padding-phone'>{item.firstName} {item.lastName}</span>
                          <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                          <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                          <span className='col-5 card-text-phone-screen share'>Share %</span>
                          <FormGroupInline bind={item.meta.nominationPercentage}
                            onBlur={() => {
                              this.props.viewModel.updateInterimNomination(item);
                            }}
                            suppressLabel
                            className='share-input-field col-7 padding-phone share'>
                          </FormGroupInline>
                          <div className='line-badb' />
                          <span className='col-4'>
                            <img src={edit} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => this.props.viewModel.editBeneficiary(item)}>
                              Edit
                            </Neo.Link>
                          </span>
                          <span className='col-5'>
                            <img src={bin} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                var unApprovedBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);
                                if (unApprovedBeneficiary) {
                                  this.props.viewModel.selectedUnapprovedBeneficiary = unApprovedBeneficiary;
                                  this.props.viewModel.showDeleteModal = true;
                                }
                              }}>
                              Remove
                            </Neo.Link>
                          </span>
                        </div>
                      </Neo.Card>
                    ))}
                  </div>
                  <div className='blue-block'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Neo.Link className='blue-block-text'
                          onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                          Select existing beneficiary
                        </Neo.Link>
                        <span className='line-break'><br></br></span>
                        <span className='or-text'> or</span>
                        <span className='line-break'><br></br></span>
                        <img src={group} className="edit-image-size padding" />
                        <Neo.Link
                          onClick={() => this.props.viewModel.initiateAddNewBeneficiary()}
                          className='add-text'>
                          Add new beneficiary
                        </Neo.Link>
                      </div>
                    </div>
                  </div>
                </span>
              }
              <FileUploadBeneficiaries viewModel={this.props.viewModel} />
              <div className='last-updated-by'>
                Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedUnApproved.Role + ' - ' + this.props.viewModel.lastModifiedUnApproved.UserName}</span>
              </div>
              {this.props.viewModel.unApprovedBeneficiaryGrouping.isActiveInterim &&
                <div className='align-changes-text'>
                  The values on this screen are incomplete and unsubmitted
                </div>
              }
            </div>
          </div>
        }
        {this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='adding-margin-to-the-sides adding-margin-bottom'>
              {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
                <span>
                  <div className='show-on-web-screen'>
                    <div className='row table-heading-badb'>
                      <span className='col-3 card-heading-text '>Full name</span>
                      <span className='col-2 card-heading-text '>ID Number</span>
                      <span className='col-2 card-heading-text '>Relationship</span>
                      <span className='col-2 card-heading-text '>Share %</span>
                      <span className='col-3 card-heading-text ' />
                    </div>
                    {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                      <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                        <div className='row'>
                          <span className='col-3'>
                            <MemberIcon firstName={item.firstName} lastName={item.lastName} className='icon-cu-beneficiaries-table'></MemberIcon>
                            <span className='table-card-name'>{item.firstName} {item.lastName} </span>
                          </span>
                          <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                          <span className='col-2 card-items-padding relationship'>{item.relationshipType}</span>
                          <span className='col-2 card-items-padding relationship'>{item.nominationPercentage}</span>
                        </div>
                      </Neo.Card>
                    ))}
                  </div>
                </span>
              }
            </div>
            <div className='show-on-phone-screen'>
              {this.props.viewModel.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).map((item) => (
                <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId}>
                  <div className='row'>
                    <span className='col-5 card-text-phone-screen'>Fullname </span><span className='col-7 padding-phone'>{item.firstName} {item.lastName}</span>
                    <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                    <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                    <span className='col-5 card-text-phone-screen'>Share %</span><span className='col-7 padding-phone'>{item.nominationPercentage}</span>
                  </div>
                </Neo.Card>
              ))}
            </div>
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedUnApproved.Role + ' - ' + this.props.viewModel.lastModifiedUnApproved.UserName}</span>
            </div>
          </div>
        }
        <ContainerFooterComponent
          showPrimaryButton={true}
          showSecondaryButton={true}
          backLinkText='Save & exit'
          primaryButtonText='Update'
          primaryButtonCallback={() => this.props.viewModel.verifyUpdatedNominations()}
          disablePrimaryButton={this.props.viewModel.unapprovedTotalShareValue === 100 ? false : true}
        />
        <Neo.Modal title='What is unapproved death benefits? '
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showUnapprovedDeathBenefitInfoModal = false }}
          closeButton={{
            text: "Watch explainer video", variant: "danger", onClick: () => window.open('https://www.youtube.com/watch?v=r5VSWHqStSo', '_blank')
          }}
          bind={this.props.viewModel.meta.showUnapprovedDeathBenefitInfoModal}>
          Some benefits payable on your death are "approved" while others are "unapproved". Some or all of your risk benefits may be unapproved. If you
          pass away and any of your unapproved risk benefits become payable, the insurer (e.g. Liberty) will pay the benefit according to your beneficiary
          nomination form.
          <br /> <br />
          If a completed beneficiary nomination form is not on record, the benefit will be payable to your estate in line with the policy
          terms and conditions. This is different to approved benefits, where the trustees may override your beneficiary nomination form when making the final payment decision.
        </Neo.Modal>
        <Neo.Modal
          bind={this.props.viewModel.meta.showDeleteModal}
          title='Remove Beneficiary'
          acceptButton={{
            text: "Yes", variant: "primary",
            onClick: () => this.props.viewModel.deleteUnapprovedBeneficiary(this.props.viewModel.selectedUnapprovedBeneficiary)
          }}
          closeButton={{ text: "No", variant: "danger", onClick: () => this.props.viewModel.showDeleteModal = false }}>
          Are you sure you want to remove this nominated beneficiary for this benefit?
          All beneficaries will remain in existing beneficiaries for future use.
        </Neo.Modal>
      </div>
    );
  }
}