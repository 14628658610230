import { Model, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import VASTemplateLookup from '../../Domain/Models/VAS/VASTemplateLookup';
import { AppService, Types } from '../Services/AppService';

@NeoModel
export default class ValueAddedServicesVM extends Views.ViewModelBase {

  public isFuneralExpanded = new Model.ObservableValue(false);
  public isFuneralTelephoneAssistanceExpanded = new Model.ObservableValue(false);
  public vasTemplate = new VASTemplateLookup();
  public showFuneralServices: boolean = false;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private vasApiClient = AppService.get(Types.Domain.ApiClients.VASQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService)) {

    super(taskRunner);
  }

  public async initialise() {
    this.showFuneralServices = this.userRoleService.hasFuneralBenefits;
    this.getLatestVASTemplate();
  }

  public setParentCardShowMoreShowLess(e: any, isExpanded: Model.ObservableValue<boolean>) {
    if (e.target.className.indexOf("card-header") >= 0) {
      isExpanded.value = !isExpanded.value;
    }
  }

  public getLatestVASTemplate = async () => {
    const vasTemplate= await this.vasApiClient.getVASTemplate();
    const response = await this.vasApiClient.getVASTemplateLookup(vasTemplate.data.vasTemplateId);
    const latestVasTemplate = response.data;
    this.vasTemplate.set(latestVasTemplate);
  }

  public async downloadVCardFile(item: VASTemplateLookup): Promise<void> {
    const tokenResponse = await this.vasApiClient.getVCardDownloadToken(item.vasTemplateId);
    const downloadResponse = await this.vasApiClient.getVCardFile(item.vasTemplateId, tokenResponse.data);
    this.renderReport('liberty|vcf|text/vcard', downloadResponse.data, 'download');
  }

  private renderReport(documentKey: string, base64String: string, displayMethod: string) {
    const fileName: string = documentKey.split("|")[0];
    const fileExtension: string = documentKey.split("|")[1];
    const extensionMimeType: string = documentKey.split("|")[2];
    const srcStringBase = `data:${extensionMimeType};base64,`;
    if (displayMethod === "download") {
      const link = document.createElement("a");
      link.href = srcStringBase + base64String;;
      link.download = `${fileName}.${fileExtension}`;
      link.click();
    }
    else if (displayMethod === "view") {
      let viewTab = window.open();
      viewTab!.document.write('<iframe width="100%" height="100%" src="' + srcStringBase + encodeURI(base64String) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%"; allowfullscreen></iframe>');
      viewTab!.document.title = `${fileName}.${fileExtension}`;
    }
  }
}