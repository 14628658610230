import { UserType } from "../Enums/UserType.enum";

export class User {
  public id: number = 0;
  public userType: UserType = UserType.Legacy;

  constructor(userType: UserType) {
    this.userType = userType;
  }
}


