import { Neo, Views } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimOverviewVM from './SavingsWithdrawalClaimOverviewVM';
import { observer } from 'mobx-react';
import SchemeInformationHeader from '../../App/Views/Components/SchemeInformationHeader';
import '../Styles/SavingsWithdrawalClaim.scss';
import icn_alert from '../../App/assets/img/alert.png';
import icn_valid from '../../App/assets/img/img-valid.png';
import ContainerFooterComponent from '../../App/Views/Components/ContainerFooterComponent';
import BlueRibbon from '../../App/Views/Components/BlueRibbon';
import { NumberUtils } from '@singularsystems/neo-core';

@observer
export default class SavingsWithdrawalClaimOverviewView extends Views.ViewBase<SavingsWithdrawalClaimOverviewVM> {

  constructor(props: unknown) {
    super("Savings withdrawals", SavingsWithdrawalClaimOverviewVM, props);
  }

  public render() {
    return (
      <div className='claims savings-withdrawal-claim-view-container'>
        <div className=' steps-progress'>
          <SchemeInformationHeader schemeHeader={'Select a related scheme.'}
            memberName={'Benji'}
            showClaimButton={false}
            extraCssClasses=''
            onSchemeSelected={async () => {
              await this.viewModel.getMemberEligibilityData();
              await this.viewModel.getMemberSavingsClaimActiveStatus();
            }} />
          <div className='savings-withdrawal-claim-view'>
            <div className='card-heading-products'>
              <p>Scheme information</p>
            </div>
            <div className='product-css m-0'>
              <div className='scheme-information-cards-container m-3'>
                <div className='row'>
                  <div className='col-12 col-sm-4'>
                    <div className='scheme-information-cards card-one'>
                      <div className='scheme-information-heading'>
                        Savings component current balance
                      </div>
                      <div className='scheme-information-description '>
                        {NumberUtils.format(this.viewModel.eligibilityData.sofSavingsPot, "R # ##0.00")}
                      </div>
                      <div className='scheme-information-description'>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-4 mt-3 mt-sm-0'>
                    <div className='scheme-information-cards card-two'>
                      <div className='scheme-information-heading'>
                        Last savings withdrawal
                      </div>
                      <div className='scheme-information-description'>
                        {this.viewModel.eligibilityData.claimDateToBeShown}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-4 mt-3 mt-sm-0'>
                    <div className='scheme-information-cards card-three'>
                      <div className='scheme-information-heading'>
                        Savings component accessibility
                      </div>
                      {this.viewModel.eligibilityData.isEligible &&
                        <div className='scheme-information-description green'>
                          <img src={icn_valid} /> &nbsp; Accessible
                        </div>
                      }
                      {!this.viewModel.eligibilityData.isEligible &&
                        <div className='scheme-information-description yellow'>
                          <img src={icn_alert} /> &nbsp; Not accessible
                        </div>
                      }
                      <div className='scheme-information-description eligibility-reason'>
                        {this.viewModel.eligibilityData.eligibilityReason}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className='card-margin m-3'>
              <div className='savings-withdrawal-claim-view-heading'>
                How does a savings withdrawal work?
              </div>
              <div className='mt-4'>
                <BlueRibbon
                  width='100%'
                  text='You will only be allowed to proceed to the Online Claims portal to submit a savings withdrawal if you meet both requirements below'
                />
              </div>
              {this.viewModel.savingsWithdrawalClaimTemplate.savingsWithdrawalClaimTemplateCards.map((item) => (
                item.order < 2 &&
                <div className='card-container'>
                  <div className='col-12'>
                    <Neo.Card key={item.savingsWithdrawalClaimTemplateCardId}
                      title={
                        <div className='align-title'>
                          <img className='accessible-image' src={icn_valid} />
                          {item.title}
                        </div>}
                      isExpanded={item.isExpanded}
                      className='withdrawal-claims-cards'>
                      <div className='col-md-12'>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </Neo.Card >
                  </div>
                </div>
              ))}

              {this.viewModel.savingsWithdrawalClaimTemplate.savingsWithdrawalClaimTemplateCards.map((item) => (
                item.order > 1 && item.order < 3 &&
                <div className='card-container'>
                  <div className='col-12'>
                    <Neo.Card key={item.savingsWithdrawalClaimTemplateCardId}
                      title={
                        <div className='align-title'>
                          {this.viewModel.eligibilityData.sofSavingsPot > this.viewModel.minimumSofSavingsPotAmount &&
                            <img className='accessible-image' src={icn_valid} />
                          }
                          {this.viewModel.eligibilityData.sofSavingsPot < this.viewModel.minimumSofSavingsPotAmount &&
                            <img className='accessible-image' src={icn_alert} />
                          }
                          {item.title}
                        </div>}
                      isExpanded={item.isExpanded}
                      className='withdrawal-claims-cards'>
                      <div className='col-md-12'>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </Neo.Card >
                  </div>
                </div>
              ))}
              <div className='start-claim-button'>
                {/* TODO: I have not tested this as there is no member with multiple schemes that are eligible */}
                {this.viewModel.savingsWithdrawalClaimCapture.isActive &&
                  <Neo.Button text="Continue a savings withdrawal claim"
                    onClick={() => this.viewModel.navigateToActiveSavingsClaim()}
                    disabled={!this.viewModel.eligibilityData.isEligible}
                    variant='primary'
                  />
                }
                {!this.viewModel.savingsWithdrawalClaimCapture.isActive &&
                  <Neo.Button text="Start a savings withdrawal claim"
                    onClick={() => this.viewModel.navigateToSavingsClaim()}
                    disabled={!this.viewModel.eligibilityData.isEligible}
                    variant='primary'
                  />
                }
              </div>
              <div className='savings-withdrawal-claim-view-heading'>
                Additional information on savings withdrawals
              </div>
              {this.viewModel.savingsWithdrawalClaimTemplate.savingsWithdrawalClaimTemplateCards.map((item) => (
                item.order > 2 &&
                <div className='card-container'>
                  <div className='col-12'>
                    <Neo.Card key={item.savingsWithdrawalClaimTemplateCardId}
                      title={
                        <div className='align-title'>
                          <Neo.Button className="fa-2x cards-img-vas" variant='link' icon={item.icon} />
                          {item.title}
                        </div>}
                      isExpanded={item.isExpanded}
                      className='withdrawal-claims-cards'>
                      <div className='col-md-12'>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </Neo.Card >
                  </div>
                </div>
              ))}
            </div>
            <ContainerFooterComponent
              backLinkText='Back to dashboard'
              backLinkCallback={() => this.viewModel.navigateToDashboard()}
            />
          </div>
        </div>
      </div >
    );
  }
}