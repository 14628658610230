import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ProfileVM from './ProfileVM';
import { observer } from 'mobx-react';
import '../../Styles/Components/Profile.scss';
import PersonContactDetailsComponent from '../../../Party/Components/Profile/PersonContactDetailsComponent';
import ContainerFooterComponent from '../Components/ContainerFooterComponent';
import BlueRibbon from '../Components/BlueRibbon';

@observer
export default class ProfileView extends Views.ViewBase<ProfileVM> {

  constructor(props: unknown) {
    super("My profile", ProfileVM, props);
  }

  public render() {
    return (
      <div className='member-profile-container'>
        <div className='member-profile-card'>
          <Neo.Modal
            title='Profile health'
            bind={this.viewModel.meta.showProfileHealthModal}
            acceptButton={{ text: "Okay", variant: "success" }}
            showCancelButton={false}
            closeButton={false}
            className='profile-modal'
          >
            <div className='modal-content'>
              <p className='modal-text'>  For your profile health and quicker processing of claims,
                please ensure that your details with us are up to date.
                You can check the box next to the information if the listed info is correct as you input your information or edit.</p>
            </div>
          </Neo.Modal>
          <Neo.Modal
            title='Updated successfully!'
            bind={this.viewModel.personContactDetailsComponentVM.meta.showUpdateModal}
            closeButton={false}
            showCancelButton={false}
            acceptButton={{ text: "Back to dashboard", variant: "success" }}
            className='profile-modal'
            onClose={() => this.viewModel.navigateToDashboard()}
          >
            <div className='modal-content'>
              <h1 className='modal-text'>Your profile information has been updated successfully.</h1>
            </div>
          </Neo.Modal>
          <Neo.Modal
            bind={this.viewModel.meta.showCancelModal}
            title='Are you sure you want to cancel?'
            acceptButton={{ text: "Continue", variant: "primary", onClick: () => { this.viewModel.onCancelClicked() } }}
            closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showCancelModal = false }}
          >
            <p>Any information that you have updated will be cleared</p>
          </Neo.Modal>
          <div className='row'>
            <div className='align-profile-info'>
              <div className='col-md-6 col-12'>
                <p className='card-heading-profile'>Member profile information</p>
              </div>
              {(this.viewModel.getMemberProfileCompleteness() <= 90) &&
                <div className='col-md-6 col-12 align-alert-info'>
                  <i><img src={this.viewModel.alertImageDisplay} /></i>
                  <p className='alert-text'>{this.viewModel.popUpText}</p>
                </div>
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='divider-profile-header' />
            </div>
          </div>
          <div className='form-container'>
            <BlueRibbon width='100%' text="If you would like to edit your identification information please contact your employer to make the changes." />
            <Neo.GridLayout>
              <Neo.FormGroup className='top-section' href='Title' bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.title} readOnly isDisabled />
              <Neo.FormGroup className='top-section' href='Surname' bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.lastName} readOnly isDisabled />
              <Neo.FormGroup className='top-section' href='Name' bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.firstName} readOnly isDisabled />
              <Neo.FormGroup className='top-section' href="SAIdOrPassportNumber" bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.nationalIdNumber} readOnly isDisabled />
              <Neo.FormGroup className='top-section' href='DateOfBirth' bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.birthDate} readOnly isDisabled />
              <Neo.FormGroup className='top-section' href='CountryOfIssue' bind={this.viewModel.personContactDetailsComponentVM.personContactDetailsLookup.meta.passportCountryOfIssue} readOnly isDisabled />
            </Neo.GridLayout>
            <div className='divider-profile-content' />
          </div>
          <div className='form-container-second'>
            <PersonContactDetailsComponent viewModel={this.viewModel.personContactDetailsComponentVM} />
          </div>
          <ContainerFooterComponent
            primaryButtonCallback={async () => this.viewModel.personContactDetailsComponentVM.updateAllDetails()}
            secondaryButtonCallback={() => { this.viewModel.showCancelModal = true }}
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonText='Update'
          />
        </div>
        <div className='version-number'> Version Number: {this.viewModel.versionNumber}</div>
      </div>
    );
  }
}