import React from 'react';
import { observer } from 'mobx-react';
import { AppService, Types } from '../../Services/AppService';
import { Neo } from '@singularsystems/neo-react';

interface IContainerFooterComponent {
  /** Text for the back link defaults to "Back to dashboard"  */
  backLinkText?: string;
  /** Text for the secondary button defaults to "Cancel"  */
  secondaryButtonText?: string;
  /** Text for the primary defaults to "Submit" */
  primaryButtonText?: string;
  /** Callback function for the back link  */
  backLinkCallback?: (event?: any, data?: any) => void;
  /** Callback function for the primary button  */
  primaryButtonCallback?: (event?: any, data?: any) => void;
  /** Callback function for the secondary button  */
  secondaryButtonCallback?: (event?: any, data?: any) => void;
  /** Display the primary button?  */
  showPrimaryButton?: boolean;
  /** Display the secondary button?  */
  showSecondaryButton?: boolean;
  /** Disable the primary button? */
  disablePrimaryButton?: boolean;
  /** Makes the primary button look/appear to be disabled */
  looksDisablePrimaryButton?: boolean;
  /** Disable the secondary button? */
  disableSecondaryButton?: boolean;
  /** Display the back link? */
  hideBackLink?: boolean;
}

@observer
export default class ContainerFooterComponent extends React.Component<IContainerFooterComponent> {
  private taskRunner = AppService.get(Types.Neo.TaskRunner);
  private userRoleService = AppService.get(Types.App.Services.UserRoleService);
  private secondaryButtonText = this.props.secondaryButtonText || 'Cancel';
  private primaryButtonText = this.props.primaryButtonText || 'Submit';
  private showSecondaryButton = this.props.showSecondaryButton || false;
  private showPrimaryButton = this.props.showPrimaryButton || false;

  private backButtonCallback = this.props.backLinkCallback || (() => this.userRoleService.navigateToDashboard());
  private primaryButtonCallback = this.props.primaryButtonCallback || (() => { });
  private secondaryButtonCallBack = this.props.secondaryButtonCallback || (() => { });

  constructor(props: IContainerFooterComponent) {
    super(props);
  }

  public render() {
    return (
      <div className='container-footer-component'>
        <hr />
        <div className='row'>
          <div className='link-container col-md-6'>
            {!this.props.hideBackLink &&
              <Neo.Link
                className='back-link'
                onClick={() => this.backButtonCallback()}>
                {this.props.backLinkText ?? 'Back to dashboard'}
              </Neo.Link>
            }
          </div>
          <div className='btn-container col-md-6'>
            {this.showSecondaryButton &&
              <Neo.Button
                variant='secondary'
                className='footer-button'
                onClick={() => this.secondaryButtonCallBack()}
                disabled={this.props.disableSecondaryButton ?? false}
              >
                {this.secondaryButtonText}
              </Neo.Button>
            }
            {this.showPrimaryButton &&
              <Neo.Button
                variant='primary'
                className={`footer-button ${this.props.looksDisablePrimaryButton ? 'looks-disabled' : ''}`}
                onClick={() => this.primaryButtonCallback()}
                disabled={this.props.disablePrimaryButton ?? false}
              >
                {this.primaryButtonText}
              </Neo.Button>
            }
          </div>
          <div className='link-container-mobile col-md-6'>
            <Neo.Link
              className='back-link-mobile'
              onClick={() => this.backButtonCallback()}>
              {this.props.backLinkText ?? 'Back to dashboard'}
            </Neo.Link>
          </div>
        </div>
      </div>
    );
  }
}