import React from 'react';
import { observer } from 'mobx-react';
import icn_pdf from '../../../../App/assets/img/icon-pdf.png';

interface IInvestmentsTableCardProps {
  investmentName: string | null,
  value: number | null,
  url: string | null,
  children?: React.ReactNode;
}

@observer
export default class InvestmentsTableCard extends React.Component<IInvestmentsTableCardProps> {

  constructor(props: IInvestmentsTableCardProps) {
    super(props);
  }

  public render() {
    return (

      <div className='table-row-cards-investment'>
        <div className='row'>
          <div className='col-7'>
            <div className='table-column-investment-name'>
              {this.props.investmentName}
            </div>
          </div>
          <div className='col-2'>
            <div className='table-column-investment-amount value-padding-investment'>
              {this.props.value?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
            </div>
          </div>
          <div className='col-3'>
            <div className='table-column-investment-sheet'>
              {this.props.url ? (
                <a target="_blank" className='set-link-css' href={this.props.url}>
                  <img src={icn_pdf} className="pdf-icon-product" />
                  View latest
                </a>
              ) : (
                <span>
                  <a target="_blank" className='set-link-css' href={'https://www.liberty.co.za/fund-fact-sheets'}>
                    <img src={icn_pdf} className="pdf-icon-product" />
                    View latest
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}