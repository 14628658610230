import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import SavingsWithdrawalClaimTemplate from '../../Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplate';

export interface ISavingsWithdrawalClaimCommandApiClient {

    insertSavingsWithdrawalClaimTemplate(savingsWithdrawalClaimTemplate: Model.PartialPlainObject<SavingsWithdrawalClaimTemplate>): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplate>>;

    /** 
     * Updates a SavingsWithdrawalClaim template.
     * @returns The updated template.
     */
    updateSavingsWithdrawalClaimTemplate(savingsWithdrawalClaimTemplate: Model.PartialPlainObject<SavingsWithdrawalClaimTemplate>): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplate>>;

    /** 
     * Deletes a SavingsWithdrawalClaim template.
     * @param templateId The SavingsWithdrawalClaim template to delete.
     * @returns A list of SavingsWithdrawalClaim templates.
     */
    deleteSavingsWithdrawalClaimTemplate(templateId: number): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class SavingsWithdrawalClaimCommandApiClient extends Data.ApiClientBase implements ISavingsWithdrawalClaimCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/savings-withdrawal-claim`);
    }

    public insertSavingsWithdrawalClaimTemplate(savingsWithdrawalClaimTemplate: Model.PartialPlainObject<SavingsWithdrawalClaimTemplate>): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplate>> {
        return this.axios.post(`${this.apiPath}/savings-claim-template`, savingsWithdrawalClaimTemplate);
    }

    public updateSavingsWithdrawalClaimTemplate(savingsWithdrawalClaimTemplate: Model.PartialPlainObject<SavingsWithdrawalClaimTemplate>): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplate>> {
        return this.axios.put(`${this.apiPath}/savings-claim-template`, savingsWithdrawalClaimTemplate);
    }

    public deleteSavingsWithdrawalClaimTemplate(templateId: number): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/savings-claim-template/${templateId}`);
    }

    // Client only properties / methods
}