import { EnumHelper, List, NeoModel, NotifyUtils, StringUtils, Validation } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../App/Services/AppService';
import { UserType } from '../../../Enums/UserType.enum';
import { User } from '../../../UserModel/User';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';
import { BeneficiaryGroupingType } from '../../Models/LookUps/BeneficiaryGroupingType.enum';
import MemberBeneficiaryGroupingDataLookup from '../../Models/LookUps/MemberNominations/MemberBeneficiaryGroupingDataLookup';
import MemberNominationDataLookup from '../../Models/LookUps/MemberNominations/MemberNominationDataLookup';
import UpdateMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateMemberNominationCommand';
import UpdateMemberBeneficiariesCommand from '../../Models/LookUps/MemberBeneficiaries/UpdateMemberBeneficiariesCommand';
import Country from '../../../MasterData/Models/Country';
import { NotificationDuration } from '../../../App/Models/NotificationDuration';
import UpdateInterimMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateInterimMemberNominationCommand';

@NeoModel
export default class BeneficiariesVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private memberNominationsService = AppService.get(Types.Parties.ApiClients.MemberNominationsCommandApiClient),
    private memberBeneficiariesQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberBeneficiariesQueryApiClient),
    private memberNominationsQueryApiClient = AppService.get(Types.Parties.ApiClients.MemberNominationsQueryApiClient),
    private memberBeneficiariesCommandApiClient = AppService.get(Types.Parties.ApiClients.MemberBeneficiariesCommandApiClient),
    private authService = AppService.get(Types.App.Services.AuthenticationService),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService)) {
    super(taskRunner);
  }

  public allBeneficiaries: boolean = false;
  public tabValueId: number = 1;
  public approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup;
  public approvedOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public unApprovedOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public funeralOriginalBeneficiaryGrouping = new List(UpdateInterimMemberNominationCommand);
  public nominationsDataArray = new List(UpdateInterimMemberNominationCommand);

  public knownBeneficiaries = new List(MemberBeneficiaryLookup);
  public trustedContactPerson: MemberBeneficiaryLookup = new MemberBeneficiaryLookup();
  public matchingBeneficiaries = new List(MemberBeneficiaryLookup);

  public newBeneficiary: MemberBeneficiaryLookup = new MemberBeneficiaryLookup();

  public selectedBeneficiaryToEdit = new MemberBeneficiaryLookup();
  public selectedApprovedBeneficiary = new MemberBeneficiaryLookup();
  public selectedUnapprovedBeneficiary = new MemberBeneficiaryLookup();
  public selectedFuneralBenefitBeneficiary = new MemberBeneficiaryLookup();
  public showApprovedAddedAlert: boolean = false;
  public showUserAddedAsThirdParty: boolean = false;
  public showNotHundredShareAlert: boolean = false;
  public recievedNominatedForm: boolean = false;
  public showNeedsNominatedFromAlert: boolean = false;

  public showBeneficiariesApprovedDeathBenefit: boolean = false;
  public showBeneficiariesUnApprovedDeathBenefit: boolean = false;
  public showBeneficiariesFuneralBenefits: boolean = false;
  public showAddApprovedBeneficiariesForm: boolean = false;
  public showAddUnApprovedBeneficiariesForm: boolean = false;
  public showAddFuneralDeathBenefitForm: boolean = false;
  public showBeneficiariesAddKnown: boolean = false;
  public showExceedShareLimitModal: boolean = false;

  public showBeneficiariesEdit: boolean = false;

  public showEditBeneficiaryModal: boolean = false;
  public showSelectExistingBeneficiariesModal: boolean = false;
  public showKnowBeneficiariesModalForTrustedPerson: boolean = false;
  public showMatchingKnownBeneficiariesModal: boolean = false;
  public showAddBeneficiariesModal: boolean = false;
  public showFAEmployerStaffModal: boolean = true;
  public showDeleteModal: boolean = false;
  public showFuneralDeletedModal: boolean = false;
  public countryValueId: number = 1;
  public alertInfoHidden: boolean = false;
  public cellNumber: string = "83 901 3333";
  public countryId: number | null = 1;
  public country: string = '+27';
  public memberNominationsForAllBenefits = new List(MemberBeneficiaryGroupingDataLookup);

  public tabValue: string = 'Approved Death Benefits';
  public showApprovedDeathBenefitInfoModal: boolean = false;
  public showUnapprovedDeathBenefitInfoModal: boolean = false;
  public showFuneralBenefitInfoModal: boolean = false;
  public showTrustedContactPersonModal: boolean = false;
  public showConfirmSaveChangesModal: boolean = false;
  public showDashboardNavigationModal: boolean = false;
  public showConfirmSwitchSchemeModal: boolean = false;

  public exceedShareLimit: boolean = false;
  public memberName: string = '';
  public dontShowMessageAgain: boolean = false;
  public currentUser = new User(UserType.Member);
  public viewOnlyBeneficiary: boolean = false;

  public lastModifiedApproved = { Role: '', UserName: '' };
  public lastModifiedUnApproved = { Role: '', UserName: '' };
  public lastModifiedFuneral = { Role: '', UserName: '' };
  public lastModifiedTrustedPerson = { Role: '', UserName: '' };
  public countries: Country[] = [];
  public showAuthorisationFormRequiredModal: boolean = false;
  private hasInitialised: boolean = false;

  public get approvedTotalShareValue() {
    let total: number = 0;
    this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(c => {
      total += c.nominationPercentage;
    });
    return total;
  }

  public get unapprovedTotalShareValue() {
    let total: number = 0;
    this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(c => {
      total += c.nominationPercentage;
    });
    return total;
  }

  public async initialise() {
    this.currentUser = this.userRoleService.loggedInUser;
    this.viewOnlyBeneficiary = this.userRoleService.viewOnlyBeneficiary;
    this.countries = this.masterDataService.countries;
    this.getMemberBeneficiariesLookups();
    this.getMemberNominationsLookups();
    this.hasInitialised = true;
  }

  public async getMemberBeneficiariesLookups() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      var memberBeneficiaries = await this.taskRunner.waitFor(this.memberBeneficiariesQueryApiClient.getMemberBeneficiariesLookups(this.userMemberSchemeService.currentSchemeSelected.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId));

      if (memberBeneficiaries.data.length != 0) {
        this.knownBeneficiaries.set(memberBeneficiaries.data);
        let trustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
        if (trustedPerson) {
          this.trustedContactPerson.mapFrom(trustedPerson);
          this.lastModifiedTrustedPerson.Role = trustedPerson.lastModifiedByRole;
          this.lastModifiedTrustedPerson.UserName = trustedPerson.lastModifiedUser;
        } else {
          this.trustedContactPerson.trustedPerson = true;
          this.trustedContactPerson.markOld();
        }
      }
      else {
        this.knownBeneficiaries.set([]);
      }
      this.trustedContactPerson.markOld();
    } else {
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Beneficiaries", "There was an issue fetching beneficiaries.", "warning", NotificationDuration.Long);
    }
  }

  public async confirmSwitchScheme() {
    if (this.hasInitialised && this.hasUnsubmittedChanges() && this.userMemberSchemeService.memberSchemes.length > 1) {
      this.showConfirmSwitchSchemeModal = true;
    }
    else {
      await this.taskRunner.waitFor(this.getMemberNominationsLookups());
    }
  }

  public async getMemberNominationsLookups() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      var memberNominations = await this.taskRunner.waitFor(this.memberNominationsQueryApiClient.getMemberNominationLookups(this.userMemberSchemeService.currentSchemeSelected.memberId, this.userMemberSchemeService.currentSchemeSelected.sourceId));

      if (memberNominations.data.length != 0) {
        this.memberNominationsForAllBenefits.set(memberNominations.data);
      }
      else {
        this.memberNominationsForAllBenefits.set([]);
      }

      if (this.memberNominationsForAllBenefits.length != 0) {
        let approvedBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.ApprovedBenefits);
        let unApprovedBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.UnapprovedBenefits);
        let funeralBeneficiaryGrouping = this.memberNominationsForAllBenefits.find(x => x.beneficiaryGroupingCode == BeneficiaryGroupingType.FuneralBenefits);

        if (approvedBeneficiaryGrouping) {
          this.approvedBeneficiaryGrouping = approvedBeneficiaryGrouping;

          let lastModified = approvedBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedApproved.Role = lastModified.lastModifiedByRole;
            this.lastModifiedApproved.UserName = lastModified.lastModifiedUser;
          }
        } else {
          this.approvedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.ApprovedBenefits;
          this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name
          this.approvedBeneficiaryGrouping.nominationData.set([]);
        }

        if (unApprovedBeneficiaryGrouping) {
          this.unApprovedBeneficiaryGrouping = unApprovedBeneficiaryGrouping;

          let lastModified = this.unApprovedBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedUnApproved.Role = lastModified.lastModifiedByRole;
            this.lastModifiedUnApproved.UserName = lastModified.lastModifiedUser;
          }
        } else {
          this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
          this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name
          this.unApprovedBeneficiaryGrouping.nominationData.set([]);
        }

        if (funeralBeneficiaryGrouping) {
          this.funeralBeneficiaryGrouping = funeralBeneficiaryGrouping;

          let lastModified = this.funeralBeneficiaryGrouping.nominationData.find(nomination => !StringUtils.isNullOrWhitespace(nomination.lastModifiedByRole) ||
            !StringUtils.isNullOrWhitespace(nomination.lastModifiedUser));
          if (lastModified) {
            this.lastModifiedFuneral.Role = lastModified.lastModifiedByRole;
            this.lastModifiedFuneral.UserName = lastModified.lastModifiedUser;
          }

          this.sortFuneralBeneficiaries();
          // Assign sequence numbers to nominationData
          this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach((nomination, index) => {
            nomination.sequence = index + 1;
          });
        } else {
          this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
          this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name
          this.funeralBeneficiaryGrouping.nominationData.set([]);
        }

      }
      else {
        this.approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;

        this.unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
        this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name

        this.funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
        this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
        this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name
      }
    } else {
      this.memberNominationsForAllBenefits.set([]);
      this.approvedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.approvedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;

      this.unApprovedBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.unApprovedBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.UnapprovedBenefits;
      this.unApprovedBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name

      this.funeralBeneficiaryGrouping = new MemberBeneficiaryGroupingDataLookup();
      this.funeralBeneficiaryGrouping.beneficiaryGroupingCode = BeneficiaryGroupingType.FuneralBenefits;
      this.funeralBeneficiaryGrouping.beneficiaryGroupingDescription = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name
      NotifyUtils.addOrUpdate("missingInfo", "Issue Fetching Beneficiaries", "There was an issue fetching beneficiaries.", "warning", NotificationDuration.Long);
    }
    this.approvedBeneficiaryGrouping.markOld();
    this.unApprovedBeneficiaryGrouping.markOld();
    this.funeralBeneficiaryGrouping.markOld();
    this.trustedContactPerson.markOld();
  }

  public selectAllBtnCssClassName() {
    if (this.tabValue == "Funeral Benefits" && this.knownBeneficiaries.length > 2) {
      return 'checkbox-disabled';
    }
    return '';
  }

  public selectAllBeneficiaries() {
    if (this.tabValue == "Funeral Benefits" && this.knownBeneficiaries.length > 2) {
      NotifyUtils.addWarning("Too Many Beneficiaries", "You may nominate only 2 beneficiaries for funeral Benefits", NotificationDuration.Long);
    } else {
      if (this.allBeneficiaries == false) {
        this.allBeneficiaries = true;
      } else {
        this.allBeneficiaries = false;
      }
      this.knownBeneficiaries.forEach(beneficiary => {
        if (beneficiary.canChangeSelection) {
          beneficiary.isSelected = this.allBeneficiaries
        }
      });
    }
  }

  public selectExistingBeneficiary() {
    if (this.tabValue == "Approved Death Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Unapproved Death Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Funeral Benefits") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showSelectExistingBeneficiariesModal = true;
    } else if (this.tabValue == "Trusted Contact Person") {
      this.knownBeneficiaries.forEach(beneficiary => {
        if (this.trustedContactPerson.beneficiaryId == beneficiary.beneficiaryId) {
          beneficiary.isSelected = true;
          beneficiary.canChangeSelection = false;
        } else {
          beneficiary.isSelected = false;
          beneficiary.canChangeSelection = true;
        }
      });
      this.allBeneficiaries = this.knownBeneficiaries.length == this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length;
      this.showKnowBeneficiariesModalForTrustedPerson = true;
    }
  }

  public canSelectExistingBeneficiary(existingBeneficiary: MemberBeneficiaryLookup) {
    if (this.tabValue == "Funeral Benefits") {
      return existingBeneficiary.isSelected || this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1;
    } else if (this.tabValue == "Trusted Contact Person") {
      return existingBeneficiary.isSelected || this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length < 1;
    } else {
      return existingBeneficiary.canChangeSelection;
    }
  }

  public canSelectMatchingBeneficiary(existingBeneficiary: MemberBeneficiaryLookup) {
    return existingBeneficiary.isSelected || this.matchingBeneficiaries.filter(beneficiary => beneficiary.isSelected).length < 1;
  }

  public async addSelectedExistingBeneficiaries() {
    if (this.tabValue == "Approved Death Benefits") {
      this.knownBeneficiaries.forEach(async beneficiary => {
        if (beneficiary.isSelected) {
          if (!this.approvedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
            if (!this.approvedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              let newApprovedNomination = this.approvedBeneficiaryGrouping.nominationData.addNew();
              newApprovedNomination.mapFrom(beneficiary);
              newApprovedNomination.isDeleted = false;
              newApprovedNomination.relationshipType = beneficiary.relationship;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.approvedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newApprovedNomination));
            }
            else {
              let deletedNomination = this.approvedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
              let newApprovedNomination = this.approvedBeneficiaryGrouping.nominationData.addNew();
              if (deletedNomination) {
                newApprovedNomination.mapFrom(deletedNomination);
                this.approvedBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
              }
              newApprovedNomination.nominationPercentage = 0;
              newApprovedNomination.isDeleted = false;
              newApprovedNomination.expiryDate = null;
              this.approvedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newApprovedNomination));
            }
          }
        } else {
          var nominationToRemove = this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
          if (nominationToRemove) {
            this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
          }
        }
      });
    } else if (this.tabValue == "Unapproved Death Benefits") {
      this.knownBeneficiaries.forEach(async beneficiary => {
        if (beneficiary.isSelected) {
          if (!this.unApprovedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
            if (!this.unApprovedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              let newUnApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.addNew();
              newUnApprovedNomination.mapFrom(beneficiary);
              newUnApprovedNomination.isDeleted = false;
              newUnApprovedNomination.relationshipType = beneficiary.relationship;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newUnApprovedNomination));
            }
            else {
              let deletedNomination = this.unApprovedBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
              let newunApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.addNew();
              if (deletedNomination) {
                newunApprovedNomination.mapFrom(deletedNomination);
                this.unApprovedBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
              }
              newunApprovedNomination.nominationPercentage = 0;
              newunApprovedNomination.isDeleted = false;
              newunApprovedNomination.expiryDate = null;
              NotifyUtils.addSuccess("Existing beneficiary added", "Your beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);
              this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
              await this.taskRunner.run(async () => this.addInterimNomination(newunApprovedNomination));
            }
          }
        } else {
          var nominationToRemove = this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
          if (nominationToRemove) {
            this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
          }
        }
      });
    } else if (this.tabValue == "Funeral Benefits") {
      if (this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 2) {
        this.knownBeneficiaries.forEach(async beneficiary => {
          if (beneficiary.isSelected) {
            if (!this.funeralBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
              if (!this.funeralBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId)) {
                let newFuneralNomination = this.funeralBeneficiaryGrouping.nominationData.addNew();
                newFuneralNomination.mapFrom(beneficiary);
                newFuneralNomination.isDeleted = false;
                newFuneralNomination.relationshipType = beneficiary.relationship;
                newFuneralNomination.sequence = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length;
                NotifyUtils.addSuccess("Existing beneficiary added", "The beneficiary has been added.", NotificationDuration.Long);
                this.funeralBeneficiaryGrouping.isActiveInterim = true;
                await this.taskRunner.run(async () => this.addInterimNomination(newFuneralNomination));
              }
              else {
                let deletedNomination = this.funeralBeneficiaryGrouping.nominationData.deletedList.find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
                let newfuneralNomination = this.funeralBeneficiaryGrouping.nominationData.addNew();
                if (deletedNomination) {
                  newfuneralNomination.mapFrom(deletedNomination);
                  this.funeralBeneficiaryGrouping.nominationData.deletedList.remove(deletedNomination);
                }
                newfuneralNomination.nominationPercentage = 0;
                newfuneralNomination.isDeleted = false;
                newfuneralNomination.expiryDate = null;
                NotifyUtils.addSuccess("Existing beneficiary added", "The beneficiary has been added.", NotificationDuration.Long);
                this.funeralBeneficiaryGrouping.isActiveInterim = true;
                await this.taskRunner.run(async () => this.addInterimNomination(newfuneralNomination));
              }
            }
          } else {
            var nominationToRemove = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId);
            if (nominationToRemove) {
              this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).remove(nominationToRemove);
            }
          }
        });
      } else {
        NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select two beneficiaries for funeral Benefits", NotificationDuration.Long);
      }
    } else if (this.tabValue == "Trusted Contact Person") {
      if (this.knownBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1) {
        let selectedTrustedBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.isSelected);
        if (selectedTrustedBeneficiary) {
          if (!selectedTrustedBeneficiary.trustedPerson) {
            let currentTrustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
            if (currentTrustedPerson) {
              currentTrustedPerson.trustedPerson = false;
            }
          }
          selectedTrustedBeneficiary.trustedPerson = true;
          this.trustedContactPerson.mapFrom(selectedTrustedBeneficiary);
          this.trustedContactPerson.markOld();
        }
      } else {
        NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select one existing beneficiary to be your trusted contact person", NotificationDuration.Long);
      }
    }
  }

  public addSelectedMatchingBeneficiary() {
    if (this.matchingBeneficiaries.filter(beneficiary => beneficiary.isSelected).length <= 1) {
      let selectedTrustedBeneficiary = this.matchingBeneficiaries.find(beneficiary => beneficiary.isSelected);
      if (selectedTrustedBeneficiary) {
        if (!selectedTrustedBeneficiary.trustedPerson) {
          let currentTrustedPerson = this.knownBeneficiaries.find(beneficiary => beneficiary.trustedPerson);
          if (currentTrustedPerson) {
            currentTrustedPerson.trustedPerson = false;
          }
        }
        selectedTrustedBeneficiary.trustedPerson = true;
        this.trustedContactPerson.mapFrom(selectedTrustedBeneficiary);
        this.trustedContactPerson.markOld();
      }
      this.updateTrustedPerson();
    } else {
      NotifyUtils.addWarning("Too Many Beneficiaries", "You may only select one existing beneficiary to be your trusted contact person", NotificationDuration.Long);
    }
  }

  public initiateAddNewBeneficiary() {
    this.newBeneficiary = new MemberBeneficiaryLookup();
    this.showAddBeneficiariesModal = true;
  }

  public async addNewBeneficiary() {
    if (this.knownBeneficiaries.find(beneficiary =>
      (!StringUtils.isNullOrWhitespace(this.newBeneficiary.nationalIdNo) && beneficiary.nationalIdNo === this.newBeneficiary.nationalIdNo) ||
      (!StringUtils.isNullOrWhitespace(this.newBeneficiary.passportNo) && beneficiary.passportNo === this.newBeneficiary.passportNo))) {
      NotifyUtils.addWarning("Duplicate Beneficiary", "A beneficiary with matching unique details already exists.", NotificationDuration.Long);
      return;
    }

    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateMemberBeneficiariesCommand();

      updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId!.toString();
      updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

      if (this.userMemberSchemeService.currentSchemeSelected.memberNumber && this.userMemberSchemeService.currentSchemeSelected.schemeNumber) {
        updateCommand.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber;
        updateCommand.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber;
      }

      if (!this.newBeneficiary.isIDNumber) {
        this.newBeneficiary.passportCountryOfIssue = this.getCountryById(this.newBeneficiary.countryOfIssueValueId);
      }

      this.newBeneficiary.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
      this.newBeneficiary.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      updateCommand.memberBeneficiaries.push(this.newBeneficiary);

      const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

      let newBeneficiaryResult = response.data.find(beneficiary => beneficiary.message == "Inserted a new record");

      if (newBeneficiaryResult) {
        this.newBeneficiary.beneficiaryId = newBeneficiaryResult.beneficiaryId;

        if (this.newBeneficiary.nationalIdNo) {
          this.newBeneficiary.nationalIdNoOrPassportNo = this.newBeneficiary.nationalIdNo;
        } else {
          this.newBeneficiary.nationalIdNoOrPassportNo = this.newBeneficiary.passportNo ?? "";
        }
      }

      this.knownBeneficiaries.push(this.newBeneficiary);

      this.showAddBeneficiariesModal = false;
      NotifyUtils.addSuccess("New beneficiary added", "A new beneficiary has been added. You can now allocate a share to them.", NotificationDuration.Long);

      if (this.tabValue == "Approved Death Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        this.approvedBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.approvedBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      } else if (this.tabValue == "Unapproved Death Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        this.unApprovedBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      } else if (this.tabValue == "Funeral Benefits") {
        var newBeneficiary = new MemberNominationDataLookup();
        newBeneficiary.mapFrom(this.newBeneficiary);

        let knownBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.newBeneficiary.beneficiaryId);

        if (knownBeneficiary) {
          newBeneficiary.relationshipType = knownBeneficiary.relationship;
        }

        newBeneficiary.sequence = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length + 1;
        this.funeralBeneficiaryGrouping.nominationData.push(newBeneficiary);
        this.newBeneficiary = new MemberBeneficiaryLookup();

        this.funeralBeneficiaryGrouping.isActiveInterim = true;
        await this.addInterimNomination(newBeneficiary);
      }
    } else {
      NotifyUtils.addWarning("Issue Adding Beneficiary", "There was an issue trying to add a new beneficiary.", NotificationDuration.Long);
    }
  }

  public handleSelectedTrustedPerson(item: MemberBeneficiaryLookup) {
    item.isSelected = !item.isSelected;

    // Update the selected status for other items
    this.knownBeneficiaries.forEach((otherItem) => {
      if (otherItem !== item) {
        otherItem.isSelected = false;
      }
    });
  }

  public clearTrustedPersonDetails() {
    this.trustedContactPerson = new MemberBeneficiaryLookup();
    this.trustedContactPerson.trustedPerson = true;
  }

  public switchBeneficiariesAround() {
    if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1) {
      const primaryBeneficiary = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find((item) => {
        return item.sequence === 1;
      });

      const nonPrimaryBeneficiary = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find((item) => {
        return item.sequence !== 1;
      })

      primaryBeneficiary!.sequence = 2;
      nonPrimaryBeneficiary!.sequence = 1;
    }
    this.sortFuneralBeneficiaries();
    this.funeralBeneficiaryGrouping.markOld();
  }

  private sortFuneralBeneficiaries() {
    this.funeralBeneficiaryGrouping.nominationData
      .sort((item1, item2) => {
        return item1.sequence - item2.sequence;
      })
  }

  public verifyUpdatedNominations() {
    if (this.currentUser.userType === UserType.Legacy || this.currentUser.userType === UserType.Member) {
      if (this.tabValue === "Approved Death Benefits") {
        if (this.approvedTotalShareValue === 100) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("Total share value", "Please ensure that the total share value is equal to 100%.", NotificationDuration.Long);
        }
      } else if (this.tabValue == "Unapproved Death Benefits") {
        if (this.unapprovedTotalShareValue === 100) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("Total share value", "Please ensure that the total share value is equal to 100%.", NotificationDuration.Long);
        }
      } else if (this.tabValue == "Funeral Benefits") {
        if (this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 0) {
          this.updateAllocations();
        } else {
          NotifyUtils.addWarning("No Beneficiary Selected", "Please select a beneficiary.", 10);
        }
      }
    } else if (this.currentUser.userType === UserType.Staff || this.currentUser.userType === UserType.Employer || this.currentUser.userType === UserType.FA) {
      // TODO: Implement logic for staff/employers/FAs
      NotifyUtils.addWarning("Not Available", "Third Party Users may not update beneficiaries at this time", NotificationDuration.Long);
      if (this.recievedNominatedForm) {
        /* 
        this.showUserAddedAsThirdParty = true;
        this.recievedNominatedForm = false;
        */
      } else if (!this.recievedNominatedForm) {
        // this.showNeedsNominatedFromAlert = true;
      }
    }
  }

  private async updateAllocations() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      var command = new UpdateMemberNominationCommand();
      command.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId!.toString();
      command.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

      if (this.userMemberSchemeService.currentSchemeSelected.memberNumber && this.userMemberSchemeService.currentSchemeSelected.schemeNumber) {
        command.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber;
        command.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber;
      }

      if (this.tabValue === "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });
        command.memberNominations = this.approvedBeneficiaryGrouping

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedApproved.Role = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
        this.lastModifiedApproved.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      } else if (this.tabValue === "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });
        command.memberNominations = this.unApprovedBeneficiaryGrouping;

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedUnApproved.Role = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
        this.lastModifiedUnApproved.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      } else if (this.tabValue === "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.nominationData.forEach(nomination => {
          nomination.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
          nomination.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
        });

        command.memberNominations = this.funeralBeneficiaryGrouping;

        command.memberNominations.nominationData.forEach(nomination => {
          if (nomination.isDeleted) {
            nomination.expiryDate = new Date();
          }
        });

        const response = await this.taskRunner.run(async () => this.memberNominationsService.postMemberNominations(command.toJSObject({ includeClean: true })));

        let nominationsResponse = response.data;

        this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach(nomination => {
          if (StringUtils.isNullOrWhitespace(nomination.nominationId)) {
            let newNomination = nominationsResponse.find(nominationResponse => nominationResponse.beneficiaryId == nomination.beneficiaryId);
            if (newNomination) {
              nomination.nominationId = newNomination.nominationId;
            }
          }
          nomination.markOld();
        });

        this.lastModifiedFuneral.Role = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
        this.lastModifiedFuneral.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        //Delete InterimMemberNomination
        await this.deleteInterimNomination();
      }

      NotifyUtils.addSuccess("Beneficiary allocation", "The total share value is now fully allocated between your below beneficiaries.", NotificationDuration.Long);
    } else {
      NotifyUtils.addWarning("Issue Updating Nominations", "There was an issue updating your nominations.", NotificationDuration.Long);
    }
  }


  public async deleteApprovedBeneficiary(item: MemberBeneficiaryLookup) {
    var approvedBeneficiaryItem = this.approvedBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (approvedBeneficiaryItem) {
      approvedBeneficiaryItem.isDeleted = true;
      approvedBeneficiaryItem.expiryDate = new Date();
      approvedBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
      approvedBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      this.approvedBeneficiaryGrouping.nominationData.remove(approvedBeneficiaryItem);
      await this.deleteInterimBeneficiaryNomination(approvedBeneficiaryItem);
      this.showDeleteModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public async deleteUnapprovedBeneficiary(item: MemberBeneficiaryLookup) {
    var unApprovedBeneficiaryItem = this.unApprovedBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (unApprovedBeneficiaryItem) {
      unApprovedBeneficiaryItem.isDeleted = true;
      unApprovedBeneficiaryItem.expiryDate = new Date();
      unApprovedBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
      unApprovedBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      this.unApprovedBeneficiaryGrouping.nominationData.remove(unApprovedBeneficiaryItem);
      await this.deleteInterimBeneficiaryNomination(unApprovedBeneficiaryItem);
      this.showDeleteModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public async deleteFuneralBeneficiary(item: MemberBeneficiaryLookup) {
    var funeralBeneficiaryItem = this.funeralBeneficiaryGrouping.nominationData.find(x => x.beneficiaryId == item.beneficiaryId);
    if (funeralBeneficiaryItem) {
      funeralBeneficiaryItem.isDeleted = true;
      funeralBeneficiaryItem.expiryDate = new Date();
      funeralBeneficiaryItem.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
      funeralBeneficiaryItem.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
      await this.deleteInterimBeneficiaryNomination(funeralBeneficiaryItem);
      this.funeralBeneficiaryGrouping.nominationData.remove(funeralBeneficiaryItem);

      // Reset sequence numbers
      this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).forEach((nomination, index) => {
        nomination.sequence = index + 1;
      });

      this.showFuneralDeletedModal = false;
      NotifyUtils.addPrimary("Beneficiary removed", "You have removed a beneficiary.", NotificationDuration.Long);
    }
  }

  public editBeneficiary(item: MemberNominationDataLookup) {
    let knownBeneficiaryToEdit = this.knownBeneficiaries.find(x => x.beneficiaryId === item.beneficiaryId);

    if (knownBeneficiaryToEdit) {
      this.selectedBeneficiaryToEdit.mapFrom(knownBeneficiaryToEdit);

      if (knownBeneficiaryToEdit.passportCountryOfIssue) {
        this.selectedBeneficiaryToEdit.countryOfIssueValueId = this.getCountryIdByName(knownBeneficiaryToEdit.passportCountryOfIssue);
      }

      this.showEditBeneficiaryModal = true;
    }
  }

  public async submitUpdatedBeneficiary() {
    if (this.selectedBeneficiaryToEdit.isValid && this.userMemberSchemeService.currentSchemeSelected.memberId &&
      !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      if (this.selectedBeneficiaryToEdit.isIDNumber) {
        this.selectedBeneficiaryToEdit.nationalIdNoOrPassportNo = this.selectedBeneficiaryToEdit.nationalIdNo ?? '';
        this.selectedBeneficiaryToEdit.passportNo = null;
      } else {
        this.selectedBeneficiaryToEdit.nationalIdNoOrPassportNo = this.selectedBeneficiaryToEdit.passportNo ?? '';
        this.selectedBeneficiaryToEdit.nationalIdNo = null;
        this.selectedBeneficiaryToEdit.passportCountryOfIssue = this.getCountryById(this.selectedBeneficiaryToEdit.countryOfIssueValueId);
      }

      let updateCommand = new UpdateMemberBeneficiariesCommand();

      updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId!.toString();
      updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

      if (this.userMemberSchemeService.currentSchemeSelected.memberNumber && this.userMemberSchemeService.currentSchemeSelected.schemeNumber) {
        updateCommand.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber;
        updateCommand.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber;
      }

      this.selectedBeneficiaryToEdit.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
      this.selectedBeneficiaryToEdit.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';

      updateCommand.memberBeneficiaries.push(this.selectedBeneficiaryToEdit);

      const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

      let updatedBeneficiary = this.knownBeneficiaries.find(beneficiary => beneficiary.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (updatedBeneficiary) {
        updatedBeneficiary.mapFrom(this.selectedBeneficiaryToEdit);
        updatedBeneficiary.relationship = this.selectedBeneficiaryToEdit.relationship;
        updatedBeneficiary.markOld();
      }

      let approvedNomination = this.approvedBeneficiaryGrouping.nominationData.find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (approvedNomination) {
        approvedNomination.mapFrom(this.selectedBeneficiaryToEdit);
        approvedNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.approvedBeneficiaryGrouping.markOld();
      }
      let unApprovedNomination = this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (unApprovedNomination) {
        unApprovedNomination.mapFrom(this.selectedBeneficiaryToEdit);
        unApprovedNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.unApprovedBeneficiaryGrouping.markOld();
      }
      let funeralNomination = this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == this.selectedBeneficiaryToEdit.beneficiaryId);
      if (funeralNomination) {
        funeralNomination.mapFrom(this.selectedBeneficiaryToEdit);
        funeralNomination.relationshipType = this.selectedBeneficiaryToEdit.relationship;

        //Unsubmitted changes modal shouldn't popup when Beneficiary details gets updated
        this.funeralBeneficiaryGrouping.markOld();
      }

      this.showEditBeneficiaryModal = false;
      this.selectedBeneficiaryToEdit = new MemberBeneficiaryLookup();
      NotifyUtils.addPrimary("Beneficiary updated", "Your beneficiary information has been updated.", NotificationDuration.Long);
    } else {
      NotifyUtils.addWarning("Issue Updating Beneficiary", "There was an issue updating the beneficiary.", NotificationDuration.Long);
    }
  }

  public trustedContactPersonHasData() {
    return !StringUtils.isNullOrWhitespace(this.trustedContactPerson.firstName) ||
      !StringUtils.isNullOrWhitespace(this.trustedContactPerson.lastName) ||
      !StringUtils.isNullOrWhitespace(this.trustedContactPerson.telephoneNo);
  }

  public verifyTrustedPerson() {
    if (this.currentUser.userType === UserType.Legacy || this.currentUser.userType === UserType.Member) {
      if (this.trustedContactPerson.isValidTrustedPerson()) {
        this.matchingBeneficiaries.set(this.findMatchingBeneficiaries().map(beneficiary => beneficiary.toJSObject()));
        if (this.matchingBeneficiaries && this.matchingBeneficiaries.length > 1) {
          this.showMatchingKnownBeneficiariesModal = true;
        } else {
          this.updateTrustedPerson();
        }
      }
    } else if (this.currentUser.userType === UserType.Staff || this.currentUser.userType === UserType.Employer || this.currentUser.userType === UserType.FA) {
      NotifyUtils.addWarning("Not Available", "Third Party Users may not update trusted contact person", NotificationDuration.Long);
    }
  }

  public findMatchingBeneficiaries() {
    let matchingBeneficiaries = this.knownBeneficiaries.filter(beneficiary =>
      beneficiary.beneficiaryId !== this.trustedContactPerson.beneficiaryId &&
      beneficiary.firstName.toLocaleLowerCase() === this.trustedContactPerson.firstName.toLocaleLowerCase() &&
      beneficiary.lastName.toLocaleLowerCase() === this.trustedContactPerson.lastName.toLocaleLowerCase()
    );
    matchingBeneficiaries.push(this.trustedContactPerson);
    return matchingBeneficiaries;
  }

  public async updateTrustedPerson() {
    if (this.currentUser.userType === UserType.Legacy || this.currentUser.userType === UserType.Member) {
      if (this.trustedContactPerson.isValidTrustedPerson()) {
        let updateCommand = new UpdateMemberBeneficiariesCommand();

        updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId!.toString();
        updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

        if (this.userMemberSchemeService.currentSchemeSelected.memberNumber && this.userMemberSchemeService.currentSchemeSelected.schemeNumber) {
          updateCommand.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber;
          updateCommand.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber;
        }

        this.knownBeneficiaries.forEach(beneficiary => {
          if (beneficiary.beneficiaryId === this.trustedContactPerson.beneficiaryId) {
            beneficiary.trustedPerson = true;
          } else {
            beneficiary.trustedPerson = false;
            if (!beneficiary.isValid) {
              beneficiary.expiryDate = new Date();
            }
          }
          beneficiary.lastModifiedByRole = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
          beneficiary.lastModifiedUser = this.authService.user != null ? this.authService.user?.userName : '';
          updateCommand.memberBeneficiaries.push(beneficiary);
        });

        if (updateCommand.memberBeneficiaries && !updateCommand.memberBeneficiaries.find(beneficiary => beneficiary.trustedPerson)) {
          updateCommand.memberBeneficiaries.push(this.trustedContactPerson);
        }

        const response = await this.taskRunner.run(async () => this.memberBeneficiariesCommandApiClient.updateMemberBeneficiaries(updateCommand.toJSObject({ includeClean: true })));

        // Remove any expired beneficiaries
        this.knownBeneficiaries.set(this.knownBeneficiaries.filter(beneficiary => beneficiary.expiryDate == null).map(beneficiary => beneficiary.toJSObject()));

        this.lastModifiedTrustedPerson.Role = EnumHelper.getItemMetadata(UserType, this.currentUser.userType).name;
        this.lastModifiedTrustedPerson.UserName = this.authService.user != null ? this.authService.user?.userName : '';

        NotifyUtils.addPrimary("Updated trusted contact person", "You have successfully updated the details of your trusted contact person.", NotificationDuration.Long);
      } else {
        this.trustedContactPerson.validator.forceShowErrors = true;
        NotifyUtils.addWarning("Missing Details", "Your trusted contact person is missing required information.", NotificationDuration.Long);
      }
    } else if (this.currentUser.userType === UserType.Staff || this.currentUser.userType === UserType.Employer || this.currentUser.userType === UserType.FA) {
      NotifyUtils.addWarning("Not Available", "Third Party Users may not update trusted contact person", NotificationDuration.Long);
    }
  }

  private beneficiaryIsNominated(beneficiary: MemberBeneficiaryLookup) {
    let nominated = false;
    nominated = this.approvedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined ||
      this.unApprovedBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined ||
      this.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).find(nomination => nomination.beneficiaryId == beneficiary.beneficiaryId) != undefined;
    return nominated;
  }

  public navigateToDashboard() {
    this.showDashboardNavigationModal = false;
    this.userRoleService.navigateToDashboard();
  }

  private hasUnsubmittedChanges() {
    return this.approvedBeneficiaryGrouping.nominationData.isDirty
      || this.unApprovedBeneficiaryGrouping.nominationData.isDirty
      || this.funeralBeneficiaryGrouping.nominationData.isDirty
      || this.trustedContactPerson.isDirty;
  }

  public getCountryIdByName(country: string): number {
    let result = this.countries.find(s => s.name.toLowerCase() === country.toLowerCase());

    if (result) {
      return result.code;
    }
    else {
      //Setting default to "South Africa" if the country is not found
      return 1;
    }
  }

  public getCountryById(Id: number): string | null {
    let result = this.countries.find(country => country.code == Id);
    if (result) {
      return result?.name;
    }
    else {
      return null;
    }
  }

  public async updateInterimNomination(item: MemberNominationDataLookup) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateInterimMemberNominationCommand();
      updateCommand.beneficiaryId = item.beneficiaryId;
      updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      updateCommand.nominationPercentage = item.nominationPercentage;
      updateCommand.nominationId = item.nominationId;
      updateCommand.isDeleted = item.isDeleted;

      if (this.tabValue == "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.isActiveInterim = true;
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
        const interimBeneficiaryNominationRequestLookup = updateCommand.toJSObject()

        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup);
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
        const interimBeneficiaryNominationRequestLookup = updateCommand.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup);
      }
    }
  }

  public async deleteInterimBeneficiaryNomination(item: MemberNominationDataLookup) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      let request = new UpdateInterimMemberNominationCommand();
      request.beneficiaryId = item.beneficiaryId;
      request.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      request.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      request.isDeleted = true;
      request.nominationId = item.nominationId;
      request.nominationPercentage = 0;
      if (this.tabValue == "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.isActiveInterim = true;
        request.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
        const updateInterimMemberNominationCommand = request.toJSObject()
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
    }
  }

  public async addInterimNomination(item: MemberNominationDataLookup) {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateInterimMemberNominationCommand();
      updateCommand.beneficiaryId = item.beneficiaryId;
      updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
      updateCommand.nominationPercentage = item.nominationPercentage;
      updateCommand.nominationId = item.nominationId;

      if (this.tabValue == "Approved Death Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
      else if (this.tabValue == "Funeral Benefits") {
        updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
        const updateInterimMemberNominationCommand = updateCommand.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false })
        await this.memberNominationsService.editMemberInterimMemberNominationLookupAsync(updateInterimMemberNominationCommand);
      }
    }
  }

  public async deleteInterimNomination() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      if (this.tabValue == "Approved Death Benefits") {
        this.approvedBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.userMemberSchemeService.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name,
          this.userMemberSchemeService.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.approvedBeneficiaryGrouping.markOld();
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        this.unApprovedBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.userMemberSchemeService.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name,
          this.userMemberSchemeService.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.unApprovedBeneficiaryGrouping.markOld();
      }
      else if (this.tabValue == "Funeral Benefits") {
        this.funeralBeneficiaryGrouping.isActiveInterim = false;
        await this.memberNominationsService.deleteInterimMemberNomination(this.userMemberSchemeService.currentSchemeSelected.memberId,
          EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name,
          this.userMemberSchemeService.currentSchemeSelected.sourceId);
        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
        this.funeralBeneficiaryGrouping.markOld();
      }
    }
  }

  //This code is currently unused but may be useful in the future.
  //Executed when click accept yes on cancel 
  public async resetInterimNominations() {
    if (this.userMemberSchemeService.currentSchemeSelected.memberId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      let updateCommand = new UpdateInterimMemberNominationCommand();
      updateCommand.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
      updateCommand.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;

      if (this.tabValue == "Approved Death Benefits") {
        if (this.approvedOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.approvedOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
            if (this.userMemberSchemeService.currentSchemeSelected.memberId)
              nomination.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.ApprovedBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
      else if (this.tabValue == "Unapproved Death Benefits") {
        if (this.unApprovedOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.unApprovedOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
            if (this.userMemberSchemeService.currentSchemeSelected.memberId)
              nomination.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.UnapprovedBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
      else if (this.tabValue == "Funeral Benefits") {
        if (this.funeralOriginalBeneficiaryGrouping.toJSArray().length > 0) {
          const interimNominationCommands = this.funeralOriginalBeneficiaryGrouping.toJSArray();
          interimNominationCommands.forEach(nomination => {
            nomination.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
            if (this.userMemberSchemeService.currentSchemeSelected.memberId)
              nomination.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId;
            nomination.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
          });

          await this.memberNominationsService.resetInterimNominations(interimNominationCommands);
        }
        else {
          updateCommand.nominationType = EnumHelper.getItemMetadata(BeneficiaryGroupingType, BeneficiaryGroupingType.FuneralBenefits).name;
          const interimNominations = new List(UpdateInterimMemberNominationCommand);
          interimNominations.push(updateCommand);

          await this.memberNominationsService.resetInterimNominations(interimNominations.toJSArray());
        }

        await this.taskRunner.waitFor(this.getMemberNominationsLookups());
      }
    }
  }

  //This code is currently unused but may be useful in the future.
  public convertToInterimNomination(memberNominationData: List<MemberNominationDataLookup>) {
    this.nominationsDataArray.set([]);
    if (memberNominationData) {
      memberNominationData.forEach((item) => {
        let memberNomination = new UpdateInterimMemberNominationCommand();
        memberNomination.beneficiaryId = item.beneficiaryId;
        memberNomination.nominationId = item.nominationId;
        memberNomination.nominationPercentage = item.nominationPercentage;
        memberNomination.isDeleted = item.isDeleted;
        memberNomination.nominationType = item.nominationType;
        this.nominationsDataArray.push(memberNomination);
      });
      return this.nominationsDataArray;
    }
    return null;
  }
}

