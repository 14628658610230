import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import SavingsWithdrawalClaimTemplate from '../../Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplate';
import SavingsWithdrawalClaimTemplateLookup from '../../Models/SavingsWithdrawalClaim/SavingsWithdrawalClaimTemplateLookup';

export interface ISavingsWithdrawalClaimQueryApiClient {

    /** 
     * Gets a VAS template.
     * @returns A VAS template.
     */
    getSavingsWithdrawalClaimTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>>;

    /** 
     * Gets the SavingsWithdrawalClaim templates.
     * @returns A list of SavingsWithdrawalClaim templates.
     */
    getSavingsWithdrawalClaimTemplateLookups(): AxiosPromise<Array<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>>>;

    /** 
     * Gets the latest SavingsWithdrawalClaim template.
     * @returns A list of SavingsWithdrawalClaim templates.
     */
    getLatestSavingsWithdrawalClaimTemplate(): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>>;

    // Client only properties / methods
}

@injectable()
export default class SavingsWithdrawalClaimQueryApiClient extends Data.ApiClientBase implements ISavingsWithdrawalClaimQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/savings-withdrawal-claim`);
    }

    public getSavingsWithdrawalClaimTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<SavingsWithdrawalClaimTemplate>> {
        return this.axios.get(`${this.apiPath}/savings-template/${templateId}`);
    }

    public getSavingsWithdrawalClaimTemplateLookups(): AxiosPromise<Array<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>>> {
        return this.axios.get(`${this.apiPath}/savings-template-lookups`);
    }

    public getLatestSavingsWithdrawalClaimTemplate(): AxiosPromise<Model.PlainObject<SavingsWithdrawalClaimTemplateLookup>> {
        return this.axios.get(`${this.apiPath}/savings-template/latest`);
    }

    // Client only properties / methods
}