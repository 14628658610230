import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import InstructionsVM from '../InstructionsVM';

interface IInstructionUpdateBeneficiaryViewCompleteProps {
  viewModel: InstructionsVM
}

@observer
export default class InstructionUpdateBeneficiaryViewComplete extends React.Component<IInstructionUpdateBeneficiaryViewCompleteProps> {

  constructor(props: IInstructionUpdateBeneficiaryViewCompleteProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 update-bf-height'>
            <p className='instructon-view-message'>Please proceed to the member profile to begin updating beneficiary information.</p>
            <p className='instructon-view-message'>Please have the beneficiary nomination ready for submission after making beneficiary updates</p>
          </div>
        </div>
        <div className='container'>
          <Neo.Button text="View" className='center btn btn-submit btn-lg' onClick={() => this.props.viewModel.navigateToBeneficiaries()} />
        </div>
      </div>
    );
  }
}