import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import checked from '../../../App/assets/img/checked.png';
import unchecked from '../../../App/assets/img/unchecked.png';
import information from '../../../App/assets/img/information.png';

interface IBeneficiariesAddKnownForTrustedPersonProps {
  viewModel: BeneficiariesVM,
}

@observer
export default class BeneficiariesAddKnownForTrustedPerson extends React.Component<IBeneficiariesAddKnownForTrustedPersonProps> {

  constructor(props: IBeneficiariesAddKnownForTrustedPersonProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='beneficiaries-all-known'>
          {this.props.viewModel.knownBeneficiaries.length > 0 ? (
            <NeoGrid.Grid items={this.props.viewModel.knownBeneficiaries} className='known-beneficiaries-table'>
              {(item, meta) => (
                <NeoGrid.Row>
                  <NeoGrid.Column label='Full name' className='col-md-3 member-name' sort={meta.firstName}>
                    {item.getFullName()}
                  </NeoGrid.Column>
                  <NeoGrid.Column label='ID/Passport Number' className='col-md-3' sort={meta.nationalIdNoOrPassportNo} display={meta.nationalIdNoOrPassportNo} />
                  <NeoGrid.Column label='Relationship' sort={false} className='col-md-2 ' display={meta.relationship} />
                  <NeoGrid.Column sort={false} alignment={'right'}
                    className='col-md-2' >
                    <Neo.Button className='checkbox-table-bak sub-select btn-no-outline-secondary'
                      onClick={() => this.props.viewModel.handleSelectedTrustedPerson(item)}
                      disabled={!this.props.viewModel.canSelectExistingBeneficiary(item)}>
                      {item.isSelected ? <img src={checked} /> : <img src={unchecked} />} Select
                    </Neo.Button>
                  </NeoGrid.Column>
                </NeoGrid.Row >
              )}
            </NeoGrid.Grid>
          ) : (

            <div className='empty-beneficiaries'>
              <img src={information} />
              <div className='empty-beneficiaries-text'>You have no existing beneficiaries at this time.
                Please create new beneficiaries.</div>
            </div>
          )}
          <div className='show-on-phone-bat'>
            {this.props.viewModel.knownBeneficiaries.filter(beneficiary => beneficiary.isValid).map((item) => (
              <Neo.Card className='table-card-bak'>
                <div className='row'>
                  <div className='col-10'>
                    <div className='row '>
                      <span className='col-5'>
                        <span className='table-heading-text-phone-bat'>Full Name </span>
                      </span>
                      <span className='col-7'>
                        <span className='table-text-phone-bat'>{item.getFullName()}</span>
                      </span>
                    </div>
                    <div className='row '>
                      <span className='col-5 table-heading-text-phone-bat'>Cell number </span>
                      <span className='col-5'>
                        <span className='table-text-phone-bat'>{item.telephoneNo}</span>
                      </span>
                    </div>
                    <div className='row '>
                      <span className='col-5 table-heading-text-phone-bat'>Email address </span>
                      <span className='col-7'>
                        <span className='table-text-phone-bat'>{item.emailAddress}</span>
                      </span>
                    </div>
                    <div className='row id-padding '>
                      <span className='col-5 table-heading-text-phone-bat '>Relationship </span>
                      <span className='col-5 table-padding-right'>
                        <span className='table-text-phone-bat'>{item.relationship} </span>
                      </span>
                    </div>
                  </div>
                  <div className='col-2 align-checkbox-on-trusted-persons-card'>
                    <Neo.Button className='checkbox-table-bak btn-no-outline-secondary'
                      onClick={() => this.props.viewModel.handleSelectedTrustedPerson(item)}
                      disabled={!this.props.viewModel.canSelectExistingBeneficiary(item)} >
                      {item.isSelected ? <img src={checked} className="check-box-image-size" /> : <img src={unchecked}
                        className="check-box-image-size" />}
                    </Neo.Button>

                  </div>
                </div>
              </Neo.Card>
            ))}
          </div>
        </div >
      </div >
    );
  }
}