import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { StaffDashboardExportedTypes } from './StaffDashboardExportedTypes';

const StaffDashboardTypes = {
  ApiClients: {
    ...StaffDashboardExportedTypes.ApiClients
  },
  Services: {
    ...StaffDashboardExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  StaffDashboard: StaffDashboardTypes
}

export { AppService, Types, StaffDashboardTypes }