import * as React from "react";
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from "react-router";
import { AppService, Types } from '../../Services/AppService';
import { UserType } from "../../../Enums/UserType.enum";
import { Views } from "@singularsystems/neo-react";
import { AppServices } from "@singularsystems/neo-core";
import { EmptyViewModel } from "@singularsystems/neo-react/dist/Views";

export default class OidcLoginRedirect extends Views.ViewBase {

  constructor(props: unknown) {
    super("Please wait... Loading Data...", EmptyViewModel, props)
  }

  public async componentDidMount() {

    const navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
    const authService = AppService.get(Types.Neo.Security.AuthenticationService);

    if (!authService.isAuthenticated) {
      const result = await this.taskRunner.waitFor(authService.tryCompleteSignIn());
      if (result.success) {

        if (result.redirectPath) {
          this.navigation.navigateInternal(result.redirectPath)

        } else {
          this.navigation.navigateInternal("/")
        }
      }
    }
  }

  public render() {
    return <div></div>
  }
}