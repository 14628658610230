import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import VASTemplate from '../../Models/VAS/VASTemplate';
import VASTemplateLookup from '../../Models/VAS/VASTemplateLookup';

export interface IVASQueryApiClient {

  /**
   * Gets a VAS template.
   * @returns A VAS template.
   */
  getVASTemplate(): AxiosPromise<Model.PlainTrackedObject<VASTemplate>>;

  /** 
   * Gets a VAS template.
   * @returns A VAS template.
   */
  getVASTemplateById(templateId: number): AxiosPromise<Model.PlainTrackedObject<VASTemplate>>;

  /**
   * Gets the VAS templates.
   * @param templateId The template for which to download the vard file for.
   * @returns A list of VAS templates.
   */
  getVCardDownloadToken(templateId: number): AxiosPromise<string>;

  /**
   * Gets the VAS templates.
   * @param templateId The template for which to download the vard file for.
   * @param token The download token.
   * @returns The file as a byte array.
   */
  getVCardFile(templateId: number, token: string): AxiosPromise<string>;

  /** 
   * Gets a VasTemplateLookup.
   * @returns A VAS template Lookup.
   */
  getVASTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<VASTemplateLookup>>;

  // Client only properties / methods
}

@injectable()
export default class VASQueryApiClient extends Data.ApiClientBase implements IVASQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/vas`);
  }

  public getVASTemplate(): AxiosPromise<Model.PlainTrackedObject<VASTemplate>> {
    return this.axios.get(`${this.apiPath}/vas-template`);
  }

  public getVASTemplateById(templateId: number): AxiosPromise<Model.PlainTrackedObject<VASTemplate>> {
    return this.axios.get(`${this.apiPath}/vas-template/${templateId}`);
  }

  public getVCardDownloadToken(templateId: number): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/vcard-download-token/${templateId}`);
  }

  public getVCardFile(templateId: number, token: string): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/vcard-file/${templateId}?token=${encodeURIComponent(token)}`);
  }

  public getVASTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<VASTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/vas-template-lookup-by-id/${templateId}`);
  }

  // Client only properties / methods
}