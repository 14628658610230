import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import SavingsClaimDataCaptureLookup from '../../Models/LookUps/MemberClaims/SavingsClaimDataCaptureLookup';
import PostCaseLookup from '../../Models/LookUps/MemberClaims/PostCaseLookup';
import SavingsClaimSecureDocumentLookup from '../../Models/LookUps/MemberClaims/SavingsClaimSecureDocumentLookup';

export interface ISavingsClaimCommandApiClient {


    /**
     * Uploads a secure document
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A member savings claim
     */
  getUploadSavingsClaimSecureDocumentUrl(): string;

    /**
     * Marks member savings claim as inactive
     * @param memberId The member Id
     * @param nameId The name Id
     * @param sourceId The source Id
     * @returns Sets a members active state to false
     */
  deleteMemberSavingsClaim(memberId: number, nameId: number, sourceId: string): AxiosPromise;

    /**
     * Deletes member savings claim file.
     * @param memberId The member Id
     * @param nameId The name Id
     * @param fileName The file name for the file needed to get deleted.
     * @param sourceId The source Id.
     * @returns A message if the file was not deleted
     */
  deleteMemberSavingsClaimFiles(memberId: number, nameId: number, sourceId: string, fileName: string): AxiosPromise<string>;

    /**
     * Submits savings claim query.
     * @param savingsClaimQuery The savings claim query to submit.
     * @returns The updated savings claim query.
     */
  submitSavingsClaimQuery(savingsClaimQuery: Array<Model.PartialPlainObject<PostCaseLookup>>): AxiosPromise;

    /**
     * Submits savings claim query.
     * @param savingsClaimQuery The savings claim query to submit.
     * @returns The updated savings claim query.
     */
  submitSavingsClaimEmailQuery(savingsClaimQuery: Model.PartialPlainObject<PostCaseLookup>): AxiosPromise<Model.PlainObject<PostCaseLookup>>;

    /**
     * Submits the bank validation of savings claim
     * @param savingsClaimBankingValidationQuery The savings claim query to submit.
     * @returns The updated savings claim query.
     */
  submitSavingsClaimBankingValidationQuery(savingsClaimBankingValidationQuery: Model.PartialPlainObject<SavingsClaimDataCaptureLookup>): AxiosPromise;

    /**
     * Inserts a savings claim query.
     * @param savingsClaimQuery The savings claim query to update.
     * @returns The updated savings claim query.
     */
  insertAndUpdateSavingsClaimQuery(savingsClaimQuery: Model.PartialPlainObject<SavingsClaimDataCaptureLookup>): AxiosPromise<Model.PlainObject<SavingsClaimDataCaptureLookup>>;

    /** 
     * Gets the stream of a secure file
     * @param savingsClaimSecureDocument The savings claim secure document lookup.
     * @returns A  representing the result of the asynchronous operation.
     */
    getSavingsClaimSecureDocument(savingsClaimSecureDocument: Model.PartialPlainObject<SavingsClaimSecureDocumentLookup>): AxiosPromise<Blob>;

  // Client only properties / methods
}

@injectable()
export default class SavingsClaimCommandApiClient extends Data.ApiClientBase implements ISavingsClaimCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-savings-claim-command`);
  }

  public getUploadSavingsClaimSecureDocumentUrl(): string {
    return `${this.apiPath}/upload-secure-document`;
  }

  public deleteMemberSavingsClaim(memberId: number, nameId: number, sourceId: string): AxiosPromise {
    return this.axios.post(`${this.apiPath}/delete-active-claim/${memberId}/${nameId}/${encodeURIComponent(sourceId)}`);
  }

  public deleteMemberSavingsClaimFiles(memberId: number, nameId: number, sourceId: string, fileName: string): AxiosPromise<string> {
    return this.axios.delete(`${this.apiPath}/delete-file/${memberId}/${nameId}/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}`);
  }

  public submitSavingsClaimQuery(savingsClaimQuery: Array<Model.PartialPlainObject<PostCaseLookup>>): AxiosPromise {
    return this.axios.post(`${this.apiPath}/submit`, savingsClaimQuery);
  }

  public submitSavingsClaimEmailQuery(savingsClaimQuery: Model.PartialPlainObject<PostCaseLookup>): AxiosPromise<Model.PlainObject<PostCaseLookup>> {
    return this.axios.post(`${this.apiPath}/submit-email`, savingsClaimQuery);
  }

  public submitSavingsClaimBankingValidationQuery(savingsClaimBankingValidationQuery: Model.PartialPlainObject<SavingsClaimDataCaptureLookup>): AxiosPromise {
    return this.axios.post(`${this.apiPath}/bank-validation`, savingsClaimBankingValidationQuery);
  }

  public insertAndUpdateSavingsClaimQuery(savingsClaimQuery: Model.PartialPlainObject<SavingsClaimDataCaptureLookup>): AxiosPromise<Model.PlainObject<SavingsClaimDataCaptureLookup>> {
    return this.axios.post(`${this.apiPath}/insert`, savingsClaimQuery);
  }

    public getSavingsClaimSecureDocument(savingsClaimSecureDocument: Model.PartialPlainObject<SavingsClaimSecureDocumentLookup>): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/get-secure-document?${Utils.getQueryString(savingsClaimSecureDocument)}`, { responseType: "blob" });
    }

  // Client only properties / methods
}