import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import group from '../../../App/assets/img/group.png';
import greoutlineWarningyPlus from '../../../MemberDashboard/Assets/img/outlineWarning.png';
import { UserType } from '../../../Enums/UserType.enum';

interface INoBeneficiariesProps {
  viewModel: BeneficiariesVM
}

@observer
export default class NoBeneficiaries extends React.Component<INoBeneficiariesProps> {

  constructor(props: INoBeneficiariesProps) {
    super(props);
  }

  public render() {
    return (
      <div className='row'>
        <div className='col-12'>
          <div className='grey-no-benificiaries-box'>
            <img className='grey-no-benificiaries-box-image' src={greoutlineWarningyPlus} />
            <div className='grey-no-benificiaries-box-text'>
              Currently you have no beneficiaries assigned to this scheme. Select the options below to get started
            </div>
            {!this.props.viewModel.viewOnlyBeneficiary &&
              <div className='blue-block'>
                <div className='row'>
                  <div className='col-md-12'>
                    {this.props.viewModel.tabValue == "Approved Death Benefits" &&
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                        Select existing beneficiary
                      </Neo.Link>
                    }
                    {this.props.viewModel.tabValue == "Unapproved Death Benefits" &&
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                        Select existing beneficiary
                      </Neo.Link>
                    }
                    {this.props.viewModel.tabValue == "Funeral Benefits" &&
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                        Select existing beneficiary
                      </Neo.Link>
                    }
                    {this.props.viewModel.tabValue == "Trusted Contact Person" &&
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                        Select existing beneficiary
                      </Neo.Link>
                    }
                    <span className='line-break'><br></br></span>
                    <span className='or-text'> or</span>
                    <span className='line-break'><br></br></span>
                    <img src={group} className="edit-image-size padding" />
                    <Neo.Link
                      onClick={() => this.props.viewModel.showAddBeneficiariesModal = true}
                      className='add-text'>
                      Add new beneficiary
                    </Neo.Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}