import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import UsefulLinksVM from './UsefulLinksVM';
import { observer } from 'mobx-react';
import '../Styles/Components/UsefulLinks.scss';
import RichTextEditor from './Components/RichTextEditor';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class UsefulLinksView extends Views.ViewBase<UsefulLinksVM> {

  constructor(props: unknown) {
    super("Useful links", UsefulLinksVM, props);
  }

  public render() {
    return (
      <div className='useful-links-container'>
        <div className='container'>
          <div className='mt-4 useful-links-card'>
            <div className='row'>
              <div className='form-group align-header col-12 col-md-12'>
                <div className='card-header-ul'>Useful links</div>
                {this.viewModel.isAdmin &&
                  <Neo.Button text="Edit" className='align-edit-button' onClick={() => this.viewModel.showEditTemplateModel = true} />
                }
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='line-ul'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <Neo.Card className='regulatory-bodies-card'>
                  <div className='row' spellCheck={false}>
                    <RichTextEditor height='100%' autoFocus={false} readOnly hideToolbar bind={this.viewModel.usefulLinkTemplate.meta.templateContent}></RichTextEditor>
                  </div>
                </Neo.Card>
              </div>
            </div>
            <ContainerFooterComponent
              backLinkText='Back'
              backLinkCallback={() => this.viewModel.homeClicked()}
            />
          </div>
        </div>
        <Neo.Modal title="UsefulLinks Template Editor"
          size="xl"
          show={this.viewModel.showEditTemplateModel}
          onClose={() => this.viewModel.hideTemplateModel()}
          className="tandc-template-modal"
          footer={
            <div className='pull-right'>
              <Neo.Button
                variant='primary'
                icon={"fa-save"}
                onClick={() => this.viewModel.updateUsefulLinksContent()}
              >
                Save
              </Neo.Button>
            </div>
          }>
          <Neo.Loader task={this.viewModel.taskRunner}>
            <div className='row'>
              <div className='col-12'>
                <RichTextEditor bind={this.viewModel.usefulLinkTemplate.meta.templateContent}></RichTextEditor>
              </div>
            </div>
          </Neo.Loader>
        </Neo.Modal>
      </div>
    );
  }
}