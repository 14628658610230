import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberBillingLookup from '../../Models/LookUps/MemberBilling/MemberBillingLookup';

export interface IMemberBillingApiClient {

  /** 
   * Retrieves memberBilling Lookup.
   * @param memberId Id
   * @param sourceId sourceId
   * @returns MemberBillingLookup.
   */
  getMemberBillingById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberBillingLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberBillingApiClient extends Data.ApiClientBase implements IMemberBillingApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-billing`);
  }

  public getMemberBillingById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberBillingLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}