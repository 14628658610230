import { AppServices } from '@singularsystems/neo-core';
import BankQueryApiClient from './ApiClients/Bank/BankQueryApiClient';
import CountryQueryApiClient from './ApiClients/Country/CountryQueryApiClient';
import MemberStatusGroupQueryApiClient from './ApiClients/MemberStatus/MemberStatusGroupQueryApiClient';
import SchemeStatusGroupQueryApiClient from './ApiClients/SchemeStatus/SchemeStatusGroupQueryApiClient';
import TitleQueryApiClient from './ApiClients/Title/TitleQueryApiClient';

// Symbols to expose outside of this module
export const MasterDataExportedTypes = {
  ApiClients: {
    TitleQueryApiClient: new AppServices.ServiceIdentifier<TitleQueryApiClient>("MasterData.ApiClients.TitleQuery"),
    BankQueryApiClient: new AppServices.ServiceIdentifier<BankQueryApiClient>("MasterData.ApiClients.BankQuery"),
    SchemeStatusGroupQueryApiClient: new AppServices.ServiceIdentifier<SchemeStatusGroupQueryApiClient>("MasterData.ApiClients.SchemeStatusGroupQuery"),
    CountryQueryApiClient: new AppServices.ServiceIdentifier<CountryQueryApiClient>("MasterData.ApiClients.CountryQuery"),
    MemberStatusGroupQueryApiClient: new AppServices.ServiceIdentifier<MemberStatusGroupQueryApiClient>("MasterData.ApiClients.MasterStatusGroupQuery"),
  },
  Services: {
  }
}