import React from 'react';
import { observer } from 'mobx-react';
import InformationCard from './InformationCard';
import { AppService, Types } from '../../Services/AppService';
import { List } from '@singularsystems/neo-core';
import ContactUsTemplateLookup from '../../../Domain/Models/ContactUs/ContactUsTemplateLookup';
import ContactUsTemplateCardLookup from '../../../Domain/Models/ContactUs/ContactUsTemplateCardLookup';
import { ContactUsTemplateType } from '../../../Domain/Models/ContactUs/Enums/ContactUsTemplateType.enum';
import { Neo } from '@singularsystems/neo-react';
import ContainerFooterComponent from './ContainerFooterComponent';

interface IComplainComponentProps {

}

@observer
export default class ComplainComponent extends React.Component<IComplainComponentProps> {
  private taskRunner = AppService.get(Types.Neo.TaskRunner);
  private contactUsQueryApiClient = AppService.get(Types.Domain.ApiClients.ContactUsQueryApiClient);

  public libertyContactUsTemplateLookup = new ContactUsTemplateLookup;
  public complaintsContactUsTemplateLookup = new ContactUsTemplateLookup;
  public formalComplaintsContactUsTemplateLookup = new ContactUsTemplateLookup;
  public complaintsResolutionManagerContactUsTemplateLookup = new ContactUsTemplateLookup;
  public contactUsTemplateLookups = new List(ContactUsTemplateLookup);

  constructor(props: IComplainComponentProps) {
    super(props);
  }

  public componentDidMount() {
    this.setContactUsTemplates();
  }

  private async setContactUsTemplates() {
    const contactUsResponse = await this.taskRunner.waitFor(this.contactUsQueryApiClient.getContactUsTemplateLookups());

    this.contactUsTemplateLookups.set(contactUsResponse.data);
    this.libertyContactUsTemplateLookup.set(contactUsResponse.data[0]);
    this.complaintsContactUsTemplateLookup.set(contactUsResponse.data[1]);
    this.formalComplaintsContactUsTemplateLookup.set(contactUsResponse.data[2]);
    this.complaintsResolutionManagerContactUsTemplateLookup.set(contactUsResponse.data[3]);
  }

  public render() {
    return (
      <Neo.Loader task={this.taskRunner}>
        <div>
          <div className='row center-cards-mobile'>
            {this.libertyContactUsTemplateLookup.contactUsTemplateCards.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  headingText={item.heading}
                  available={(item.availableFrom == null || item.availableTill == null) ? '' : 'Available: ' + item.availableFrom + '- ' + item.availableTill}
                  telHeading={item.telephoneNumber == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.getFormattedTelephoneNumber()}
                  additionalTelHeading={item.additionalTelephoneNumber == '' ? '' : 'Sharecall:'}
                  additionalTel={' ' + item.getFormattedAdditionalTelNumber()}
                  faxHeading={item.fax == '' ? '' : 'Fax'}
                  fax={' ' + item.getFormattedFax()}
                  emailHeading={item.email == '' ? '' : 'Email address'}
                  email={' ' + item.email}
                  additionalEmailHeading={item.additionalEmail == '' ? '' : 'General enquiries'}
                  additionalEmail={' ' + item.additionalEmail}
                  postalAddressHeading={item.address == '' ? '' : 'Postal address'}
                  postalAddressName={' ' + item.address}
                  postalAddressPOBox={' ' + item.postalBox}
                  postalAddressCity={' ' + item.city}
                  postalAddressCityCode={' ' + item.postalCode} />
              </div>
            ))}
          </div>
          <div className='line-cu' />
          <div className='complaints-heading-cu'>Complaints</div>
          <div className='complaints-sub-heading-cu'>Complaints or comments should be directed to:</div>
          <div className='row center-cards-mobile'>
            {this.complaintsContactUsTemplateLookup.contactUsTemplateCards.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  headingText={item.heading}
                  available={(item.availableFrom == null || item.availableTill == null) ? '' : 'Available: ' + item.availableFrom + '- ' + item.availableTill}
                  telHeading={item.telephoneNumber == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.getFormattedTelephoneNumber()}
                  additionalTelHeading={item.additionalTelephoneNumber == '' ? '' : 'Sharecall:'}
                  additionalTel={' ' + item.getFormattedAdditionalTelNumber()}
                  faxHeading={item.fax == '' ? '' : 'Fax'}
                  fax={' ' + item.getFormattedFax()}
                  emailHeading={item.email == '' ? '' : 'Email address'}
                  email={' ' + item.email}
                  additionalEmailHeading={item.additionalEmail == '' ? '' : 'General enquiries'}
                  additionalEmail={' ' + item.additionalEmail}
                  postalAddressHeading={item.address == '' ? '' : 'Postal address'}
                  postalAddressName={' ' + item.address}
                  postalAddressPOBox={' ' + item.postalBox}
                  postalAddressCity={' ' + item.city + ','}
                  postalAddressCityCode={' ' + item.postalCode} />
              </div>
            ))}
          </div>
          <div className='row '>
            <div className='col-12 negate-col-padding'>
              <div className='shadowbox-cu'>
                <div className='shadowbox-text-cu'>If a complaint is not resolved satisfactorily by Liberty, customers may contact one of the legislative
                  bodies that have been tasked to look after their interests.</div>
              </div>
            </div>
          </div>
          <div className='row center-cards-mobile'>
            {this.formalComplaintsContactUsTemplateLookup.contactUsTemplateCards.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  headingText={item.heading}
                  available={(item.availableFrom == null || item.availableTill == null) ? '' : 'Available: ' + item.availableFrom + '- ' + item.availableTill}
                  telHeading={item.telephoneNumber == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.getFormattedTelephoneNumber()}
                  additionalTelHeading={item.additionalTelephoneNumber == '' ? '' : 'Sharecall:'}
                  additionalTel={' ' + item.getFormattedAdditionalTelNumber()}
                  faxHeading={item.fax == '' ? '' : 'Fax'}
                  fax={' ' + item.getFormattedFax()}
                  emailHeading={item.email == '' ? '' : 'Email address'}
                  email={' ' + item.email}
                  additionalEmailHeading={item.additionalEmail == '' ? '' : 'General enquiries'}
                  additionalEmail={' ' + item.additionalEmail}
                  postalAddressHeading={item.address == '' ? '' : 'Postal address'}
                  postalAddressName={' ' + item.address}
                  postalAddressPOBox={' ' + item.postalBox}
                  postalAddressCity={' ' + item.city + ','}
                  postalAddressCityCode={' ' + item.postalCode} />
              </div>
            ))}
          </div>

          <div className='row '>
            <div className='col-12 negate-col-padding'>
              <div className='shadowbox-cu'>
                <div className='shadowbox-text-cu'>The above process is our formal complaints process and a reference number will always be provided.
                  If you do not receive a reference number for a complaint, please contact the Complaints Resolution Manager using the contact details above.</div>
              </div>
            </div>
          </div>
          <div className='row center-cards-mobile'>
            {this.complaintsResolutionManagerContactUsTemplateLookup.contactUsTemplateCards.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  headingText={item.heading}
                  available={(item.availableFrom == null || item.availableTill == null) ? '' : 'Available: ' + item.availableFrom + '- ' + item.availableTill}
                  telHeading={item.telephoneNumber == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.getFormattedTelephoneNumber()}
                  additionalTelHeading={item.additionalTelephoneNumber == '' ? '' : 'Sharecall:'}
                  additionalTel={' ' + item.getFormattedAdditionalTelNumber()}
                  faxHeading={item.fax == '' ? '' : 'Fax'}
                  fax={' ' + item.getFormattedFax()}
                  emailHeading={item.email == '' ? '' : 'Email address'}
                  email={' ' + item.email}
                  additionalEmailHeading={item.additionalEmail == '' ? '' : 'General enquiries'}
                  additionalEmail={' ' + item.additionalEmail}
                  postalAddressHeading={item.address == '' ? '' : 'Postal address'}
                  postalAddressName={' ' + item.address}
                  postalAddressPOBox={' ' + item.postalBox}
                  postalAddressCity={' ' + item.city + ','}
                  postalAddressCityCode={' ' + item.postalCode} />
              </div>
            ))}
          </div>
        </div>
        <ContainerFooterComponent />
      </Neo.Loader>
    );
  }
}