import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import PersonContactDetailsComponent from '../Profile/PersonContactDetailsComponent';
import { Misc, Validation } from '@singularsystems/neo-core';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import { ClassMetadata } from '@singularsystems/neo-core/dist/JsonSchema';

interface IMemberDetailsProps {
  viewModel: SavingsWithdrawalClaimVM,
}

@observer
export default class MemberDetails extends React.Component<IMemberDetailsProps> {
  constructor(props: IMemberDetailsProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='p-2 p-sm-0'>
          <BlueRibbon
            width='97%'
            text='If you would like to edit your identification information,
                             please contact your employer to make the changes before you proceed with the withdrawal.'/>
        </div>
        <Neo.GridLayout>
          <Neo.FormGroup className='form-text' href='Title' bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.title} readOnly />
          <Neo.FormGroup className='form-text right-side' href='Surname' bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.lastName} readOnly />
          <Neo.FormGroup className='form-text' href='Name' bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.firstName} readOnly />
          <Neo.FormGroup className='form-text right-side' href={'SA ID or passport number'} bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.nationalIdNumber} readOnly />
          <Neo.FormGroup className='form-text' href='Date of Birth' bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.birthDate} readOnly />
          <Neo.FormGroup className='form-text right-side' href='Country of issue' bind={this.props.viewModel.userRoleService.personContactDetailsLookup.meta.passportCountryOfIssue} readOnly />
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <Neo.FormGroup className='form-text change-color' href='Tax number' bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.southAfricanIncomeTaxNumber}
              label={<span>South african tax number<span className='display-asterisk'>*</span></span>}
              onBlur={() => this.props.viewModel.saveSavingsClaimData()}
              required />
          </Neo.FormContext>
          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
            <div>
              <Neo.FormGroup className='form-text remove-margin-bottom right-side change-color'
                label={<span>Annual income<span className='display-asterisk'>*</span></span>}
                numProps={{ formatString: "R # ### ###" }}
                bind={this.props.viewModel.savingsWithdrawalClaimCapture.meta.annualIncome}
                onBlur={() => this.props.viewModel.saveSavingsClaimData()} />
              <span className='sub-text-savings-withdrawal-amount annual-income-note'>Please ensure that you provide the amount to the nearest Rand. No decimals.</span>
            </div>
          </Neo.FormContext>
        </Neo.GridLayout>
        <div className='pika-separating-line m-4' />
        <div className='form-container-second m-sm-4 p-sm-2 m-3'>
          <PersonContactDetailsComponent viewModel={this.props.viewModel.personContactDetailsComponentVM} />
        </div>
      </div>
    );
  }
}