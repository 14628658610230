import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { UserType } from '../../../Enums/UserType.enum';
import { Neo } from '@singularsystems/neo-react';
import img_Upload from '../../../App/assets/img/primary-upload.png';
import eye from '../../../MemberDashboard/Assets/img/open.png';
import close from '../../../App/assets/img/close.png';
import { User } from '../../../UserModel/User';

interface IFileUploadBeneficiariesProps {
  viewModel: BeneficiariesVM
}

@observer
export default class FileUploadBeneficiaries extends React.Component<IFileUploadBeneficiariesProps> {

  constructor(props: IFileUploadBeneficiariesProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        {(this.props.viewModel.currentUser.userType === UserType.Staff || this.props.viewModel.currentUser.userType === UserType.Employer || this.props.viewModel.currentUser.userType === UserType.FA) &&
          <div className='nominated-form-background'>
            <div>
              <div className='nominated-form-heading'>
                Upload Beneficiary Nomination Form
              </div>
              <div className=' row'>
                <div className='col-md-12 col-12 alert-heading'>
                  <Neo.Alert variant="primary" className='display-alert-employer-beneficiary' heading="Please note: you may not submit any updates without including the signed beneficiary nomination form in the submission." >
                  </Neo.Alert>
                </div>
              </div>
              <div className='nominated-form-sub-heading'>
                Max file size 5mb File types allowed: .pdf, .jpeg, .doc
              </div>
              <Neo.FileContext
                onFilesSelected={() => { this.props.viewModel.recievedNominatedForm = true }}
                allowedExtensions={[".pdf", ".doc", ".jpeg"]} >
                <Neo.FileDropArea className='file-drop-area'>
                  <i><img src={img_Upload} /></i>
                  <label className='file-upload-label'> Drag-and-drop a file or</label><Neo.FileUploadButton className='browse-button'>browse to upload</Neo.FileUploadButton>
                  <Neo.FileUploadProgress />
                </Neo.FileDropArea>
              </Neo.FileContext>
              {this.props.viewModel.recievedNominatedForm &&
                <div>
                  <div className='nominated-form-heading margin'>
                    Uploaded Beneficiary Nomination Form
                  </div>
                  <div className='nominated-grey-form-background'>
                    <div className='nominated-grey-form-heading'>
                      Beneficiary Nomination Form.pdf
                      <span className='float-right hover'><img src={eye} /><img src={close} onClick={() => this.props.viewModel.recievedNominatedForm = false} /></span>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}