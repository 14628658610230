import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { FADashboardExportedTypes } from './FADashboardExportedTypes';

const FADashboardTypes = {
  ApiClients: {
    ...FADashboardExportedTypes.ApiClients
  },
  Services: {
    ...FADashboardExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  FADashboard: FADashboardTypes
}

export { AppService, Types, FADashboardTypes }