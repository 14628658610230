import { Attributes, ModelBase } from '@singularsystems/neo-core';
import MemberDependentsBenefitDataLookup from './MemberDependentsBenefitDataLookup';

export default class MemberDependentBenefitsLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public memberId: number = 0;

  public benefitType: string = "";

  public funeralScaleId: number = 0;

  public funeralScaleName: string = "";

  @Attributes.Nullable()
  public benefitOptionId: number | null = null;

  @Attributes.Nullable()
  @Attributes.Integer()
  public benefitMultiple: number | null = null;

  public memberDependentsBenefitData: MemberDependentsBenefitDataLookup[] = [];

  // Client only properties / methods
}