import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberQueryLookup from '../../Models/LookUps/MemberQueries/MemberQueryLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberQueriesQueryApiClient {

  /** 
   * Gets a member query.
   * @param memberId The member id.
   * @param sourceId The source id
   * @returns A member query.
   */
  getMemberQuery(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberQueryLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberQueriesQueryApiClient extends Data.ApiClientBase implements IMemberQueriesQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-queries`);
  }

  public getMemberQuery(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberQueryLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}