import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberInvestmentValuesLookup from '../../Models/LookUps/MemberInvestmentValuesLookup';

export interface IMemberInvestmentValuesApiClient {

  /** 
   * Retrieves memberInvestmentValues Lookup.
   * @param memberId Id
   * @param sourceId sourceId
   * @returns MemberInvestmentValuesLookup.
   */
  getMemberInvestmentValuesLookupById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberInvestmentValuesLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberInvestmentValuesApiClient extends Data.ApiClientBase implements IMemberInvestmentValuesApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-investment-values`);
  }

  public getMemberInvestmentValuesLookupById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberInvestmentValuesLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}