import { Data, Model } from "@singularsystems/neo-core";
import { AxiosPromise } from "axios";
import { injectable } from "inversify";
import { AppService, Types } from "../../DomainTypes";
import MbsResponse from "../../Models/Reports/MbsResponse";
import ReportCriteria from "../../Models/Reports/ReportCriteria";
import IfpCertResponse from "../../Models/Reports/Response/IfpCertResponse";
import IfpMisResponse from "../../Models/Reports/Response/IfpMisResponse";
import LCUMbsResponse from "../../Models/Reports/Response/LcuMbsResponse";
import LCUMisResponse from "../../Models/Reports/Response/LcuMisResponse";

export interface IReportsApiClient {
  /** 
   * Gets the LCU MBS Report
   * @returns An Mbs Response
   */
  generateLCBMbsReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<MbsResponse>

  /** 
 * Gets the LCB MBS Report
 * @returns An Mbs Response
 */
  generateLCBMisReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMisResponse>

  /** 
   * Gets LCU MIS Report
   * @returns A string id to be used to get the report
   */
  generateLCUMisReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMisResponse>

  /** 
   * Gets LCU MBS Report document
   * @returns A string id to be used to get the report
   */
  generateLCUMbsReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMbsResponse>

  /** 
  * Gets Report document
  * @returns A pdf document for an mis report
  */
  getMisReportDocument(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<Blob>

  /** 
* Returns a IFP Member Investment Summary report
* @returns The Member Investment Summary report for Preservation schemes.
*/
  generateIfpMemberInvestmentSummary(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<IfpMisResponse>

  /** 
  * Returns a IFP Member Certificate
  * @returns The Member Certificate for Preservation schemes.
  */
  generateIfpMemberCertificate(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<IfpCertResponse>
}

@injectable()
export default class ReportsApiClient extends Data.ApiClientBase implements IReportsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/ExternalReports`);
  }

  generateLCBMbsReport(mbsRequestBody: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<MbsResponse> {
    return this.axios.post(`${this.apiPath}/lcb-member-benefit-statement`, mbsRequestBody);
  }

  generateLCBMisReport(misRequestBody: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMisResponse> {
    return this.axios.post(`${this.apiPath}/lcb-member-investment-statement`, misRequestBody);
  }

  generateLCUMisReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMisResponse> {
    return this.axios.post(`${this.apiPath}/lcu-member-investment-statement`, criteria);
  }

  generateLCUMbsReport(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<LCUMbsResponse> {
    return this.axios.post(`${this.apiPath}/lcu-member-benefit-statement`, criteria);
  }

  getMisReportDocument(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/download-document?documentId=` + criteria.documentId + `&sourceId=` + criteria.sourceId + `&reportType=` + criteria.reportType, { responseType: 'blob' });
  }

  generateIfpMemberInvestmentSummary(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<IfpMisResponse> {
    return this.axios.post(`${this.apiPath}/ifp-member-investment-statement`, criteria);
  }

  generateIfpMemberCertificate(criteria: Model.PartialPlainObject<ReportCriteria>): AxiosPromise<IfpCertResponse> {
    return this.axios.post(`${this.apiPath}/ifp-member-certificate`, criteria);
  }
}