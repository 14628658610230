import React from 'react';
import { observer } from 'mobx-react';
import icn_pdf from '../../../../App/assets/img/icon-pdf.png';
import { Cursor } from '@singularsystems/neo-canvas-grid/dist/Drawing/OffsetMouseEvent';

interface IPhoneScreenInvestmentsTableCardProps {
  investmentName: string | null,
  value: number | null,
  url: string | null,
  children: React.ReactNode
}

@observer
export default class PhoneScreenInvestmentsTableCard extends React.Component<IPhoneScreenInvestmentsTableCardProps> {

  constructor(props: IPhoneScreenInvestmentsTableCardProps) {
    super(props);
  }

  public render() {
    return (
      <div className='table-row-cards-investment'>
        <div className='row'>
          <div className='col-12'>
            <div className='table-column-investment-name'>
              {this.props.investmentName}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='table-headers-investment value-padding-investment'>
              Value:
              <span className='table-column-investment-amount value-padding-investment'>
                R {this.props.value?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='table-headers-investment heading-sheet-padding-products'>
              Fund fact sheet:
              {this.props.url ? (
                <a target="_blank" className='set-link-css' href={this.props.url}>
                  <img src={icn_pdf} className="pdf-icon-product table-icon" />
                  View latest
                </a>
              ) : (
                <span className='table-column-investment-sheet'>
                  <a target="_blank" className='set-link-css' href={'https://www.liberty.co.za/fund-fact-sheets'}>
                    <img src={icn_pdf} className="pdf-icon-product table-icon" />
                    View latest
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}