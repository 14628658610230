import { Components, Data, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';
import img_alert from '../../App/assets/img/alert.png';
import PortfolioView from '../../MemberDashboard/Views/MemberDashboardView';
import InstructionsSearchCriteria from '../../Instructions/Models/Lookups/IntructionsSearchCriteria';
import InstructionsSearchResultsLookup from '../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';
import { UserType } from '../../Enums/UserType.enum';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import PropertyInfo from '@singularsystems/neo-core/dist/Model/PropertyInfo';
import QueriesView from './QueriesAndComplimentsView';
import ComplaintsView from './ComplaintsView';
import InstructionStartNew from './Components/InstructionStartNew';
import SavingsWithdrawalClaimView from '../../Party/Views/SavingsWithdrawalClaimView';

@NeoModel
export class Query extends ModelBase {
  public id: string = '';
  public membershipNumber: string = '9892892';
  public isEmailSelected: boolean = true;
  public cellNumber: string = '';
  public emailAddress: string = '';
  public query: string = '';
  public schemeNumber: string = '0030010775';
  public schemeName: string = 'Fempower Consultants PTY Ltd.';
}
@NeoModel
export default class InstructionsVM extends Views.ViewModelBase {

  public tabValue: string = 'Scheme instructions';
  public tabValueNewInstruction: string = 'Members';
  public sampleString: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
  public search: string = '';
  public alertImageDisplay: string = img_alert;
  public showInstructionInformation: boolean = false;
  public ascending: boolean = false;
  public query: Query = new Query();
  public countryValue: string = '+27';
  public selectedInstruction: InstructionsSearchResultsLookup = new InstructionsSearchResultsLookup();
  public showInstructionDetails: boolean = false;
  public showGeneralInformation: boolean = false;
  public showStartInstruction: boolean = false;
  public showGeneralCards: boolean = true;
  public showInstructionCards: boolean = true;
  public showInstructionStartNew: boolean = false;
  public userType = UserType.Employer;
  public propValueId: number = 1;
  public propValue: string = "Name";
  public instructionTypeId: number = 1;
  public instructionTypeValue: string = "Instruction Type";
  public schemeTypeId: number = 1;
  public schemeTypeValue: string = "";
  public memberNameAndCode: string = "John Doe M 654213"

  public instructionTypeValues = [
    { Id: 1, prop: "Instruction type" },
    { Id: 2, prop: "Update beneficiaries" },
    { Id: 3, prop: "Query" },
    { Id: 4, prop: "Compliment" },
    { Id: 5, prop: "Complaint" }
  ]

  public schemeTypeValues = [
    { Id: 1, prop: "Related Scheme" },
    { Id: 2, prop: "Fem Power Consultants (Pty)Ltd" },
    { Id: 3, prop: "Liberty Preserver Provident Fund" }
  ]

  public propValues = [
    { Id: 1, prop: "Name" },
    { Id: 2, prop: "Number" },
    { Id: 3, prop: "Instruction" },
    { Id: 4, prop: "Scheme Name" },
    { Id: 5, prop: "Scheme Number" }
  ]

  public criteria = new InstructionsSearchCriteria();
  public memberList = this.callsAndInstructionApiClient.getMemberInstrcuctionsResults;
  public schemeList = this.callsAndInstructionApiClient.getSchemeInstructionsSearchResults;
  public completedMemberList = this.callsAndInstructionApiClient.getMemberCompletedInstrcuctionsResults;
  public completedSchemeList = this.callsAndInstructionApiClient.getSchemeCompletedInstrcuctionsResults;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private callsAndInstructionApiClient = AppService.get(Types.MemberDashboard.ApiClients.CallsAndInstructionsApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
    this.gotToInstructionView = this.gotToInstructionView.bind(this);
  }

  public navigateToBeneficiaries(): void {
    this.navigation.navigateToView(BeneficiariesView);
  }

  public navigateToWithdrawalClaimView(): void {
    this.navigation.navigateToView(SavingsWithdrawalClaimView);
  }
  public updateGrid(instructionType: string, status: string) {
    if (instructionType == "Member" && !status.includes("Completed"))
      this.pageManagerMember.refreshData();
    if (instructionType == "Scheme" && !status.includes("Completed"))
      this.pageManagerScheme.refreshData();
    if (status.includes("Completed")) {
      this.pageManagerSchemeCompleted.refreshData();
      this.pageManagerMemberCompleted.refreshData();
    }
  }

  public updateNewInstructionGrid(instructionType: string) {
    if (instructionType == "Members") {
      this.pageManagerMember.refreshData();
    }
    if (instructionType == "Schemes") {
      this.pageManagerScheme.refreshData();
    }
  }

  public swapToNewInstrucion() {
    this.showInstructionStartNew = true;
    if (this.tabValue == "Member instructions") {
      this.tabValueNewInstruction = "Schemes"
    } else {
      this.tabValueNewInstruction = "Members"
    }
  }

  public startInstruction(intructionType: string): void {
    if (intructionType == "Update beneficiaries") {
      this.navigation.navigateToView(BeneficiariesView);
    }
    else if (intructionType == "Query" || intructionType == "Compliment") {
      this.navigation.navigateToView(QueriesView);
    }
    else {
      this.navigation.navigateToView(ComplaintsView);
    }
  }

  public gotToInstructionView(instruction: InstructionsSearchResultsLookup): void {
    this.selectedInstruction = instruction;
    if (instruction.instruction == "Query") {
      this.query.membershipNumber = "9892892";
      this.query.emailAddress = "john.doe@gmail.com";
      this.query.cellNumber = "83 901 2109";
      this.query.isEmailSelected = true
      this.query.query = this.sampleString;
    }
    this.showInstructionDetails = true;
  }

  public onDocumentSelected(fileList: File[] | FileList, fileManager: Components.IFileManager) {

  }

  public SortItems(filterBy: string | any) {
    if (filterBy == "Name") {
      this.pageManagerMember.resetSort(new PropertyInfo("Name"), !this.ascending);
      this.ascending = !this.ascending;
    }
    if (filterBy == "Status") {
      this.pageManagerMember.resetSort(new PropertyInfo("Status"), !this.ascending);
      this.ascending = !this.ascending;
    }
    if (filterBy == "Instruction") {
      this.pageManagerMember.resetSort(new PropertyInfo("Instruction"), !this.ascending);
      this.ascending = !this.ascending;
    }
    if (filterBy == "Number") {
      this.pageManagerMember.resetSort(new PropertyInfo("Number"), !this.ascending);
      this.ascending = !this.ascending;
    }
    if (filterBy == "Scheme Name") {
      this.pageManagerMember.resetSort(new PropertyInfo("SchemeName"), !this.ascending);
      this.ascending = !this.ascending;
    }
    if (filterBy == "Scheme Number") {
      this.pageManagerMember.resetSort(new PropertyInfo("SchemeNumber"), !this.ascending);
      this.ascending = !this.ascending;
    }
  }

  public pageManagerMember = new Data.PageManager(this.criteria, InstructionsSearchResultsLookup,
    this.memberList,
    {
      pageSize: 5,
      sortBy: "instructionId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public pageManagerScheme = new Data.PageManager(this.criteria, InstructionsSearchResultsLookup,
    this.schemeList,
    {
      pageSize: 5,
      pageSizeOptions: [5, 10],
      sortBy: "instructionId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public pageManagerMemberCompleted = new Data.PageManager(this.criteria, InstructionsSearchResultsLookup,
    this.completedMemberList,
    {
      pageSize: 5,
      pageSizeOptions: [5, 10],
      sortBy: "instructionId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public pageManagerSchemeCompleted = new Data.PageManager(this.criteria, InstructionsSearchResultsLookup,
    this.completedSchemeList,
    {
      pageSize: 5,
      pageSizeOptions: [5, 10],
      sortBy: "instructionId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

}