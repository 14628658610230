import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import img_Upload from '../../assets/img/primary-upload.png';
import InstructionsVM from '../InstructionsVM';

interface IInstructionWithdrawalClaimCompleteViewProps {
  viewModel: InstructionsVM
}

@observer
export default class InstructionWithdrawalClaimCompleteView extends React.Component<IInstructionWithdrawalClaimCompleteViewProps> {

  constructor(props: IInstructionWithdrawalClaimCompleteViewProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='upload-card'>
          <p className='instructon-view-message'>Please submit documents for the member.</p>
          <label className='mt-2 ms-2 form-label up-docs'>Uploaded document/s</label>
          <div className='col-12 col-md-12'>
            <Neo.FileContext
              onFilesSelected={this.props.viewModel.onDocumentSelected}
              allowedExtensions={[".pdf", ".doc", ".jpeg"]} >
              <Neo.FileDropArea className='file-download-area'>
                <i><img src={img_Upload} /></i>
                <label className='file-download-label'>Withdrawal claim_12/01/2023</label>
                <Neo.FileUploadProgress />
              </Neo.FileDropArea>
            </Neo.FileContext>
          </div>
          <div className='col-12 col-md-12'>
            <Neo.FileContext
              onFilesSelected={this.props.viewModel.onDocumentSelected}
              allowedExtensions={[".pdf", ".doc", ".jpeg"]} >
              <Neo.FileDropArea className='file-download-area'>
                <i><img src={img_Upload} /></i>
                <label className='file-download-label'>Withdrawal claim_12/01/2023</label>
                <Neo.FileUploadProgress />
              </Neo.FileDropArea>
            </Neo.FileContext>
          </div>
        </div>
        <div className='container'></div>
      </div>
    );
  }
}