import { Attributes, ModelBase } from '@singularsystems/neo-core';
import { SchemeProductClassType } from '../SchemeProductClassType';

export default class MemberSchemeMembershipLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public nameId: number = 0;

  @Attributes.Nullable()
  public schemeId: number | null = null;

  public schemeNumber: string | null = null;

  public schemeName: string | null = null;

  @Attributes.Nullable()
  public memberId: number | null = null;

  public memberNumber: string | null = null;

  public readonly schemeStatus: string | null = null;

  public readonly memberStatus: string | null = null;

  public readonly schemeProductClassType: SchemeProductClassType = SchemeProductClassType.RiskOnly;

  public isPreservationScheme: boolean = false;

  public employerName: string | null = null;

  // Client only properties / methods
}