import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../..//MasterDataTypes';
import Country from '../../Models/Country';

export interface ICountryQueryApiClient {

    /** 
     * Gets a list of Countries
     * @returns A List of Countries
     */
    getCountries(): AxiosPromise<Array<Model.PlainTrackedObject<Country>>>;

    /** 
     * Gets Country
     * @returns Country
     */
    getCountry(code: number): AxiosPromise<Model.PlainTrackedObject<Country>>;

    // Client only properties / methods
}

@injectable()
export default class CountryQueryApiClient extends Data.ApiClientBase implements ICountryQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/countries`);
    }

    public getCountries(): AxiosPromise<Array<Model.PlainTrackedObject<Country>>> {
        return this.axios.get(`${this.apiPath}/country-list`);
    }

    public getCountry(code: number): AxiosPromise<Model.PlainTrackedObject<Country>> {
        return this.axios.get(`${this.apiPath}/country/${code}`);
    }

    // Client only properties / methods
}