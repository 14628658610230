import React from 'react';
import { observer } from 'mobx-react';
import ComplaintsVM from '../ComplaintsVM';
import "../../../App/Styles/Components/ComplaintsReview.scss";
import { Neo } from '@singularsystems/neo-react';
import ContainerFooterComponent from './ContainerFooterComponent';

interface IComplaintReviewProps {
  viewModel: ComplaintsVM
}

@observer
export default class ComplaintReview extends React.Component<IComplaintReviewProps> {

  constructor(props: IComplaintReviewProps) {
    super(props);
  }

  public render() {
    return (
      <div className='complaints-review-css'>
        <div className='background-card complaint-summary-card'>
          <div className='show-on-web-screen'>
            <div className='heading-co pad-left'>Review complaint information</div><br />
            <div className='row'>
              <div className='divider1 col-md-12'></div>
            </div><br />
            <div className='pad-rl'>
              <div className='border'>
                <div className='heading-cu pad-left'>Member information</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <div className='row table-heading-badb'>
                  <span className='col-4 card-text'>Membership number</span>
                  <span className='col-4 card-text'>Scheme name</span>
                  <span className='col-4 card-text'>Scheme number</span>
                </div>
                <div className='row table-heading-badb'>
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.memberNo}</span>
                  <span className='col-4 card-subheading-text '> {this.props.viewModel.schemeName}</span>
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.schemeNumber}</span>
                </div><br />
                <div className='row table-heading-badb mt-4'>
                  <span className='col-4 card-text'>Cell number</span>
                  <span className='col-4 card-text'>Email address</span>
                </div>
                <div className='row table-heading-badb'>
                  {this.props.viewModel.memberComplaint.contactNumber.length > 6 &&
                    <span className='col-4 card-subheading-text '>{this.props.viewModel.memberComplaint.contactNumber}</span>
                  }
                  {this.props.viewModel.memberComplaint.contactNumber.length <= 6 &&
                    <span className='col-4 card-subheading-text '></span>
                  }
                  <span className='col-4 card-subheading-text '>{this.props.viewModel.memberComplaint.emailAddress}</span>
                </div><br />
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Complaint note</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                <p className='card-summary-text'>{this.props.viewModel.memberComplaint.description}</p><br />
                <div>
                  <div className='divider1'></div>
                </div>
                <div className='heading-cu pad-left'>Uploaded document/s</div>
                <div>
                  <div className='divider1'></div>
                </div><br />
                {this.props.viewModel.memberComplaint.files.map(item => (
                  <p className='card-summary-uploaded-document pad-left'>{item.fileName}</p>
                ))}
              </div>
            </div>
          </div>
          <div className='show-on-phone-screen'>
            <div className='heading-cu-phone page-label-heading'>Review complaint information</div><br />
            <div className='row'>
              <div className='divider1'></div>
            </div><br />
            <Neo.Card className='member-info-card'>
              <div className='heading-cu-phone'>Member information</div>
              <div className='row'>
                <div className='divider1'></div>
              </div>
              <div className='row'>
                <span className='phone-heading'>Membership number</span>
                <span className='phone-value'>{this.props.viewModel.memberNo}</span>
                <span className='phone-heading'>Scheme name</span>
                <span className='phone-value'>{this.props.viewModel.schemeName}</span>
                <span className='phone-heading'>Scheme number</span>
                <span className='phone-value'>{this.props.viewModel.schemeNumber}</span>
                <span className='phone-heading'>Cell number</span>
                {this.props.viewModel.memberComplaint.contactNumber.length > 6 &&
                  <span className='phone-value'>{this.props.viewModel.memberComplaint.contactNumber}</span>
                }
                {this.props.viewModel.memberComplaint.contactNumber.length <= 6 &&
                  <span className='phone-value'></span>
                }
                <span className='phone-heading mt-3'>Email address</span>
                <span className='phone-value'>{this.props.viewModel.memberComplaint.emailAddress}</span>
              </div>
              <br />
              <div className='row'>
                <div className='divider1'></div>
              </div>
              <div className='heading-cu-phone complaint-note'>Complaint note</div><br />
              <div className='row'>
                <div className='divider1'></div>
              </div><br />
              <p className='card-summary-text-phone'>{this.props.viewModel.memberComplaint.description}</p><br />
              <div className='row'>
                <div className='divider1'></div>
              </div>
              <div className='heading-cu-phone document-label-heading'>Uploaded document/s</div><br />
              <div className='row'>
                <div className='divider1'></div>
              </div>
              {this.props.viewModel.memberComplaint.files.map(item => (
                <p className='card-summary-uploaded-document'>{item.fileName}</p>
              ))}
            </Neo.Card>
          </div><br />
          <ContainerFooterComponent
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonText='Continue'
            secondaryButtonText='Back'
            backLinkText='Save & exit'
            secondaryButtonCallback={() => this.props.viewModel.complaintReview = false}
            primaryButtonCallback={() => this.props.viewModel.submitComplaint()}
          />
        </div>
      </div>
    );
  }
}