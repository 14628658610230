import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import Underwriting from '../../Models/RiskBenefits/Underwriting';
import UnderwritingLookup from '../../Models/RiskBenefits/UnderwritingLookup';

export interface IRiskBenefitsQueryApiClient {

    /** 
     * Fetches the Underwriting Template By Id.
     * @returns Underwriting
     */
    getUnderwriting(templateId: number): AxiosPromise<Model.PlainTrackedObject<Underwriting>>;

    /** 
     * Fetches the UnderwritingLookup By Id.
     * @returns UnderwritingLookup
     */
    getUnderwritingLookup(templateId: number): AxiosPromise<Model.PlainObject<UnderwritingLookup>>;

    /** 
     * Fetches the Underwring templates.
     * @returns A List of Underwritings.
     */
    getUnderwritings(): AxiosPromise<Array<Model.PlainTrackedObject<Underwriting>>>;

    /** 
     * Fetches the UnderwrtingLookups
     * @returns A List of UnderwritingLookups
     */
    getUnderwritingLookups(): AxiosPromise<Array<Model.PlainObject<UnderwritingLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class RiskBenefitsQueryApiClient extends Data.ApiClientBase implements IRiskBenefitsQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/risk-benefits`);
    }

    public getUnderwriting(templateId: number): AxiosPromise<Model.PlainTrackedObject<Underwriting>> {
        return this.axios.get(`${this.apiPath}/underwriting-template/${templateId}`);
    }

    public getUnderwritingLookup(templateId: number): AxiosPromise<Model.PlainObject<UnderwritingLookup>> {
        return this.axios.get(`${this.apiPath}/underwriting-template-lookup/${templateId}`);
    }

    public getUnderwritings(): AxiosPromise<Array<Model.PlainTrackedObject<Underwriting>>> {
        return this.axios.get(`${this.apiPath}/underwriting-templates`);
    }

    public getUnderwritingLookups(): AxiosPromise<Array<Model.PlainObject<UnderwritingLookup>>> {
        return this.axios.get(`${this.apiPath}/underwriting-template-lookups`);
    }

    // Client only properties / methods
}