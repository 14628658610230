import { Attributes, DateUtils, List, LookupBase, ModelBase, NeoModel } from '@singularsystems/neo-core';
import SavingsWithdrawalClaimTemplateCardLookup from './SavingsWithdrawalClaimTemplateCardLookup';

@NeoModel
export default class SavingsWithdrawalClaimTemplateLookup extends ModelBase {

    public savingsWithdrawalClaimTemplateId: number = 0;

    @Attributes.Date()
    public effectiveFrom: Date = new Date();

    @Attributes.Integer()
    @Attributes.Display("Cards")
    public cardCount: number = 0;

    public savingsWithdrawalClaimTemplateCards = new List(SavingsWithdrawalClaimTemplateCardLookup);

    // Client only properties / methods

    @Attributes.Display("Effective From")
    public get effectiveFromString() {
        return DateUtils.format(this.effectiveFrom, "dd MMM yyyy");
    }
}