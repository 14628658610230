import { LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class MemberContactDetailsLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public firstName: string = "";

  public lastName: string = "";

  public contactRoleType: string = "";

  public telePhoneNo: string = "";

  public mobileNo: string = "";

  public emailAddress: string = "";

  // Client only properties / methods

  public getFormattedTelephoneNumber(): string {
    if (this.telePhoneNo.length === 12) {
      return `${this.telePhoneNo.substring(0, 5)} ${this.telePhoneNo.substring(5, 8)} ${this.telePhoneNo.substring(8)}`;
    }
    else if (this.telePhoneNo.length >= 10) {
      return `${this.telePhoneNo.substring(0, 3)} ${this.telePhoneNo.substring(3, 6)} ${this.telePhoneNo.substring(6)}`;
    }
    else {
      return '';
    }
  }

  public getFormattedMobileNumber(): string {
    if (this.mobileNo.length === 12) {
      return `${this.mobileNo.substring(0, 5)} ${this.mobileNo.substring(5, 8)} ${this.mobileNo.substring(8)}`;
    }
    else if (this.mobileNo.length >= 10) {
      return `${this.mobileNo.substring(0, 3)} ${this.mobileNo.substring(3, 6)} ${this.mobileNo.substring(6)}`;
    }
    else {
      return '';
    }
  }
}