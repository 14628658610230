import { Attributes, List, ModelBase } from '@singularsystems/neo-core';
import MemberContributionDetailsContributionGetMemberBillingLookup from './MemberContributionDetailsContributionGetMemberBillingLookup';

export default class MemberBillingLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }


  public sourceId: string = "";

  public memberId: number = 0;

  @Attributes.Date()
  public billDueDate: Date | null = null;

  public billDueDateFormatted: string = "";

  @Attributes.Nullable()
  @Attributes.Float()
  public totalAmountOfContributions: number | null = 0.00;

  public contributionDetails = new List(MemberContributionDetailsContributionGetMemberBillingLookup);

  // Client only properties / methods
}