import { NeoModel, ModelBase } from "@singularsystems/neo-core";

@NeoModel
export default class ProgressStep extends ModelBase {
    public stepId: number = 0;
    public stepNumber: number = 0;
    public stepName: string = "";
    public isActive: boolean = false;
    public isCompleted: boolean = false;
    public canProceed: (() => boolean) = () => true;

    constructor(props: any = null) {
        super();
        if (props) {
            this.stepId = props.stepId;
            this.stepNumber = props.stepNumber;
            this.stepName = props.stepName;

            if (props.canProceed) {
                this.canProceed = props.canProceed;
            }
        }
    }

    public get cssClass(): string {
        return "step" + (this.isActive ? " active" : "") + (this.isCompleted ? " completed" : "")
    }
}