import { LookupBase } from '@singularsystems/neo-core';

export default class EmployerComplaintsSchemeResultsLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public schemeName: string = "";

  public schemeNumber: string = "";

  public cellNumber: string = "";

  public emailAdress: string = "";

  public keyword: string = "";

  // Client only properties / methods

  public get schemeNameAndNumber(): string {
    return this.schemeName + " - " + this.schemeNumber;
  }
}