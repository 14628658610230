import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import TAndCTemplateLookup from '../../Domain/Models/TAndC/TAndCTemplateLookup';
import MemberDashboardView from '../../MemberDashboard/Views/MemberDashboardView';
import { AppService, Types } from '../Services/AppService';

@NeoModel
export default class TAndCVM extends Views.ViewModelBase {
  public showTemplateModal: boolean = true;
  public tAndCTemplate = new TAndCTemplateLookup();
  public tAndC: any = null;

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private tAndcQueryApiClient = AppService.get(Types.Domain.ApiClients.TAndCQueryApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

    super(taskRunner);
  }

  public async initialise() {
    await this.taskRunner.waitFor(this.getLatestTAndCTemplate);
  }

  public navigatePortfolio(): void {
    this.navigation.navigateToView(MemberDashboardView);
  }

  public getLatestTAndCTemplate = this.taskRunner.run(async () => {
    const response = await this.tAndcQueryApiClient.getLatestTAndCTemplate();
    const latestTAndCTemplate = response.data;
    this.tAndC = latestTAndCTemplate;
    this.tAndCTemplate = TAndCTemplateLookup.fromJSObject<TAndCTemplateLookup>(latestTAndCTemplate);
  });
}