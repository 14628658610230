import React from "react";
import { AppService, Types } from "../Services/AppService";
import NotificationsView from "../Views/NotificationsView";
import { Neo } from "@singularsystems/neo-react";
import { EnumHelper, List, ModelBase, NeoModel } from "@singularsystems/neo-core";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import EmployerDashboardView from "../../Dashboard/Views/EmployerDashboardView";
import FADashboardView from "../../FADashboard/Views/FADashboardView";
import LegacyMemberDashboardView from "../../LegacyMemberDashboard/Views/LegacyMemberDashboardView";
import { UserType } from "../../Enums/UserType.enum";

@NeoModel
class UserDetails extends ModelBase {
  public memberValueId: RoleOptions | null = null;
  public memberValue: string = '';
}

enum RoleOptions {
  libertyMember = 1,
  //TODO: uncomment for R2.2
  // financialAdviser = 2,
  // employer = 3,
  // changeDefault = 4
}

EnumHelper.decorateEnum(RoleOptions, e => {
  e.describe(RoleOptions.libertyMember, "Liberty member", "");

  //TODO: uncomment for R2.2
  // e.describe(RoleOptions.libertyMember, "Liberty Member", ""),
  // e.describe(RoleOptions.financialAdviser, "Financial Adviser", ""),
  // e.describe(RoleOptions.employer, "Employer", ""),
  // e.describe(RoleOptions.changeDefault, "Change Default Role", "");
});

export default class UserStatus extends React.Component {
  public isExpanded = false;
  public userDetails = new UserDetails;
  public headerRightClass = "role-codes-dropdown";
  private authService = AppService.get(Types.App.Services.AuthenticationService);
  private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
  private userRoleService = AppService.get(Types.App.Services.UserRoleService);

  constructor(props: any) {
    super(props);

    this.logoutClicked = this.logoutClicked.bind(this);
    this.userDetails.memberValue = "Liberty Member";
    this.userDetails.memberValueId = RoleOptions.libertyMember;
  }

  private logoutClicked(e: React.MouseEvent) {
    e.preventDefault();
    this.authService.beginSignOut();
  }

  public navigateNotification(): void {
    this.navigation.navigateToView(NotificationsView);
  }

  public render() {
    const user = this.authService.user;
    return (
      user && <div className="app-user-icon">
        <div className="role-codes-dropdown">
          <Neo.AutoCompleteDropDown
            isSearchable={false}
            bind={this.userDetails.meta.memberValueId}
            isClearable={false}
            items={EnumHelper.asList(RoleOptions)}
            //TODO: Remove for R2.2
            readOnly
          //TODO: uncomment for R2.2
          //onItemSelected={() => this.selectedRole(this.userDetails.meta.memberValueId)} 
          />
        </div>
      </div>
    )
  }

  //TODO: uncomment for R2.2
  // private selectedRole(memberValueId: IPropertyInstance<RoleOptions | null>) {
  //   if (memberValueId.value === RoleOptions.changeDefault) {
  //     // #TODO: navigate to the Role Selector Screen
  //   } else if (memberValueId.value === RoleOptions.employer) {
  //     this.userRoleService.setRole(UserType.Employer);
  //     this.navigation.navigateToView(EmployerDashboardView);
  //   } else if (memberValueId.value === RoleOptions.libertyMember) {
  //     this.userRoleService.setRole(UserType.Legacy);
  //     this.navigation.navigateToView(LegacyMemberDashboardView);
  //   } else if (memberValueId.value === RoleOptions.financialAdviser) {
  //     this.userRoleService.setRole(UserType.FA);
  //     this.navigation.navigateToView(FADashboardView);
  //   }
  // }
}