import React from 'react';
import { observer } from 'mobx-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import { Neo } from '@singularsystems/neo-react';
import InformationCard from '../../../App/Views/Components/InformationCard';

interface IHowItWorksProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class HowItWorks extends React.Component<IHowItWorksProps> {
  constructor(props: IHowItWorksProps) {
    super(props);
  }


  public render() {
    return (
      <div>
        <div className='header-of-step'>
          How does a savings withdrawal work?
        </div>
        {this.props.viewModel.savingsWithdrawalClaimTemplate.savingsWithdrawalClaimTemplateCards.map((item) => (
          <div className='card-container'>
            <div className='col-12'>
              <Neo.Card key={item.savingsWithdrawalClaimTemplateCardId}
                title={
                  <div className='align-title'>
                    <Neo.Button className="fa-2x cards-img-vas" variant='link' icon={item.icon} />
                    {item.title}
                  </div>}
                isExpanded={item.isExpanded}
                className='withdrawal-claims-cards'>
                <div className='col-md-12'>
                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </Neo.Card >
            </div>
          </div>
        ))}
        <div className='m-4'>
          <div className='separating-line' />
        </div>
        <div className='header-of-step'>
          How does a savings withdrawal work?
        </div>
        <div className='m-4 mt-2'>
          Contact the Benefit Counselling team for more information (offered in various languages at no additional cost).
          Interested in receiving advice based on your individual needs? Speak to your fund financial adviser.
        </div>
        <div className='how-it-works-contact-card-container'>
          <div className='row align-cards-savings-claim'>
            {this.props.viewModel.benefitCounsellingContactUsTemplateLookup.contactUsTemplateCards.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  firstName={item.splitHeading()}
                  lastName={item.splitHeadingTwo}
                  headingText={item.heading}
                  available={(item.availableFrom == null || item.availableTill == null) ? '' : 'Available: ' + item.availableFrom + '- ' + item.availableTill}
                  telHeading={item.telephoneNumber == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.getFormattedTelephoneNumber()}
                  additionalTelHeading={item.additionalTelephoneNumber == '' ? '' : '(select the Benefit Counselling)'}
                  additionalTel={' ' + item.getFormattedAdditionalTelNumber()}
                  faxHeading={item.fax == '' ? '' : 'Fax'}
                  fax={' ' + item.getFormattedFax()}
                  emailHeading={item.email == '' ? '' : 'Email address'}
                  email={' ' + item.email}
                  additionalEmailHeading={item.additionalEmail == '' ? '' : 'General enquiries'}
                  additionalEmail={' ' + item.additionalEmail}
                  postalAddressHeading={item.address == '' ? '' : 'Postal address'}
                  postalAddressName={' ' + item.address}
                  postalAddressPOBox={' ' + item.postalBox}
                  postalAddressCity={' ' + item.city}
                  postalAddressCityCode={' ' + item.postalCode} />
              </div>
            ))}
            {this.props.viewModel.memberFAContactDetails.map((item) => (
              <div className='col-md-3 negate-col-information-card-margin'>
                <InformationCard
                  firstName={item.firstName}
                  lastName={item.lastName}
                  headingText={'Fund financial adviser for ' + this.props.viewModel.userMemberSchemeService.currentSchemeSelected.schemeName ?? ""}
                  available={item.firstName + ' ' + item.lastName}
                  telHeading={item.telePhoneNo == '' ? '' : 'Tel. no.'}
                  tel={' ' + item.telePhoneNo}
                  additionalTelHeading={''}
                  additionalTel={''}
                  faxHeading={item.mobileNo == '' ? '' : 'Cellphone'}
                  fax={' ' + item.mobileNo}
                  emailHeading={item.emailAddress == '' ? '' : 'Email address'}
                  email={' ' + item.emailAddress}
                  additionalEmailHeading={''}
                  additionalEmail={' '}
                  postalAddressHeading={''}
                  postalAddressName={' '}
                  postalAddressPOBox={' '}
                  postalAddressCity={' '}
                  postalAddressCityCode={' '} />
              </div>
            ))}
          </div>
        </div>
      </div >
    );
  }
}