import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import ClaimDeclarationTemplate from '../../Models/Claims/ClaimDeclarationTemplate';

export interface IClaimDeclarationCommandApiClient {

    /** 
     * Updates a ClaimDeclaration template.
     * @param ClaimDeclarationTemplate The ClaimDeclaration template to update.
     * @returns The updated template.
     */
    updateClaimDeclarationTemplate(ClaimDeclarationTemplate: Model.PartialPlainObject<ClaimDeclarationTemplate>): AxiosPromise<Model.PlainTrackedObject<ClaimDeclarationTemplate>>;

    // Client only properties / methods
}

@injectable()
export default class ClaimDeclarationCommandApiClient extends Data.ApiClientBase implements IClaimDeclarationCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/claimDeclaration`);
    }

    public updateClaimDeclarationTemplate(ClaimDeclarationTemplate: Model.PartialPlainObject<ClaimDeclarationTemplate>): AxiosPromise<Model.PlainTrackedObject<ClaimDeclarationTemplate>> {
        return this.axios.put(`${this.apiPath}/claimDeclaration-template`, ClaimDeclarationTemplate);
    }

    // Client only properties / methods
}