import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import ClaimDeclarationGroupTemplateLookup from '../../Models/Claims/ClaimDeclarationGroupedTemplateLookup';

export interface IClaimDeclarationQueryApiClient {

    /** 
     * Gets the ClaimDeclarations templates.
     * @returns A list of ClaimDeclarations templates.
     */
    getClaimDeclarations(): AxiosPromise<Array<Model.PlainObject<ClaimDeclarationGroupTemplateLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class ClaimDeclarationQueryApiClient extends Data.ApiClientBase implements IClaimDeclarationQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/claimDeclaration`);
    }

    public getClaimDeclarations(): AxiosPromise<Array<Model.PlainObject<ClaimDeclarationGroupTemplateLookup>>> {
        return this.axios.get(`${this.apiPath}/claimDeclaration-templates`);
    }

    // Client only properties / methods
}