import { Components, ModalUtils, TaskRunner } from "@singularsystems/neo-core";
import { ErrorHandler } from "@singularsystems/neo-core/dist/Components/ErrorHandler";
import IModalOptions from "@singularsystems/neo-core/dist/Components/Modal/ModalOptions";
import { observable } from "mobx";
import { Observer } from "mobx-react";
import blue_guy from '../assets/img/blue-guy.png'
import { Neo } from "@singularsystems/neo-react";
import '../Styles/Components/CustomErrorHandler.scss'

class ErrorGroup {
  constructor(public errorDisplay: Components.IErrorDisplay) { }
  @observable
  public count = 1;
}

export class CustomErrorHandler extends ErrorHandler {
  @observable.shallow
  private errorList: ErrorGroup[] = [];

  @observable.ref
  private hidingErrorList: ErrorGroup[] | null = null;

  protected displayModal(error: any, errorDisplay: Components.IErrorDisplay) {
    const errorGroup = new ErrorGroup(errorDisplay);

    if (errorDisplay.statusCode) {
      let existing = this.errorList.find(c => c.errorDisplay.statusCode === errorDisplay.statusCode);
      if (existing) {
        existing.count += 1;
        return;
      }
    }
    if (this.errorList.length === 0) {
      this.hidingErrorList = null;
      const modalOptions: Partial<IModalOptions> = errorDisplay.isServerError ? {
        footerContent:
          <div className="neo-error-modal-info">
            Timestamp: {new Date().toISOString()}
          </div>
      } : {};

      modalOptions.onClose = () => {
        this.hidingErrorList = this.errorList;
        this.errorList = [];
      }

      modalOptions.closeButton = false;

      ModalUtils.showModal("Something went wrong",
        <Observer>
          {() => (
            <>
              {(this.hidingErrorList ?? this.errorList).map((errorGroup, index) => (
                <div className="custom-error-handler" key={index}>
                  <div>
                    {errorGroup.errorDisplay.body === "Network Error" ? (
                      <p className="modal-text">
                        Please try again. If problem persists please contact{" "}
                        <a style={{ wordBreak: "break-word" }} className="link-under" href={`mailto:lc.contact@liberty.co.za`}>lc.contact@liberty.co.za</a>
                        {" "}or{" "}
                        <a className="link-under" href={"tel:{+2711558299}"}>+21 11 558 299</a>
                      </p>
                    ) : (
                      <p className="modal-text">{errorGroup.errorDisplay.body}</p>
                    )}
                    <img className="blue-guy mt-5" src={blue_guy} alt="Blue Guy" />
                    <br />
                    <br />
                    <Neo.Button
                      onClick={(e) => {
                        if (modalOptions.onClose) {
                          modalOptions.onClose(e);
                        }
                      }}
                      className="modal-button"
                    >
                      Okay
                    </Neo.Button>
                  </div>
                </div>
              ))}
            </>
          )}
        </Observer>, modalOptions
      );
    }
    if (this.errorList.length < 5) {
      this.errorList.push(errorGroup);
    }
  }
}