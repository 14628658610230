import { Attributes, LookupBase, Model, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SavingsWithdrawalClaimTemplateCardLookup extends ModelBase {

    public savingsWithdrawalClaimTemplateCardId: number = 0;

    public savingsWithdrawalClaimTemplateId: number = 0;

    public title: string = "";

    public description: string = "";

    public icon: string = "";

    @Attributes.Integer()
    public order: number = 99;

    public modifiedBy: number = 0;

    @Attributes.Date()
    public modifiedOn: Date = new Date();

    // Client only properties / methods

    public  isExpanded = new Model.ObservableValue(false);
}