import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import DisabilityBenefitsTable from './DisabilityBenefitsTable';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';

interface IDisabilityBenefitsCardProps {
  benefitGroup: BenefitGroup
  disclaimerText: string
  tableText: string
  benefitType: string
}

@observer
export default class DisabilityBenefitsCard extends React.Component<IDisabilityBenefitsCardProps> {

  constructor(props: IDisabilityBenefitsCardProps) {
    super(props);
  }

  public render() {
    return (
      <Neo.Card
        title={this.props.benefitType}
        className='cards-sub-risk cards-sub-headings-risk'
        isExpanded={this.props.benefitGroup.isExpanded}>
        <DisabilityBenefitsTable
          acceptedAmountTotal={this.props.benefitGroup.approvedAcceptedAmount + this.props.benefitGroup.unApprovedAcceptedAmount}
          benefitGroup={this.props.benefitGroup}
          disclaimerText={this.props.disclaimerText}
          tableText={this.props.tableText}
          benefitType={this.props.benefitType}
          entitledAmountTotal={this.props.benefitGroup.approvedEntitledAmount + this.props.benefitGroup.unApprovedEntitledAmount}
        />
      </Neo.Card>
    );
  }
}