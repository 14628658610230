import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { Model } from '@singularsystems/neo-core';
import ProductsVM from '../Views/ProductsVM';
import DeathBenefitsCard from './RiskBenefits/DeathBenefitsCard';
import RiskBenefitCard from './RiskBenefits/RiskBenefitCard';
import DisabilityBenefitsCard from './RiskBenefits/DisabilityBenefitsCard';
import MemberBenefitsData from '../../Party/Models/LookUps/MemberBenefits/MemberBenefitsData';
import { BenefitGroup } from '../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import ProductDisabilityBenefitCard from './ProductDisabilityBenefitCard';
import BenefitClassName from '../../Party/Models/Enums/BenefitClassName';
import BenefitType from '../../Party/Models/Enums/BenefitType';

interface IProductDeathBenefitsProps {
  viewModel: ProductsVM
}

@observer
export default class ProductDeathBenefits extends React.Component<IProductDeathBenefitsProps> {

  public isParentExpanded = new Model.ObservableValue(false);
  public isDeathExpanded = new Model.ObservableValue(false);
  public isAccidentExpanded = new Model.ObservableValue(false);
  public isSpouseExpanded = new Model.ObservableValue(false);
  public isChildrenExpanded = new Model.ObservableValue(false);
  public deathSeen = false;

  constructor(props: IProductDeathBenefitsProps) {
    super(props);
  }
  public render() {
    return (
      <div>
        {
          this.props.viewModel.memberBenefitsLookup.memberBenefitsData.filter(x => x.benefitClassName !== BenefitClassName.deathOfSpouse)?.filter(x => x.benefitGrouping.length > 0)?.map((memberbenefitData: MemberBenefitsData, index: number) => (
            memberbenefitData.benefitClassName &&
            <Neo.Card key={index} title={
              memberbenefitData.benefitClassName == BenefitClassName.deathOfMember ? "Risk benefits payable on your death" :
                memberbenefitData.benefitClassName == BenefitClassName.disabilityOfMember ? "Risk benefits payable if you become disabled" :
                  memberbenefitData.benefitClassName == BenefitClassName.criticalIllnessOfMember ? "Risk benefits payable if you contract a critical illness" :
                    memberbenefitData.benefitClassName == ""}
              className="cards-risk cards-headings-risk"
              isExpanded={memberbenefitData.isExpanded}>
              {
                memberbenefitData.benefitClassName == BenefitClassName.deathOfMember &&
                <div>
                  {
                    memberbenefitData.benefitGrouping?.map((DeathBenefitGroup: BenefitGroup, index: number) => (
                      DeathBenefitGroup.benefitType.includes(BenefitType.deathBenefit) ?
                        <DeathBenefitsCard
                          key={index}
                          benefitGroup={DeathBenefitGroup}
                          disclaimer={DeathBenefitGroup.disclaimer}
                          tableText={DeathBenefitGroup.tableText}
                        />
                        :
                        <RiskBenefitCard
                          benefitGroup={DeathBenefitGroup}
                          disclaimer={DeathBenefitGroup.disclaimer}
                          tableText={DeathBenefitGroup.tableText}
                        />
                    ))
                  }
                  <div>
                    {
                      this.props.viewModel.memberBenefitsLookup.deathEducatorBenefit == BenefitType.progressiveEducatorBenefit &&
                      <div className='grey-box-risk'>
                        Progressive Educator Benefit Death cover( if you pass away while still employed the Progressive Educator Benefit provides towards the education of your children, up to defined limit as indicated in the policy terms risk benefits)
                      </div>
                    }
                    {
                      this.props.viewModel.memberBenefitsLookup.deathEducatorBenefit == BenefitType.foundationEducatorBenefit &&
                      <div className='grey-box-risk'>
                        Foundation Educator Benefit Death cover( if you pass away while still employed the Foundation Educator Benefit provides towards the education of your children, up to defined limit as indicated in the policy terms risk benefits)
                      </div>
                    }
                    <br />
                  </div>
                </div>
              }
              {
                memberbenefitData.benefitClassName == BenefitClassName.disabilityOfMember &&
                <div>
                  <div className='grey-box-risk'>
                    These are benefits that may become payable should you become disabled while still employed.
                  </div>
                  <br />
                  {
                    memberbenefitData.benefitGrouping?.filter(x => (x.benefitType !== BenefitType.progressiveLevelIncomeProtectionBenefit &&
                      x.benefitType !== BenefitType.progressiveReducingIncomeProtectionBenefit &&
                      x.benefitType !== BenefitType.occupationalLimitedTermIncomeProtectionBenefit &&
                      x.benefitType !== BenefitType.occupationalLevelIncomeProtectionBenefit &&
                      x.benefitType !== BenefitType.occupationalReducingIncomeProtectionBenefit &&
                      x.benefitType !== BenefitType.healthPremiumWaiver &&
                      x.benefitType !== BenefitType.employerRetirementContributionAndPremiumWaiver &&
                      x.benefitType !== BenefitType.incomeProtectionPlusBenefit)
                    ).map((disabilityBenefitGroup: BenefitGroup, index: number) => (
                      <div>
                        {
                          this.props.viewModel.checkIfLumpSumBenefit(disabilityBenefitGroup) == true &&
                          <div className='card-heading-products'><p>Lump sum disability benefits</p></div>
                        }
                        {
                          disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalCapitalDisability) ?
                            <DisabilityBenefitsCard
                              benefitGroup={disabilityBenefitGroup}
                              disclaimerText={disabilityBenefitGroup.disclaimer}
                              tableText={disabilityBenefitGroup.tableText}
                              benefitType={disabilityBenefitGroup.benefitType}
                            /> :
                            disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveCapitalDisability) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalLevelIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalReducingIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveLevelIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveReducingIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalLimitedTermIncomeProtectionBenefit) ?
                              <ProductDisabilityBenefitCard
                                benefitGroup={disabilityBenefitGroup}
                                disclaimer={disabilityBenefitGroup.disclaimer}
                                tableText={disabilityBenefitGroup.tableText}
                              />
                              :
                              <RiskBenefitCard
                                benefitGroup={disabilityBenefitGroup}
                                disclaimer={disabilityBenefitGroup.disclaimer}
                                tableText={disabilityBenefitGroup.tableText}
                              />
                        }
                      </div>
                    ))
                  }
                  {
                    memberbenefitData.benefitGrouping?.filter(x => (x.benefitType !== BenefitType.progressiveCapitalDisability &&
                      x.benefitType !== BenefitType.occupationalCapitalDisability &&
                      x.benefitType !== BenefitType.accidentalDisability)
                    ).map((disabilityBenefitGroup: BenefitGroup, index: number) => (
                      <div>
                        {
                          this.props.viewModel.checkIfMonthlyBenefit(disabilityBenefitGroup) == true &&
                          <div className='card-heading-products'><p>Income disability benefits</p></div>
                        }
                        {
                          disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalCapitalDisability) ?
                            <DisabilityBenefitsCard
                              benefitGroup={disabilityBenefitGroup}
                              disclaimerText={disabilityBenefitGroup.disclaimer}
                              tableText={disabilityBenefitGroup.tableText}
                              benefitType={disabilityBenefitGroup.benefitType}
                            /> :
                            disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveCapitalDisability) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalLevelIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalReducingIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveLevelIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.progressiveReducingIncomeProtectionBenefit) ||
                              disabilityBenefitGroup.benefitType.includes(BenefitType.occupationalLimitedTermIncomeProtectionBenefit) ?
                              <ProductDisabilityBenefitCard
                                benefitGroup={disabilityBenefitGroup}
                                disclaimer={disabilityBenefitGroup.disclaimer}
                                tableText={disabilityBenefitGroup.tableText}
                              />
                              :
                              <RiskBenefitCard
                                benefitGroup={disabilityBenefitGroup}
                                disclaimer={disabilityBenefitGroup.disclaimer}
                                tableText={disabilityBenefitGroup.tableText}
                              />
                        }
                      </div>
                    ))
                  }
                  {
                    this.props.viewModel.memberBenefitsLookup.disabledEducatorBenefit == BenefitType.progressiveEducatorBenefit &&
                    <div className='grey-box-risk'>
                      Progressive Educator Benefit Disability cover( if you become disabled while still employed the Progressive Educator Benefit provides towards the education of your children, up to defined limit as indicated in the policy terms risk benefits)
                    </div>
                  }
                  {
                    this.props.viewModel.memberBenefitsLookup.disabledEducatorBenefit == BenefitType.foundationEducatorBenefit &&
                    <div className='grey-box-risk'>
                      If you become disabled while still employed, the foundation educator benefit provides towards the education of your children, up to defined limit as indicated in the policy terms
                    </div>
                  }
                </div>
              }
              {
                memberbenefitData.benefitClassName == BenefitClassName.criticalIllnessOfMember &&
                <div>
                  <div className='grey-box-risk'>
                    These are benefits that may become payable should you contract a critical illness while still employed.
                  </div>
                  <br />
                  {
                    memberbenefitData.benefitGrouping?.map((criticalIllnessBenefitGroup: BenefitGroup) => (
                      <ProductDisabilityBenefitCard
                        benefitGroup={criticalIllnessBenefitGroup}
                        disclaimer={criticalIllnessBenefitGroup.disclaimer}
                        tableText={criticalIllnessBenefitGroup.tableText}
                      />
                    ))
                  }
                </div>
              }
            </Neo.Card>
          ))
        }
      </div>
    );
  }
}