import { AppServices } from '@singularsystems/neo-core';
import VASQueryApiClient from './ApiClients/VAS/VASQueryApiClient';
import VASCommandApiClient from './ApiClients/VAS/VASCommandApiClient';
import { DomainTypes } from './DomainTypes';
import { DomainDataCache } from './Services/DomainDataCache';
import KCQueryApiClient from './ApiClients/KnowledgeCentre/KCQueryApiClient';
import FundFactSheetQueryApiClient from './ApiClients/FundFactSheets/FundFactSheetQueryApiClient';
import FundFactSheetCommandApiClient from './ApiClients/FundFactSheets/FundFactSheetCommandApiClient';
import TAndCQueryApiClient from './ApiClients/TAndC/TAndCQueryApiClient';
import TAndCCommandApiClient from './ApiClients/TAndC/TAndCCommandApiClient';
import ContactUsCommandApiClient from './ApiClients/ContactUs/ContactUsCommandApiClient';
import ContactUsQueryApiClient from './ApiClients/ContactUs/ContactUsQueryApiClient';
import PDFQueryApiClient from './ApiClients/PDF/PDFQueryApiClient';
import EducationalInformationQueryApiClient from './ApiClients/EducationalInformation/EducationalInformationQueryApiClient';
import EducationalInformationCommandApiClient from './ApiClients/EducationalInformation/EducationalInformationCommandApiClient';
import JWTTokenApiClient from './ApiClients/JWT/JWTTokenApiClient';
import EmployerReportsAndDocumentsApiClient from '../Dashboard/ApiClients/EmployerReportsAndDocumentsApiClient';
import UserTAndCQueryApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCQueryApiClient';
import UserTAndCCommandApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCCommandApiClient';
import ReportsApiClient from './ApiClients/Reports/ReportsApiClient';
import SystemInformationQueryApiClient from './ApiClients/SystemInformation/SystemInformationQueryApiClient';
import MemberWebInterfaceOptionsCommandApiClient from './ApiClients/WebInterfaceOptions/MemberWebInterfaceOptionsCommandApiClient';
import MemberWebInterfaceOptionsQueryApiClient from './ApiClients/WebInterfaceOptions/MemberWebInterfaceOptionsQueryApiClient';
import RiskBenefitsQueryApiClient from './ApiClients/RiskBenefits/RiskBenefitsQueryApiClient';
import RiskBenefitsCommandApiClient from './ApiClients/RiskBenefits/RiskBenefitsCommandApiClient';
import KCCommandApiClient from './ApiClients/KnowledgeCentre/KCCommandApiClient';
import SavingsWithdrawalClaimQueryApiClient from './ApiClients/SavingsWithdrawalClaim/SavingsWithdrawalClaimQueryApiClient';
import SavingsWithdrawalClaimCommandApiClient from './ApiClients/SavingsWithdrawalClaim/SavingsWithdrawalClaimCommandApiClient';
import ClaimDeclarationQueryApiClient from './ApiClients/Claims/ClaimDeclarationQueryApiClient';
import ClaimDeclarationCommandApiClient from './ApiClients/Claims/ClaimDeclarationCommandApiClient';

export const DomainAppModule = new AppServices.Module("Domain", container => {
  // Api Clients
  container.bind(DomainTypes.ApiClients.VASQueryApiClient).to(VASQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.VASCommandApiClient).to(VASCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.KCQueryApiClient).to(KCQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.TAndCQueryApiClient).to(TAndCQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.TAndCCommandApiClient).to(TAndCCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.FundFactSheetQueryApiClient).to(FundFactSheetQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.FundFactSheetCommandApiClient).to(FundFactSheetCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.ContactUsQueryApiClient).to(ContactUsQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.ContactUsCommandApiClient).to(ContactUsCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.PDFQueryApiClient).to(PDFQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.EducationalInformationQueryApiClient).to(EducationalInformationQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.EducationalInformationCommandApiClient).to(EducationalInformationCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.JWTTokenApiClient).to(JWTTokenApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.UserTAndCQueryApiClient).to(UserTAndCQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.UserTAndCCommandApiClient).to(UserTAndCCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.ReportsApiClient).to(ReportsApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.SavingsWithdrawalClaimQueryApiClient).to(SavingsWithdrawalClaimQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.SavingsWithdrawalClaimCommandApiClient).to(SavingsWithdrawalClaimCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.SystemInformationQueryApiClient).to(SystemInformationQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.MemberWebInterfaceOptionsCommandApiClient).to(MemberWebInterfaceOptionsCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.MemberWebInterfaceOptionsQueryApiClient).to(MemberWebInterfaceOptionsQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.RiskBenefitsQueryApiClient).to(RiskBenefitsQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.RiskBenefitsCommandApiClient).to(RiskBenefitsCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.KCCommandApiClient).to(KCCommandApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.ClaimDeclarationQueryApiClient).to(ClaimDeclarationQueryApiClient).inSingletonScope();
  container.bind(DomainTypes.ApiClients.ClaimDeclarationCommandApiClient).to(ClaimDeclarationCommandApiClient).inSingletonScope();

  // Services
  container.bind(DomainTypes.Services.DataCache).to(DomainDataCache).inSingletonScope();
});

export const DomainTestModule = new AppServices.Module("Domain", container => {
  // bind test types
});