import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import ContactUsTemplate from '../../Models/ContactUs/ContactUsTemplate';

export interface IContactUsCommandApiClient {

    /** 
     * Updates a ContactUs template.
     * @param contactUsTemplate The ContactUs template to update.
     * @returns The updated template.
     */
    updateContactUsTemplate(contactUsTemplate: Model.PartialPlainObject<ContactUsTemplate>): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>>;

    /** 
     * Deletes the contactUsCardTemplate
     */
    deleteContactUsCard(templateId: number): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class ContactUsCommandApiClient extends Data.ApiClientBase implements IContactUsCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/contactUs`);
    }

    public updateContactUsTemplate(contactUsTemplate: Model.PartialPlainObject<ContactUsTemplate>): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>> {
        return this.axios.put(`${this.apiPath}/contactUs-template`, contactUsTemplate);
    }

    public deleteContactUsCard(templateId: number): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/contactUs-template/${templateId}`);
    }

    // Client only properties / methods
}