import React from 'react';
import { observer } from 'mobx-react';
import InstructionsVM from '../InstructionsVM';
import { Neo } from '@singularsystems/neo-react';

interface IInstructionQueryViewProps {
    viewModel: InstructionsVM
}

@observer
export default class InstructionQueryView extends React.Component<IInstructionQueryViewProps> {

    constructor(props: IInstructionQueryViewProps) {
        super(props);
    }

    public render() {
        return (
            <div>
                <p className='instructon-view-message pb-message'>Please respond to the member query with the contact details provided. Once you have completed your query request you can complete the instruction.</p>
                <div className='border'>
                    <div className='show-on-web-screen'>
                        <div className='heading-cu query-heading'>Member Information</div>
                        <div>
                            <div className='divider1'></div>
                        </div>
                        <div className='row table-heading-badb'>
                            <span className='col-3 card-text'>Membership number</span>
                            <span className='col-3 card-text'>Preferred communication method</span>
                            <span className='col-3 card-text'>Cell number</span>
                            <span className='col-3 card-text'>Email address</span>
                        </div>
                        <div className='row table-heading-badb'>
                            <span className='col-3 card-subheading-text '>{this.props.viewModel.meta.query.value.membershipNumber}</span>
                            <span className='col-3 card-subheading-text '>{this.props.viewModel.query.isEmailSelected ? "Email" : "SMS"}</span>
                            <span className='col-3 card-subheading-text '>{this.props.viewModel.countryValue} {this.props.viewModel.query.cellNumber}</span>
                            <span className='col-3 card-subheading-text '>{this.props.viewModel.query.emailAddress}</span>
                        </div>
                        <div>
                            <div className='divider1'></div>
                        </div>
                    </div>
                    <div className='show-on-phone-screen'>
                        <div className='heading-cu-phone'>Member information</div><br />
                        <div className='divider1'></div>
                        <br />
                        <div className='row'>
                            <span className='phone-heading'>Membership number</span><br />
                            <span className='phone-value'>{this.props.viewModel.meta.query.value.membershipNumber}</span><br /><br />
                            <span className='phone-heading'>Preffered communication method</span><br />
                            <span className='phone-value'>{this.props.viewModel.query.isEmailSelected ? "Email" : "SMS"}</span> <br /><br />
                            <span className='phone-heading'>Cell number</span><br />
                            <span className='phone-value'>{this.props.viewModel.countryValue} {this.props.viewModel.query.cellNumber}</span> <br /><br />
                            <span className='phone-heading'>Email address</span><br />
                            <span className='phone-value'>{this.props.viewModel.query.emailAddress}</span>
                        </div>
                        <br />
                    </div>
                    <div className='heading-cu query-heading'>Query Note</div>
                    <br />
                    <p className='card-text'>Query</p>
                    <p className='card-summary-text'>{this.props.viewModel.query.query}</p>
                    <br />
                    <div className='heading-cu instructon-view-docs-label'>Documents</div>
                    <div className='divider1'></div>
                    <p className='instructon-view-uploaded-docs'>Uploaded documents</p>
                    <Neo.Link className='instructon-view-query-pdf'>Query pdf</Neo.Link>
                    <br /><br />
                </div>
                <br />
            </div>
        );
    }
}