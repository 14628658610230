import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import '../Styles/EmployerDashboard.scss';
import img_info from '../../App/assets/img/information.png';
import img_profile from '../Assets/Img/profile-image.png';
import img_filter from '../Assets/Img/img-slider-controls.png';
import img_users from '../../App/assets/img/users.png';
import EmployerDashboardVM from './EmployerDashboardVM';
import EmployerDashboardSearchResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerDashboardSearchResultsLookup';
import LibertyPager from '../Components/LibertyPager';
import { EnumHelper } from '@singularsystems/neo-core';
import { MemberStatus } from '../Models/Enums/Employer/MemberStatus.enum';
import EmployerDashboardSchemeSearchResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerDashboardSchemeSearchResultsLookup';

@observer
export default class EmployerDashboardView extends Views.ViewBase<EmployerDashboardVM> {

  constructor(props: unknown) {
    super("Employer Dashboard", EmployerDashboardVM, props);

  }

  public render() {
    return (
      <div>
        <div className='mt-4 employer-dashboard-card'>
          <div className='row'>
            <div className='align-profile-info'>
              <div className='col-md-6 col-12'>
                <p className='emp-dashboard-card-heading'>Overview</p>
                <p className='card-sub-heading-emp-dashboard'>An overview of all information regarding your portfolio</p>
              </div>
            </div>
          </div>
          <Neo.TabContainer
            className='employer-dashboard-tabs'
            selectedTab={this.viewModel.meta.selectedTab}>
            <Neo.Tab
              header={"Members"}
              name={"Members"}>
              <div className='row search-by'>
                <Neo.Card className='col-md-10'>
                  <Neo.FormGroup
                    className='search-dropdown'
                    bind={this.viewModel.criteria.meta.keyword}
                    placeholder="Search by..."
                    onChange={() => this.viewModel.updateDocumentSetGrid()}>
                  </Neo.FormGroup>
                  <Neo.Button className="align-filter" tooltip="Filter" onClick={() => { this.viewModel.showCardCss = true }}><i><img src={img_filter}></img></i></Neo.Button>
                </Neo.Card>
                <Neo.Button className="col-md-2 btn-filter" onClick={() => { this.viewModel.updateDocumentSetGrid() }}>Search</Neo.Button>
                {this.viewModel.showCardCss &&
                  <div className='show-dropdown'>
                    <div className='row padding-bottom-radio'>
                      <div className='col-md-2 expand-col-surface'>
                        <div className='filter-header'>
                          Member status:
                        </div>
                        <Neo.RadioList bind={this.viewModel.meta.memberStatus} radioList={{ enumType: MemberStatus }} className="filter-radio-list" />
                      </div>
                      <div className='col-md-6'>
                        <div className='filter-header'>
                          Scheme status:
                        </div>
                        <div className='filter-inline-checkboxes'>
                          {this.viewModel.schemeStatusList.map(element => {
                            return [
                              <div className='filter-inline-checkboxes-label'>
                                <input
                                  key={element.value}
                                  checked={element.isSelected}
                                  type="radio"
                                  onClick={(evt) => {
                                    element.isSelected = !element.isSelected;
                                  }}
                                  value={element.name}>
                                </input>
                                <label htmlFor={element.name} >{element.name}</label>
                              </div>
                            ]
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='row separating-line-padding '>
                      <div className='separating-line-max' />
                    </div>
                    <div className='row'>
                      <div className='align-filter-link row'>
                        <span className="save-return-link col-md-6">
                          <a className='save-return-link' onClick={() => this.viewModel.removeAllFilters()}>Clear all filters </a>
                        </span>
                        <span className='align-buttons col-md-6'>
                          <Neo.Button text="Cancel" onClick={() => { this.viewModel.showCardCss = false }} className='btn btn-cancel' />
                          <Neo.Button text="Apply" onClick={() => this.viewModel.showChosenFilters()} className='btn btn-apply' />
                        </span>
                      </div>
                    </div>
                  </div>
                }
                {(this.viewModel.memberStatus !== null || this.viewModel.totalMemberEnumValue !== null) &&
                  <div>
                    <div className='row'>
                      <div className='col-md-2'>
                        <div className='filtered-by-header'>
                          Filtered by:
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {this.viewModel.memberStatus !== null &&
                        <div className='col-md-2'>
                          <div className='filtered-by-sub-header'>
                            Member status
                          </div>
                        </div>
                      }
                      {this.viewModel.totalMemberEnumValue !== null &&
                        <div className='col-md-2'>
                          <div className='filtered-by-sub-header'>
                            Scheme status
                          </div>
                        </div>
                      }
                    </div>
                    <div className='row'>
                      {this.viewModel.memberStatus !== null &&
                        <div className='col-md-2'>
                          <span className='selected-pill-items'>
                            <span className='background-pill'>
                              {(this.viewModel.memberStatus !== null) &&
                                EnumHelper.getItemMetadata(MemberStatus, this.viewModel.memberStatus).name
                              }
                              <Neo.Button className='close-button' onClick={() => this.viewModel.removeMemberFilter()}></Neo.Button>
                            </span>
                          </span>
                        </div>
                      }
                      <div className='col-md-8'>
                        {this.viewModel.totalMemberEnumValue !== null &&
                          <span className='selected-pill-items'>
                            {this.viewModel.schemeStatusList.filter(elem => { return elem.isSelected }).map(element => {
                              return <span className='background-pill'>{element.name}
                                <Neo.Button className='close-button' onClick={() => this.viewModel.removeSchemeFilter(element)}></Neo.Button>
                              </span>
                            })}
                          </span>
                        }
                        {(this.viewModel.memberStatus !== null || this.viewModel.totalMemberEnumValue !== null) &&
                          <Neo.Button className='clear-all-button' text='Clear all' onClick={() => this.viewModel.removeAllFilters()} />
                        }
                      </div>
                    </div>
                  </div>
                }
                {(this.viewModel.memberStatus !== null || this.viewModel.totalMemberEnumValue !== null) &&
                  <div className='separating-line margin-top' />
                }
                <div className='row mt-2 resize'>
                  <div className='col-12 col-md-12 resize'>
                    {!this.viewModel.showgrid &&
                      <div className={this.viewModel.emptyGridCssClass}>
                        <i> <img src={img_info}></img></i>
                        <div className='grid-info'>Search or filter to manage your portfolio within your organisation</div>
                      </div>
                    }
                    {this.viewModel.showgrid &&
                      <div className='report-grid'>
                        <div className='row'>
                          <LibertyPager
                            maxPagesToShow={2}
                            pageManager={this.viewModel.memberPageManager}
                            pageControlProps={{
                              pageSizeLabel: " ",
                              lastText: " ", totalRecords: 20
                            }}>
                            <NeoGrid.Grid<EmployerDashboardSearchResultsLookup>>
                              {(item, meta) => (
                                <NeoGrid.Row>
                                  <NeoGrid.Column label='Member Name' className='col-md-3 member-name' sort={meta.firstName}><div><img className='align-profile' src={img_profile}></img>{item.firstName + " " + item.lastName}</div></NeoGrid.Column>
                                  <NeoGrid.Column label='Scheme' className='col-md-3' display={meta.scheme} />
                                  <NeoGrid.Column label='Member Status' className='col-md-2 ' display={meta.memberstatusName} />
                                  <NeoGrid.Column label='ID number' className='col-md-2 ' display={meta.idNumber} numProps={{ formatString: "0" }} />
                                  <NeoGrid.Column label='Member number' className='col-md-2 ' display={meta.memberNumber} />
                                  <NeoGrid.Column className='border-three-dots'>
                                    <span className='dropdown-three-dots'>
                                      <Neo.Button
                                        menuAlignment="right"
                                        onClick={() => alert("This won't appear")}
                                        menuItems={
                                          [{
                                            icon: <img src={img_users}></img>,
                                            text: "Update beneficiaries",
                                            onClick: (evt) => { this.viewModel.changeToBenefeciariesView(item) }
                                          },
                                          {
                                            icon: <img src={img_users}></img>,
                                            text: "View member",
                                            onClick: (evt) => { this.viewModel.changeToProfileView() }
                                          },
                                          ]
                                        }>
                                      </Neo.Button>
                                    </span>
                                  </NeoGrid.Column>
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          </LibertyPager>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Neo.Tab>
            <Neo.Tab
              header={"Schemes"}
              name={"Schemes"}>
              <div className='row search-by'>
                <Neo.Card className='col-md-10'>
                  <Neo.FormGroup
                    className='search-dropdown'
                    bind={this.viewModel.schemeCriteria.meta.keyword}
                    placeholder="Search by..."
                    onChange={() => this.viewModel.searchSchemeData()}>
                  </Neo.FormGroup>
                  <Neo.Button className="align-filter" tooltip="Filter" onClick={() => { this.viewModel.showSchemeCard = true }}><i><img src={img_filter}></img></i></Neo.Button>
                </Neo.Card>
                <Neo.Button className="col-md-2 btn-filter" onClick={() => { this.viewModel.searchSchemeData() }}>Search</Neo.Button>
                {this.viewModel.showSchemeCard &&
                  <div className='show-dropdown'>
                    <div className='row padding-bottom-radio'>
                      <div className='col-md-6 scheme-options-align'>
                        <div className='filter-header'>
                          Scheme status:
                        </div>
                        <div className='filter-inline-checkboxes'>
                          {this.viewModel.schemeTabStatusList.map(element => {
                            return [
                              <div className='filter-inline-checkboxes-label'>
                                <input
                                  key={element.value}
                                  checked={element.isSelected}
                                  type="radio"
                                  onClick={(evt) => {
                                    element.isSelected = !element.isSelected;
                                  }}
                                  value={element.name}>
                                </input>
                                <label htmlFor={element.name} >{element.name}</label>
                              </div>
                            ]
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='row separating-line-padding '>
                      <div className='separating-line-max' />
                    </div>
                    <div className='row'>
                      <div className='align-filter-link row'>
                        <span className="save-return-link col-md-6">
                          <a className='save-return-link' onClick={() => this.viewModel.removeAllSchemeTabFilters()}>Clear all filters </a>
                        </span>
                        <span className='align-buttons col-md-6'>
                          <Neo.Button text="Cancel" onClick={() => { this.viewModel.showSchemeCard = false }} className='btn btn-cancel' />
                          <Neo.Button text="Apply" onClick={() => this.viewModel.showChosenSchemeFilters()} className='btn btn-apply' />
                        </span>
                      </div>
                    </div>
                  </div>
                }
                {(this.viewModel.totalSchemeEnumValue !== null) &&
                  <div>
                    <div className='row'>
                      <div className='col-md-2'>
                        <div className='filtered-by-header'>
                          Filtered by:
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {this.viewModel.totalSchemeEnumValue !== null &&
                        <div className='col-md-2'>
                          <div className='filtered-by-sub-header'>
                            Scheme status
                          </div>
                        </div>
                      }
                    </div>
                    <div className='row'>
                      <div className='col-md-8'>
                        {this.viewModel.totalSchemeEnumValue !== null &&
                          <span className='selected-pill-items'>
                            {this.viewModel.schemeTabStatusList.filter(elem => { return elem.isSelected }).map(element => {
                              return <span className='background-pill'>{element.name}
                                <Neo.Button className='close-button' onClick={() => this.viewModel.removeTabSchemeFilter(element)}></Neo.Button>
                              </span>
                            })}
                          </span>
                        }
                        {(this.viewModel.schemeStatus !== null || this.viewModel.totalSchemeEnumValue !== null) &&
                          <Neo.Button className='clear-all-button' text='Clear all' onClick={() => this.viewModel.removeAllSchemeTabFilters()} />
                        }
                      </div>
                    </div>
                  </div>
                }
                {(this.viewModel.totalSchemeEnumValue !== null) &&
                  <div className='separating-line margin-top' />
                }
                <div className='row mt-2 resize'>
                  <div className='col-12 col-md-12 resize'>
                    {!this.viewModel.showSchemeGrid &&
                      <div className={this.viewModel.emptyGridCssClass}>
                        <i> <img src={img_info}></img></i>
                        <div className='grid-info'>Search or filter to manage your portfolio within your organisation</div>
                      </div>
                    }
                    {this.viewModel.showSchemeGrid &&
                      <div className='report-grid'>
                        <div className='row'>
                          <LibertyPager
                            maxPagesToShow={2}
                            pageManager={this.viewModel.schemePageManager}
                            pageControlProps={{
                              pageSizeLabel: " ",
                              lastText: " ", totalRecords: 20
                            }}>
                            <NeoGrid.Grid<EmployerDashboardSchemeSearchResultsLookup>>
                              {(item, meta) => (
                                <NeoGrid.Row >
                                  <NeoGrid.Column label='Name' className='col-md-3 member-name' sort={meta.name} ><span className='scheme-name'>{item.name}</span> </NeoGrid.Column>
                                  <NeoGrid.Column label='Number' className='col-md-3 ' display={meta.number} />
                                  <NeoGrid.Column label='No. of members' className='col-md-3 ' sort={meta.numberOfMembers} >
                                    <Neo.Link onClick={() => this.viewModel.allSchemeMembers(item)}>
                                      {item.numberOfMembers}
                                    </Neo.Link>
                                  </NeoGrid.Column>
                                  <NeoGrid.Column label='Status' className='col-md-3 ' display={meta.schemeStatusName} />
                                  <NeoGrid.Column className='border-three-dots'>
                                    <span className='dropdown-three-dots'>
                                      <Neo.Button
                                        menuAlignment="right"
                                        onClick={() => alert("This won't appear")}
                                        menuItems={
                                          [{
                                            icon: <img src={img_users}></img>,
                                            text: "Update scheme",
                                            // No path was given yet
                                            // onClick: (evt) => { this.viewModel.changeToBenefeciariesView(item) }
                                          }]}>
                                      </Neo.Button>
                                    </span>
                                  </NeoGrid.Column>
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          </LibertyPager>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Neo.Tab>
          </Neo.TabContainer >
        </div >

      </div >
    );
  }
}