import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import EmployerMemberProfileVM from './EmployerMemberProfileVM';
import { observer } from 'mobx-react';
import '../Styles/EmployerMemberProfileView.scss';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';

@observer
export default class EmployerMemberProfileView extends Views.ViewBase<EmployerMemberProfileVM> {

  constructor(props: unknown) {
    super("Employer Member Profile", EmployerMemberProfileVM, props);
  }

  //ToDo: schemes should be coming from a class.
  public schemes = [
    { Id: 1, SchemeName: "Fempower Consultants" },
    { Id: 2, SchemeName: "Snail Private Limited" },
    { Id: 3, SchemeName: "The ABC Company(PTY)LTD-123" }
  ];

  //ToDo: ProfileSections should be coming from a class.
  public profileSections = [
    { ProfileSectionId: 1, ProfileSectionName: "Profile" },
    { ProfileSectionId: 2, ProfileSectionName: "Beneficiaries" },
    { ProfileSectionId: 3, ProfileSectionName: "Documents" },
    { ProfileSectionId: 4, ProfileSectionName: "Products" }
  ];

  //ToDo: provinces list should be coming from a class.
  private provinces = [
    { Id: 1, Province: "Eastern Cape" },
    { Id: 2, Province: "Free State" },
    { Id: 3, Province: "Gauteng" },
    { Id: 4, Province: "KwaZulu-Natal" }
  ];

  public render() {
    return (
      <div className='employer-member-profile-view-css'>
        <div className='scheme-information-header-employer'>
          <div className='row'>
            <span className='scheme-card-header'>Review information on behalf of <span className='scheme-card-header-bold'>John Doe - M 536215A</span></span>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='divider-employer-header' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className="employer-member-profile-dropdown">
                <div className='employer-card-header'>Select a scheme for more information.</div>
                <label className='dropdowm-label'> Scheme </label>
                <Neo.AutoCompleteDropDown
                  bind={this.viewModel.meta.schemeId}
                  bindDisplay={this.viewModel.meta.schemeName}
                  items={this.schemes}
                />
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className="employer-member-profile-dropdown">
                <div className='employer-card-header'>Please select a section to view member information.</div>
                <label className='dropdowm-label'> Select the page you want to view </label>
                <Neo.AutoCompleteDropDown
                  bind={this.viewModel.meta.profileSectionId}
                  bindDisplay={this.viewModel.meta.profileSectionName}
                  items={this.profileSections}
                  onItemSelected={(item) => this.viewModel.profileSectionSelected(item?.ProfileSectionId ?? 0)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.viewModel.showMemberProfile &&
          <div className='employer-profile-css'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <Neo.Alert variant="primary" className='display-alert-profile-employer'
                  heading="You can update member information on your employer billing schedule." />
              </div>
            </div>
            <div className='align-form-group-row-profile'>
              <Neo.GridLayout>
                <Neo.FormGroup href='Title' bind={this.viewModel.memberProfileDetails.meta.title} readOnly isDisabled />
                <Neo.FormGroup href='Surname' bind={this.viewModel.memberProfileDetails.meta.surName} readOnly isDisabled />
                <Neo.FormGroup href='Name' bind={this.viewModel.memberProfileDetails.meta.name} readOnly isDisabled />
                <Neo.FormGroup href="SAIdOrPassportNumber" bind={this.viewModel.memberProfileDetails.meta.passportOrNationalIdNumber} readOnly isDisabled />
                <Neo.FormGroup href='DateOfBirth' bind={this.viewModel.memberProfileDetails.meta.dateOfBirth} readOnly isDisabled />
                <Neo.FormGroup href='CountryOfIssue' bind={this.viewModel.memberProfileDetails.meta.passportCountryOfIssue} readOnly isDisabled />
              </Neo.GridLayout>
            </div>
            <div className='line-profile-employer' />
            <Neo.Alert variant="primary" className='display-alert-profile-employer'
              heading="The information displayed may not be as expected as the member might have changed it." />
            <div className='align-form-group-row-profile add-background-color'>
              <div className='align-address-box'>
                <div className='address-header'>Address</div>
                <Neo.GridLayout>
                  <Neo.FormGroup href='StreetName' bind={this.viewModel.memberProfileDetails.meta.streetName} readOnly />
                  <Neo.FormGroup href='BuildingName' bind={this.viewModel.memberProfileDetails.meta.buildingName} readOnly />
                  <Neo.FormGroup href='City' bind={this.viewModel.memberProfileDetails.meta.city} readOnly />
                  <Neo.FormGroup href="Suburb" bind={this.viewModel.memberProfileDetails.meta.suburb} readOnly />
                  <div className="employer-member-profile-dropdown">
                    <label className='dropdowm-label'> Province/State </label>
                    <Neo.AutoCompleteDropDown
                      bind={this.viewModel.meta.provinceId}
                      bindDisplay={this.viewModel.meta.provinceName}
                      items={this.provinces}
                    />
                  </div>
                  <div className="employer-member-profile-dropdown">
                    <label className='dropdowm-label'> Country </label>
                    <Neo.AutoCompleteDropDown
                      bind={this.viewModel.meta.countryId}
                      items={this.viewModel.countries}
                    />
                  </div>
                  <Neo.FormGroup href='PostalCode' bind={this.viewModel.memberProfileDetails.meta.postalCode} readOnly />
                </Neo.GridLayout>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='line-employer-header' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='container-div'>
                  <div className="left-div">
                    <Neo.Link
                      onClick={() => this.viewModel.navigateToDashboard()}
                      className='back-to-port'>
                      Return to Member List
                    </Neo.Link>
                  </div>
                  {/* ToDo:Needs to be uncommented in future */}
                  {/* <div className="right-div">
                  <Neo.Button
                    className='cancel-btn-css'
                    variant='primary'
                  >
                    Cancel
                  </Neo.Button>
                  <Neo.Button
                    variant='primary'
                    className='cancel-btn-css update-btn'
                  >
                    Update
                  </Neo.Button>
                </div> */}
                </div>
              </div>
            </div>
          </div >
        }
        {this.viewModel.showMemberBeneficiary &&
          <BeneficiariesView></BeneficiariesView>
        }
      </div >
    );
  }
}