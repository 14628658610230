import { EnumHelper } from "@singularsystems/neo-core";

export enum BankAccountType {
  None = 0,
  Current,
  Savings,
  Transmission,
}

EnumHelper.decorateEnum(BankAccountType, decorator => {
  decorator.describe(BankAccountType.Current, "Current");
  decorator.describe(BankAccountType.Savings, "Savings");
  decorator.describe(BankAccountType.Transmission, "Transmission");
});