import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';
import KCTemplate from '../../Domain/Models/KnowledgeCentre/KCTemplate';
import KCTemplateLookup from '../../Domain/Models/KnowledgeCentre/KCTemplateLookup';
import UsefulLinksView from './UsefulLinksView';
import EducationalMaterialView from './EducationalMaterialView';
import { KCTemplateType } from '../../Domain/Models/KnowledgeCentre/Enums/KCTemplateType.enum';

@NeoModel
export default class KnowledgeAndFormsVM extends Views.ViewModelBase {
  public currentKcTemplate: KCTemplateLookup = new KCTemplateLookup();
  public kcTemplates = new List(KCTemplate);

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private kcQueryApiClient = AppService.get(Types.Domain.ApiClients.KCQueryApiClient),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService)) {

    super(taskRunner);
  }

  public async initialise() {
    await this.getKCTemplates();
  }

  public async getKCTemplates() {
    const response = await this.kcQueryApiClient.getKCTemplates();
    this.kcTemplates.set(response.data);
  }

  public async getKCTemplateLookup(item: KCTemplateLookup): Promise<void> {
    const kcTemplateResponse = await this.kcQueryApiClient.getKCTemplateLookup(item.kcTemplateType);
    this.currentKcTemplate = new KCTemplateLookup();
    this.currentKcTemplate.set(kcTemplateResponse.data);
  }

  public navigateToPage(item: KCTemplate) {
    if (item.kcTemplateType == KCTemplateType.UsefulLinks) {
      this.navigation.navigateToView(UsefulLinksView);
    }
    else if (item.kcTemplateType == KCTemplateType.EducationalMaterial) {
      this.navigation.navigateToView(EducationalMaterialView);
    }
    else {
      window.open(
        'https://' + item.url,
        '_blank'
      );
    }
  }
}