import { Attributes, LookupBase, ModelBase, Rules } from '@singularsystems/neo-core';

export default class EmailInfoLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public nameId: number = 0;

  @Attributes.Integer()
  public addressId: number = 0;

  public addOtherId: number = 0;

  public emailType: string = "";

  @Rules.EmailAddress("Email address is invalid")
  @Rules.Required("Email address is required")
  @Rules.StringLength(0, 80, "Email address cannot be more than 80 characters in length")
  @Attributes.Display("Email address")
  public emailAddress: string = "";

  public isPreferred: string = "";

  public effectiveDate: string | null = null;

  public emailTypeCode: string = "";

  public isVerified: boolean = false;

  // Client only properties / methods
}