import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import UpdateMemberBeneficiariesCommand from '../../Models/LookUps/MemberBeneficiaries/UpdateMemberBeneficiariesCommand';
import MemberBeneficiaryResponseLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryResponseLookup';

export interface IMemberBeneficiariesCommandApiClient {

  /** 
   * Updates the member beneficiaries.
   * @param updateCommand The member beneficiaries.
   * @returns The member beneficiary response.
   */
  updateMemberBeneficiaries(updateCommand: Model.PartialPlainObject<UpdateMemberBeneficiariesCommand>): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryResponseLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class MemberBeneficiariesCommandApiClient extends Data.ApiClientBase implements IMemberBeneficiariesCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-beneficiaries`);
  }

  public updateMemberBeneficiaries(updateCommand: Model.PartialPlainObject<UpdateMemberBeneficiariesCommand>): AxiosPromise<Array<Model.PlainObject<MemberBeneficiaryResponseLookup>>> {
    return this.axios.post(`${this.apiPath}/submit-updated`, updateCommand);
  }

  // Client only properties / methods
}