import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import FundFactSheetLookup from '../../Models/FundFactSheets/FundFactSheetLookup';
import FundFactSheet from '../../Models/FundFactSheets/FundFactSheet';

export interface IFundFactSheetQueryApiClient {

  /**
   * Gets the FundFactSheets.
   * @returns A list of FundFactSheets s.
   */
  getFundFactSheetLookups(): AxiosPromise<Array<Model.PlainObject<FundFactSheetLookup>>>;

  /**
   * Gets the FundFactSheets.
   * @returns A list of FundFactSheets s.
   */
  getFundFactSheets(): AxiosPromise<Array<Model.PlainTrackedObject<FundFactSheet>>>;

  /**
   * Gets a FundFactSheets.
   * @returns A FundFactSheets.
   */
  getFundFactSheet(Id: number): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>>;

  /** 
   * Gets a FundFactSheets.
   * @returns A FundFactSheets.
   */
  getFundFactSheetUrl(fundName: string): AxiosPromise<string>;

  // Client only properties / methods
}

@injectable()
export default class FundFactSheetQueryApiClient extends Data.ApiClientBase implements IFundFactSheetQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/FundFactSheetQuery`);
  }

  public getFundFactSheetLookups(): AxiosPromise<Array<Model.PlainObject<FundFactSheetLookup>>> {
    return this.axios.get(`${this.apiPath}/fund-fact-sheet-lookups`);
  }

  public getFundFactSheets(): AxiosPromise<Array<Model.PlainTrackedObject<FundFactSheet>>> {
    return this.axios.get(`${this.apiPath}/fund-fact-sheets`);
  }

  public getFundFactSheet(Id: number): AxiosPromise<Model.PlainTrackedObject<FundFactSheet>> {
    return this.axios.get(`${this.apiPath}/fund-fact-sheet/${Id}`);
  }

  public getFundFactSheetUrl(fundName: string): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/fund-fact-sheet-url/${encodeURIComponent(fundName)}`);
  }

  // Client only properties / methods
}