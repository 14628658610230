import React from 'react';
import { observer } from 'mobx-react';
import InstructionsVM from '../InstructionsVM';
import LibertyPager from '../../../Dashboard/Components/LibertyPager';
import { NeoGrid } from '@singularsystems/neo-react';
import InstructionsSearchResultsLookup from '../../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';
import { Data } from '@singularsystems/neo-core';
import InstructionsSearchCriteria from '../../../Instructions/Models/Lookups/IntructionsSearchCriteria';

interface IInstructionsWebTableProps {
  sort_img: string,
  pageManagerMember: Data.PageManager<InstructionsSearchCriteria, InstructionsSearchResultsLookup>,
  viewModel: InstructionsVM
  gotToInstructionView(instruction: InstructionsSearchResultsLookup): void

}

@observer
export default class InstructionsWebMemberTable extends React.Component<IInstructionsWebTableProps> {

  constructor(props: IInstructionsWebTableProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-web-screen'>
        <div className='row table-heading-badb'>
          <span onClick={() => { this.props.viewModel.SortItems("Name") }} className='col-2 col-md-2 card-heading-text'>Name
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Number") }} className='col-2 col-md-2 card-heading-text'>Number
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Scheme Name") }} className='col-2 col-md-2 card-heading-text'>Scheme Name
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Scheme Number") }} className='col-2 col-md-2 card-heading-text'>Scheme Number
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Instruction") }} className='col-2 col-md-2 card-heading-text'>Instruction
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
          <span onClick={() => { this.props.viewModel.SortItems("Status") }} className='col-2 col-md-2 card-heading-text-status'>Status
            <i className=''>
              <img className='heading-img' src={this.props.sort_img} />
            </i>
          </span>
        </div>
        <LibertyPager
          maxPagesToShow={2}
          pageManager={this.props.pageManagerMember}
          pageControlProps={{
            pageSizeLabel: " ",
            lastText: " ", totalRecords: this.props.pageManagerMember.totalRecords
          }}>
          <NeoGrid.Grid<InstructionsSearchResultsLookup>>
            {(item) => (
              item.instructionType == "Member" && item.status !== "Completed" &&
              <div
                className='table-row-cards-investment'
                onClick={() => this.props.gotToInstructionView(item)}
              >
                <div className='row'>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span data-full-text={item.name} className='truncate'>{item.name}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name trancate'>
                      <span className='truncate'>{item.instructionNumber}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.schemeName}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.schemeNumber}</span>
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='table-column-instruction-name'>
                      <span className='truncate'>{item.instruction}</span>
                    </div>
                  </div>
                  <div className='col-2 pill-container'>
                    <div className={item.status == 'In progress' ? 'in-progress-pill truncate' : item.status == 'Incomplete' || item.status == 'Requires documents' ? 'incomplete-pill truncate' : 'completed-pill truncate'}>
                      <span className='truncate'>{item.status}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </NeoGrid.Grid>
        </LibertyPager>
      </div>
    );
  }
}