import { Attributes, Misc, ModelBase, Rules, StringUtils, Validation } from '@singularsystems/neo-core';
import { IRuleContext } from '@singularsystems/neo-core/dist/Validation';

export default class MemberQueryLookupBase extends ModelBase {

  public memberId: number | null = 0;

  public schemeId: number | null = 0;

  public nameId: number | null = 0;

  public sourceId: string = "";

  public contactNumber: string = "";

  @Rules.EmailAddress("Email address is invalid")
  @Rules.StringLength(0, 80, "Email address cannot be more than 80 characters in length")
  public emailAddress?: string = "";

  @Rules.StringLength(0, 18000)
  public description: string = "";

  public isActive: boolean = true;

  public isSaved: boolean = false;

  @Attributes.Date(Misc.TimeZoneFormat.None)
  public sentDate: Date | null = null;

  // Client only properties / methods
  public queryType: number = 0;

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.description.length === 0 && c.queryType === 1, "Query detail is required");
    rules.failWhen(c => c.description.length === 0 && c.queryType === 2, "Compliment detail is required");
    rules.failWhen(c => c.description.length === 0 && c.queryType === 3, "Complaint detail is required");
    rules.add(this.contactNumberRule).onProperties(c => c.memberContactNumber);
    rules.add(this.mandatoryFields).onProperties(c => [c.memberContactNumber, c.emailAddress]);
  }

  private contactNumberRule(context: IRuleContext) {
    if (this.memberContactNumber.length > 0) {
      if (this.countryValue === "+27") {
        // This test for that if a user uses spaces in the number the format
        // will be xx xxx xxxx or xxx xxx xxxx where the spaces are optional and letters are not allowed
        // not allowing for more than 10 characters.
        const cellNumberRegex = /^\(?(\d{2,3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        var valid = cellNumberRegex.test(this.memberContactNumber)
        if (!valid || (this.memberContactNumber.length >= 10 && this.memberContactNumber.charAt(0) !== "0" && !this.memberContactNumber.includes(" "))) {
          context.addError("Cell number is invalid");
        }
      } else {
        const foreignNumber = /^[0-9]*$/;
        var foreignNumberValid = foreignNumber.test(this.memberContactNumber)
        if (this.memberContactNumber.length > 15 || !foreignNumberValid) {
          context.addError("Cell number is invalid");
        }
      }
    }
  }

  private mandatoryFields(context: IRuleContext) {
    if (this.memberContactNumber.length === 0 && this.emailAddress?.length === 0) {
      context.addError("One of these fields is required");
    }
  }

  @Attributes.NoTracking()
  public contactNumberBase: string = '';

  @Attributes.NoTracking()
  public countryValue: string = '';

  public get memberContactNumber() {
    return this.contactNumberBase;
  }

  public set memberContactNumber(value: string) {
    this.contactNumber = this.countryValue + " " + value.replace(" ", "");
    this.contactNumberBase = value;
  }
}