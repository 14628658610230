import { Validation, ValueObject } from '@singularsystems/neo-core';

export default class PersonContactDetailsAddressCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public addressLine1: string = "";

  public addressLine2: string = "";

  public addressLine3: string = "";

  public postalCode: string = "";

  public province: string | null = null;

  public suburb: string = "";

  public country: string | null = null;

  public city: string = "";

  public sourceId: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Person Contact Details Address Command";
  }
}