import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../..//MasterDataTypes';
import MemberStatusGroup from '../../Models/MemberStatusGroup';

export interface IMemberStatusGroupQueryApiClient {

    /** 
     * Gets a list of MemberStatusGroup
     * @returns A List of MemberStatusGroups
     */
    getMemberStatusGroups(): AxiosPromise<Array<Model.PlainTrackedObject<MemberStatusGroup>>>;

    /** 
     * Gets MemberStatusGroup
     * @returns MemberStatusGroup
     */
    getMemberStatusGroup(code: number): AxiosPromise<Model.PlainTrackedObject<MemberStatusGroup>>;

    // Client only properties / methods
}

@injectable()
export default class MemberStatusGroupQueryApiClient extends Data.ApiClientBase implements IMemberStatusGroupQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/member-status-group`);
    }

    public getMemberStatusGroups(): AxiosPromise<Array<Model.PlainTrackedObject<MemberStatusGroup>>> {
        return this.axios.get(`${this.apiPath}/member-status-group-templates`);
    }

    public getMemberStatusGroup(code: number): AxiosPromise<Model.PlainTrackedObject<MemberStatusGroup>> {
        return this.axios.get(`${this.apiPath}/member-status-group/${code}`);
    }

    // Client only properties / methods
}