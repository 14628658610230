import { ModelBase, NeoModel } from "@singularsystems/neo-core";
import { ReportType } from "../../../Enums/ReportType.enum";

@NeoModel
export default class ReportCriteria extends ModelBase {
  public memberId: number = 0;
  public effectiveDate: string = "";
  public startDate: string = "";
  public endDate: string = "";
  public sourceId: string = "";
  public schemeId: number = 0;
  public documentId: string = "";
  public reportType: ReportType = ReportType.None;
  public schemeNo: string = "";
  public memberNo: string = "";
}