import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import VASTemplate from '../../Models/VAS/VASTemplate';

export interface IVASCommandApiClient {

  /** 
   * Updates a VAS template.
   * @param vasTemplate The VAS template to update.
   * @returns The updated template.
   */
  updateVASTemplate(vasTemplate: Model.PartialPlainObject<VASTemplate>): AxiosPromise<Model.PlainTrackedObject<VASTemplate>>;

  /** 
   * Deletes a VAS template Card.
   * @param templateId The VAS template Card to delete.
   * @returns A list of VAS templates.
   */
  deleteVASTemplateCard(templateId: number): AxiosPromise;

  // Client only properties / methods
}

@injectable()
export default class VASCommandApiClient extends Data.ApiClientBase implements IVASCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/vas`);
  }

  public updateVASTemplate(vasTemplate: Model.PartialPlainObject<VASTemplate>): AxiosPromise<Model.PlainTrackedObject<VASTemplate>> {
    return this.axios.put(`${this.apiPath}/vas-template`, vasTemplate);
  }

  public deleteVASTemplateCard(templateId: number): AxiosPromise {
    return this.axios.delete(`${this.apiPath}/vas-template-card/${templateId}`);
  }

  // Client only properties / methods
}