import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import GetPersonContactDetailLookup from '../../Models/LookUps/GetPersonContactDetailLookup';

export interface IPersonContactDetailsQueryApiClient {

  /** 
   * Retrieves Person Contact Details Lookup.
   * @returns GetPersonContactDetailLookup.
   */
  getPersonContactDetailsLookupById(): AxiosPromise<Model.PlainObject<GetPersonContactDetailLookup>>;

  /** 
   * Retrieves Person Contact Details Lookup summary.
   * @returns GetPersonContactDetailLookup.
   */
  getPersonContactDetailsSummaryLookupById(): AxiosPromise<Model.PlainObject<GetPersonContactDetailLookup>>;

  // Client only properties / methods
}

@injectable()
export default class PersonContactDetailsQueryApiClient extends Data.ApiClientBase implements IPersonContactDetailsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/person-contact-details`);
  }

  public getPersonContactDetailsLookupById(): AxiosPromise<Model.PlainObject<GetPersonContactDetailLookup>> {
    return this.axios.get(`${this.apiPath}/lookup`);
  }

  public getPersonContactDetailsSummaryLookupById(): AxiosPromise<Model.PlainObject<GetPersonContactDetailLookup>> {
    return this.axios.get(`${this.apiPath}/lookup-summary`);
  }

  // Client only properties / methods
}