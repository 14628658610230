import { DateUtils, ModelBase, NotifyUtils, StringUtils } from "@singularsystems/neo-core"
import { injectable } from "inversify";
import MemberWebInterfaceOptionLookup from "../../Domain/Models/WebInterfaceOptions/MemberWebInterfaceOptionsLookup";
import { UserType } from "../../Enums/UserType.enum";
import { User } from '../../UserModel/User';
import FADashboardView from "../../FADashboard/Views/FADashboardView";
import UserProfileLookup from "../../Identity/Models/Lookups/UserProfileLookup";
import LegacyMemberDashboardView from "../../LegacyMemberDashboard/Views/LegacyMemberDashboardView";
import MemberDashboardView from "../../MemberDashboard/Views/MemberDashboardView";
import AddressInfoLookup from "../../Party/Models/LookUps/AddressInfoLookup";
import GetPersonContactDetailLookup from "../../Party/Models/LookUps/GetPersonContactDetailLookup";
import StaffDashboardView from "../../StaffDashboard/Views/StaffDashboardView";
import { AppService, Types } from "./AppService";
import WalkMeScriptHelper from "./WalkMeScriptHelper";
import { SchemeProductClassType } from "../../Party/Models/LookUps/SchemeProductClassType";
import EmployerDashboardView from "../../Dashboard/Views/EmployerDashboardView";

@injectable()
export default class UserRoleService extends ModelBase {
  public loggedInUser: User = new User(UserType.Legacy);
  private hasInitialised = false;
  private isFetching = false;
  public userProfile: UserProfileLookup | null = null;
  public acceptedTAndC: boolean = true;
  public personEmailAddress: string = "";
  public personTelephoneNumber: string = "";
  public personContactDetailsLookup = new GetPersonContactDetailLookup;
  public memberWebInterfaceOptionLookup = new MemberWebInterfaceOptionLookup();
  public hasFuneralBenefits: boolean = false;
  public memberLandingPageType: SchemeProductClassType = SchemeProductClassType.RiskOnly;
  public viewOnlyBeneficiary: boolean = false;

  constructor(
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private personContactDetailsQueryApiClient = AppService.get(Types.Parties.ApiClients.PersonContactDetailsQueryApiClient),
    private userProfileApiClient = AppService.get(Types.Identity.ApiClients.UserProfileApiClient),
    private userTAndCCommandApiClient = AppService.get(Types.Domain.ApiClients.UserTAndCCommandApiClient),
    private MemberWebInterfaceOptionsQueryApiClient = AppService.get(Types.Domain.ApiClients.MemberWebInterfaceOptionsQueryApiClient),
    private backgroundDataApiClient = AppService.get(Types.Parties.ApiClients.BackgroundDataApiClient),
    private memberBenefitsApiClient = AppService.get(Types.Parties.ApiClients.MemberBenefitsApiClient),
    private legacyMemberDashboardApiClient = AppService.get(Types.LegacyMemberDashboard.ApiClients.LegacyMemberDashboardApiClient)) {
    super();
    this.makeObservable();
  }

  public async populateData() {
    if (!this.hasInitialised && !this.isFetching) {
      this.isFetching = true;
      await this.fetchUserProfile();
      await this.getHasUserAcceptedTAncC();
      await this.getPersonContactDetails();
      await this.fetchMemberLandingPage();
      await this.fetchInterfaceOptions();
      if (this.memberWebInterfaceOptionLookup) {
        WalkMeScriptHelper.injectScript(this.memberWebInterfaceOptionLookup);
      }
      this.isFetching = false, this.hasInitialised = true;
    }
  }

  public async fetchInterfaceOptions() {
    var interfaceResult = await this.MemberWebInterfaceOptionsQueryApiClient.getWebInterfaceOption();
    if (interfaceResult) {
      this.memberWebInterfaceOptionLookup.mapFrom(interfaceResult.data);
    }
  }

  public async fetchUserProfile() {
    if (!this.userProfile) {
      var result = await this.userProfileApiClient.getUserProfileLookup();
      this.userProfile = new UserProfileLookup();
      this.userProfile.set(result.data);
      this.loggedInUser = new User(UserType.Legacy)
    }
  }

  public async fetchMemberLandingPage() {
    var result = await this.legacyMemberDashboardApiClient.getMemberLandingPage();
    if (result) {
      this.memberLandingPageType = result.data.schemeProductClassType;
    }
  }

  public async fetchBackgroundData() {
    this.backgroundDataApiClient.fetchMemberBackgroundData();
  }

  public async getHasUserAcceptedTAncC() {
    var respAccepted = await this.userTAndCCommandApiClient.hasAccepted();
    this.acceptedTAndC = respAccepted.data;
  }

  public navigateToDashboard() {
    if (this.loggedInUser.userType == UserType.Member) {
      this.navigation.navigateToView(MemberDashboardView);
    }
    else if (this.loggedInUser.userType == UserType.Employer) {
      this.navigation.navigateToView(EmployerDashboardView);
    }
    else if (this.loggedInUser.userType == UserType.FA) {
      this.navigation.navigateToView(FADashboardView);
    }
    else if (this.loggedInUser.userType == UserType.Staff) {
      this.navigation.navigateToView(StaffDashboardView);
    }
    else if (this.loggedInUser.userType == UserType.Legacy) {
      this.navigation.navigateToView(LegacyMemberDashboardView);
    }
  }

  public async getPersonContactDetails() {
    var personContactDetails = await this.personContactDetailsQueryApiClient.getPersonContactDetailsSummaryLookupById();
    if (personContactDetails.data) {
      this.personContactDetailsLookup.mapFrom(personContactDetails.data);

      this.personContactDetailsLookup.personFullName = this.personContactDetailsLookup.firstName + " " + this.personContactDetailsLookup.lastName;

      let defaultEmailInfo = this.personContactDetailsLookup.emailInfo.find(c => !StringUtils.isNullOrWhitespace(c.emailAddress));
      this.personEmailAddress = defaultEmailInfo ? defaultEmailInfo.emailAddress : "";

      let defaultTelephoneInfo = this.personContactDetailsLookup.telephoneInfo.find(c => !StringUtils.isNullOrWhitespace(c.telePhoneNo));
      this.personTelephoneNumber = defaultTelephoneInfo ? defaultTelephoneInfo.telePhoneNo : "";

      if (this.personContactDetailsLookup.nationalIdNumber) {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.nationalIdNumber
      } else {
        this.personContactDetailsLookup.idOrPassport = this.personContactDetailsLookup.passportNumber
      }
    }
    else {
      this.personContactDetailsLookup = new GetPersonContactDetailLookup();
    }
  }

  public getLastLogin() {
    if (this.userProfile) {
      if (this.userProfile.lastLoginDate) {
        return this.userProfile.lastLoginDate.toDateString();
      } else if (this.userProfile.currentSessionLoginDate) {
        return this.userProfile.currentSessionLoginDate.toDateString();
      }
    }
    return DateUtils.today().toDateString();
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }

  public async getHasFuneralBenefits() {
    var hasFuneralBenefits = await this.memberBenefitsApiClient.hasFuneralBenefits();
    this.hasFuneralBenefits = hasFuneralBenefits.data;
  }

  public async setRole(roleOption: UserType) {
    this.loggedInUser = new User(roleOption);
  }

  public getFirstPersonAddressInfo() {
    var addressInfo = this.personContactDetailsLookup.addressInfo.find(a => StringUtils.isNullOrWhitespace(a.addressLine1));
    if (!addressInfo) {
      addressInfo = new AddressInfoLookup();
    }
    return addressInfo;
  }
}