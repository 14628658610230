import { EnumHelper, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../DomainTypes';
import Step from '../Models/Steps/Step';

export default class StepsContainerComponentVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner)) {

    super(taskRunner);
    this.makeObservable();
  }

  public stepList = new List(Step);
  public currentStep: Step = new Step();
  public nextStep: Step = new Step();
  public previousStep: Step = new Step();

  public async initialise() {

  }

  public moveToDesiredStep(step: EnumHelper) {
    this.currentStep.isActive = false;
    this.currentStep.isCompleted = true;
    this.currentStep = this.stepList.find(item => item.stepId === step)!;

    if (this.currentStep) {
      this.currentStep.isActive = true;
    }
  }

  public moveToNextStep() {
    if (this.currentStep.stepId === 0 || this.currentStep.stepId === null) {
      const nextStep = this.stepList.find(step => step.stepId === 1);
      if (nextStep) {
        this.setActiveStep(nextStep);
      }
      return;
    }

    if (!this.currentStep.isCompleted) {
      this.currentStep.isCompleted = true;
    }

    const nextStepId = this.currentStep.stepId + 1;
    const nextStep = this.stepList.find(step => step.stepId === nextStepId);

    if (nextStep) {
      this.setActiveStep(nextStep);
    }
  }

  private setActiveStep(step: Step) {
    this.currentStep.isActive = false;
    this.currentStep = step;
    this.currentStep.isActive = true;
  }

  public moveToPreviousStep() {

    const prevStepId = this.currentStep.stepId - 1;
    const previousStep = this.stepList.find(item => item.stepId === prevStepId);

    if (previousStep) {
      this.currentStep.isActive = false;
      this.currentStep = previousStep;
      this.currentStep.isCompleted = true;
      this.currentStep.isActive = true;
    }
  }
}