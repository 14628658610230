export enum DocumentType {
  None = 0,
  NotMembersAccount = 1,
  JointAccount = 2,
  ThirdPartyAccount = 3,
  DivorceOrderAgainstFund = 4,
  DivorceOrderFinalised = 5,
  MaintenanceOrderAgainstFund = 6,
  MaintenanceOrderFinalised = 7,
  DebtToEmployerForHousingLoan = 8,
  DebtToEmployerForDeception = 9,
  Extra = 10,
  NameChange = 11,
}