import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TAndCTemplateLookup extends ModelBase {

  public tAndCTemplateId: number = 0;

  @Attributes.Date()
  @Attributes.Display("Effective Date")
  public effectiveDate: Date = new Date();

  @Attributes.Display("Content")
  public templateContent: string = "";

  // Client only properties / methods
}