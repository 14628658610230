import { Attributes, ModelBase, Rules, Validation } from '@singularsystems/neo-core';
import { IRuleContext } from '@singularsystems/neo-core/dist/Validation';

export default class TelephoneInfoLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public nameId: number = 0;

  @Attributes.Nullable()
  @Attributes.Integer()
  public addressNo: number | null = null;

  public addressPhoneId: number = 0;

  public extension: string = "";

  public telePhoneNo: string = "";

  public isPreferred: string = "";

  public telephoneType: string = "";

  public telephoneTypeCode: string = "";

  @Attributes.Date()
  public effectiveDate: Date | null = null;

  public isVerified: boolean = false;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.add(this.contactNumberRule).onProperties(c => c.contactNumberBase);
    rules.add(this.mandatoryFields).onProperties(c => [c.contactNumberBase]);
  }

  private contactNumberRule(context: IRuleContext) {
    if (this.contactNumberBase.length > 0) {
      if (this.countryValue === "+27") {
        // This test for that if a user uses spaces in the number the format
        // will be xx xxx xxxx or xxx xxx xxxx where the spaces are optional and letters are not allowed
        // not allowing for more than 10 characters.
        const cellNumberRegex = /^\(?(\d{2,3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        var valid = cellNumberRegex.test(this.contactNumberBase)
        if (!valid || (this.contactNumberBase.length >= 10 && this.contactNumberBase.charAt(0) !== "0" && !this.contactNumberBase.includes(" "))) {
          context.addError("Cell number is invalid");
        }
      } else {
        const foreignNumber = /^[0-9]*$/;
        var foreignNumberValid = foreignNumber.test(this.contactNumberBase)
        if (this.contactNumberBase.length > 15 || !foreignNumberValid) {
          context.addError("Cell number is invalid");
        }
      }
    }
  }

  private mandatoryFields(context: IRuleContext) {
    if (this.contactNumberBase.length === 0) {
      context.addError("Cell number is required");
    }
  }

  public contactNumberBase: string = '';

  public countryValue: string = '+27';
}