import { LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class MemberBankingResponseLookup extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public passed: string = "";

    public errors: string[] = [];

    public sourceId: string = "";

    // Client only properties / methods
}