import { AppServices } from '@singularsystems/neo-core';
import LegacyMemberDashboardApiClient from './ApiClients/LegacyMemberDashboardApiClient';

export const LegacyMemberDashboardExportedTypes = {
  ApiClients: {
    LegacyMemberDashboardApiClient: new AppServices.ServiceIdentifier<LegacyMemberDashboardApiClient>("LegacyMemberDashboard.ApiClient.LegacyMemberDashboardApiClient"),
    //Add Api Clients here
  },
  Services: {
    // Add Services here
  },
};