import { Model } from "@singularsystems/neo-core";

export class BenefitGroup {
  benefitType: string = "";
  benefitTotal: number = 0;
  benefitClassId: number = 0;
  disclaimer: string = "";
  tableText: string = "";
  approvedAcceptedAmount: number = 0;
  unApprovedAcceptedAmount: number = 0;
  sequenceId: number = 0;
  isEducatorBenefit: boolean = false;
  approvedEntitledAmount: number = 0;
  unApprovedEntitledAmount: number = 0;

  public isExpanded = new Model.ObservableValue(false);

  constructor(benefitType: string, benefitTotal: number) {
    this.benefitType = benefitType;
    this.benefitTotal = benefitTotal;
  }
}