import { Attributes, EnumHelper, List, ModelBase, Validation } from '@singularsystems/neo-core';
import { ClaimTypeSubCategory } from './Enums/ClaimTypeSubCategory.enum';
import ClaimDeclarationTemplate from './ClaimDeclarationTemplate';

export default class ClaimTypeSubCategoryGroupTemplateLookup extends ModelBase {
    static typeName = "ClaimTypeSubCategoryGroupTemplateLookup";

    constructor() {
        super();
        this.makeObservable();
    }

    public claimTypeSubCategory: ClaimTypeSubCategory = ClaimTypeSubCategory.None;

    public claimDeclarations = new List(ClaimDeclarationTemplate);

    // Client only properties / methods

    @Attributes.NoTracking()
    public isExpanded : boolean = false;

    @Attributes.Display("Claim Type Sub Category")
    public get claimTypeSubCategoryDisplay() {
        return EnumHelper.getItemMetadata(ClaimTypeSubCategory, this.claimTypeSubCategory).display;    
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New claim type sub category group template lookup";
        } else {
            return "Claim Type Sub Category Group Template Lookup";
        }
    }
}