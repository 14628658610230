import { Attributes, LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class UpdateInterimMemberNominationCommand extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public sourceId: string = "";

    public beneficiaryId: string = "";

    public nominationId: string | null = null;

    public memberId: number = 0;

    public nominationType: string = "";

    @Attributes.Float()
    public nominationPercentage: number = 0;

    public isDeleted: boolean = false;

    // Client only properties / methods
}