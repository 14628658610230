import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberSchemeMembershipLookup from '../../Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import PersonFundsFromSchemesLookup from '../../Models/LookUps/PersonProfile/PersonFundsFromSchemesLookup';

export interface IPersonProfileApiClient {

  /**
   * GetMemberSchemeMembershipLookups
   */
  getMemberSchemeMembershipLookups(): AxiosPromise<Array<Model.PlainObject<MemberSchemeMembershipLookup>>>;

  /**
   * GetPersonFundsFromSchemesLookup
   */
  getFundsFromSchemes(): AxiosPromise<Array<Model.PlainObject<PersonFundsFromSchemesLookup>>>;

  /**
   * GetActiveMemberSchemeMembershipLookups
   */
  getActiveMemberSchemeMembershipLookups(): AxiosPromise<Array<Model.PlainObject<MemberSchemeMembershipLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class PersonProfileApiClient extends Data.ApiClientBase implements IPersonProfileApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/person-profile`);
  }

  public getMemberSchemeMembershipLookups(): AxiosPromise<Array<Model.PlainObject<MemberSchemeMembershipLookup>>> {
    return this.axios.get(`${this.apiPath}/scheme-membership-lookups`);
  }

  public getFundsFromSchemes(): AxiosPromise<Array<Model.PlainObject<PersonFundsFromSchemesLookup>>> {
    return this.axios.get(`${this.apiPath}/funds-from-schemes`);
  }

  public getActiveMemberSchemeMembershipLookups(): AxiosPromise<Array<Model.PlainObject<MemberSchemeMembershipLookup>>> {
    return this.axios.get(`${this.apiPath}/active-scheme-membership-lookups`);
  }

  // Client only properties / methods
}