import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import FADashboardVM from './FADashboardVM';
import { observer } from 'mobx-react';
import '../Styles/FADashboard.scss';
import img_info from '../../App/assets/img/information.png';
import img_profile from '../Assets/Img/profile-image.png';
import img_filter from '../Assets/Img/img-slider-controls.png';
import img_users from '../../App/assets/img/users.png';
import FADashboardSearchResultsLookup from '../Models/Lookups/FADashboardSearchResultsLookup';
import LibertyPager from '../../Dashboard/Components/LibertyPager';

@observer
export default class FADashboardView extends Views.ViewBase<FADashboardVM> {

  constructor(props: unknown) {
    super("FA Dashboard", FADashboardVM, props);
  }

  public showGrid() {
    this.viewModel.showCardCssClass = 'hide-grid';
    this.viewModel.hideReportGridCssClass = 'report-grid';
    this.viewModel.emptyGridCssClass = 'hide-grid';
  }

  public hideCard() {
    if (this.viewModel.showCardCssClass == 'show-dropdown') {
      this.viewModel.showCardCssClass = 'hide-grid';
    }
    else {
      this.viewModel.showCardCssClass = 'show-dropdown';
    }
  }

  private schemes = [
    { Id: 1, Scheme: "Fempower Consultants Pty Ltd" },
    { Id: 2, Scheme: "Liberty Preserver fund" },
  ];

  public render() {
    return (
      <div className='container'>
        <div className='mt-4 fa-dashboard-card'>
          <div className='row'>
            <div className='align-profile-info'>
              <div className='col-md-6 col-12'>
                <p className='fa-dashboard-card-heading'>Overview</p>
                <p className='card-sub-heading-fa-dashboard'>An overview of all information regarding your portfolio</p>
              </div>
            </div>
          </div>
          <div className='row search-by'>
            <Neo.Card className='col-md-10'>
              <Neo.AutoCompleteDropDown
                className='search-dropdown'
                bind={this.viewModel.meta.searchId}
                placeholder="Search by..."
                minCharacters={2}
              ></Neo.AutoCompleteDropDown>
              <Neo.Button className="align-filter" tooltip="Filter" onClick={() => { this.hideCard() }}><i><img src={img_filter}></img></i></Neo.Button>
            </Neo.Card>
            <Neo.Button className="col-md-2 btn-filter" onClick={() => { this.showGrid() }}>Search</Neo.Button>
          </div>
          <div className='row mt-4'>
            <div className='col-12 col-md-12'>
              <div className={this.viewModel.emptyGridCssClass}>
                <i> <img src={img_info}></img></i>
                <div className='grid-info'>Search or filter to manage your portfolio within your organisation</div>
              </div>
              <div className={this.viewModel.hideReportGridCssClass}>
                <div className='row mt-4'>
                  <Neo.GridLayout md={4}>
                    <label className='heading-text'>Member name</label>
                    <label className='heading-text' >Schemes</label>
                    <label className='heading-text' >Member status</label>
                    <label className='heading-text'>ID number</label>
                    <label className='heading-text'>Member number</label>
                  </Neo.GridLayout>
                </div>
                <div className='row'>
                  <LibertyPager
                    maxPagesToShow={2}
                    pageManager={this.viewModel.pageManager}
                    pageControlProps={{
                      pageSizeLabel: " ",
                      lastText: " ", totalRecords: 20
                    }}>
                    <NeoGrid.Grid<FADashboardSearchResultsLookup>>
                      {(item) => (
                        <Neo.Card className='mt-3 col-md-12 reports-grid-card'>
                          <span className='col-md-2 align-img-value'><i><img className='align-profile' src={img_profile}></img></i>
                            {item.firstName + item.lastName}</span>
                          <span className='col-md-2 card-value scheme-text'>{item.scheme}</span>
                          <span className='col-md-2 card-value status-text'>{item.status}</span>
                          <span className='col-md-2 card-value align-id'>{item.idNumber}</span>
                          <span className='col-md-2 card-value align-member-name'>{item.memberNumber}</span>
                          <span className='col-md-1 dropdown-three-dots'>
                            <Neo.Button
                              menuAlignment="right"
                              onClick={() => alert("This won't appear")}
                              menuItems={
                                [{
                                  icon: <img src={img_users}></img>,
                                  text: "Update beneficiaries",
                                  onClick: (evt) => { this.viewModel.changeToBenefeciariesView(item) }
                                }]}>
                            </Neo.Button>
                          </span>
                        </Neo.Card>
                      )}
                    </NeoGrid.Grid>
                  </LibertyPager>
                </div>
              </div>
            </div>
          </div>
          <div className={this.viewModel.showCardCssClass}>
            <div className='row'>
              <div className="col-md-4 align-reports-dropdown">
                <label className='reports-label'> Scheme </label>
                <Neo.AutoCompleteDropDown
                  disabled
                  bind={this.viewModel.meta.schemeId}
                  items={this.schemes}
                  placeholder="Select Scheme"
                />
              </div>
              <div className="col-md-4 align-reports-dropdown">
                <label className='reports-label'> Scheme status </label>
                <Neo.AutoCompleteDropDown
                  disabled
                  bind={this.viewModel.meta.schemeStatusId}
                  items={this.viewModel.schemeStatuses}
                  placeholder="Select scheme status"
                />
              </div>
            </div>
            <div className='row'>
              <div className='align-filter-link row'>
                <span className="save-return-link col-md-6">
                  <a className='save-return-link'>Clear all filters </a>
                </span>
                <span className='align-buttons col-md-6'>
                  <Neo.Button text="Cancel" onClick={() => { this.hideCard() }} className='btn btn-cancel' />
                  <Neo.Button text="Apply" onClick={() => { this.showGrid() }} className='btn btn-apply' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}