import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';

export interface IJWTTokenApiClient {

  /** 
   * Returns the billing and qoute jwt header.
   * @returns BillingAndQuoteJWTToken.
   */
  getJWTToken(): AxiosPromise<string>;

  // Client only properties / methods
}

@injectable()
export default class JWTTokenApiClient extends Data.ApiClientBase implements IJWTTokenApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/JWTToken`);
  }

  public getJWTToken(): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/jwt-token`);
  }

  // Client only properties / methods
}