import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import DeathBenefitsTable from './DeathBenefitsTable';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import BenefitType from '../../../Party/Models/Enums/BenefitType';

interface IDeathBenefitsCardProps {
  benefitGroup: BenefitGroup
  disclaimer: string
  tableText: string
}

@observer
export default class DeathBenefitsCard extends React.Component<IDeathBenefitsCardProps> {

  constructor(props: IDeathBenefitsCardProps) {
    super(props);
  }

  public render() {
    return (
      <Neo.Card
        title={BenefitType.deathBenefit}
        className='cards-sub-risk cards-sub-headings-risk'
        isExpanded={this.props.benefitGroup.isExpanded}>
        <DeathBenefitsTable
          benefitGroup={this.props.benefitGroup}
          disclaimer={this.props.disclaimer}
          tableText={this.props.tableText}
          totalPayableAtTimeOfDeath={
            this.props.benefitGroup.approvedAcceptedAmount + this.props.benefitGroup.unApprovedAcceptedAmount + (this.props.benefitGroup.benefitTotal ?? 0)
          }
          acceptedAmountTotal={this.props.benefitGroup.approvedAcceptedAmount + this.props.benefitGroup.unApprovedAcceptedAmount}
          entitledAmountTotal={this.props.benefitGroup.approvedEntitledAmount + this.props.benefitGroup.unApprovedEntitledAmount}
        />
      </Neo.Card>
    );
  }
}