import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ComplaintsVM from './ComplaintsVM';
import { observer } from 'mobx-react';
import img_Upload from '../../App/assets/img/primary-upload.png';
import img_view from '../../MemberDashboard/Assets//img/open.png';
import img_cross from '../../App/assets/img/close-cross.png';
import "../../App/Styles/Components/Complaints.scss";
import ComplainComponent from './Components/ComplainComponent';
import SchemeInformationHeader from './Components/SchemeInformationHeader';
import ComplaintReview from './Components/ComplaintReview';
import successful from '../assets/img/successful.png';
import { Validation } from '@singularsystems/neo-core';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class ComplaintsView extends Views.ViewBase<ComplaintsVM> {

  constructor(props: unknown) {
    super("Complaints", ComplaintsVM, props);
    this.viewModel.fetchMemberComplaint();
  }

  private handleQueryChange = (event: any) => {
    this.viewModel.memberComplaint.description = event.target.value;
  };

  public render() {
    return (
      <div className='add-padding-to-background'>
        <Neo.Modal
          title='File upload error'
          acceptButton={{ text: "Okay", variant: "primary" }}
          closeButton={false}
          bind={this.viewModel.meta.showFileSizeModal}>
          <p >When uploading documents, members may only submit up to 5 files that are each 5mb or less.</p>
        </Neo.Modal>
        {!this.viewModel.complaintReview &&
          <div>
            <div className='background-card body-card-complaints-dropdown'>
              <div className='row'>
                <div className='form-group col-12 col-md-12'>
                  <p className='card-header-query mt-2'>Have we disappointed you?</p>
                  <div>
                  </div>
                  <p className='card-header-complaint'>Please inform us how things went wrong, we will do our best to resolve it for you.</p>
                </div>
              </div>
              <div className='row btn-row'>
                <div className='align-contact-complain-buttons mt-2 col-6 col-md-6'>
                  <Neo.Button text="Contact us" id='btn-contact-us' onClick={(e) => this.viewModel.showContactUs(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-complain' : 'col-6 btn btn-contact'} />
                  <Neo.Button text="Complain online" id='btn-complain' onClick={(e) => this.viewModel.showComplain(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-contact' : 'col-6 btn btn-complain'} />
                </div>
              </div>
            </div>
            <div className={this.viewModel.displayComplaint ? 'show-complain' : 'hide-complain'}>
              <div className='background-card body-card-complaints'>
                <div className='complaints-css'>
                  <SchemeInformationHeader schemeHeader={'Select a related scheme'} memberName={this.viewModel.memberName} showClaimButton={false} extraCssClasses="" onSchemeSelected={(scheme) => { this.viewModel.setSchemeInfo(scheme); }} />
                  <div className='row ps-2 me-2'>
                    <div className='col-md-6'>
                      <Neo.Card className='member-details-card-bfs' >
                        <div className='member-card-information-bfs'>
                          <div className='complaint-card-header'>Member details</div>
                          <div>{this.viewModel.memberName}</div>
                          <div>{this.viewModel.memberNo}</div>
                        </div>
                      </Neo.Card>
                    </div>
                    <div className='col-md-6'>
                      <Neo.Card className='member-details-card-bfs'>
                        <div className='member-card-information-bfs'>
                          <div className='complaint-card-header'>Scheme details</div>
                          <div> {this.viewModel.schemeName}</div>
                          <div>{this.viewModel.schemeNumber}</div>
                        </div>
                      </Neo.Card>
                    </div>
                  </div>
                  <div className='pt-3 mt-4 pb-3 ps-2 me-2 row'>
                    <div className='form-group padding-fix-complaint col-12 col-md-6'>
                      <label className='form-label'>Cell number</label>
                      <div className='row p-0 col-md-12'>
                        <div className='country-dropdown col-2 col-md-2'>
                          <Neo.AutoCompleteDropDown
                            bind={this.viewModel.helpersService.meta.countryValueId}
                            bindDisplay={this.viewModel.helpersService.meta.countryValue}
                            items={this.viewModel.helpersService.countryCodes}
                            onItemSelected={() => {
                              this.viewModel.helpersService.setCountryValue(this.viewModel.memberComplaint);
                              this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberComplaint);
                              this.viewModel.hasNoInfo();
                            }} />
                        </div>
                        <div className='col-9 col-md-10 phone-control-col'>
                          <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                            <Neo.FormGroup className='phone-control tel-control-complaint'
                              bind={this.viewModel.memberComplaint.meta.memberContactNumber}
                              placeholder={"Please enter cell number"}
                              onBlur={() => {
                                this.viewModel.helpersService.cleanUpContactNumber(this.viewModel.memberComplaint);
                                this.viewModel.hasNoInfo()
                              }} />
                          </Neo.FormContext>
                        </div>
                      </div>
                    </div>
                    <div className='form-group form-group-email col-12 col-md-6'>
                      <label className='form-label'>Email address</label>
                      <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                        <Neo.FormGroup className='phone-control email-control'
                          bind={this.viewModel.memberComplaint.meta.emailAddress}
                          placeholder={"Please enter email address"}
                          required
                          onBlur={() => this.viewModel.hasNoInfo()} />
                      </Neo.FormContext>
                    </div>
                  </div>
                  <div className='p-2 mt-2 mb-md-4 row'>
                    <div className="form-group padding-fix-complaint-box col-12 col-md-12">
                      <label className='form-label'>Complaint<span className='display-asterisk'>*</span></label>
                      <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                        <Neo.FormGroup
                          className="query-control"
                          bind={this.viewModel.memberComplaint.meta.description}
                          placeholder='Please enter complaint'
                          input={{ rows: 4 }} />
                      </Neo.FormContext>
                    </div>
                  </div>
                  <div className='p-2 mt-2 mb-2 mb-md-4 row'>
                    <div className='col-12 padding-fix-complaint col-md-12'>
                      <div className='upload-card card'>
                        <div className='row'>
                          <div className="form-group padding-fix-complaint col-12 col-md-12">
                            <label className='mt-2 ms-2 form-label semi-bold'>Upload document/s</label>
                          </div>
                        </div>
                        <div className="form-group col-12 padding-fix-complaint col-md-12">
                          <label className='ms-2 form-label'>Max file size: 5mb <br></br> File types allowed: .pdf, .jpeg, .docx, .png, .tiff, .heic</label>
                        </div>
                        <div className='form-group col-12 col-md-12'>
                          <Neo.FileContext fileManager={this.viewModel.complaintsFileManager}>
                            <Neo.FileDropArea
                              className='file-drop-area'>
                              <i><img src={img_Upload} /></i>
                              <label className='file-upload-label'> Drag-and-drop a file or</label>
                              <Neo.FileUploadButton
                                //disabled = {this.viewModel.memberComplaint.getfilesCount() > 4 ? true : false}
                                fileManager={this.viewModel.complaintsFileManager}
                                className='browse-button'>browse to upload</Neo.FileUploadButton>
                            </Neo.FileDropArea>
                            <Neo.FileUploadProgress className='grey-upload-box' />
                            {(this.viewModel.memberComplaint.files && this.viewModel.memberComplaint.files.length > 0) &&
                              <div className='pb-3'>
                                < div className="form-group padding-fix-complaints ms-2 mt-2 col-12 col-md-12 ">
                                  <label className='form-label'>Uploaded document/s</label>
                                </div>
                                <div>
                                  {this.viewModel.memberComplaint.files.map((item) => {
                                    return <div className='row file-upload-documents phone-remove-margin'>
                                      <div className='col-12 phone-padding-left-zero'>
                                        <div className='add-margin-to-right phone-justify-left'>
                                          <div className='uploaded-document-info-block'>
                                            <div className='uploaded-document-info-text'>
                                              <div className='file-name'>
                                                {item.fileName}
                                              </div>
                                            </div>
                                            <span className='image-container'>
                                              <img src={img_view} onClick={() => this.viewModel.downloadComplaintSecureDocument(item.fileDescriptorId, item.fileName)} />
                                              <img src={img_cross} onClick={() => this.viewModel.deleteComplaintFile(item.fileName)} /></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </div>
                              </div>
                            }
                          </Neo.FileContext>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ContainerFooterComponent
                    showPrimaryButton={true}
                    showSecondaryButton={true}
                    primaryButtonText="Continue"
                    secondaryButtonText="Clear"
                    disablePrimaryButton={!this.viewModel.memberComplaint.isValid}
                    primaryButtonCallback={() => { this.viewModel.complaintReview = true; window.scrollTo(0, 0) }}
                    backLinkText={(this.viewModel.memberComplaint.isSelfDirty && this.viewModel.memberComplaint.isValid) ? 'Save & exit' : 'Back to dashboard'}
                    secondaryButtonCallback={() => this.viewModel.showModal = true}
                    backLinkCallback={() => { this.viewModel.navigateToDashboard(); this.viewModel.hasNoInfo() }}
                  />
                </div>
              </div>
            </div>
            <div className={this.viewModel.displayComplaint ? 'hide-complain' : 'show-complain'}>
              <div className='background-card body-card-complaints body-card-complaints-divider'>
                <div className='row'>
                  <div className='form-group col-12 col-md-12'>
                    <div className='contact-us-outside-margins margin-change-complaint'>
                      <div className='contact-us complaint-contact-us'>
                        <ComplainComponent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.viewModel.complaintReview &&
          <ComplaintReview viewModel={this.viewModel} />
        }
        <Neo.Modal
          bind={this.viewModel.meta.showModal}
          title='Are you sure you want to clear?'
          acceptButton={{ text: "Continue", variant: "primary", onClick: () => this.viewModel.clearComplaintInfo() }}
          closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showModal = false }}
        >
          The information that you have filled out will be lost and you will be directed to an empty screen.
        </Neo.Modal>
        <Neo.Modal title='Complaint submitted' bind={this.viewModel.meta.complaintSummary}
          acceptButton={{ text: "Back to dashboard", variant: "primary", onClick: () => this.viewModel.navigateToDashboard() }}
          closeButton={{ text: "Make another complaint", variant: "danger", onClick: () => this.viewModel.backToComplaintAfterSubmit() }}
        >
          <img src={successful} className='checked-img' />
          <br />< br />
          Your complaint has been submitted.<br />
          A Liberty consultant will be in touch as soon as possible.
        </Neo.Modal>
        <Neo.Modal
          bind={this.viewModel.meta.showSaveExitModal}
          title='Save & exit?'
          acceptButton={{
            text: "Continue", variant: "primary", onClick: () => {
              this.viewModel.hasNoInfo();
              this.viewModel.navigateToDashboard();
            }
          }}
          closeButton={{ text: "Cancel", variant: "danger", onClick: () => this.viewModel.showSaveExitModal = false }}
        >
          <p>Please note: This complaint has not been submitted. The information captured will be retained for 30 days. Please return to this screen to complete and submit.</p>
        </Neo.Modal>
      </div >
    );
  }
}