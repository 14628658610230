import { Validation, ValueObject } from '@singularsystems/neo-core';
import { MemberStatus } from '../../Enums/Employer/MemberStatus.enum';
import { SchemeStatus } from '../../Enums/Employer/SchemeStatus.enum';

export default class EmployerSearchCriteria extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public memberStatuses: MemberStatus | null = null;

  public schemeStatuses: SchemeStatus | null = null;

  public keyword: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Employer Search Criteria";
  }
}