import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import TAndCTemplateLookup from '../../Models/TAndC/TAndCTemplateLookup';
import TAndCTemplate from '../../Models/TAndC/TAndCTemplate';

export interface ITAndCQueryApiClient {

  /** 
   * Gets the TAndC templates.
   * @returns A list of TAndC templates.
   */
  getTAndCTemplateLookups(): AxiosPromise<Array<Model.PlainObject<TAndCTemplateLookup>>>;

  /** 
   * Gets the TAndC templates.
   * @returns A list of TAndC templates.
   */
  getTAndCTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<TAndCTemplate>>>;

  /** 
   * Gets a TAndC template.
   * @returns A TAndC template.
   */
  getTAndCTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<TAndCTemplate>>;

  /** 
   * Gets the latest TAndC template.
   * @returns A list of TAndC templates.
   */
  getLatestTAndCTemplate(): AxiosPromise<Model.PlainObject<TAndCTemplateLookup>>;

  /** 
 * Get Terms and Contionds Pdf
 * @returns Returns true or false
 */
  getTermsAndConditionsPdf(): AxiosPromise<Blob>;

  // Client only properties / methods
}

@injectable()
export default class TAndCQueryApiClient extends Data.ApiClientBase implements ITAndCQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/tandc`);
  }

  public getTAndCTemplateLookups(): AxiosPromise<Array<Model.PlainObject<TAndCTemplateLookup>>> {
    return this.axios.get(`${this.apiPath}/tandc-template-lookups`);
  }

  public getTAndCTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<TAndCTemplate>>> {
    return this.axios.get(`${this.apiPath}/tandc-templates`);
  }

  public getTAndCTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<TAndCTemplate>> {
    return this.axios.get(`${this.apiPath}/tandc-template/${templateId}`);
  }

  public getLatestTAndCTemplate(): AxiosPromise<Model.PlainObject<TAndCTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/tandc-template/latest`);
  }

  public getTermsAndConditionsPdf(): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/get-terms-and-conditions-pdf`, { responseType: 'blob' });
  }

  // Client only properties / methods
}