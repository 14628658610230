import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../..//MasterDataTypes';
import SchemeStatusGroup from '../../Models/SchemeStatusGroup';

export interface ISchemeStatusGroupQueryApiClient {

    /** 
     * Gets a list of SchemeStatusGroups
     * @returns A List of SchemeStatusGroup
     */
    getSchemeStatusGroups(): AxiosPromise<Array<Model.PlainTrackedObject<SchemeStatusGroup>>>;

    /** 
     * Gets SchemeStatusGroup
     * @returns SchemeStatusGroup
     */
    getSchemeStatusGroup(code: number): AxiosPromise<Model.PlainTrackedObject<SchemeStatusGroup>>;

    // Client only properties / methods
}

@injectable()
export default class SchemeStatusGroupQueryApiClient extends Data.ApiClientBase implements ISchemeStatusGroupQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/scheme-status-group`);
    }

    public getSchemeStatusGroups(): AxiosPromise<Array<Model.PlainTrackedObject<SchemeStatusGroup>>> {
        return this.axios.get(`${this.apiPath}/scheme-status-group-templates`);
    }

    public getSchemeStatusGroup(code: number): AxiosPromise<Model.PlainTrackedObject<SchemeStatusGroup>> {
        return this.axios.get(`${this.apiPath}/scheme-status-group/${code}`);
    }

    // Client only properties / methods
}