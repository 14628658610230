import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberQueryLookup from '../../Models/LookUps/MemberQueries/MemberQueryLookup';

export interface IMemberQueriesCommandApiClient {

    /** 
     * Inserts or updates a member query.
     * @param memberQuery The member query to update.
     * @returns The updated member query.
     */
    insertUpdateMemberQuery(memberQuery: Model.PartialPlainObject<MemberQueryLookup>): AxiosPromise<Model.PlainObject<MemberQueryLookup>>;

    /** 
     * Deletes a member query.
     * @param memberId The member id for the query to delete.
     * @param sourceId The source id
     */
    deleteMemberQuery(memberId: number, sourceId: string): AxiosPromise;

    /** 
     * Deletes member queries file.
     * @param memberId The member Id
     * @param sourceId The source Id
     * @param fileName The file name for the file needed to get deleted.
     * @returns A message if the file was not deleted
     */
    deleteMemberQueriesFiles(memberId: number, sourceId: string, fileName: string): AxiosPromise<string>;

    /** 
     * Uploads a secure document
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns A member query
     */
    getUploadQueriesSecureDocumentUrl(): string;

    /** 
     * 
     * @param fileDescriptorId The file descriptor Id
     * @param memberId The member Id
     * @param sourceId The source Id
     * @param fileName The file name
     * @returns The document file stream result
     */
    getQueriesSecureDocument(fileDescriptorId: number, memberId: number, sourceId: string, fileName: string): AxiosPromise<Blob>;

    /** 
     * Submits a member query.
     * @param memberQuery The member query to submit.
     * @returns The member query.
     */
    submitMemberQuery(memberQuery: Model.PartialPlainObject<MemberQueryLookup>): AxiosPromise<Model.PlainObject<MemberQueryLookup>>;

    // Client only properties / methods
}

@injectable()
export default class MemberQueriesCommandApiClient extends Data.ApiClientBase implements IMemberQueriesCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/member-queries`);
    }

    public insertUpdateMemberQuery(memberQuery: Model.PartialPlainObject<MemberQueryLookup>): AxiosPromise<Model.PlainObject<MemberQueryLookup>> {
        return this.axios.post(`${this.apiPath}/save-query`, memberQuery);
    }

    public deleteMemberQuery(memberId: number, sourceId: string): AxiosPromise {
        return this.axios.delete(`${this.apiPath}/query/${memberId}/${encodeURIComponent(sourceId)}`);
    }

    public deleteMemberQueriesFiles(memberId: number, sourceId: string, fileName: string): AxiosPromise<string> {
        return this.axios.delete(`${this.apiPath}/file/${memberId}/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}`);
    }

    public getUploadQueriesSecureDocumentUrl(): string {
        return `${this.apiPath}/upload-document`;
    }

    public getQueriesSecureDocument(fileDescriptorId: number, memberId: number, sourceId: string, fileName: string): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/document/${fileDescriptorId}/${memberId}/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}`, { responseType: "blob" });
    }

    public submitMemberQuery(memberQuery: Model.PartialPlainObject<MemberQueryLookup>): AxiosPromise<Model.PlainObject<MemberQueryLookup>> {
        return this.axios.post(`${this.apiPath}/submit-query`, memberQuery);
    }

    // Client only properties / methods
}