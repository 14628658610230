import React from 'react';
import { Data, Model, ValueObject, Utils } from '@singularsystems/neo-core';
import { observer } from 'mobx-react';
import Loader from '@singularsystems/neo-react/dist/ReactComponents/Loader';
import { DataViewContext, DataViewContextParams } from '@singularsystems/neo-react/dist/React/DataView';
import BasicPagerControls, { IPagerControlsProps } from './LibertyBasicPagerControls';

export interface IPagerBaseProps<TCriteria extends ValueObject, TModel extends Model.IBindableBase> extends Omit<React.HTMLProps<HTMLDivElement>, "children"> {

  /** Page manager which controls this component. */
  pageManager: Data.PageManager<TCriteria, TModel>;

  /** Child can either be a component which works with a dataview (like Neo.Grid), or a callback which will be passed the items of the current page. */
  children: React.ReactNode | ((items: TModel[]) => React.ReactNode);

  /** Where to put the page controls? */
  pageControls?: "none" | "top" | "bottom" | "topAndBottom";

  /** True if the built in loader component should be hidden. */
  suppressLoader?: boolean;

  /** Properties to be passed to the pager controls. */
  pageControlProps?: Partial<IPagerControlsProps>;

  /** Set to false if you don't want the child control to be hidden before data is fetched for the first time. */
  hideBeforeLoad?: boolean;

  noItemsFoundElement?: JSX.Element | null;

  /** Maximum pages to show */
  maxPagesToShow: number;
}

@observer
export default class LibertyPager<TCriteria extends ValueObject, TModel extends Model.IBindableBase> extends React.Component<IPagerBaseProps<TCriteria, TModel>> {

  dataViewContext = new DataViewContextParams<TModel>();

  constructor(props: IPagerBaseProps<TCriteria, TModel>) {
    super(props);

    this.dataViewContext.dataView = this.props.pageManager;
  }

  public componentDidMount() {
    this.props.pageManager.initialise();
  }

  public render() {
    const { pageManager, pageControls, pageControlProps, suppressLoader, maxPagesToShow, hideBeforeLoad, ...domProps } = this.props;


    if (!pageManager.hasFetched && !pageManager.taskRunner.isBusy && hideBeforeLoad !== false) { return null; }

    const className = Utils.joinWithSpaces("neo-pager liberty-pager", domProps.className);

    const hideGridIfNotItems: boolean = (this.props.noItemsFoundElement !== null);

    if (hideGridIfNotItems && pageManager.getItems().length === 0) {
      return <div {...domProps} className={className}>
        {this.props.noItemsFoundElement}
      </div>
    } else {
      const pager =
        <div {...domProps} className={className}>

          {/* {(this.props.noItemsFoundElement !== null && pageManager.getItems().length === 0) 
                    &&
                    this.props.noItemsFoundElement} */}

          {/* Top Pager */}
          {(pageManager.hasFetched && pageControls && (pageControls === "top" || pageControls === "topAndBottom"))
            &&
            <div className="pager-controls-container-top">
              <BasicPagerControls maxPagesToShow={maxPagesToShow} {...pageControlProps} ref={undefined} pageManager={pageManager} />
            </div>}

          {/* Grid/Data etc */}
          <DataViewContext.Provider value={this.dataViewContext}>
            {this.props.children instanceof Function ? this.props.children(pageManager.getItems()) : this.props.children}
          </DataViewContext.Provider>

          {/* Bottom Pager */}
          {(pageManager.hasFetched && (!pageControls || pageControls === "bottom" || pageControls === "topAndBottom"))
            &&
            <div className="pager-controls-container-bottom">
              <BasicPagerControls maxPagesToShow={maxPagesToShow} {...pageControlProps} ref={undefined} pageManager={pageManager} />
            </div>}

        </div>

      return suppressLoader ? pager : <Loader task={pageManager.taskRunner}>{pager}</Loader>;
    }
  }
}