import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import img_alert from '../../assets/img/alert.png';
import PersonContactDetailsComponentVM from '../../../Party/Components/Profile/PersonContactDetailsComponentVM';
import SystemInformationQueryApiClient from '../../../Domain/ApiClients/SystemInformation/SystemInformationQueryApiClient';

export default class ProfileVM extends Views.ViewModelBase {

  public showUpdateModal: boolean = false;
  public showCancelModal: boolean = false;
  public popUpText: string = 'Details outstanding';
  public alertImageDisplay: string = img_alert;
  public versionNumber = "v0.0";
  public showProfileHealthModal: boolean = false;

  public personContactDetailsComponentVM = this.registerViewModel(PersonContactDetailsComponentVM);

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private systemInformationQueryApiClient: SystemInformationQueryApiClient = AppService.get(Types.Domain.ApiClients.SystemInformationQueryApiClient)
  ) {
    super(taskRunner);
    this.makeObservable();
  }

  public async initialise() {
    await Promise.all([this.getVersionInformation(), this.personContactDetailsComponentVM.initialise(), this.showOrHideProfileHealthModal()])
  }

  public async getVersionInformation() {
    let response = await this.systemInformationQueryApiClient.getAssemblyVersionLookup();
    this.versionNumber = response.data.majorMinorPatch;
  }

  public async showOrHideProfileHealthModal() {
    if (!this.personContactDetailsComponentVM.allVerified) {
      this.showProfileHealthModal = true;
    }
  }

  public getMemberProfileCompleteness() {
    let percentageComplete = this.personContactDetailsComponentVM.getMemberProfileCompleteness();

    return percentageComplete;
  }

  public async navigateToDashboard() {
    this.userRoleService.navigateToDashboard();
  }

  public async onCancelClicked() {
    this.personContactDetailsComponentVM.cancelChanges();
    this.showCancelModal = false;
  }
}
