import { Attributes, EnumHelper, List, ModelBase, Validation } from '@singularsystems/neo-core';
import { RoleType } from './Enums/RoleType.enum';
import ClaimDeclarationTemplate from './ClaimDeclarationTemplate';
import ClaimTypeCategoryGroupTemplateLookup from './ClaimTypeCategoryGroupTemplateLookup';

export default class RoleTypeGroupTemplateLookup extends ModelBase {
    static typeName = "RoleTypeGroupTemplateLookup";

    constructor() {
        super();
        this.makeObservable();
    }

    public roleType: RoleType = RoleType.MemberDeclaration;

    public claimTypeCategoryGroups = new List(ClaimTypeCategoryGroupTemplateLookup);

    public claimDeclarationTemplates = new List(ClaimDeclarationTemplate);

    // Client only properties / methods

    @Attributes.NoTracking()
    public isExpanded : boolean = false;

    @Attributes.Display("Role Type")
    public get roleTypeDisplay() {
        return EnumHelper.getItemMetadata(RoleType, this.roleType).display;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New role type group template lookup";
        } else {
            return "Role Type Group Template Lookup";
        }
    }
}