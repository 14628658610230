import { Data, Model, Validation } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../DomainTypes';
import TAndCTemplate from '../../../Models/TAndC/TAndCTemplate';

export interface IUserTAndCCommandApiClient {

  acceptTAndC(): AxiosPromise;

  hasAcceptedById(templateId: number): AxiosPromise<boolean>;

  hasAccepted(): AxiosPromise<boolean>;

  // Client only properties / methods
}

@injectable()
export default class UserTAndCCommandApiClient extends Data.ApiClientBase implements IUserTAndCCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/usertandc-command`);
  }

  public acceptTAndC(): AxiosPromise {
    return this.axios.put(`${this.apiPath}/accept`);
  }

  public hasAcceptedById(templateId: number): AxiosPromise<boolean> {
    return this.axios.get(`${this.apiPath}/accepted/${templateId}`);
  }

  public hasAccepted(): AxiosPromise<boolean> {
    return this.axios.get(`${this.apiPath}/accepted`);
  }
  // Client only properties / methods
}