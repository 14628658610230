import { EnumHelper } from "@singularsystems/neo-core";

export enum ReportType {
  None = 0,
  MemberInvestmentStatement = 1,
  MemberBenefitStatement = 2,
  PaidupMemberInvestmentStatement = 3,
  PaidupMemberCertificate = 4
}

EnumHelper.decorateEnum(ReportType, decorator => {
  decorator.describe(ReportType.MemberInvestmentStatement, "Member investment statement", "");
  decorator.describe(ReportType.MemberBenefitStatement, "Member benefit statement", "");
  decorator.describe(ReportType.PaidupMemberInvestmentStatement, "Paid-up member investment statement", "");
  decorator.describe(ReportType.PaidupMemberCertificate, "Paid-up member certificate", "");
});