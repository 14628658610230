import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { KCTemplateType } from './Enums/KCTemplateType.enum';

@NeoModel
export default class KCTemplate extends ModelBase {

  public kcTemplateId: number = 0;

  @Rules.Required()
  @Rules.StringLength(100)
  public title: string = "";

  @Rules.Required()
  @Rules.StringLength(1000)
  public description: string = "";

  @Rules.Required()
  @Rules.StringLength(1000)
  public url: string = "";

  public kcTemplateType: KCTemplateType = KCTemplateType.None;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.title) {
      return "New kc template";
    } else {
      return this.title;
    }
  }
}