import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import InstructionsSearchResultsLookup from '../../../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';

interface IInstructionDateStatusPhoneScreenProps {
  instruction: InstructionsSearchResultsLookup
}

@observer
export default class InstructionDateStatusPhoneScreen extends React.Component<IInstructionDateStatusPhoneScreenProps> {

  constructor(props: IInstructionDateStatusPhoneScreenProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-phone-screen'>
        <div className={this.props.instruction.status == 'In progress' ? 'in-progress-pill' : this.props.instruction.status == 'Incomplete' || this.props.instruction.status == 'Requires documents' ? 'incomplete-pill' : 'completed-pill'}>
          <Neo.Tooltip position='bottom' content={this.props.instruction.status}>{this.props.instruction.status}</Neo.Tooltip>
        </div>
        <br /><br />
        <p className='instructon-view-date'>20 Nov 2022</p>
        <br /><br />
      </div>
    );
  }
}