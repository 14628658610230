import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { User } from '../../../UserModel/User';
import { UserType } from '../../../Enums/UserType.enum';
import { AppService, Types } from '../../Services/AppService';
import MemberSchemeMembershipLookup from '../../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import SavingsWithdrawalClaimView from '../../../Party/Views/SavingsWithdrawalClaimView';

interface ISchemeInformationHeaderProps {
  memberName: string,
  schemeHeader: string,
  showClaimButton: boolean,
  extraCssClasses: string,
  onSchemeSelected: (scheme: MemberSchemeMembershipLookup) => void
}

@observer
export default class SchemeInformationHeader extends React.Component<ISchemeInformationHeaderProps> {
  private taskRunner = AppService.get(Types.Neo.TaskRunner);
  private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);
  private userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService);

  private scheme: MemberSchemeMembershipLookup | undefined;

  constructor(
    props: ISchemeInformationHeaderProps) {
    super(props);
  }

  public async initialise() {
  }

  public componentDidMount() {
    this.setSchemeInfo();
  }

  public navigateToWithdrawalClaim() {
    this.navigation.navigateToView(SavingsWithdrawalClaimView);
  }

  private async setSchemeInfo() {
    if (this.userMemberSchemeService.currentSchemeSelected.schemeId && this.userMemberSchemeService.currentSchemeSelected.schemeId > 0 && this.userMemberSchemeService.schemeDetailsLookups.length > 0) {
      this.scheme = this.userMemberSchemeService.schemeDetailsLookups.find(s => s.schemeId === this.userMemberSchemeService.currentSchemeSelected.schemeId);
    }
    else {
      this.scheme = await this.taskRunner.waitFor(this.userMemberSchemeService.getSchemeDetails());
    }

    this.props.onSchemeSelected(this.scheme ?? new MemberSchemeMembershipLookup())
  }

  public selectSchemeId(schemeId: number) {
    if (schemeId === 0) { return; }

    this.userMemberSchemeService.setCurrentSchemeMember(schemeId);
    this.setSchemeInfo();
  }

  public loggedInUser = new User(UserType.Legacy);

  public render() {
    return (
      <Neo.Loader task={this.taskRunner}>
        <div className={'row ' + this.props.extraCssClasses}>
          <div className='col-12 col-md-12'>
            <div className='background-card scheme-information-header'>
              {(this.loggedInUser.userType === UserType.Staff || this.loggedInUser.userType === UserType.Employer || this.loggedInUser.userType === UserType.FA) &&
                <div>
                  <div className='scheme-card-header-for-staff'>
                    Beneficiary update on behalf of <span className='scheme-card-header-bold-for-staff'> {this.props.memberName}</span>
                  </div>
                  <div className='separating-line' />
                </div>
              }
              <div className='row'>
                <div className='col-md-4 col-12 padding-top-scheme-header '>
                  <div className='form-group'>
                    <span className='scheme-card-header'>{this.props.schemeHeader}</span>
                  </div>
                  <Neo.AutoCompleteDropDown
                    className='scheme-information-header-dropdown'
                    bind={this.userMemberSchemeService.currentSchemeSelected.meta.schemeId}
                    bindDisplay={this.userMemberSchemeService.currentSchemeSelected.meta.schemeName}
                    isClearable={false}
                    items={this.userMemberSchemeService.namesArray}
                    onItemSelected={(item) => this.selectSchemeId(item?.schemeId ?? 0)}
                  />
                </div>
                <div className='col-md-7 align-claim-button' hidden={!this.props.showClaimButton}>
                  <Neo.Button text="Start a claim" className='btn btn-primary btn-update-claim'
                    onClick={() => {
                      this.navigateToWithdrawalClaim();
                    }}
                  />
                </div>
                {(this.loggedInUser.userType === UserType.Staff || this.loggedInUser.userType === UserType.Employer || this.loggedInUser.userType === UserType.FA) &&
                  <div className='col-md-8 col-12 padding-top-scheme-header'>
                    <div className='background-card-member-details-staff'>
                      <div className='background-card-member-details-header-staff'>Member details</div>
                      <div className='background-card-memver-details-name-staff'>{this.props.memberName}</div>
                      <div className='background-card-memver-details-name-staff'>M 536215A</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Neo.Loader>
    );
  }
}