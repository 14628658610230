import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';

export interface IPDFQueryApiClient {

  /** 
   * Gets a pdf template.
   * @returns A pdf document
   */
  getPDFasBytes(): AxiosPromise<string>;

  /** 
   * Recive a boolean if the PDF is encrypted or not.
   * @returns Returns true or false
   */
  isPDFEncrypted(): AxiosPromise<boolean>;

  // Client only properties / methods
}

@injectable()
export default class PDFQueryApiClient extends Data.ApiClientBase implements IPDFQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/pdf`);
  }

  public getPDFasBytes(): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/get-pdf`);
  }

  public isPDFEncrypted(): AxiosPromise<boolean> {
    return this.axios.get(`${this.apiPath}/get-encrypted-pdf`);
  }

  // Client only properties / methods
}