import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberEligibilityLookup from '../../Models/LookUps/MemberClaims/MemberEligibilityLookup';

export interface IMemberSavingsClaimEligibilityQueryApiClient {

    /** 
     * Retrieves Eligibility Lookup.
     * @param memberId Id
     * @param sourceId sourceId
     * @returns MemberInvestmentValuesLookup.
     */
    getMemberEligibilityLookupById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberEligibilityLookup>>;

    // Client only properties / methods
}

@injectable()
export default class MemberSavingsClaimEligibilityQueryApiClient extends Data.ApiClientBase implements IMemberSavingsClaimEligibilityQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/member-eligibility-values`);
    }

    public getMemberEligibilityLookupById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberEligibilityLookup>> {
        return this.axios.get(`${this.apiPath}/${memberId}/${encodeURIComponent(sourceId)}`);
    }

    // Client only properties / methods
}