import { Validation, ValueObject } from '@singularsystems/neo-core';
import { SchemeStatus } from '../../Enums/Employer/SchemeStatus.enum';

export default class EmployerDashboardSchemeSearchCriteria extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public schemeStatuses: SchemeStatus | null = null;

  public keyword: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Employer Dashboard Scheme Search Criteria";
  }
}