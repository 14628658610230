import { AppServices } from '@singularsystems/neo-core';
import StaffDashboardApiClient from './ApiClients/StaffDashboardApiClient';

export const StaffDashboardExportedTypes = {
  ApiClients: {
    StaffDashboardApiClient: new AppServices.ServiceIdentifier<StaffDashboardApiClient>("StaffDashboard.ApiClient.StaffDashboardApiClient"),
  },
  Services: {
    // Add Services here
  },
};