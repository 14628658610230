import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import NonEditableProfileVM from './NonEditableProfileVM';
import { observer } from 'mobx-react';
import '../../Styles/Components/NonEditableProfile.scss';
import img_alert from '../../assets/img/alert.png';
import { AppConfig } from '../../Models/AppConfig';
import ContainerFooterComponent from '../Components/ContainerFooterComponent';
import BlueRibbon from '../Components/BlueRibbon';

@observer
export default class NonEditableProfileView extends Views.ViewBase<NonEditableProfileVM> {

  //ToDo: countries list should be coming from a class.
  public countries = [
    { id: 0, country: "Select country" },
    { id: 1, country: "Afghanistan" },
    { id: 2, country: "Albania" },
    { id: 3, country: "Algeria" },
    { id: 4, country: "Andorra" },
    { id: 5, country: "Angola" },
    { id: 6, country: "Antigua and Barbuda" },
    { id: 7, country: "Argentina" },
    { id: 8, country: "Armenia" },
    { id: 9, country: "Australia" },
    { id: 10, country: "Austria" },
    { id: 11, country: "Azerbaijan" },
    { id: 12, country: "Bahamas" },
    { id: 13, country: "Bahrain" },
    { id: 14, country: "Bangladesh" },
    { id: 15, country: "Barbados" },
    { id: 16, country: "Belarus" },
    { id: 17, country: "Belgium" },
    { id: 18, country: "Belize" },
    { id: 19, country: "Benin" },
    { id: 20, country: "Bhutan" },
    { id: 21, country: "Bolivia" },
    { id: 22, country: "Bosnia and Herzegovina" },
    { id: 23, country: "Botswana" },
    { id: 24, country: "Brazil" },
    { id: 25, country: "Brunei" },
    { id: 26, country: "Bulgaria" },
    { id: 27, country: "Burkina Faso" },
    { id: 28, country: "Burundi" },
    { id: 29, country: "Cabo Verde" },
    { id: 30, country: "Cambodia" },
    { id: 31, country: "Cameroon" },
    { id: 32, country: "Canada" },
    { id: 33, country: "Central African Republic" },
    { id: 34, country: "Chad" },
    { id: 35, country: "Chile" },
    { id: 36, country: "China" },
    { id: 37, country: "Colombia" },
    { id: 38, country: "Comoros" },
    { id: 39, country: "Congo, Democratic Republic of the" },
    { id: 40, country: "Congo, Republic of the" },
    { id: 41, country: "Costa Rica" },
    { id: 42, country: "Croatia" },
    { id: 43, country: "Cuba" },
    { id: 44, country: "Cyprus" },
    { id: 45, country: "Czech Republic" },
    { id: 46, country: "Denmark" },
    { id: 47, country: "Djibouti" },
    { id: 48, country: "Dominica" },
    { id: 49, country: "Dominican Republic" },
    { id: 50, country: "Ecuador" },
    { id: 51, country: "Egypt" },
    { id: 52, country: "El Salvador" },
    { id: 53, country: "Equatorial Guinea" },
    { id: 54, country: "Eritrea" },
    { id: 55, country: "Estonia" },
    { id: 56, country: "Eswatini" },
    { id: 57, country: "Ethiopia" },
    { id: 58, country: "Fiji" },
    { id: 59, country: "Finland" },
    { id: 60, country: "France" },
    { id: 61, country: "Gabon" },
    { id: 62, country: "Gambia" },
    { id: 63, country: "Georgia" },
    { id: 64, country: "Germany" },
    { id: 65, country: "Ghana" },
    { id: 66, country: "Greece" },
    { id: 67, country: "Grenada" },
    { id: 68, country: "Guatemala" },
    { id: 69, country: "Guinea" },
    { id: 70, country: "Guinea-Bissau" },
    { id: 71, country: "Guyana" },
    { id: 72, country: "Haiti" },
    { id: 73, country: "Honduras" },
    { id: 74, country: "Hungary" },
    { id: 75, country: "Iceland" },
    { id: 76, country: "India" },
    { id: 77, country: "Indonesia" },
    { id: 78, country: "Iran" },
    { id: 79, country: "Iraq" },
    { id: 80, country: "Ireland" },
    { id: 81, country: "Israel" },
    { id: 82, country: "Italy" },
    { id: 83, country: "Jamaica" },
    { id: 84, country: "Japan" },
    { id: 85, country: "Jordan" },
    { id: 86, country: "Kazakhstan" },
    { id: 87, country: "Kenya" },
    { id: 88, country: "Kiribati" },
    { id: 89, country: "Korea, North" },
    { id: 90, country: "Korea, South" },
    { id: 91, country: "Kosovo" },
    { id: 92, country: "Kuwait" },
    { id: 93, country: "Kyrgyzstan" },
    { id: 94, country: "Laos" },
    { id: 95, country: "Latvia" },
    { id: 96, country: "Lebanon" },
    { id: 97, country: "Lesotho" },
    { id: 98, country: "Liberia" },
    { id: 99, country: "Libya" },
    { id: 100, country: "Liechtenstein" },
    { id: 101, country: "Lithuania" },
    { id: 102, country: "Luxembourg" },
    { id: 103, country: "Madagascar" },
    { id: 104, country: "Malawi" },
    { id: 105, country: "Malaysia" },
    { id: 106, country: "Maldives" },
    { id: 107, country: "Mali" },
    { id: 108, country: "Malta" },
    { id: 109, country: "Marshall Islands" },
    { id: 110, country: "Mauritania" },
    { id: 111, country: "Mauritius" },
    { id: 112, country: "Mexico" },
    { id: 113, country: "Micronesia" },
    { id: 114, country: "Moldova" },
    { id: 115, country: "Monaco" },
    { id: 116, country: "Mongolia" },
    { id: 117, country: "Montenegro" },
    { id: 118, country: "Morocco" },
    { id: 119, country: "Mozambique" },
    { id: 120, country: "Myanmar" },
    { id: 121, country: "Namibia" },
    { id: 122, country: "Nauru" },
    { id: 123, country: "Nepal" },
    { id: 124, country: "Netherlands" },
    { id: 125, country: "New Zealand" },
    { id: 126, country: "Nicaragua" },
    { id: 127, country: "Niger" },
    { id: 128, country: "Nigeria" },
    { id: 129, country: "North Macedonia" },
    { id: 130, country: "Norway" },
    { id: 131, country: "Oman" },
    { id: 132, country: "Pakistan" },
    { id: 133, country: "Palau" },
    { id: 134, country: "Panama" },
    { id: 135, country: "Papua New Guinea" },
    { id: 136, country: "Paraguay" },
    { id: 137, country: "Peru" },
    { id: 138, country: "Philippines" },
    { id: 139, country: "Poland" },
    { id: 140, country: "Portugal" },
    { id: 141, country: "Qatar" },
    { id: 142, country: "Romania" },
    { id: 143, country: "Russia" },
    { id: 144, country: "Rwanda" },
    { id: 145, country: "Saint Kitts and Nevis" },
    { id: 146, country: "Saint Lucia" },
    { id: 147, country: "Saint Vincent and the Grenadines" },
    { id: 148, country: "Samoa" },
    { id: 149, country: "San Marino" },
    { id: 150, country: "Sao Tome and Principe" },
    { id: 151, country: "Saudi Arabia" },
    { id: 152, country: "Senegal" },
    { id: 153, country: "Serbia" },
    { id: 154, country: "Seychelles" },
    { id: 155, country: "Sierra Leone" },
    { id: 156, country: "Singapore" },
    { id: 157, country: "Slovakia" },
    { id: 158, country: "Slovenia" },
    { id: 159, country: "Solomon Islands" },
    { id: 160, country: "Somalia" },
    { id: 161, country: "South Africa" },
    { id: 162, country: "South Sudan" },
    { id: 163, country: "Spain" },
    { id: 164, country: "Sri Lanka" },
    { id: 165, country: "Sudan" },
    { id: 166, country: "Suriname" },
    { id: 167, country: "Sweden" },
    { id: 168, country: "Switzerland" },
    { id: 169, country: "Syria" },
    { id: 170, country: "Taiwan" },
    { id: 171, country: "Tajikistan" },
    { id: 172, country: "Tanzania" },
    { id: 173, country: "Thailand" },
    { id: 174, country: "Timor-Leste" },
    { id: 175, country: "Togo" },
    { id: 176, country: "Tonga" },
    { id: 177, country: "Trinidad and Tobago" },
    { id: 178, country: "Tunisia" },
    { id: 179, country: "Turkey" },
    { id: 180, country: "Turkmenistan" },
    { id: 181, country: "Tuvalu" },
    { id: 182, country: "Uganda" },
    { id: 183, country: "Ukraine" },
    { id: 184, country: "United Arab Emirates" },
    { id: 185, country: "United Kingdom" },
    { id: 186, country: "United States" },
    { id: 187, country: "Uruguay" },
    { id: 188, country: "Uzbekistan" },
    { id: 189, country: "Vanuatu" },
    { id: 190, country: "Vatican City" },
    { id: 191, country: "Venezuela" },
    { id: 192, country: "Vietnam" },
    { id: 193, country: "Yemen" },
    { id: 194, country: "Zambia" },
    { id: 195, country: "Zimbabwe" }
  ];

  //ToDo: states list should be coming from a class.
  private states = [
    { id: 1, province: "Eastern Cape" },
    { id: 2, province: "Free State" },
    { id: 3, province: "Gauteng" },
    { id: 4, province: "KwaZulu-Natal" },
    { id: 5, province: "Limpopo" },
    { id: 6, province: "Mpumalanga" },
    { id: 7, province: "Northern Cape" },
    { id: 8, province: "North West" },
    { id: 9, province: "Western Cape" },
  ];

  //ToDo: countryCodes should be coming from a class.
  private countryCodes = [
    { Id: 1, CountryCode: "+27" },
    { Id: 2, CountryCode: "+44" },
    { Id: 3, CountryCode: "+21" }
  ];

  constructor(props: unknown) {
    super("My profile", NonEditableProfileVM, props);
  }

  public render() {
    return (
      <>
        <div>
          <div className='member-profile-container-non-editable'>
            <div className='member-profile-card-non-editable'>
              <div className='row'>
                <div className='align-profile-info'>
                  <div className='col-md-6 col-12'>
                    <p className='card-heading-profile'>Member profile information</p>
                  </div>
                  <div className='col-md-6 col-12 align-alert-info'>
                    <i><img className='mb-1' src={img_alert} /></i>
                    <p className='alert-text'>Details outstanding</p>
                  </div>
                </div>
              </div>
              <div className='row align-divider'>
                <div className='divider1'></div>
              </div>
              <BlueRibbon
                text='If you would like to edit your identification information, please contact your employer to make the changes.'
              />
              <div>
                <div className='align-form-group-row-profile row'>
                  <Neo.GridLayout>
                    <Neo.FormGroup href='Title' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.title} readOnly isDisabled />
                    <Neo.FormGroup href='Surname' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.lastName} readOnly isDisabled />
                    <Neo.FormGroup href='Name' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.firstName} readOnly isDisabled />
                    <Neo.FormGroup href="SAIdOrPassportNumber" bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.nationalIdNumber} readOnly isDisabled />
                    <Neo.FormGroup href='DateOfBirth' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.birthDate} readOnly isDisabled />
                    <div className='col-md-12'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Cell number</label>
                        <div className='row country-dropdown-row-profile'>
                          <div className='col-2 col-md-2 country-col'>
                            <div className='country-codes-dropdown-profile'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryId}
                                bindDisplay={this.viewModel.meta.country}
                                items={this.countryCodes}
                                menuIsOpen={false}
                                disabled />
                            </div>
                          </div>
                          <div className='col-10 col-md-10'>
                            <div className="input-group cell-group-control">
                              <input type="text" className="form-control"
                                value={this.viewModel.userRoleService.personTelephoneNumber.substring(1)}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Neo.FormGroup href='CountryOfIssue' bind={this.viewModel.userRoleService.personContactDetailsLookup.meta.passportCountryOfIssue} readOnly isDisabled />
                  </Neo.GridLayout>
                </div>
                <div className='line-profile' />
                <div className='row mt-4'>
                  <div className='col-12 col-md-12'>
                    <Neo.Alert variant="primary" className='display-alert-profile non-editable-alert' heading="Not editable at this time" />
                  </div>
                </div>
                <div className='align-form-group-row-profile row'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Cell number</label>
                        <div className='row country-dropdown-row-profile'>
                          <div className='col-2 col-md-2 country-col'>
                            <div className='country-codes-dropdown-profile'>
                              <Neo.AutoCompleteDropDown
                                bind={this.viewModel.meta.countryId}
                                bindDisplay={this.viewModel.meta.country}
                                items={this.countryCodes}
                                menuIsOpen={false}
                                disabled />
                            </div>
                          </div>
                          <div className='col-10 col-md-10'>
                            <div className="input-group cell-group-control">
                              <input type="text" className="form-control"
                                value={this.viewModel.userRoleService.personTelephoneNumber.substring(1)}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row'>
                        <label className='align-cell-label-profile form-label'>Email address</label>
                        <div className='row country-dropdown-row-second-profile'>
                          <div className="input-group">
                            <input type="text" className="form-control add-border"
                              defaultValue={this.viewModel.userRoleService.personEmailAddress}
                              aria-label="Text input with checkbox" readOnly disabled />
                            <div className="input-group-text">
                              <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Street name & number</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.addressLine1 == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].addressLine1}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Unit/apartment number & complex/building name</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.addressLine2 == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].addressLine2}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>City/Town</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.city == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].city}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Suburb</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.subUrban == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].subUrban}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Commented for Launch1 */}
                  {/* <div className='align-form-group-row-profile row'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className="align-communication-method-profile">
                                                        <label className='align-province-label mt-0'> Provice/State </label>
                                                        <div className='row dropdown-row'>
                                                            <div className='input-group dropdown-input-group'>
                                                                <Neo.AutoCompleteDropDown className='mt-0 custom-select form-control'
                                                                    bind={this.viewModel.meta.provinceValueId}
                                                                    items={this.states}
                                                                    placeholder="Select province/state"
                                                                    disabled
                                                                >
                                                                </Neo.AutoCompleteDropDown>
                                                                <div className="input-group-text dropdown-append-profile">
                                                                    <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className="align-communication-method-profile">
                                                        <label className='align-province-label mt-0'> Country </label>
                                                        <div className='row dropdown-row'>
                                                            <div className='input-group dropdown-input-group'>
                                                                <Neo.AutoCompleteDropDown className='mt-0 mb-1 custom-select form-control'
                                                                    bind={this.viewModel.meta.countryValueId}
                                                                    items={this.countries}
                                                                    placeholder="Select country"
                                                                    disabled
                                                                >
                                                                </Neo.AutoCompleteDropDown>
                                                                <div className="input-group-text dropdown-append-profile">
                                                                    <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Province/State</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.province == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].province}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Country</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group">
                              <input type="text" className="form-control add-border"
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.country == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].country}
                                aria-label="Text input with checkbox" readOnly disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='align-form-group-row-profile row'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row'>
                          <label className='align-cell-label-profile form-label'>Postal code</label>
                          <div className='row country-dropdown-row-second-profile'>
                            <div className="input-group align-on-andriod">
                              <input type="text" className="form-control add-border" readOnly
                                defaultValue={this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0]?.postalCode == null ? ''
                                  : this.viewModel.userRoleService.personContactDetailsLookup.addressInfo[0].postalCode}
                                aria-label="Text input with checkbox" disabled />
                              <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ContainerFooterComponent />
            </div>
          </div>
        </div>
        <div className='version-number'> Version Number: {this.viewModel.versionNumber}</div>
      </>
    );
  }
}