import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class FADashboardSearchResultsLookup extends LookupBase {

  public firstName: string = "";

  public lastName: string = "";

  public scheme: string = "";

  public status: string = "";

  @Attributes.Integer()
  public idNumber: number = 0;

  public memberNumber: string = "";

  // Client only properties / methods
}