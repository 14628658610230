import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { DashboardExportedTypes } from './DashboardExportedTypes';

const DashboardTypes = {
  ApiClients: {
    ...DashboardExportedTypes.ApiClients
  },
  Services: {
    ...DashboardExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  Dashboard: DashboardTypes
}

export { AppService, Types, DashboardTypes }