import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class StaffSearchCriteria extends ValueObject {

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Staff Search Criteria";
  }
}