import { EnumHelper } from '@singularsystems/neo-core';

export enum ClaimType {
    SavingsClaim = 1,
    FuneralClaim = 2,
    DeathClaim = 3,
    WithdrawalClaim = 4,
    RetirementClaim = 5,
}

EnumHelper.decorateEnum(ClaimType, decorator => {
    decorator.describe(ClaimType.SavingsClaim, "Savings Claim");
    decorator.describe(ClaimType.FuneralClaim, "Funeral Claim");
    decorator.describe(ClaimType.DeathClaim, "Death Claim");
    decorator.describe(ClaimType.WithdrawalClaim, "Withdrawal Claim");
    decorator.describe(ClaimType.RetirementClaim, "Retirement Claim");
});

