import { Validation, ValueObject } from '@singularsystems/neo-core';
import PersonContactDetailsAddressCommand from './PersonContactDetailsAddressCommand';

export default class UpdatePersonContactDetailsCommand extends ValueObject {

  constructor() {
    super();
    this.makeObservable();
  }

  public partyId: string = "";

  public telephoneNo: string | null = null;

  public emailAddress: string | null = null;

  public addressInfo: PersonContactDetailsAddressCommand | null = null;

  public sourceId: string = "";

  public userName: string = "";

  public roleName: string = "";

  public nameId: number = 0;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    return "Update Person Contact Details Command";
  }
}