import { Attributes, List, LookupBase } from '@singularsystems/neo-core';
import MemberCurrentInvestmentValuesLookup from '../MemberInvestmentValues/MemberCurrentInvestmentValuesLookup';

export default class PersonFundsFromSchemesLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  @Attributes.Nullable()
  public memberId: number | null = null;

  @Attributes.Nullable()
  public schemeId: number | null = null;

  public schemeName: string | null = null;

  public currentInvestmentValues = new List(MemberCurrentInvestmentValuesLookup);

  @Attributes.Nullable()
  @Attributes.Float()
  public bestNetReplacementRatio: number | null = null;

  @Attributes.Nullable()
  @Attributes.Float()
  public bestEstimatedMonthlyIncome: number | null = null;

  public isEligible: boolean = false;

  @Attributes.Float()
  public sofSavingsPot: number = 0;

  // Client only properties / methods

  public get bestNetReplacementRatioSafe() {
    return this.bestNetReplacementRatio ?? 0;
  }

  public get bestEstimatedMonthlyIncomeSafe() {
    return this.bestEstimatedMonthlyIncome ?? 0;
  }

  public get schemeIdSafe() {
    return this.schemeId ?? 0;
  }
}