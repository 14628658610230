import { AppServices } from '@singularsystems/neo-core';
import CallsAndInstructionsApiClient from './ApiClients/CallsAndInstructionsApiClient';
import CallsAndNotificationsApiClient from './ApiClients/CallsAndNotificationsApiClient';
import MemberDashboardApiClient from './ApiClients/MemberDashboardApiClient';

export const MemberDashboardExportedTypes = {
  ApiClients: {
    MemberDashboardApiClient: new AppServices.ServiceIdentifier<MemberDashboardApiClient>("MemberDashboard.ApiClient.MemberDashboardApiClient"),
    CallsAndNotificationsApiClient: new AppServices.ServiceIdentifier<CallsAndNotificationsApiClient>("MemberDashboard.ApiClient.CallsAndNotificationsApiClient"),
    CallsAndInstructionsApiClient: new AppServices.ServiceIdentifier<CallsAndInstructionsApiClient>("MemberDashboard.ApiClient.CallsAndInstructionsApiClient"),
    //Add Api Clients here
  },
  Services: {
    // Add Services here
  },
};