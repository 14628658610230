import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import ContactUsTemplateLookup from '../../Models/ContactUs/ContactUsTemplateLookup';
import ContactUsTemplate from '../../Models/ContactUs/ContactUsTemplate';
import { ContactUsTemplateType } from '../../Models/ContactUs/Enums/ContactUsTemplateType.enum';

export interface IContactUsQueryApiClient {

  /** 
   * Gets the ContactUs templates.
   * @returns A list of ContactUs templates.
   */
  getContactUsTemplateLookups(): AxiosPromise<Array<Model.PlainObject<ContactUsTemplateLookup>>>;

  /** 
   * Gets the ContactUs templates.
   * @returns A list of ContactUs templates.
   */
  getContactUsTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<ContactUsTemplate>>>;

  /** 
   * Gets a ContactUs template.
   * @returns A ContactUs template.
   */
  getContactUsTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>>;

  /** 
   * Gets the ContactUsTemplateLookup along with the cards
   * @returns ContactUsTemplateLookup
   */
  getContactUsTemplateLookup(contactUsTemplateType: ContactUsTemplateType): AxiosPromise<Model.PlainObject<ContactUsTemplateLookup>>;

  /** 
   * Gets the ContactUsTemplate along with the cards
   * @returns ContactUsTemplate
   */
  getContactUsTemplateByType(contactUsTemplateType: ContactUsTemplateType): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>>;

  // Client only properties / methods
}

@injectable()
export default class ContactUsQueryApiClient extends Data.ApiClientBase implements IContactUsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/contactUs`);
  }

  public getContactUsTemplateLookups(): AxiosPromise<Array<Model.PlainObject<ContactUsTemplateLookup>>> {
    return this.axios.get(`${this.apiPath}/contactUs-template-lookups`);
  }

  public getContactUsTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<ContactUsTemplate>>> {
    return this.axios.get(`${this.apiPath}/contactUs-templates`);
  }

  public getContactUsTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>> {
    return this.axios.get(`${this.apiPath}/contactUs-template/${templateId}`);
  }

  public getContactUsTemplateLookup(contactUsTemplateType: ContactUsTemplateType): AxiosPromise<Model.PlainObject<ContactUsTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/contactUs-template-lookup/${contactUsTemplateType}`);
  }

  public getContactUsTemplateByType(contactUsTemplateType: ContactUsTemplateType): AxiosPromise<Model.PlainTrackedObject<ContactUsTemplate>> {
    return this.axios.get(`${this.apiPath}/contactUs-template-by-type/${contactUsTemplateType}`);
  }

  // Client only properties / methods
}