import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import KnowledgeAndFormsVM from './KnowledgeAndFormsVM';
import { observer } from 'mobx-react';
import '../Styles/Components/KnowledgeAndForms.scss';
import img_Clipboard from "../assets/img/clipboard.png";
import img_Forms from "../assets/img/paper-stack.png";
import img_Link from "../assets/img/link.png";
import img_Cap from "../assets/img/graduate-cap.png";
import { KCTemplateType } from '../../Domain/Models/KnowledgeCentre/Enums/KCTemplateType.enum';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class KnowledgeAndFormsView extends Views.ViewBase<KnowledgeAndFormsVM> {

  constructor(props: unknown) {
    super("Knowledge centre", KnowledgeAndFormsVM, props);
  }

  public render() {
    return (
      <div>
        <div className='knowledge-forms-container'>
          <div>
            <div className='knowledge-forms-card'>
              <div className='row sub-card-container'>
                {this.viewModel.kcTemplates.map((item, index) => (
                  <div key={index} className='col-md-6'>
                    <div className='knowledge-forms-inner-card'>
                      <div className='sub-card-header'>
                        {item.title}
                        <div className='align-image'>
                          <img src={item.kcTemplateType == KCTemplateType.FundFactSheets ? img_Clipboard : item.kcTemplateType == KCTemplateType.Forms ? img_Forms : item.kcTemplateType == KCTemplateType.UsefulLinks ? img_Link : img_Cap} />
                          <div className='inner-card-content'>
                            {item.description}
                          </div>
                          <Neo.Button text="Find out more" className='btn-kf' onClick={() => this.viewModel.navigateToPage(item)} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <ContainerFooterComponent />
            </div>
          </div>
        </div>
      </div >
    );

  }
}