import { Attributes, DateUtils, List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import VASTemplateCardLookup from './VASTemplateCardLookup';

export default class VASTemplateLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public vasTemplateId: number = 0;

  @Attributes.Display("Contact Card Label")
  public contactCardLabel: string = "";

  @Attributes.Display("Contact Number (Local)")
  public contactNumberLocal: string = "";

  @Attributes.Display("Website URL")
  public websiteURL: string = "";

  @Attributes.Display("Contact Number (International)")
  public contactNumberInternational: string = "";

  public vasTemplateCards = new List(VASTemplateCardLookup);

  // Client only properties / methods

  public getFormattedContactNumberLocal(): string {
    if (this.contactNumberLocal.length === 10) {
      return `${this.contactNumberLocal.substring(0, 3)} ${this.contactNumberLocal.substring(3, 6)} ${this.contactNumberLocal.substring(6)}`;
    }
    else {
      return '';
    }
  }

  public getFormattedContactNumberInternational(): string {
    if (this.contactNumberInternational.length === 12) {
      return `${this.contactNumberInternational.substring(0, 3)} ${this.contactNumberInternational.substring(3, 5)} ${this.contactNumberInternational.substring(5, 8)} ${this.contactNumberInternational.substring(8)}`;
    }
    else {
      return '';
    }
  }
}