import { LookupBase, ModelBase } from '@singularsystems/neo-core';

export default class PartyFileDescriptorLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public fileDescriptorId: number = 0;

  public fileName: string = "";

  // Client only properties / methods
}