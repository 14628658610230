import { ModelBase, StringUtils } from "@singularsystems/neo-core"
import { injectable } from "inversify";
import MemberQueryLookupBase from "../../Party/Models/LookUps/MemberQueries/MemberQueryLookupBase";
import TelephoneInfoLookup from "../../Party/Models/LookUps/TelephoneInfoLookup";

@injectable()
export default class HelpersService extends ModelBase {
  private hasInitialised = false;
  public countryValueId: number | null = 148;
  public countryValue: string = '+27';

  //ToDo: countryCodes should be coming from a class. Leaving the commented countries in here as it may be useful for future reference
  public countryCodes = [
    { Id: 148, CountryCode: "+27" }, //  South Africa
    { Id: 1, CountryCode: "+93" }, //  Afghanistan
    { Id: 2, CountryCode: "+355" }, //  Albania
    { Id: 3, CountryCode: "+213" }, //  Algeria
    { Id: 4, CountryCode: "+1684" }, //  American Samoa
    { Id: 5, CountryCode: "+376" }, //  Andorra
    { Id: 6, CountryCode: "+244" }, //  Angola
    { Id: 7, CountryCode: "+1" }, //  Antigua and Barbuda
    { Id: 8, CountryCode: "+54" }, //  Argentina
    { Id: 9, CountryCode: "+374" }, //  Armenia
    { Id: 10, CountryCode: "+297" }, //  Aruba
    { Id: 11, CountryCode: "+61" }, //  Australia
    { Id: 12, CountryCode: "+43" }, //  Austria
    { Id: 13, CountryCode: "+994" }, //  Azerbaijan
    { Id: 14, CountryCode: "+242" }, //  Botswana
    { Id: 15, CountryCode: "+1" }, //  Bahamas
    { Id: 16, CountryCode: "+973" }, //  Bahrain
    { Id: 17, CountryCode: "+880" }, //  Bangladesh
    { Id: 18, CountryCode: "+1242" }, //  Barbados
    { Id: 19, CountryCode: "+375" }, //  Belarus
    { Id: 20, CountryCode: "+32" }, //  Belgium
    { Id: 21, CountryCode: "+501" }, //  Belize
    { Id: 22, CountryCode: "+229" }, //  Benin
    { Id: 23, CountryCode: "+975" }, //  Bhutan
    { Id: 24, CountryCode: "+591" }, //  Bolivia
    { Id: 25, CountryCode: "+387" }, //  Bosnia and Herzegovina
    { Id: 26, CountryCode: "+267" }, //  Botswana
    { Id: 27, CountryCode: "+55" }, //  Brazil
    { Id: 28, CountryCode: "+1284" }, //  British Virgin Islands
    { Id: 29, CountryCode: "+673" }, //  Brunei
    { Id: 30, CountryCode: "+359" }, //  Bulgaria
    { Id: 31, CountryCode: "+226" }, //  Burkina Faso
    { Id: 32, CountryCode: "+257" }, //  Burundi
    { Id: 33, CountryCode: "+855" }, //  Cambodia
    { Id: 34, CountryCode: "+237" }, //  Cameroon
    { Id: 35, CountryCode: "+1" }, //  Canada
    { Id: 36, CountryCode: "+238" }, //  Cape Verde
    { Id: 37, CountryCode: "+1" }, //  Cayman Islands
    { Id: 38, CountryCode: "+236" }, //  Central African Republic
    { Id: 39, CountryCode: "+235" }, //  Chad
    { Id: 40, CountryCode: "+56" }, //  Chile
    { Id: 41, CountryCode: "+86" }, //  China
    { Id: 42, CountryCode: "+61" }, //  Christmas Island
    { Id: 43, CountryCode: "+57" }, //  Colombia
    { Id: 44, CountryCode: "+682" }, //  Cook Islands
    { Id: 45, CountryCode: "+506" }, //  Costa Rica
    { Id: 46, CountryCode: "+385" }, //  Croatia
    { Id: 47, CountryCode: "+53" }, //  Cuba
    { Id: 48, CountryCode: "+599" }, //  Curacao
    { Id: 49, CountryCode: "+357" }, //  Cyprus
    { Id: 50, CountryCode: "+420" }, //  Czech Republic
    { Id: 51, CountryCode: "+45" }, //  Denmark
    { Id: 52, CountryCode: "+253" }, //  Djibouti
    { Id: 53, CountryCode: "+1" }, //  Dominica
    { Id: 54, CountryCode: "+509" }, //  Haiti
    { Id: 55, CountryCode: "+504" }, //  Honduras
    { Id: 56, CountryCode: "+852" }, //  Hong Kong
    { Id: 57, CountryCode: "+36" }, //  Hungary
    { Id: 58, CountryCode: "+354" }, //  Iceland
    { Id: 59, CountryCode: "+91" }, //  India
    { Id: 60, CountryCode: "+62" }, //  Indonesia
    { Id: 61, CountryCode: "+98" }, //  Iran
    { Id: 62, CountryCode: "+964" }, //  Iraq
    { Id: 63, CountryCode: "+353" }, //  Ireland
    { Id: 64, CountryCode: "+972" }, //  Israel
    { Id: 65, CountryCode: "+39" }, //  Italy
    { Id: 66, CountryCode: "+225" }, //  Ivory Coast
    { Id: 67, CountryCode: "+1" }, //  Jamaica
    { Id: 68, CountryCode: "+81" }, //  Japan
    { Id: 69, CountryCode: "+44" }, //  United Kingdom
    { Id: 70, CountryCode: "+962" }, //  Jordan
    { Id: 71, CountryCode: "+7" }, //  Kazakhstan
    { Id: 72, CountryCode: "+254" }, //  Kenya
    { Id: 73, CountryCode: "+686" }, //  Kiribati
    { Id: 74, CountryCode: "+850" }, //  North Korea
    { Id: 75, CountryCode: "+82" }, //  South Korea
    { Id: 76, CountryCode: "+965" }, //  Kuwait
    { Id: 77, CountryCode: "+996" }, //  Kyrgyzstan
    { Id: 78, CountryCode: "+856" }, //  Laos
    { Id: 79, CountryCode: "+371" }, //  Latvia
    { Id: 80, CountryCode: "+961" }, //  Lebanon
    { Id: 81, CountryCode: "+266" }, //  Lesotho
    { Id: 82, CountryCode: "+231" }, //  Liberia
    { Id: 83, CountryCode: "+218" }, //  Libya
    { Id: 84, CountryCode: "+423" }, //  Liechtenstein
    { Id: 85, CountryCode: "+370" }, //  Lithuania
    { Id: 86, CountryCode: "+352" }, //  Luxembourg
    { Id: 87, CountryCode: "+853" }, //  Macau
    { Id: 88, CountryCode: "+389" }, //  North Macedonia
    { Id: 89, CountryCode: "+261" }, //  Madagascar
    { Id: 90, CountryCode: "+265" }, //  Malawi
    { Id: 91, CountryCode: "+60" }, //  Malaysia
    { Id: 92, CountryCode: "+960" }, //  Maldives
    { Id: 93, CountryCode: "+223" }, //  Mali
    { Id: 94, CountryCode: "+356" }, //  Malta
    { Id: 95, CountryCode: "+692" }, //  Marshall Islands
    { Id: 96, CountryCode: "+596" }, //  Martinique
    { Id: 97, CountryCode: "+222" }, //  Mauritania
    { Id: 98, CountryCode: "+230" }, //  Mauritius
    { Id: 99, CountryCode: "+52" }, //  Mexico
    { Id: 100, CountryCode: "+691" }, //  Micronesia
    { Id: 101, CountryCode: "+373" }, //  Moldova
    { Id: 102, CountryCode: "+377" }, //  Monaco
    { Id: 103, CountryCode: "+976" }, //  Mongolia
    { Id: 104, CountryCode: "+382" }, //  Montenegro
    { Id: 105, CountryCode: "+1664" }, //  Montserrat
    { Id: 106, CountryCode: "+212" }, //  Morocco
    { Id: 107, CountryCode: "+258" }, //  Mozambique
    { Id: 108, CountryCode: "+95" }, //  Myanmar
    { Id: 109, CountryCode: "+264" }, //  Namibia
    { Id: 110, CountryCode: "+674" }, //  Nauru
    { Id: 111, CountryCode: "+977" }, //  Nepal
    { Id: 112, CountryCode: "+31" }, //  Netherlands
    { Id: 113, CountryCode: "+687" }, //  New Caledonia
    { Id: 114, CountryCode: "+64" }, //  New Zealand
    { Id: 115, CountryCode: "+505" }, //  Nicaragua
    { Id: 116, CountryCode: "+227" }, //  Niger
    { Id: 117, CountryCode: "+234" }, //  Nigeria
    { Id: 118, CountryCode: "+683" }, //  Niue
    { Id: 119, CountryCode: "+850" }, //  North Korea
    { Id: 120, CountryCode: "+1671" }, //  Guam
    { Id: 121, CountryCode: "+507" }, //  Panama
    { Id: 122, CountryCode: "+675" }, //  Papua New Guinea
    { Id: 123, CountryCode: "+595" }, //  Paraguay
    { Id: 124, CountryCode: "+51" }, //  Peru
    { Id: 125, CountryCode: "+63" }, //  Philippines
    { Id: 126, CountryCode: "+48" }, //  Poland
    { Id: 127, CountryCode: "+351" }, //  Portugal
    { Id: 128, CountryCode: "+1787" }, //  Puerto Rico
    { Id: 129, CountryCode: "+974" }, //  Qatar
    { Id: 130, CountryCode: "+262" }, //  Réunion
    { Id: 131, CountryCode: "+40" }, //  Romania
    { Id: 132, CountryCode: "+7" }, //  Russia
    { Id: 133, CountryCode: "+250" }, //  Rwanda
    { Id: 134, CountryCode: "+590" }, //  Guadeloupe
    { Id: 135, CountryCode: "+685" }, //  Samoa
    { Id: 136, CountryCode: "+378" }, //  San Marino
    { Id: 137, CountryCode: "+239" }, //  São Tomé and Príncipe
    { Id: 138, CountryCode: "+966" }, //  Saudi Arabia
    { Id: 139, CountryCode: "+221" }, //  Senegal
    { Id: 140, CountryCode: "+381" }, //  Serbia
    { Id: 141, CountryCode: "+248" }, //  Seychelles
    { Id: 142, CountryCode: "+232" }, //  Sierra Leone
    { Id: 143, CountryCode: "+65" }, //  Singapore
    { Id: 144, CountryCode: "+421" }, //  Slovakia
    { Id: 145, CountryCode: "+386" }, //  Slovenia
    { Id: 146, CountryCode: "+677" }, //  Solomon Islands
    { Id: 147, CountryCode: "+252" }, //  Somalia
    { Id: 149, CountryCode: "+34" }, //  Spain
    { Id: 150, CountryCode: "+94" }, //  Sri Lanka
    { Id: 151, CountryCode: "+249" }, //  Sudan
    { Id: 152, CountryCode: "+597" }, //  Suriname
    { Id: 153, CountryCode: "+268" }, //  Eswatini
    { Id: 154, CountryCode: "+46" }, //  Sweden
    { Id: 155, CountryCode: "+41" }, //  Switzerland
    { Id: 156, CountryCode: "+963" }, //  Syria
    { Id: 157, CountryCode: "+886" }, //  Taiwan
    { Id: 158, CountryCode: "+992" }, //  Tajikistan
    { Id: 159, CountryCode: "+255" }, //  Tanzania
    { Id: 160, CountryCode: "+66" }, //  Thailand
    { Id: 161, CountryCode: "+670" }, //  Timor, //  Leste
    { Id: 162, CountryCode: "+228" }, //  Togo
    { Id: 163, CountryCode: "+676" }, //  Tonga
    { Id: 164, CountryCode: "+1868" }, //  Trinidad and Tobago
    { Id: 165, CountryCode: "+216" }, //  Tunisia
    { Id: 166, CountryCode: "+90" }, //  Turkey
    { Id: 167, CountryCode: "+993" }, //  Turkmenistan
    { Id: 169, CountryCode: "+256" }, //  Uganda
    { Id: 170, CountryCode: "+380" }, //  Ukraine
    { Id: 171, CountryCode: "+971" }, //  United Arab Emirates
    { Id: 172, CountryCode: "+44" }, //  United Kingdom
    { Id: 173, CountryCode: "+1" }, //  United States
    { Id: 174, CountryCode: "+598" }, //  Uruguay
    { Id: 175, CountryCode: "+998" }, //  Uzbekistan
    { Id: 176, CountryCode: "+678" }, //  Vanuatu
    { Id: 177, CountryCode: "+39" }, //  Vatican City
    { Id: 178, CountryCode: "+58" }, //  Venezuela
    { Id: 179, CountryCode: "+84" }, //  Vietnam
    { Id: 180, CountryCode: "+678" }, //  Vanuatu
    { Id: 181, CountryCode: "+685" }, //  Samoa
    { Id: 182, CountryCode: "+681" }, //  Wallis and Futuna
    { Id: 183, CountryCode: "+967" }, //  Yemen
    { Id: 184, CountryCode: "+260" }, //  Zambia
    { Id: 185, CountryCode: "+263" }, //  Zimbabwe
  ];

  constructor() {
    super();
    this.makeObservable();
  }

  public initialise() {
    if (!this.hasInitialised) {
      this.hasInitialised = true;
    }
  }

  public parseContactNumber(number: string) {
    let numberSplit = number.split(' ');
    if (numberSplit.length >= 2) {
      let result = "";
      numberSplit.forEach(piece => {
        if (!piece.includes("+")) {
          result += piece;
        } else {
          let countryCode = this.countryCodes.find(c => c.CountryCode === piece)
          if (countryCode) {
            this.countryValueId = countryCode.Id;
            this.countryValue = countryCode.CountryCode;
          }
        }
      });
      return result;
    }
    return number;
  }

  public setCountryValue(memberQuery: MemberQueryLookupBase) {
    memberQuery.countryValue = this.countryValue;
    memberQuery.memberContactNumber = memberQuery.contactNumberBase;
  }

  public cleanUpContactNumber(memberQuery: MemberQueryLookupBase) {
    if (memberQuery.countryValue === "+27") {
      let cleanedNumber1 = memberQuery.memberContactNumber;
      cleanedNumber1 = cleanedNumber1.replace(/\s+/g, '');

      if (memberQuery.memberContactNumber.length >= 10 && memberQuery.memberContactNumber.charAt(0) === "0") {
        cleanedNumber1 = cleanedNumber1.slice(1);
      }
      memberQuery.memberContactNumber = cleanedNumber1;
    }
  }

  public cleanUpContactNumberForProfile(profile: TelephoneInfoLookup) {
    if (profile.countryValue === "+27") {
      let cleanedNumber1 = profile.contactNumberBase;
      cleanedNumber1 = cleanedNumber1.replace(/\s+/g, '');

      if (profile.contactNumberBase.length >= 10 && profile.contactNumberBase.charAt(0) === "0") {
        cleanedNumber1 = cleanedNumber1.slice(1);
      }
      profile.contactNumberBase = cleanedNumber1;
    }
  }
}