import { Data, Model, Validation } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../DomainTypes';
import UserTermsAndConditionsLookup from '../../../Models/TAndC/UserTAndC/UserTermsAndConditionsLookup';

export interface IUserTAndCQueryApiClient {

  /**
   * GetUserTAndC
   * @returns UserTermsAndConditionsLookup?
   */
  getUserTAndC(): AxiosPromise<Model.PlainObject<UserTermsAndConditionsLookup>>;

  // Client only properties / methods
}

@injectable()
export default class UserTAndCQueryApiClient extends Data.ApiClientBase implements IUserTAndCQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/usertandc`);
  }

  public getUserTAndC(): AxiosPromise<Model.PlainObject<UserTermsAndConditionsLookup>> {
    return this.axios.get(`${this.apiPath}/user-tandc-template`);
  }

  // Client only properties / methods
}