import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import SavingsWithdrawalClaimVM from '../../Views/SavingsWithdrawalClaimVM';
import { MemberWithdrawalClaimStep } from './MemberWithdrawalClaimStep.enum';
import img_view from '../../../MemberDashboard/Assets//img/open.png';
import img_delete from '../../../App/assets//img/close-cross.png';
import { ConfirmYesNo } from '../../Models/MemberClaims/Enums/ConfirmYesNo.enum';
import { NumberUtils } from '@singularsystems/neo-core';

interface IReviewSubmitProps {
  viewModel: SavingsWithdrawalClaimVM
}

@observer
export default class ReviewSubmit extends React.Component<IReviewSubmitProps> {

  constructor(props: IReviewSubmitProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='header-of-step'>
          Savings withdrawal claim amount
        </div>
        <div className='m-2 m-md-4'>
          <div className='review-and-submit-box'>
            <div className='review-and-submit-box-heading'>
              <span className='heading'>Savings withdrawal claim amount</span>
              <Neo.Link className='edit' onClick={() => { this.props.viewModel.withdrawalClaimStepsVM.moveToDesiredStep(MemberWithdrawalClaimStep.Selection) }}> Edit</Neo.Link>
            </div>
            <div className='review-and-submit-box-context'>
              <div className='row'>
                {(this.props.viewModel.savingWithdrawalPercentage <= 0 || this.props.viewModel.savingWithdrawalPercentage > 1 ||
                  !this.props.viewModel.savingWithdrawalAmountValidation()
                ) &&
                  <div className='col-md-12'>
                    <div className='bank-validation-error-message'>
                      Invalid withdrawal amount
                    </div>
                  </div>
                }
                <div className='col-md-6'>
                  Amount withdrawn from savings component (before tax):
                  <div className='text'>
                    Approximately {NumberUtils.format(this.props.viewModel.savingWithdrawalAmount, "R # ##0.00")}
                  </div>
                </div>
              </div>
              <div className='note'>
                The above amounts are estimates.
              </div>
            </div>
          </div>
        </div>
        <div className='m-2 m-md-4'>
          <div className='review-and-submit-box'>
            <div className='review-and-submit-box-heading'>
              <span className='heading'>Payment information</span>
              <Neo.Link className='edit' onClick={() => this.props.viewModel.withdrawalClaimStepsVM.moveToDesiredStep(MemberWithdrawalClaimStep.Selection)}> Edit</Neo.Link>
            </div>
            <div className='review-and-submit-box-context'>
              <div className='row'>
                <div className='col-md-12'>
                  {!this.props.viewModel.savingsWithdrawalClaimCapture.bankValidationsCheck() &&
                    <div className='bank-validation-error-message'>
                      Invalid banking details
                    </div>}
                  {this.props.viewModel.bankingValidationResponse.passed == "Not Verified" &&
                    <div className='bank-validation-error-message'>
                      {this.props.viewModel.bankingValidationResponse.errors.map((error, index) => (
                        <div key={index}>{error}</div>
                      ))}
                    </div>
                  }
                </div>

                {(this.props.viewModel.bankingValidationResponse.passed == "NA" || this.props.viewModel.bankingValidationResponse.passed == "" || this.props.viewModel.savingsWithdrawalClaimCapture.isSouthAfricanBankAccount == ConfirmYesNo.No) &&
                  <div className='col-md-12'>
                    <div className='bank-validation-error-message waiting'>
                      Bank details submitted for manual bank validation.
                    </div>
                  </div>
                }

                {this.props.viewModel.bankingValidationResponse.passed == "Verified and Pass" && this.props.viewModel.savingsWithdrawalClaimCapture.bankValidationsCheck() &&
                  <div className='col-md-12'>
                    <div className='bank-validation-error-message valid'>
                      Bank details successfully validated.
                      {this.props.viewModel.bankingValidationResponse.errors.map((error, index) => (
                        <div key={index}>{error}</div>
                      ))}
                    </div>
                  </div>
                }

                <div className='col-md-5'>
                  Banking details:
                  <div className='text'>
                    Account holder: {this.props.viewModel.savingsWithdrawalClaimCapture.accountHolderName} <br />
                    Bank: {this.props.viewModel.savingsWithdrawalClaimCapture.nameOfBank} <br />
                    Account number: {this.props.viewModel.savingsWithdrawalClaimCapture.accountNumber} <br />
                    Branch code: {this.props.viewModel.savingsWithdrawalClaimCapture.branchCode} <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='m-2 m-md-4'>
          <div className='review-and-submit-box'>
            <div className='review-and-submit-box-heading'>
              <span className='heading'>Deductions</span>
              <Neo.Link className='edit' onClick={() => this.props.viewModel.withdrawalClaimStepsVM.moveToDesiredStep(MemberWithdrawalClaimStep.Deductions)}> Edit</Neo.Link>
            </div>
            <div className='review-and-submit-box-context'>
              <div className='row'>
                {!this.props.viewModel.savingsWithdrawalClaimCapture.deductionsValidationsCheck() &&
                  <div className='col-md-12'>
                    <div className='bank-validation-error-message'>
                      Outstanding deduction information
                    </div>
                  </div>
                }
                <div className='col-md-6'>
                  Are there any divorce orders against the fund?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderAgainstFund == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderAgainstFund == ConfirmYesNo.Yes &&
                    <div className='text mb-4'>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderAgainstFund == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
                <div className='col-md-6'>
                  Is the divorce order finalised?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderFinalised == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderFinalised == ConfirmYesNo.Yes &&
                    <div className='text mb-4 '>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.divorceOrderFinalised == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  Are there any maintenance orders against the fund?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund == ConfirmYesNo.Yes &&
                    <div className='text mb-4'>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderAgainstFund == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
                <div className='col-md-6'>
                  Is the maintenance order finalised?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderFinalised == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderFinalised == ConfirmYesNo.Yes &&
                    <div className='text mb-4'>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.maintenanceOrderFinalised == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  Does the member have any debt owed to the employer for a housing loan?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan == ConfirmYesNo.Yes &&
                    <div className='text mb-4'>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForHousingLoan == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
                <div className='col-md-6'>
                  Is there any debt owed to the employer arising from theft, dishonesty, fraud or misconduct?
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForDeception == ConfirmYesNo.No &&
                    <div className='text mb-4'>
                      No
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForDeception == ConfirmYesNo.Yes &&
                    <div className='text mb-4'>
                      Yes
                    </div>
                  }
                  {this.props.viewModel.savingsWithdrawalClaimCapture.debtToEmployerForDeception == ConfirmYesNo.NotSelected &&
                    <div className='text mb-4'>
                      -
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='m-2 m-md-4'>
          <div className='review-and-submit-box'>
            <div className='review-and-submit-box-heading'>
              <span className='heading'>Savings withdrawal fee</span>
            </div>
            <div className='review-and-submit-box-context'>
              <div className='row'>
                <div className='col-md-12'>
                  Fee amount:
                  <div className='text'>
                    {NumberUtils.format(this.props.viewModel.feeAmount, "R # ##0.00")}
                  </div>
                  <div className='note'>
                    Will not be charged against your savings withdrawal amount. This fee will be deducted proportionally from the remaining balances across your components.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='m-2 m-md-4'>
          <div className='review-and-submit-box'>
            <div className='review-and-submit-box-heading'>
              <span className='heading'>Documents</span>
              <Neo.Link className='edit' onClick={() => this.props.viewModel.withdrawalClaimStepsVM.moveToDesiredStep(MemberWithdrawalClaimStep.Documentation)}> Edit</Neo.Link>
            </div>
            <div className='review-and-submit-box-context'>
              <div className='row'>
                {(!this.props.viewModel.fileValidationCheck()) &&
                  <div className='col-md-12'>
                    <div className='bank-validation-error-message'>
                      Outstanding documents
                    </div>
                  </div>
                }
                <div className='col-md-6'>
                  Uploaded documents
                </div>
                <div className='col-12 remove-padding-to-left'>
                  {(this.props.viewModel.savingsWithdrawalClaimCapture.files && this.props.viewModel.savingsWithdrawalClaimCapture.files.length > 0) &&
                    <div>
                      {this.props.viewModel.savingsWithdrawalClaimCapture.files.map((item) => {
                        return <div className='row file-upload-documents'>
                          <div className='col-md-12 col-12'>
                            <div className='add-margin-to-right'>
                              <div className='uploaded-document-info-block'>
                                <div className='uploaded-document-info-text'>
                                  <span className='file-name' title={item.fileName}>
                                    {item.fileName}
                                  </span>
                                  <span className='image-container'>
                                    <img className='uploaded-document-info-image' src={img_view}
                                      onClick={() => this.props.viewModel.downloadSavingsClaimSecureDocument(item.fileDescriptorId, item.fileName)} />
                                    <img className='uploaded-document-info-image delete' src={img_delete}
                                      onClick={() => this.props.viewModel.deleteSavingsClaimFile(item.fileName)} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}