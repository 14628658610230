import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import EducationalInformationTemplate from '../../Models/EducationalInformation/EducationalInformationTemplate';

export interface IEducationalInformationCommandApiClient {

  /** 
   * Updates a EducationalInformation template.
   * @param educationalinformationTemplate The EducationalInformation template to update.
   * @returns The updated template.
   */
  updateEducationalInformationTemplate(educationalinformationTemplate: Model.PartialPlainObject<EducationalInformationTemplate>): AxiosPromise<Model.PlainTrackedObject<EducationalInformationTemplate>>;

  // Client only properties / methods
}

@injectable()
export default class EducationalInformationCommandApiClient extends Data.ApiClientBase implements IEducationalInformationCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/educational-information`);
  }

  public updateEducationalInformationTemplate(educationalinformationTemplate: Model.PartialPlainObject<EducationalInformationTemplate>): AxiosPromise<Model.PlainTrackedObject<EducationalInformationTemplate>> {
    return this.axios.put(`${this.apiPath}/educational-information-template`, educationalinformationTemplate);
  }

  // Client only properties / methods
}