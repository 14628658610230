import { Attributes, EnumHelper, Data, List, Model, ModelBase, NeoModel, NotifyUtils } from '@singularsystems/neo-core';
import { PageResult } from '@singularsystems/neo-core/dist/Data/Paging/PageResult';
import { Views } from '@singularsystems/neo-react';
import ReportCriteria from '../../Domain/Models/Reports/ReportCriteria';
import { DocumentType } from '../../Enums/DocumentType.enum';
import { ReportType } from '../../Enums/ReportType.enum';
import StaffSearchCriteria from '../../StaffDashboard/Models/Lookups/StaffSearchCriteria';
import { NotificationDuration } from '../Models/NotificationDuration';
import { SchemeProductClassType } from '../../Party/Models/LookUps/SchemeProductClassType';
import { AppService, Types } from '../Services/AppService';

@NeoModel
class GridItem extends ModelBase {
  report = ""
  dateValue = ""
  schemeName = ""
}

export class GridDocumentItem extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public document: string = ""

  @Attributes.Date()
  public effectiveDate: Date = new Date();
}

@NeoModel
export default class ReportsAndDocumentsVM extends Views.ViewModelBase {
  public reportType: ReportType = ReportType.MemberInvestmentStatement;
  public documentType = DocumentType.IRP5;
  public schemeId: number | null = null;
  public isGenerateDisabled: boolean = true;
  public fromDate: Date | null = null;
  public toDate: Date | null = null;
  public atDate: Date | null = null;
  public emptyGridCssClass: string = 'empty-grid-container';
  public mobileGridCssClass: string = 'hide-grid';
  public mobileDocumentsGridCssClass: string = 'hide-grid';
  public hideReportGrid: string = 'hide-grid';
  public reportList = new List(GridItem);
  public documentList = new List(GridDocumentItem);
  public showIncompatibleDatesModal: boolean = false;
  public displayDocuments: boolean = false;
  public showBlue: boolean = false;

  //Must come from report in list when historical reports are implemented
  public schemeName: string | null = null;
  public reports = EnumHelper.asList(ReportType);

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private reportsApiClient = AppService.get(Types.Domain.ApiClients.ReportsApiClient),
    public userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService)) {

    super(taskRunner);
  }

  public async initialise() {
    await this.taskRunner.waitFor(this.userMemberSchemeService.getSchemeDetails());
    this.schemeName = this.userMemberSchemeService.currentSchemeSelected.schemeName;
    await this.updateReportList(this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0);
    this.showDocumentGrid();
  }

  public selectReport(reportType: number) {
    if (this.userMemberSchemeService.currentSchemeSelected) {
      this.updateReportList(this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0);
    }
    this.atDate = null; this.toDate = null; this.fromDate = null;
    this.reportType = reportType as ReportType;
    this.toggleReportGrid();
  }

  public async selectDocument(documentSelectedType: number) {
    this.atDate = null;
    this.documentType = documentSelectedType as DocumentType;
    this.documentList.forEach(documentItem => {
      documentItem.document = (this.documentType == 1) ? "IRP5" : "IRP5";
    });
  }

  public showDocumentGrid() {
    this.documentList.set([
      {
        document: (this.documentType === DocumentType.IRP5) ? "IRP5" : "IRP5",
      }
    ]);

    this.mobileDocumentsGridCssClass = 'show-grid-mobile documents';
  }

  public tempGetDocumentListApi() {
    let data = new PageResult<Model.PlainObject<GridDocumentItem>>;
    data.entityList = [
      {
        document: "IRP5",
        effectiveDate: new Date()
      }
    ];
    data.total = data.entityList.length;
    return Promise.resolve(data)
  }

  // This criteria is being reused until we have the real data to create the correct models
  private criteria = new StaffSearchCriteria();
  public documentPageManager = new Data.PageManager(this.criteria, GridDocumentItem,
    this.tempGetDocumentListApi,
    {
      pageSize: 5,
      pageSizeOptions: [],
      sortBy: "document",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public async selectSchemeId(schemeId: number) {
    if (schemeId === 0) { return; }
    this.userMemberSchemeService.setCurrentSchemeMember(schemeId);
  }

  public async updateReportList(schemeId: number) {
    if (this.userMemberSchemeService.currentSchemeSelected) {
      if (!this.userMemberSchemeService.currentSchemeSelected.isPreservationScheme) {
        if (this.userMemberSchemeService.currentSchemeSelected.schemeProductClassType == SchemeProductClassType.InvestmentOnly) {
          this.reports = EnumHelper.asList(ReportType).filter(report => report.name == EnumHelper.getItemMetadata(ReportType, ReportType.MemberInvestmentStatement).display)
          this.reportType = ReportType.MemberInvestmentStatement;
        }
        else if (this.userMemberSchemeService.currentSchemeSelected.schemeProductClassType == SchemeProductClassType.RiskOnly) {
          this.reports = EnumHelper.asList(ReportType).filter(report => report.name == EnumHelper.getItemMetadata(ReportType, ReportType.MemberBenefitStatement).display);
          this.reportType = ReportType.MemberBenefitStatement;
        }
        else if (this.userMemberSchemeService.currentSchemeSelected.schemeProductClassType == SchemeProductClassType.RiskAndInvestment) {
          this.reports = EnumHelper.asList(ReportType).filter(report => report.name == EnumHelper.getItemMetadata(ReportType, ReportType.MemberInvestmentStatement).display ||
            report.name == EnumHelper.getItemMetadata(ReportType, ReportType.MemberBenefitStatement).display)
          this.reportType = ReportType.MemberInvestmentStatement;
        }
      }
      else {
        this.reports = EnumHelper.asList(ReportType).filter(report => report.name == EnumHelper.getItemMetadata(ReportType, ReportType.PaidupMemberInvestmentStatement).display ||
          report.name == EnumHelper.getItemMetadata(ReportType, ReportType.PaidupMemberCertificate).display);
        this.reportType = ReportType.PaidupMemberInvestmentStatement;
      }
    }
  }

  public toggleReportGrid() {
    this.hideReportGrid == 'report-grid' ? this.hideReportGrid = "hide-grid" : "report-grid"
    this.emptyGridCssClass = 'empty-grid-container'
  }

  public async showReports(e: React.MouseEvent) {
    this.displayDocuments = false;
    this.showBlue = false;
  }

  public async showDocuments(e: React.MouseEvent) {
    this.displayDocuments = true;
    this.showBlue = true;
  }

  public getReport() {
    if (this.reportType == ReportType.MemberBenefitStatement && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCU") {
      this.getMemberBenefitStatementForLCU();
    } else if (this.reportType == ReportType.MemberInvestmentStatement && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCU") {
      this.getMemberInvestmentStatementForLCU();
    } else if (this.reportType == ReportType.MemberBenefitStatement && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCB") {
      this.getMemberBenefitStatementForLCB();
    } else if (this.reportType == ReportType.MemberInvestmentStatement && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCB") {
      this.getMemberInvestmentSummaryForLCB();
    }
    else if (this.reportType == ReportType.PaidupMemberCertificate && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCU") {
      this.getIfpMemberCertificate();
    }
    else if (this.reportType == ReportType.PaidupMemberInvestmentStatement && this.userMemberSchemeService.currentSchemeSelected.sourceId == "LCU") {
      this.getIfpMemberInvestmentSummary();
    }
  }

  public getMemberBenefitStatementForLCB() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.effectiveDate = this.formatDate(this.atDate!);
    criteria.reportType = ReportType.MemberBenefitStatement;

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateLCBMbsReport(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        window.open(response.data.viewerUrl, '_blank');
      }
      else {
        NotifyUtils.addOrUpdate("lcbMbsReportGenerationError", "Member Benefit Statement Report", "There is a problem generating the Member Benefit Statement.", "warning", NotificationDuration.Long);
      }
    });
  }

  public getMemberInvestmentStatementForLCU() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.startDate = this.formatDate(this.fromDate!);
    criteria.endDate = this.formatDate(this.toDate!);
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.reportType = ReportType.MemberInvestmentStatement;

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateLCUMisReport(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        criteria.documentId = response.data["member Investment Summary"];
        await this.downloadDocument(criteria);
      }
      else {
        NotifyUtils.addOrUpdate("lcuMisReportGenerationError", "Member Investment Summary Report", "There is a problem generating the Member Investment Summary.", "warning", NotificationDuration.Long);
      }
    });
  }

  public getMemberBenefitStatementForLCU() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.effectiveDate = this.formatDate(this.atDate!);
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.reportType = ReportType.MemberBenefitStatement;

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateLCUMbsReport(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        criteria.documentId = response.data["member Benefit Statement 2"];
        await this.downloadDocument(criteria);
      }
      else {
        NotifyUtils.addOrUpdate("lcuMbsReportGenerationError", "Member Benefit Statement Report", "There is a problem generating the Member Benefit Statement.", "warning", NotificationDuration.Long);
      }
    });
  }

  public getMemberInvestmentSummaryForLCB() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.schemeId = this.userMemberSchemeService.currentSchemeSelected.schemeId ?? 0;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.startDate = this.formatDate(this.fromDate!);
    criteria.endDate = this.formatDate(this.toDate!);
    criteria.reportType = ReportType.MemberInvestmentStatement

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateLCBMisReport(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        criteria.documentId = response.data["member Investment Summary"];
        await this.downloadDocument(criteria);
      } else {
        NotifyUtils.addOrUpdate("lcbMisReportGenerationError", "Member Investment Summary Report", "There is a problem generating the Member Investment Summary.", "warning", NotificationDuration.Long);
      }
    });
  }

  public getIfpMemberInvestmentSummary() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.effectiveDate = this.formatDate(this.atDate!);
    criteria.reportType = ReportType.PaidupMemberInvestmentStatement;

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateIfpMemberInvestmentSummary(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        criteria.documentId = response.data["in-Fund Preservation - MIS"];
        await this.downloadDocument(criteria);
      } else {
        NotifyUtils.addOrUpdate("ifpMisReportGenerationError", "Paid-up Member Investment Statement", "There is a problem generating the Preservation Member Investment Statement.", "warning", NotificationDuration.Long);
      }
    });
  }

  public getIfpMemberCertificate() {
    let criteria = new ReportCriteria();
    criteria.memberId = this.userMemberSchemeService.currentSchemeSelected.memberId ?? 0;
    criteria.sourceId = this.userMemberSchemeService.currentSchemeSelected.sourceId;
    criteria.schemeNo = this.userMemberSchemeService.currentSchemeSelected.schemeNumber ?? "";
    criteria.memberNo = this.userMemberSchemeService.currentSchemeSelected.memberNumber ?? "";
    criteria.effectiveDate = this.formatDate(this.atDate!);
    criteria.reportType = ReportType.PaidupMemberCertificate;

    this.taskRunner.run(async () => {
      const response = await this.reportsApiClient.generateIfpMemberCertificate(criteria.toJSObject());
      if (response !== null && response.data !== null && response.status === 200) {
        criteria.documentId = response.data["ifpCert"];
        await this.downloadDocument(criteria);
      } else {
        NotifyUtils.addOrUpdate("ifpMemberCertificateReportGenerationError", "Paid-up Member Certificate", "There is a problem generating the Preservation Member Certificate.", "warning", NotificationDuration.Long);
      }
    });
  }

  private padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date) {
    return (
      [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    );
  }

  private async downloadDocument(criteria: ReportCriteria) {
    const pdfDocumentStream = await this.reportsApiClient.getMisReportDocument(criteria.toJSObject());
    const blob = new Blob([pdfDocumentStream.data], { type: 'application/pdf' });
    const downloadUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = downloadUrl;
    criteria.reportType == ReportType.MemberBenefitStatement ? anchor.download = "MemberBenefitStatement.pdf" :
      criteria.reportType == ReportType.MemberInvestmentStatement ? anchor.download = "MemberInvestmentSummary.pdf" :
        criteria.reportType == ReportType.PaidupMemberInvestmentStatement ? anchor.download = "PaidupMemberInvestmentStatement.pdf" :
          criteria.reportType == ReportType.PaidupMemberCertificate ? anchor.download = "PaidupMemberCertificate.pdf" : "";
    anchor.click();
    URL.revokeObjectURL(downloadUrl);
  }
}