import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../App/Services/AppService';
import SchemeStatusGroup from '../../MasterData/Models/SchemeStatusGroup';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import FADashboardSearchResultsLookup from '../Models/Lookups/FADashboardSearchResultsLookup';
import FASearchCriteria from '../Models/Lookups/FASearchCriteria';

@NeoModel
export default class FADashboardVM extends Views.ViewModelBase {
  public searchResults = new List(FADashboardSearchResultsLookup);
  public searchByScheme: string = "";
  public schemeId: number | null = null;
  public searchId: number | null = null;
  public schemeStatusId: number | null = null;
  public emptyGridCssClass: string = 'empty-grid-container';
  public hideReportGridCssClass: string = 'hide-grid';
  public showCardCssClass: string = 'hide-grid';
  public schemeStatuses: SchemeStatusGroup[] = [];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private faDashboardApiClient = AppService.get(Types.FADashboard.ApiClients.FADashboardApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService)) {

    super(taskRunner);
  }

  private criteria = new FASearchCriteria();
  public pageManager = new Data.PageManager(this.criteria, FADashboardSearchResultsLookup,
    this.faDashboardApiClient.getFaDashboardSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "idNumber",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public async initialise() {
    this.schemeStatuses = this.masterDataService.schemeStatusGroups;
  }

  changeToBenefeciariesView(item: FADashboardSearchResultsLookup): void {
    this.navigation.navigateToView(BeneficiariesView, { memberName: item.firstName + " " + item.lastName })
  }
}