import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../App/Services/AppService';

export interface IDocumentsApiClient {

  /**
   * Gets the Url for uploading a secure document
   * Note: This method returns the url of the api endpoint. It performs no logic.
   * @returns A file upload result with a file descriptor
   */
  getUploadSecureDocumentUrl(): string;

  /**
   * Gets the stream of a secure file
   * @returns A  representing the result of the asynchronous operation.
   */
  getSecureDocument(): AxiosPromise<Blob>;

  /** 
   * Gets the Url for uploading a query document
   * Note: This method returns the url of the api endpoint. It performs no logic.
   * @returns A file upload result with a file descriptor
   */
  getUploadQueryDocumentUrl(): string;

  /** 
   * Gets the stream of a query file
   * @returns A  representing the result of the asynchronous operation.
   */
  getQueryDocument(): AxiosPromise<Blob>;

  /** 
   * Gets an expiring Url to download a document from blob storage
   * @returns The file document download
   */
  getQueryDocumentDownloadUrl(): AxiosPromise<string>;

  // Client only properties / methods
}

@injectable()
export default class DocumentsApiClient extends Data.ApiClientBase implements IDocumentsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/Documents`);
  }

  public getUploadSecureDocumentUrl(): string {
    return `${this.apiPath}/upload-secure-document`;
  }

  public getSecureDocument(): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/secure-document`, { responseType: "blob" });
  }

  public getUploadQueryDocumentUrl(): string {
    return `${this.apiPath}/upload-query-document`;
  }

  public getQueryDocument(): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/query-document`, { responseType: "blob" });
  }

  public getQueryDocumentDownloadUrl(): AxiosPromise<string> {
    return this.axios.get(`${this.apiPath}/query-document-url`);
  }

  // Client only properties / methods
}