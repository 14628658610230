import React from 'react';
import { observer } from 'mobx-react';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';

interface IRiskBenefitTableProps {
  benefitGroup: BenefitGroup,
  disclaimer: string,
  tableText: string
}

@observer
export default class RiskBenefitTable extends React.Component<IRiskBenefitTableProps> {

  constructor(props: IRiskBenefitTableProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='table-title-risk'>
          <span className='table-title-risk bold'>
            {this.props.benefitGroup.benefitType}
          </span>
          <span> ({this.props.disclaimer})</span>
        </div>
        <table className='table-width-risk'>
          <tr className='table-border-risk'>
            <th className='table-border-risk table-blank-right'></th>
            <th className='table-border-risk'>Benefit amount</th>
          </tr>
          <tr className='table-border-risk'>
            <td className='table-border-risk table-row-header-risk'>
              {this.props.tableText}
            </td>
            <td className='table-border-risk table-column-content-risk'><span className=''>
              {this.props.benefitGroup.benefitTotal?.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}
            </span></td>
          </tr>
        </table>
      </div>
    );
  }
}