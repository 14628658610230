import { Views } from '@singularsystems/neo-react';
import ContactUsVM from './ContactUsVM';
import { observer } from 'mobx-react';
import "../../App/Styles/Components/ContactUs.scss";
import { Neo } from '@singularsystems/neo-react';
import ComplainComponent from './Components/ComplainComponent';
import SchemeInformationHeader from './Components/SchemeInformationHeader';
import ContactInformationCard from './Components/ContactInformationCard';
import MemberIcon from './Components/MemberIcon';
import ContainerFooterComponent from './Components/ContainerFooterComponent';
import circle from '../../App/assets/img/circle.png';
import InformationCard from './Components/InformationCard';

@observer
export default class ContactUsView extends Views.ViewBase<ContactUsVM> {

  constructor(props: unknown) {
    super("Contact us", ContactUsVM, props);
  }

  public render() {
    return (
      <div className='contact-us-outside-margins'>
        <SchemeInformationHeader schemeHeader='Select a scheme for more information' memberName={'Swathi'} showClaimButton={false} extraCssClasses=""
          onSchemeSelected={async (scheme) => {
            await this.viewModel.setSchemeInfo(scheme);
            await this.viewModel.getSchemeContactDetails();
          }} />
        <div className='contact-us'>
          <div className='background-card'>
            <div className='heading-cu'>Reach out to us</div>
            <Neo.Card title="Liberty" isExpanded={this.viewModel.isLibertyExpanded} className='cards-cu cards-headings-cu'>
              <ComplainComponent></ComplainComponent>
            </Neo.Card>
            <Neo.Card title="Financial advisers" isExpanded={this.viewModel.isParentExpanded} className='cards-cu cards-headings-cu'>
              {this.viewModel.memberFAContactDetails.length === 0 &&
                <div className='no-data-block row'>
                  <img className='info-circle' src={circle}></img>
                  <div>No data found</div>
                </div>
              }
              <div className='row center-cards-mobile'>
                {this.viewModel.memberFAContactDetails.length > 0 && this.viewModel.memberFAContactDetails.map((item) => (
                  <div className='col-md-3 negate-col-information-card-margin'>
                    <InformationCard
                      firstName={item.firstName}
                      lastName={item.lastName}
                      headingText={'Fund financial adviser for ' + this.viewModel.userMemberSchemeService.currentSchemeSelected.schemeName ?? ""}
                      available={item.firstName + ' ' + item.lastName}
                      telHeading={item.telePhoneNo == '' ? '' : 'Tel. no.'}
                      tel={' ' + item.telePhoneNo}
                      additionalTelHeading={''}
                      additionalTel={''}
                      faxHeading={item.mobileNo == '' ? '' : 'Cellphone'}
                      fax={' ' + item.mobileNo}
                      emailHeading={item.emailAddress == '' ? '' : 'Email address'}
                      email={' ' + item.emailAddress}
                      additionalEmailHeading={''}
                      additionalEmail={' '}
                      postalAddressHeading={''}
                      postalAddressName={' '}
                      postalAddressPOBox={' '}
                      postalAddressCity={' '}
                      postalAddressCityCode={' '} />
                  </div>
                ))}
              </div>
            </Neo.Card >
            <Neo.Card title="Employer details" isExpanded={this.viewModel.isEmployerExpanded} className='cards-cu cards-headings-cu'>
              {this.viewModel.memberEmployerContactDetails.length === 0 &&
                <div className='no-data-block row'>
                  <img className='info-circle' src={circle}></img>
                  <div>No data found</div>
                </div>
              }
              <div className='row center-cards-mobile'>
                {this.viewModel.memberEmployerContactDetails.length > 0 && this.viewModel.memberEmployerContactDetails.map((item) => (
                  <div className='col-md-3 negate-col-information-card-margin'>
                    <InformationCard
                      firstName={item.firstName}
                      lastName={item.lastName}
                      headingText={item.contactRoleType}
                      available={item.firstName + ' ' + item.lastName}
                      telHeading={item.telePhoneNo == '' ? '' : 'Tel. no.'}
                      tel={' ' + item.telePhoneNo}
                      additionalTelHeading={''}
                      additionalTel={''}
                      faxHeading={item.mobileNo == '' ? '' : 'Cellphone'}
                      fax={' ' + item.mobileNo}
                      emailHeading={item.emailAddress == '' ? '' : 'Email address'}
                      email={' ' + item.emailAddress}
                      additionalEmailHeading={''}
                      additionalEmail={' '}
                      postalAddressHeading={''}
                      postalAddressName={' '}
                      postalAddressPOBox={' '}
                      postalAddressCity={' '}
                      postalAddressCityCode={' '} />
                  </div>
                ))}
              </div>
            </Neo.Card>
            <ContainerFooterComponent />
          </div>
        </div>
      </div>
    );
  }
}