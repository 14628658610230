import { Attributes, Data, EnumHelper, List, Model, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../App/Services/AppService';
import EmployerDashboardSearchResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerDashboardSearchResultsLookup';
import EmployerSearchCriteria from '../Models/Lookups/EmployerDashboard/EmployerSearchCriteria';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import { SchemeStatus } from '../Models/Enums/Employer/SchemeStatus.enum';
import { MemberStatus } from '../Models/Enums/Employer/MemberStatus.enum';
import EmployerDashboardSchemeSearchResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerDashboardSchemeSearchResultsLookup';
import EmployerDashboardSchemeSearchCriteria from '../Models/Lookups/EmployerDashboard/EmployerDashboardSchemeSearchCriteria';
import EmployerMemberProfileView from './EmployerMemberProfileView';

@NeoModel
class radioButtonsOptions extends ModelBase {
  public name: string = "";
  public value: string | number = 0;
  public isSelected: boolean = false;
}

@NeoModel
export default class EmployerDashboardVM extends Views.ViewModelBase {

  public searchResults = new List(EmployerDashboardSearchResultsLookup);
  emptyGridCssClass: string = 'empty-grid-container';
  showgrid: boolean = false;
  showSchemeGrid: boolean = false;
  showCardCss: boolean = false;
  showSchemeCard: boolean = false;
  public memberStatuses = EnumHelper.asList(MemberStatus);
  public schemeStatuses = EnumHelper.asList(SchemeStatus);
  public memberStatus: MemberStatus | null = null;
  public schemeStatus: SchemeStatus | null = null;
  public schemeStatusList = new List(radioButtonsOptions);
  public schemeTabStatusList = new List(radioButtonsOptions);
  public selectedTab: string = "Members";

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private employerDashboardApiClient = AppService.get(Types.Dashboard.ApiClients.EmployerDashboardApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService)) {

    super(taskRunner);
  }

  public criteria = new EmployerSearchCriteria();
  public memberPageManager = new Data.PageManager(this.criteria, EmployerDashboardSearchResultsLookup,
    this.employerDashboardApiClient.getEmployerDashboardSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "firstName",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public schemeCriteria = new EmployerDashboardSchemeSearchCriteria();
  public schemePageManager = new Data.PageManager(this.schemeCriteria, EmployerDashboardSchemeSearchResultsLookup,
    this.employerDashboardApiClient.getEmployerDashboardSchemeSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "name",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public async initialise() {
    this.schemeStatuses.forEach(stat => {
      const newItem = new radioButtonsOptions();
      newItem.isSelected = false;
      newItem.name = stat.name!;
      newItem.value = stat.id;
      this.schemeStatusList.push(newItem);

      const newSchemeItem = new radioButtonsOptions();
      newSchemeItem.isSelected = false;
      newSchemeItem.name = stat.name!;
      newSchemeItem.value = stat.id;
      this.schemeTabStatusList.push(newSchemeItem);
    });
  }

  changeToBenefeciariesView(item: EmployerDashboardSearchResultsLookup): void {
    this.userRoleService.viewOnlyBeneficiary = false;
    this.navigation.navigateToView(BeneficiariesView, { memberName: item.firstName + " " + item.lastName })
  }

  public get totalMemberEnumValue() {
    let enumValue: number | null = 0;

    this.schemeStatusList.filter(elem => { return elem.isSelected }).forEach(element => {
      enumValue! += (element.value as number);
    })

    if (enumValue == 0) {
      enumValue = null;
    }

    return enumValue;
  }

  public get totalSchemeEnumValue() {
    let enumValue: number | null = 0;

    this.schemeTabStatusList.filter(elem => { return elem.isSelected }).forEach(element => {
      enumValue! += (element.value as number);
    })

    if (enumValue == 0) {
      enumValue = null;
    }

    return enumValue;
  }

  public showChosenFilters() {
    this.showCardCss = false;
    this.criteria.schemeStatuses = this.totalMemberEnumValue;
    this.updateDocumentSetGrid();
  }

  public showChosenSchemeFilters() {
    this.showSchemeCard = false;
    this.schemeCriteria.schemeStatuses = this.totalSchemeEnumValue;
    this.searchSchemeData();
  }

  public removeMemberFilter() {
    this.criteria.memberStatuses = null;
    this.memberStatus = null;
    this.memberPageManager.refreshData();
  }

  public removeSchemeFilter(element: radioButtonsOptions) {
    element.isSelected = false;
    this.criteria.schemeStatuses = this.totalMemberEnumValue;
    this.memberPageManager.refreshData();
  }

  public removeTabSchemeFilter(element: radioButtonsOptions) {
    element.isSelected = false;
    this.schemeCriteria.schemeStatuses = this.totalSchemeEnumValue;
    this.schemePageManager.refreshData();
  }

  public removeAllFilters() {
    this.removeMemberFilter()
    this.schemeStatusList.forEach(element => {
      element.isSelected = false;
    })
    this.criteria.schemeStatuses = this.totalMemberEnumValue;
    this.memberPageManager.refreshData();
  }

  public removeAllSchemeTabFilters() {
    this.schemeTabStatusList.forEach(element => {
      element.isSelected = false;
    })
    this.schemeCriteria.schemeStatuses = this.totalSchemeEnumValue;
    this.schemePageManager.refreshData();
  }

  public async updateDocumentSetGrid() {
    if (this.memberStatus === null && this.criteria.schemeStatuses === null && this.criteria.keyword === null) {
      this.showgrid = true
    } else {
      this.criteria.memberStatuses = this.memberStatus;
      this.memberPageManager.refreshData();
      this.showgrid = true;
    }
  }

  public async allSchemeMembers(item: EmployerDashboardSchemeSearchResultsLookup) {
    this.criteria.keyword = item.name;
    this.removeAllFilters();
    this.showgrid = true;
    this.selectedTab = "Members";
  }

  public async searchSchemeData() {
    if (this.schemeCriteria.schemeStatuses === null && this.schemeCriteria.keyword === null) {
      this.showSchemeGrid = true
    } else {
      this.schemePageManager.refreshData();
      this.showSchemeGrid = true;
    }
  }

  public changeToProfileView(): void {
    this.userRoleService.viewOnlyBeneficiary = true;
    this.navigation.navigateToView(EmployerMemberProfileView);
  }
}