import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberDetailsLookup from '../../Models/LookUps/MemberDetails/MemberDetailsLookup';

export interface IMemberDetailsApiClient {

  /** 
   * Retrieves memberDetails Lookup.
   * @param memberId Id
   * @param sourceId sourceId
   * @returns MemberBillingLookup.
   */
  getMemberDetailsById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberDetailsLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberDetailsApiClient extends Data.ApiClientBase implements IMemberDetailsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-details`);
  }

  public getMemberDetailsById(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberDetailsLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}