import { Attributes, EnumHelper, List, ModelBase, Validation } from '@singularsystems/neo-core';
import { ClaimTypeCategory } from './Enums/ClaimTypeCategory.enum';
import ClaimTypeSubCategoryGroupTemplateLookup from './ClaimTypeSubCategoryGroupTemplateLookup';

export default class ClaimTypeCategoryGroupTemplateLookup extends ModelBase {
    static typeName = "ClaimTypeCategoryGroupTemplateLookup";

    constructor() {
        super();
        this.makeObservable();
    }

    public claimTypeCategory: ClaimTypeCategory = ClaimTypeCategory.None;

    public claimTypeSubCategoryGroups = new List(ClaimTypeSubCategoryGroupTemplateLookup);

    // Client only properties / methods
 
    @Attributes.NoTracking()
    public isExpanded : boolean = false;

    @Attributes.Display("Claim Type Category")
    public get claimTypeCategoryDisplay() {
        return EnumHelper.getItemMetadata(ClaimTypeCategory, this.claimTypeCategory).display;    
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New claim type category group template lookup";
        } else {
            return "Claim Type Category Group Template Lookup";
        }
    }
}