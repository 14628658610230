import React from 'react';
import { Data, Misc, Utils } from "@singularsystems/neo-core";
import { observer } from "mobx-react";
import PageSizeControl from '@singularsystems/neo-react/dist/ReactComponents/Paging/PageSizeControl';
import '../Styles/LibertyBasicPagerControls.scss';
import img_info from '../Assets/Img/left.png'
import chevron_left from '../Assets/Img/chevron_left.png'

export interface IPagerControlsProps extends React.HTMLProps<HTMLDivElement> {
  /** Page manager to control. */
  pageManager: Data.IPageManager;

  /** No of buttons to show */
  noOfButtons?: number;

  /** Alignment of buttons. End (right) by default. */
  alignment?: "start" | "center" | "end" | "";

  /** Text of the first button. */
  firstText?: string;

  /** Text of the first button. */
  totalRecords?: number;

  /** Text of the prev button. */
  prevText?: string;

  /** Text of the next button. */
  nextText?: string;

  /** Maximum pages to show */
  maxPagesToShow: number;

  /** Text of the last button. */
  lastText?: string;

  /** Hides the pager if there is only one page. True by default. */
  autoHide?: boolean;

  /** Causes the page size control to show, with this label. */
  pageSizeLabel?: string | JSX.Element;
}

@observer
export default class LibertySimplePagerControls extends React.Component<IPagerControlsProps> {

  /** If a string starts with fa- or far-, this will return an 'i' tag with the font-awesome class name. Otherwise it will return the passed in string. */
  private static textOrIcon(textOrIcon: string) {
    if (textOrIcon.indexOf("fa-") === 0 || textOrIcon.indexOf("far-") === 0) {
      return <i className={Utils.getFontAwesomeClass(textOrIcon)}></i>
    } else {
      return textOrIcon;
    }
  }

  public render() {
    let { pageManager, noOfButtons, alignment, firstText, prevText, maxPagesToShow, nextText, lastText, totalRecords, autoHide, pageSizeLabel, ...domProps } = this.props;
    let halfPagesToShow = Math.floor(maxPagesToShow / 2);
    noOfButtons = noOfButtons || 7;
    alignment = alignment || "end";
    alignment = alignment === "start" ? "" : alignment;
    firstText = (Misc.Settings.pager.firstText !== firstText ? firstText : Misc.Settings.pager.firstText);
    prevText = (Misc.Settings.pager.prevText !== prevText ? prevText : Misc.Settings.pager.prevText);
    nextText = (Misc.Settings.pager.nextText !== nextText ? nextText : Misc.Settings.pager.nextText);
    lastText = (Misc.Settings.pager.lastText !== lastText ? lastText : Misc.Settings.pager.lastText);
    totalRecords = totalRecords;

    const className = Utils.joinWithSpaces(domProps.className, "neo-pager-controls", pageSizeLabel ? "justify-content-between" : (alignment && "justify-content-" + alignment));
    let firstButton = pageManager.pageNo - halfPagesToShow;
    let lastButton = pageManager.pageNo + halfPagesToShow;

    // Adjust start and end page numbers if they go out of bounds
    if (firstButton < 1) {
      firstButton = 1;
      lastButton = Math.min(pageManager.totalPages, maxPagesToShow);
    }
    else if (lastButton > pageManager.totalPages) {
      lastButton = pageManager.totalPages;
      firstButton = Math.max(1, pageManager.totalPages - maxPagesToShow + 1);
    }
    const buttons = [];
    for (let i = firstButton; i <= lastButton; i++) {
      buttons.push
        (
          <div key={i} className={"page-item " + (i === this.props.pageManager.pageNo ? " active" : "")}>
            <a className="page-link page-number" href="/#" onClick={(e) => { e.preventDefault(); this.props.pageManager.pageNo = i }}>{i}</a>
          </div>
        )
    }
    return (
      (pageManager.totalPages > 1 || autoHide === false) &&
      <div className={className}>
        {pageSizeLabel && <PageSizeControl pageManager={this.props.pageManager}
          label={<div>
            <span className='static-text'>Showing</span>
            <strong className='align-page-number'>{((this.props.pageManager.pageSize * this.props.pageManager.pageNo) - (this.props.pageManager.pageSize) + 1)}
              -
              {(this.props.pageManager.pageSize * this.props.pageManager.pageNo) > (this.props.totalRecords!) ?
                this.props.totalRecords : (this.props.pageManager.pageSize * this.props.pageManager.pageNo)}</strong>
            <span className='static-text'>of</span>
            <strong className='align-page-number'>{this.props.totalRecords}</strong>
          </div>}
        />}
        <div {...domProps} className="pagination">
          {
            lastText && (pageManager.pageNo > 1) &&
            <div className={"page-item first-page" + (pageManager.pageNo === 1 ? " disabled" : "")}>
              <a className="page-link" href="/#" onClick={(e) => { e.preventDefault(); pageManager.firstPage() }}>{LibertySimplePagerControls.textOrIcon(lastText)} <i> <img src={chevron_left}></img></i></a>
            </div>
          }
          {
            prevText &&
            <div className={"page-item previous-page" + (pageManager.pageNo === 1 ? " disabled" : "")}>
              <a className="page-link" href="/#" onClick={(e) => { e.preventDefault(); pageManager.previousPage() }}><i className="fa fa-chevron-left" /> {LibertySimplePagerControls.textOrIcon(prevText)}</a>
            </div>
          }

          <div className="pager-page-buttons">
            {pageManager.pageNo > maxPagesToShow &&
              <span style={{ color: "black", fontWeight: "bold" }}>...</span>
            }
            {buttons}
            {pageManager.pageNo < pageManager.totalPages - 1 && maxPagesToShow !== pageManager.totalPages &&
              <span style={{ color: "black", fontWeight: "bold" }}>...</span>
            }
            {pageManager.pageNo < pageManager.totalPages - 1 && maxPagesToShow !== pageManager.totalPages &&
              <div className={"page-item"}>
                <a className="page-link page-number" href="/#" onClick={(e) => { e.preventDefault(); this.props.pageManager.lastPage() }}>{pageManager.totalPages}</a>
              </div>
            }
          </div>

          {
            nextText &&
            <div className={"page-item next-page" + (pageManager.pageNo === pageManager.totalPages ? " disabled" : "")}>
              <a className="page-link" href="/#" onClick={(e) => { e.preventDefault(); pageManager.nextPage() }}>{LibertySimplePagerControls.textOrIcon(nextText)} <i className="fa fa-chevron-right" /></a>
            </div>
          }
          {
            lastText && (pageManager.pageNo !== pageManager.totalPages) &&
            <div className={"page-item last-page" + (pageManager.pageNo === pageManager.totalPages ? " disabled" : "")}>
              <a className="page-link" href="/#" onClick={(e) => { e.preventDefault(); pageManager.lastPage() }}>{LibertySimplePagerControls.textOrIcon(lastText)}  <i> <img src={img_info}></img></i></a>
            </div>
          }
        </div>
      </div>
    )
  }
}