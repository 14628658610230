import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';


export default class WebInterfaceOptionBase extends ModelBase {
  static typeName = "WebInterfaceOptionBase";

  constructor() {
    super();
  }

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New web interface option base";
    } else {
      return "Web Interface Option Base";
    }
  }
}