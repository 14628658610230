import { AppServices } from '@singularsystems/neo-core';
import VASQueryApiClient from './ApiClients/VAS/VASQueryApiClient';
import VASCommandApiClient from './ApiClients/VAS/VASCommandApiClient';
import StaffDashboardApiClient from '../StaffDashboard/ApiClients/StaffDashboardApiClient';
import CallsAndNotificationsApiClient from '../MemberDashboard/ApiClients/CallsAndNotificationsApiClient';
import FADashboardApiClient from '../FADashboard/ApiClients/FaDashboardApiClient';
import KCQueryApiClient from './ApiClients/KnowledgeCentre/KCQueryApiClient';
import FundFactSheetCommandApiClient from './ApiClients/FundFactSheets/FundFactSheetCommandApiClient';
import FundFactSheetQueryApiClient from './ApiClients/FundFactSheets/FundFactSheetQueryApiClient';
import TAndCQueryApiClient from './ApiClients/TAndC/TAndCQueryApiClient';
import TAndCCommandApiClient from './ApiClients/TAndC/TAndCCommandApiClient';
import EmployerDashboardApiClient from '../Dashboard/ApiClients/EmployerDashboardApiClient';
import ContactUsCommandApiClient from './ApiClients/ContactUs/ContactUsCommandApiClient';
import ContactUsQueryApiClient from './ApiClients/ContactUs/ContactUsQueryApiClient';
import CallsAndInstructionsApiClient from '../MemberDashboard/ApiClients/CallsAndInstructionsApiClient';
import PDFQueryApiClient from './ApiClients/PDF/PDFQueryApiClient';
import EducationalInformationQueryApiClient from './ApiClients/EducationalInformation/EducationalInformationQueryApiClient';
import EducationalInformationCommandApiClient from './ApiClients/EducationalInformation/EducationalInformationCommandApiClient';
import JWTTokenApiClient from './ApiClients/JWT/JWTTokenApiClient';
import EmployerReportsAndDocumentsApiClient from '../Dashboard/ApiClients/EmployerReportsAndDocumentsApiClient';
import EmployerComplaintsApiClient from '../Dashboard/ApiClients/EmployerComplaintsApiClient';
import DocumentsApiClient from '../Dashboard/ApiClients/DocumentsApiClient';
import ReportsApiClient from './ApiClients/Reports/ReportsApiClient';
import UserTAndCCommandApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCCommandApiClient';
import UserTAndCQueryApiClient from './ApiClients/TAndC/UserTAndC/UserTAndCQueryApiClient';
import SystemInformationQueryApiClient from './ApiClients/SystemInformation/SystemInformationQueryApiClient';
import MemberWebInterfaceOptionsCommandApiClient from './ApiClients/WebInterfaceOptions/MemberWebInterfaceOptionsCommandApiClient';
import MemberWebInterfaceOptionsQueryApiClient from './ApiClients/WebInterfaceOptions/MemberWebInterfaceOptionsQueryApiClient';
import RiskBenefitsQueryApiClient from './ApiClients/RiskBenefits/RiskBenefitsQueryApiClient';
import RiskBenefitsCommandApiClient from './ApiClients/RiskBenefits/RiskBenefitsCommandApiClient';
import KCCommandApiClient from './ApiClients/KnowledgeCentre/KCCommandApiClient';
import SavingsWithdrawalClaimQueryApiClient from './ApiClients/SavingsWithdrawalClaim/SavingsWithdrawalClaimQueryApiClient';
import SavingsWithdrawalClaimCommandApiClient from './ApiClients/SavingsWithdrawalClaim/SavingsWithdrawalClaimCommandApiClient';
import ClaimDeclarationQueryApiClient from './ApiClients/Claims/ClaimDeclarationQueryApiClient';
import ClaimDeclarationCommandApiClient from './ApiClients/Claims/ClaimDeclarationCommandApiClient';

// Symbols to expose outside of this module
export const DomainExportedTypes = {
  ApiClients: {
    TAndCQueryApiClient: new AppServices.ServiceIdentifier<TAndCQueryApiClient>("Domain.ApiClient.TAndCQueryApiClient"),
    TAndCCommandApiClient: new AppServices.ServiceIdentifier<TAndCCommandApiClient>("Domain.ApiClient.TAndCCommandApiClient"),
    PDFQueryApiClient: new AppServices.ServiceIdentifier<PDFQueryApiClient>("Domain.ApiClient.PDFQueryApiClient"),
    JWTTokenApiClient: new AppServices.ServiceIdentifier<JWTTokenApiClient>("Domain.ApiClient.JWTTokenApiClient"),
    VASQueryApiClient: new AppServices.ServiceIdentifier<VASQueryApiClient>("Domain.ApiClient.VASQueryApiClient"),
    VASCommandApiClient: new AppServices.ServiceIdentifier<VASCommandApiClient>("Domain.ApiClient.VASCommandApiClient"),
    EducationalInformationQueryApiClient: new AppServices.ServiceIdentifier<EducationalInformationQueryApiClient>("Domain.ApiClient.EducationalInformationQueryApiClient"),
    EducationalInformationCommandApiClient: new AppServices.ServiceIdentifier<EducationalInformationCommandApiClient>("Domain.ApiClient.EducationalInformationCommandApiClient"),
    KCQueryApiClient: new AppServices.ServiceIdentifier<KCQueryApiClient>("Domain.ApiClient.KCQueryApiClient"),
    FundFactSheetQueryApiClient: new AppServices.ServiceIdentifier<FundFactSheetQueryApiClient>("Domain.ApiClient.FundFactSheetQueryApiClient"),
    FundFactSheetCommandApiClient: new AppServices.ServiceIdentifier<FundFactSheetCommandApiClient>("Domain.ApiClient.FundFactSheetCommandApiClient"),
    ContactUsQueryApiClient: new AppServices.ServiceIdentifier<ContactUsQueryApiClient>("Domain.ApiClient.ContactUsQueryApiClient"),
    ContactUsCommandApiClient: new AppServices.ServiceIdentifier<ContactUsCommandApiClient>("Domain.ApiClient.ContactUsCommandApiClient"),
    UserTAndCCommandApiClient: new AppServices.ServiceIdentifier<UserTAndCCommandApiClient>("Domain.ApiClient.UserTAndCCommandApiClient"),
    UserTAndCQueryApiClient: new AppServices.ServiceIdentifier<UserTAndCQueryApiClient>("Domain.ApiClient.UserTAndCQueryApiClient"),
    ReportsApiClient: new AppServices.ServiceIdentifier<ReportsApiClient>("Domain.ApiClient.ReportsApiClient"),
    SystemInformationQueryApiClient: new AppServices.ServiceIdentifier<SystemInformationQueryApiClient>("Domain.ApiClient.SystemInformationQueryApiClient"),
    MemberWebInterfaceOptionsCommandApiClient: new AppServices.ServiceIdentifier<MemberWebInterfaceOptionsCommandApiClient>("Domain.ApiClient.MemberWebInterfaceOptionsCommandApiClient"),
    MemberWebInterfaceOptionsQueryApiClient: new AppServices.ServiceIdentifier<MemberWebInterfaceOptionsQueryApiClient>("Domain.ApiClient.MemberWebInterfaceOptionsQueryApiClient"),
    RiskBenefitsQueryApiClient: new AppServices.ServiceIdentifier<RiskBenefitsQueryApiClient>("Domain.ApiClient.RiskBenefitsQueryApiClient"),
    RiskBenefitsCommandApiClient: new AppServices.ServiceIdentifier<RiskBenefitsCommandApiClient>("Domain.ApiClient.RiskBenefitsCommandApiClient"),
    KCCommandApiClient: new AppServices.ServiceIdentifier<KCCommandApiClient>("Domain.ApiClient.KCCommandApiClient"),
    SavingsWithdrawalClaimQueryApiClient: new AppServices.ServiceIdentifier<SavingsWithdrawalClaimQueryApiClient>("Domain.ApiClient.SavingsWithdrawalClaimQueryApiClient"),
    SavingsWithdrawalClaimCommandApiClient: new AppServices.ServiceIdentifier<SavingsWithdrawalClaimCommandApiClient>("Domain.ApiClient.SavingsWithdrawalClaimCommandApiClient"),
    ClaimDeclarationQueryApiClient: new AppServices.ServiceIdentifier<ClaimDeclarationQueryApiClient>("Domain.ApiClient.ClaimDeclarationQueryApiClient"),
    ClaimDeclarationCommandApiClient: new AppServices.ServiceIdentifier<ClaimDeclarationCommandApiClient>("Domain.ApiClient.ClaimDeclarationCommandApiClient"),
  },
  Services: {
  }
}