import React from 'react';
import { observer } from 'mobx-react';
import PhoneScreenInvestmentsTableCard from '../../App/Views/Components/MyPortfolio/PhoneScreenInvestmentsTableCard';
import InvestmentsTableCard from '../../App/Views/Components/MyPortfolio/InvestmentsTableCard';
import { Neo } from '@singularsystems/neo-react';
import PieChart from '../../App/Views/Components/MyPortfolio/PieChart';
import SchemeInformationCards from '../../App/Views/Components/MyPortfolio/SchemeInformationCards';
import ProductsVM from '../Views/ProductsVM';
import { NumberUtils } from '@singularsystems/neo-core';

interface IInvestmentsTabProps {
  viewModel: ProductsVM
}

@observer
export default class InvestmentsTab extends React.Component<IInvestmentsTabProps> {

  constructor(props: IInvestmentsTabProps) {
    super(props);
  }
  public viewModel = this.props.viewModel;

  public render() {
    return (
      <Neo.Tab header={<div className='card-tab-text-products'>Investments</div>} name="Investment" id="Investment" >
        <div className='line-products' />
        <div className='card-heading-products'>
          <p>Scheme information</p>
        </div>
        <div className='scheme-information-cards-container'>
          <SchemeInformationCards
            schemeName={this.viewModel.schemeName}
            schemeNumber={this.viewModel.schemeNumber}
            memberName={this.viewModel.memberName}
            memberNumber={this.viewModel.memberNo}
            lastContributionAmount={this.viewModel.contributionBillingData.totalAmountOfContributions
              ? this.viewModel.contributionBillingData.totalAmountOfContributions.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })
              : ""}
            contributionDate={this.viewModel.contributionBillingData.billDueDateFormatted}
          />
        </div>
        <div className={this.viewModel.savingsGridContainerClassName}>
          <div className='savings-component-container'>
            <div className='savings-cards total-balance'>
              <div>Total current balance</div>
              <div>{NumberUtils.format(this.viewModel.investmentDetails.totalCurrentBalance, "R # ##0.00")}</div>
            </div>
            <div className={this.viewModel.savingsContainerClassName}>
              <div className='savings-cards'>
                <div>Savings component</div>
                <div> {NumberUtils.format(this.viewModel.investmentDetails.savingsComponent, "R # ##0.00")}</div>
              </div>
              <div className='savings-cards vested-component'>
                <div className='vested-heading'>
                  <div>Vested component</div>
                  <div>{NumberUtils.format(this.viewModel.investmentDetails.vestedComponent, "R # ##0.00")}</div>
                </div>
                <div className='line-products' />
                <div className='sub-vested-component'>
                  <div className='vested-sub-heading'>
                    <div>Vested benefit</div>
                    <div>{NumberUtils.format(this.viewModel.investmentDetails.vestedBenefit, "R # ##0.00")}</div>
                  </div>
                  <div className='vested-sub-heading'>
                    <div>Non-vested benefit</div>
                    <div> {NumberUtils.format(this.viewModel.investmentDetails.nonVestedBenefit, "R # ##0.00")}</div>
                  </div>
                </div>
              </div>
              <div className='savings-cards savings-retirement-card'>
                <div>Retirement component</div>
                <div>{NumberUtils.format(this.viewModel.investmentDetails.retirementComponent, "R # ##0.00")}</div>
              </div>
            </div>
            <div className='show-more-overlay'>
              <Neo.Button
                className='show-more-less-button-investment'
                onClick={() => { this.viewModel.setShowMoreShowLessSavings() }}
                text={this.viewModel.savingsGridContainerClassName.indexOf('collapsed') >= 0 ? "Show more" : "Show less"}>
              </Neo.Button>
            </div>
          </div>
        </div>

        <div className='line-products' />
        <div className='card-heading-products'>
          <span>Your investments comprise of the portfolios below:</span>
        </div>
        <div className='row'>
          <PieChart
            graphData={[
              ...this.viewModel.graphData
            ]}
            backgroundColor="#FFF"
            animate={true}>
          </PieChart>
        </div>
        {this.viewModel.investmentDetails.currentInvestmentValues.length > 0 &&
          <div>
            <div className='line-products line-two-products' />
            {/* Screens above 500px */}
            <div className={this.viewModel.investmentTableContainerClassName}>
              <div className='row table-header'>
                <div className='col-7'>
                  <div className='table-headers-investment heading-name-padding-investment'>
                    Investment portfolio name
                  </div>
                </div>
                <div className='col-2'>
                  <div className='table-headers-investment value-padding-investment'>
                    Value
                  </div>
                </div>
                <div className='col-3'>
                  <div className='table-headers-investment heading-sheet-padding-investment'>
                    Fund fact sheet
                  </div>
                </div>
              </div>
              <div className={this.viewModel.investmentContainerClassName}>
                {this.viewModel.investmentDetails.currentInvestmentValues.map((item) => (
                  <InvestmentsTableCard
                    investmentName={item.investmentFundName}
                    value={item.totalInvestmentFundValue}
                    url={item.url}
                  >
                  </InvestmentsTableCard>
                ))}
              </div>
            </div>
          </div>
        }
        {/* Screens below 500px */}
        <div className={this.viewModel.phoneScreenInvestmentTableContainerClassName}>
          <div className={this.viewModel.phoneScreenInvestmentContainerClassName}>
            {this.viewModel.investmentDetails.currentInvestmentValues.map((item) => (
              <PhoneScreenInvestmentsTableCard
                investmentName={item.investmentFundName}
                value={item.totalInvestmentFundValue}
                url={item.url}>
              </PhoneScreenInvestmentsTableCard>
            ))}
          </div>
        </div>
        <div className='show-more-overlay'>
          {this.viewModel.investmentDetails.currentInvestmentValues.length > 1 &&
            <Neo.Button
              className='show-more-less-button-investment'
              onClick={() => { this.viewModel.setShowMoreShowLessInvestments() }}
              text={this.viewModel.investmentTableContainerClassName.indexOf('collapsed') >= 0 ? "Show more" : "Show less"}>
            </Neo.Button>
          }
        </div>
      </Neo.Tab>
    );
  }
}