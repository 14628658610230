import { List, Model, NeoModel, NotifyUtils, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import MemberContactDetailsLookup from '../../Party/Models/LookUps/MemberContacts/MemberContactDetailsLookup';
import MemberSchemeMembershipLookup from '../../Party/Models/LookUps/PersonProfile/MemberSchemeMembershipLookup';
import { NotificationDuration } from '../Models/NotificationDuration';
import { AppService, Types } from '../Services/AppService';

@NeoModel
export default class ContactUsVM extends Views.ViewModelBase {

  public isParentExpanded = new Model.ObservableValue(false);
  public isLibertyExpanded = new Model.ObservableValue(false);
  public isEmployerExpanded = new Model.ObservableValue(false);
  public memberEmployerContactDetails = new List(MemberContactDetailsLookup);
  public memberFAContactDetails = new List(MemberContactDetailsLookup);

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    public userMemberSchemeService = AppService.get(Types.App.Services.UserMemberSchemeService),
    private memberContactDetailsApiClient = AppService.get(Types.Parties.ApiClients.MemberContactDetailsApiClient),) {

    super(taskRunner);
  }

  public setParentCardShowMoreShowLess(e: any, isExpanded: Model.ObservableValue<boolean>) {
    if (e.target.className.indexOf("card-header") >= 0) {
      isExpanded.value = !isExpanded.value;
    }
  }

  public async initialise() {
  }

  public navigateToDashboard() {
    this.userRoleService.navigateToDashboard();
  }

  public async setSchemeInfo(scheme: MemberSchemeMembershipLookup) {
  }

  public async getSchemeContactDetails() {
    if (this.userMemberSchemeService.currentSchemeSelected.schemeId && !StringUtils.isNullOrWhitespace(this.userMemberSchemeService.currentSchemeSelected.sourceId)) {
      //            var memberAdminContacts = await this.taskRunner.waitFor(this.memberContactDetailsApiClient.getMemberAdminContactsLookups(this.userMemberSchemeService.currentSchemeSelected.schemeId));
      //			if (this.memberEmployerContacts.data.length != 0) {
      //            	this.memberAdminContactDetails.set(memberAdminContacts.data);
      //			}
      //			else {
      //				this.memberAdminContactDetails.set([]);
      //			}

      var memberEmployerContacts = await this.taskRunner.waitFor(this.memberContactDetailsApiClient.getMemberEmployerContactsLookups(this.userMemberSchemeService.currentSchemeSelected.schemeId, this.userMemberSchemeService.currentSchemeSelected.sourceId));
      if (memberEmployerContacts.data.length != 0) {
        this.memberEmployerContactDetails.set(memberEmployerContacts.data);
      }
      else {
        this.memberEmployerContactDetails.set([]);
      }

      var memberFAContacts = await this.taskRunner.waitFor(this.memberContactDetailsApiClient.getMemberFAContactsLookups(this.userMemberSchemeService.currentSchemeSelected.schemeId, this.userMemberSchemeService.currentSchemeSelected.sourceId));
      if (memberFAContacts.data.length != 0) {
        this.memberFAContactDetails.set(memberFAContacts.data);
      } else {
        this.memberFAContactDetails.set([]);
      }
    } else {
      NotifyUtils.addWarning("Issue Fetching Contact Details", "There was an issue fetching contact details.", NotificationDuration.Long)
    }
  }
}