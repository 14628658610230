import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberComplaintLookup from '../../Models/LookUps/MemberQueries/MemberComplaintLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberComplaintsQueryApiClient {

    /**
     * Gets a member complaint.
     * @param memberId The member id.
     * @returns A member complaint.
     */
    getMemberComplaint(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberComplaintLookup>>;

    // Client only properties / methods
}

@injectable()
export default class MemberComplaintsQueryApiClient extends Data.ApiClientBase implements IMemberComplaintsQueryApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/member-complaints`);
    }

    public getMemberComplaint(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberComplaintLookup>> {
        return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
    }

    // Client only properties / methods
}