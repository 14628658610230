import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import UnderWritingVM from './UnderwritingVM';

@observer
export default class UnderWritingView extends Views.ViewBase<UnderWritingVM> {

  constructor(props: unknown) {
    super("My UnderWriting", UnderWritingVM, props);
  }

  public render() {
    return (
      <div>
        <Neo.Card title="Under Writing">

        </Neo.Card>
      </div>
    );
  }
}