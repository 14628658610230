import { Views } from '@singularsystems/neo-react';
import SystemInformationQueryApiClient from '../../../Domain/ApiClients/SystemInformation/SystemInformationQueryApiClient';
import Country from '../../../MasterData/Models/Country';
import { AppService, Types } from '../../Services/AppService';

export default class NonEditableProfileVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private systemInformationQueryApiClient: SystemInformationQueryApiClient = AppService.get(Types.Domain.ApiClients.SystemInformationQueryApiClient),
    private masterDataservice = AppService.get(Types.App.Services.MasterDataService)) {

    super(taskRunner);
    this.makeObservable();
  }

  public countryId: number | null = 161;
  public country: string = '+27';
  public countryValueId: number = 2;
  public provinceValueId: number = 3;
  public countryValue: string = "Gauteng";
  public versionNumber = "v0.0";
  public countries: Country[] = [];

  public async initialise() {
    const response = await this.systemInformationQueryApiClient.getAssemblyVersionLookup();
    this.versionNumber = response.data.majorMinorPatch;
    this.countries = this.masterDataservice.countries;
  }
}