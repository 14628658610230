import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';

interface ISchemeInformationCardsProps {
  schemeName: string,
  schemeNumber: string,
  memberName: string,
  memberNumber: string,
  lastContributionAmount: string | null,
  contributionDate: string,
}

@observer
export default class SchemeInformationCards extends React.Component<ISchemeInformationCardsProps> {

  constructor(props: ISchemeInformationCardsProps) {
    super(props);
  }

  public render() {
    return (
      <div className='row'>
        <SchemeInformationCard heading='Scheme Name' description1={this.props.schemeName} description2={this.props.schemeNumber} cardNo='card-one' />
        <SchemeInformationCard heading='Member details' description1={this.props.memberName} description2={this.props.memberNumber} cardNo='card-two' />
        <SchemeInformationCard heading='Last contribution'
          description1={this.props.lastContributionAmount}
          description2={this.props.contributionDate}
          cardNo='card-three' />
      </div>
    );
  }
}

interface ISchemeInformationCardProps {
  heading: string,
  description1: string | number | null,
  description2: string,
  cardNo: string,
}

class SchemeInformationCard extends React.Component<ISchemeInformationCardProps> {
  constructor(props: ISchemeInformationCardProps) {
    super(props);
  }

  public render() {
    return (
      <div className='col-12 col-sm-4 mt-3 mt-sm-0'>
        <div className={'scheme-information-cards ' + this.props.cardNo}>
          <div className='scheme-information-heading'>
            {this.props.heading}
          </div>
          <div className='scheme-information-description'>
            {this.props.description1}
          </div>
          <div className='scheme-information-description'>
            {this.props.description2}
          </div>
        </div>
      </div>
    );
  }
}