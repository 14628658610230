import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import information_icon from '../../../App/assets/img/information-small.png';
import { Neo } from '@singularsystems/neo-react';
import group from '../../../App/assets/img/group.png';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';
import FileUploadBeneficiaries from './FileUploadBeneficiaries';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';

interface IBeneficiariesContactPersonProps {
  viewModel: BeneficiariesVM
  currentBeneficiary: MemberBeneficiaryLookup,
}

@observer
export default class BeneficiariesContactPerson extends React.Component<IBeneficiariesContactPersonProps> {

  constructor(props: IBeneficiariesContactPersonProps) {
    super(props);
  }

  private countryCodes = [
    { Id: 1, CountryCode: "+27" },
    { Id: 2, CountryCode: "+44" },
    { Id: 3, CountryCode: "+21" }
  ];

  public render() {
    return (
      <div className='beneficiaries-approved-death-benefits'>
        <div className={this.props.viewModel.viewOnlyBeneficiary == true ? 'adding-margin-to-the-sides adding-margin-bottom' : 'adding-margin-to-the-sides'}>
          <div className='row alert-margin'>
            <div className='col-md-4'>
              <div className='benefit-info-link' onClick={() => this.props.viewModel.showTrustedContactPersonModal = true}>
                <img className='alert-approved-badb' src={information_icon} /> What is a trusted contact person?
              </div>
            </div>
          </div>
          <BlueRibbon
            text='Please note that any changes to the trusted person will be updated on the beneficiary profile.'
          />
          <div className='add-new-beneficiaries'>
            <div className='center-form'>
              <Neo.GridLayout >
                <Neo.FormGroup
                  label={
                    <div>
                      Name
                      <span className='display-asterisk'>*</span>
                    </div>
                  }
                  disabled={this.props.currentBeneficiary.isValid}
                  className='form-text' href='Name' bind={this.props.currentBeneficiary.meta.firstName}
                  placeholder={"Enter first name"} readOnly={this.props.viewModel.viewOnlyBeneficiary} />
                <Neo.FormGroup className='form-text'
                  label={
                    <div>
                      Surname
                      <span className='display-asterisk'>*</span>
                    </div>
                  }
                  disabled={this.props.currentBeneficiary.isValid}
                  href='Surname' bind={this.props.currentBeneficiary.meta.lastName} placeholder={"Enter last name"} readOnly={this.props.viewModel.viewOnlyBeneficiary} />
                <div className='cell-number-row'>
                  <label className='custom-label'>Cell Number <span className='display-asterisk'>*</span></label>
                  <div className='row'>
                    <div className='col-md-3 col-4'>
                      <Neo.AutoCompleteDropDown
                        bind={this.props.currentBeneficiary.meta.countryCodeId}
                        bindDisplay={this.props.currentBeneficiary.meta.countryCode}
                        items={this.countryCodes}
                        disabled={this.props.currentBeneficiary.isValid}
                        readOnly={this.props.viewModel.viewOnlyBeneficiary}
                      />
                    </div>
                    <div className='col-md-9 col-8'>
                      <Neo.FormGroup className='form-text'
                        bind={this.props.currentBeneficiary.meta.telephoneNo}
                        placeholder="Enter number"
                        suppressLabel={true}
                        disabled={this.props.currentBeneficiary.isValid}
                        readOnly={this.props.viewModel.viewOnlyBeneficiary} />
                    </div>
                  </div>
                </div>
                <Neo.FormGroup className='form-text' href='Email' bind={this.props.currentBeneficiary.meta.emailAddress}
                  placeholder={"Enter email address"} disabled={this.props.currentBeneficiary.isValid} readOnly={this.props.viewModel.viewOnlyBeneficiary} />
                <Neo.FormGroup className='form-text' href='Relationship' bind={this.props.currentBeneficiary.meta.trustedPersonRelationship}
                  placeholder={"Enter relationship"} disabled={this.props.currentBeneficiary.isValid} readOnly={this.props.viewModel.viewOnlyBeneficiary} />
              </Neo.GridLayout>
            </div>
            {!this.props.viewModel.viewOnlyBeneficiary &&
              <div>
                <div className='blue-block blue-block-for-trusted-person'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img src={group} className="edit-image-size padding" />
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()}>
                        Select existing beneficiary
                      </Neo.Link>
                    </div>
                  </div>
                </div>
                <FileUploadBeneficiaries viewModel={this.props.viewModel} />
              </div>
            }
          </div>
        </div>
        {!this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedTrustedPerson.Role + ' - ' + this.props.viewModel.lastModifiedTrustedPerson.UserName}</span>
            </div>
          </div>
        }
        {this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedTrustedPerson.Role + ' - ' + this.props.viewModel.lastModifiedTrustedPerson.UserName}</span>
            </div>
          </div>
        }
        <ContainerFooterComponent
          showPrimaryButton={true}
          showSecondaryButton={true}
          backLinkText='Save & exit'
          primaryButtonText='Update'
          primaryButtonCallback={() => this.props.viewModel.verifyTrustedPerson()}
          secondaryButtonText='Clear'
          secondaryButtonCallback={() => this.props.viewModel.clearTrustedPersonDetails()}
          disablePrimaryButton={this.props.viewModel.trustedContactPerson.isValidTrustedPerson() ? false : true}
        />
        <Neo.Modal title='What is a trusted contact person?'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showTrustedContactPersonModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showTrustedContactPersonModal}>
          A trusted living relative, friend, caregiver, neighbour, etc., that would be able to provide Liberty with additional information if needed.
        </Neo.Modal>
      </div>
    );
  }
}