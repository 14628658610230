import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { EducationalInformationType } from './Enums/EducationalInformationType.enum';

@NeoModel
export default class EducationalInformationTemplate extends ModelBase {
  bind(arg0: this): EducationalInformationTemplate {
    throw new Error('Method not implemented.');
  }

  public educationalInformationTemplateId: number = 0;

  @Rules.Required()
  public templateContent: string = "";

  public educationalInformationTemplateType: EducationalInformationType = EducationalInformationType.None;

  public modifiedBy: number = 0;

  @Attributes.Date()
  public modifiedOn: Date = new Date();

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.templateContent) {
      return "New educational information template";
    } else {
      return this.templateContent;
    }
  }
}