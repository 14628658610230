import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';

export default class UserProfileLookup extends ModelBase {

  constructor() {
    super();
    this.makeObservable();
  }

  public userProfileId: number = 0;

  public userGuid: string = "";

  @Attributes.Nullable()
  public libertyClientId: number | null = null;

  @Attributes.Date()
  public verified: Date | null = null;

  @Attributes.Date()
  public lastLoginDate: Date | null = null;

  @Attributes.Date()
  public currentSessionLoginDate: Date | null = null;
  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew || !this.userGuid) {
      return "New user profile lookup";
    } else {
      return this.userGuid;
    }
  }
}