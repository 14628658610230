import React from 'react';
import { observer } from 'mobx-react';
import InstructionsSearchResultsLookup from '../../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';
import { Neo } from '@singularsystems/neo-react';
import InstructionsVM from '../InstructionsVM';

import InstructionWithdrawalClaimIncompleteView from './InstructionWithdrawalClaimIncompleteView';
import InstructionWithdrawalClaimCompleteView from './InstructionWithdrawalClaimCompleteView';
import InstructionUpdateBeneficiaryViewIncomplete from './InstructionUpdateBeneficiaryViewIncomplete';
import InstructionUpdateBeneficiaryViewComplete from './InstructionUpdateBeneficiaryViewComplete';
import InstructionCardHeadingPhoneScreen from './InstructionCustomComponents/InstructionCardHeadingPhoneScreen';
import InstructionDateStatusWebScreen from './InstructionCustomComponents/InstructionDateStatusWebScreen';
import InstructionDateStatusPhoneScreen from './InstructionCustomComponents/InstructionDateStatusPhoneScreen';
import InstructionHeadingWebScreen from './InstructionCustomComponents/InstructionHeadingWebScreen';
import InstructionQueryView from './InstructionQueryView';

interface IInstructionDetailsViewProps {
  instruction: InstructionsSearchResultsLookup,
  viewModel: InstructionsVM
}

@observer
export default class InstructionDetailsView extends React.Component<IInstructionDetailsViewProps> {

  constructor(props: IInstructionDetailsViewProps) {
    super(props);
  }

  public render() {
    return (
      <div className='background-card'>
        <InstructionHeadingWebScreen instruction={this.props.instruction} />
        <InstructionCardHeadingPhoneScreen instruction={this.props.instruction} />
        <div className='row'>
          <div className='divider1'></div>
        </div>
        <InstructionDateStatusWebScreen instruction={this.props.instruction} />
        <InstructionDateStatusPhoneScreen instruction={this.props.instruction} />
        {
          this.props.instruction.instruction == "Query" &&
          <InstructionQueryView viewModel={this.props.viewModel} />
        }
        {
          this.props.instruction.instruction == "Withdrawal claim" && !this.props.instruction.status.includes("Completed") &&
          <InstructionWithdrawalClaimIncompleteView viewModel={this.props.viewModel} />
        }
        {
          this.props.instruction.instruction == "Withdrawal claim" && this.props.instruction.status.includes("Completed") &&
          <InstructionWithdrawalClaimCompleteView viewModel={this.props.viewModel} />
        }
        {
          this.props.instruction.instruction == "Update beneficiaries" && !this.props.instruction.status.includes("Completed") &&
          <InstructionUpdateBeneficiaryViewIncomplete viewModel={this.props.viewModel} />
        }
        {
          this.props.instruction.instruction == "Update beneficiaries" && this.props.instruction.status.includes("Completed") &&
          <InstructionUpdateBeneficiaryViewComplete viewModel={this.props.viewModel} />
        }
        <div className='row'>
          <div className='divider1'></div>
        </div>
        <Neo.Link className='back-link' onClick={() => this.props.viewModel.showInstructionDetails = false}>Back to instructions</Neo.Link>
      </div>
    );
  }
}