import { EnumHelper } from '@singularsystems/neo-core';

export enum RoleType {
    MemberDeclaration = 1,
    EmployerDeclarationOnBehalfOfMember = 2,
    FADeclarationOnBehalfOfMember = 3,
    EmployerDeclaration = 4,
    FADeclaration = 5,
}

EnumHelper.decorateEnum(RoleType, decorator => {
    decorator.describe(RoleType.MemberDeclaration, "Member declaration");
    decorator.describe(RoleType.EmployerDeclarationOnBehalfOfMember, "Employer declaration - Complete on behalf of member");
    decorator.describe(RoleType.FADeclarationOnBehalfOfMember, "Financial Adviser declaration - Complete on behalf of member");
    decorator.describe(RoleType.EmployerDeclaration, "Employer declaration");
    decorator.describe(RoleType.FADeclaration, "Financial Adviser declaration");
});