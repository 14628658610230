import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import TAndCVM from './TAndCVM';
import { observer } from 'mobx-react';
import '../../App/Styles/Components/TAndC.scss';

@observer
export default class TAndCView extends Views.ViewBase<TAndCVM> {

  constructor(props: unknown) {
    super("TAnd C", TAndCVM, props);
  }

  public render() {
    return (
      <div className='terms-conditions'>
        <div className='container'>
          <div className='mt-4 terms-and-conditions-card'>
            <Neo.Modal size="lg" title='Liberty Corporate Terms & Conditions:' bind={this.viewModel.meta.showTemplateModal}
              buttons={
                [
                  { text: "Download T&C's", className: 'btn-download' },
                  { text: "Done", variant: "success", className: 'btn-success', onClick: () => this.viewModel.navigatePortfolio() },
                ]}
              className='terms-and-conditions-modal'>
              <div className='modal-content'>
                <div className='modal-text' dangerouslySetInnerHTML={{ __html: this.viewModel.tAndCTemplate.templateContent }} />
              </div>
            </Neo.Modal>
          </div>
        </div>
      </div>
    );
  }
}