import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import InstructionsSearchResultsLookup from '../../../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';

interface IInstructionCardHeadingWebScreenProps {
  instruction: InstructionsSearchResultsLookup
}

@observer
export default class InstructionDateStatusWebScreen extends React.Component<IInstructionCardHeadingWebScreenProps> {

  constructor(props: IInstructionCardHeadingWebScreenProps) {
    super(props);
  }

  public render() {
    return (
      <div className='show-on-web-screen'>
        <div className='row'>
          <div className='col-md-9'>
            <p className='instructon-view-datelabel'>20 Nov 2022</p>
          </div>
          <div className='col-md-3'>
            <div className={this.props.instruction.status == 'In progress' ? 'in-progress-pill fl-right' : this.props.instruction.status == 'Incomplete' || this.props.instruction.status == 'Requires documents' ? 'incomplete-pill fl-right' : 'completed-pill fl-right'}>
              <Neo.Tooltip position='bottom' content={this.props.instruction.status}>{this.props.instruction.status}</Neo.Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
}