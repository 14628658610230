import { NeoModel, Validation } from '@singularsystems/neo-core';
import DashboardSummaryModelBase from './DashboardSummaryModelBase';

@NeoModel
export default class ProfileSummaryLookup extends DashboardSummaryModelBase {

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New profile summary lookup";
    } else {
      return "Profile Summary Lookup";
    }
  }
}