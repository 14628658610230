import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo } from '@singularsystems/neo-react';
import MemberBeneficiaryLookup from '../../Models/LookUps/MemberBeneficiaries/MemberBeneficiaryLookup';

interface IBeneficiaryDetailsProps {
  viewModel: BeneficiariesVM
  currentBeneficiary: MemberBeneficiaryLookup,
}

@observer
export default class BeneficiaryDetails extends React.Component<IBeneficiaryDetailsProps> {

  //TODO: countries list should be coming from a class.
  public provinces = [
    { id: 1, province: "Eastern Cape" },
    { id: 2, province: "Free State" },
    { id: 3, province: "Gauteng" },
    { id: 4, province: "KwaZulu-Natal" },
    { id: 5, province: "Limpopo" },
    { id: 6, province: "Mpumalanga" },
    { id: 7, province: "Northern Cape" },
    { id: 8, province: "North West" },
    { id: 9, province: "Western Cape" },
  ];

  //TODO: countries should be coming from a class.
  public countries = [
    { id: 1, country: "Afghanistan" },
    { id: 2, country: "Albania" },
    { id: 3, country: "Algeria" },
    { id: 4, country: "Andorra" },
    { id: 5, country: "Angola" },
    { id: 6, country: "Antigua and Barbuda" },
    { id: 7, country: "Argentina" },
    { id: 8, country: "Armenia" },
    { id: 9, country: "Australia" },
    { id: 10, country: "Austria" },
    { id: 11, country: "Azerbaijan" },
    { id: 12, country: "Bahamas" },
    { id: 13, country: "Bahrain" },
    { id: 14, country: "Bangladesh" },
    { id: 15, country: "Barbados" },
    { id: 16, country: "Belarus" },
    { id: 17, country: "Belgium" },
    { id: 18, country: "Belize" },
    { id: 19, country: "Benin" },
    { id: 20, country: "Bhutan" },
    { id: 21, country: "Bolivia" },
    { id: 22, country: "Bosnia and Herzegovina" },
    { id: 23, country: "Botswana" },
    { id: 24, country: "Brazil" },
    { id: 25, country: "Brunei" },
    { id: 26, country: "Bulgaria" },
    { id: 27, country: "Burkina Faso" },
    { id: 28, country: "Burundi" },
    { id: 29, country: "Cabo Verde" },
    { id: 30, country: "Cambodia" },
    { id: 31, country: "Cameroon" },
    { id: 32, country: "Canada" },
    { id: 33, country: "Central African Republic" },
    { id: 34, country: "Chad" },
    { id: 35, country: "Chile" },
    { id: 36, country: "China" },
    { id: 37, country: "Colombia" },
    { id: 38, country: "Comoros" },
    { id: 39, country: "Congo, Democratic Republic of the" },
    { id: 40, country: "Congo, Republic of the" },
    { id: 41, country: "Costa Rica" },
    { id: 42, country: "Croatia" },
    { id: 43, country: "Cuba" },
    { id: 44, country: "Cyprus" },
    { id: 45, country: "Czech Republic" },
    { id: 46, country: "Denmark" },
    { id: 47, country: "Djibouti" },
    { id: 48, country: "Dominica" },
    { id: 49, country: "Dominican Republic" },
    { id: 50, country: "Ecuador" },
    { id: 51, country: "Egypt" },
    { id: 52, country: "El Salvador" },
    { id: 53, country: "Equatorial Guinea" },
    { id: 54, country: "Eritrea" },
    { id: 55, country: "Estonia" },
    { id: 56, country: "Eswatini" },
    { id: 57, country: "Ethiopia" },
    { id: 58, country: "Fiji" },
    { id: 59, country: "Finland" },
    { id: 60, country: "France" },
    { id: 61, country: "Gabon" },
    { id: 62, country: "Gambia" },
    { id: 63, country: "Georgia" },
    { id: 64, country: "Germany" },
    { id: 65, country: "Ghana" },
    { id: 66, country: "Greece" },
    { id: 67, country: "Grenada" },
    { id: 68, country: "Guatemala" },
    { id: 69, country: "Guinea" },
    { id: 70, country: "Guinea-Bissau" },
    { id: 71, country: "Guyana" },
    { id: 72, country: "Haiti" },
    { id: 73, country: "Honduras" },
    { id: 74, country: "Hungary" },
    { id: 75, country: "Iceland" },
    { id: 76, country: "India" },
    { id: 77, country: "Indonesia" },
    { id: 78, country: "Iran" },
    { id: 79, country: "Iraq" },
    { id: 80, country: "Ireland" },
    { id: 81, country: "Israel" },
    { id: 82, country: "Italy" },
    { id: 83, country: "Jamaica" },
    { id: 84, country: "Japan" },
    { id: 85, country: "Jordan" },
    { id: 86, country: "Kazakhstan" },
    { id: 87, country: "Kenya" },
    { id: 88, country: "Kiribati" },
    { id: 89, country: "Korea, North" },
    { id: 90, country: "Korea, South" },
    { id: 91, country: "Kosovo" },
    { id: 92, country: "Kuwait" },
    { id: 93, country: "Kyrgyzstan" },
    { id: 94, country: "Laos" },
    { id: 95, country: "Latvia" },
    { id: 96, country: "Lebanon" },
    { id: 97, country: "Lesotho" },
    { id: 98, country: "Liberia" },
    { id: 99, country: "Libya" },
    { id: 100, country: "Liechtenstein" },
    { id: 101, country: "Lithuania" },
    { id: 102, country: "Luxembourg" },
    { id: 103, country: "Madagascar" },
    { id: 104, country: "Malawi" },
    { id: 105, country: "Malaysia" },
    { id: 106, country: "Maldives" },
    { id: 107, country: "Mali" },
    { id: 108, country: "Malta" },
    { id: 109, country: "Marshall Islands" },
    { id: 110, country: "Mauritania" },
    { id: 111, country: "Mauritius" },
    { id: 112, country: "Mexico" },
    { id: 113, country: "Micronesia" },
    { id: 114, country: "Moldova" },
    { id: 115, country: "Monaco" },
    { id: 116, country: "Mongolia" },
    { id: 117, country: "Montenegro" },
    { id: 118, country: "Morocco" },
    { id: 119, country: "Mozambique" },
    { id: 120, country: "Myanmar" },
    { id: 121, country: "Namibia" },
    { id: 122, country: "Nauru" },
    { id: 123, country: "Nepal" },
    { id: 124, country: "Netherlands" },
    { id: 125, country: "New Zealand" },
    { id: 126, country: "Nicaragua" },
    { id: 127, country: "Niger" },
    { id: 128, country: "Nigeria" },
    { id: 129, country: "North Macedonia" },
    { id: 130, country: "Norway" },
    { id: 131, country: "Oman" },
    { id: 132, country: "Pakistan" },
    { id: 133, country: "Palau" },
    { id: 134, country: "Panama" },
    { id: 135, country: "Papua New Guinea" },
    { id: 136, country: "Paraguay" },
    { id: 137, country: "Peru" },
    { id: 138, country: "Philippines" },
    { id: 139, country: "Poland" },
    { id: 140, country: "Portugal" },
    { id: 141, country: "Qatar" },
    { id: 142, country: "Romania" },
    { id: 143, country: "Russia" },
    { id: 144, country: "Rwanda" },
    { id: 145, country: "Saint Kitts and Nevis" },
    { id: 146, country: "Saint Lucia" },
    { id: 147, country: "Saint Vincent and the Grenadines" },
    { id: 148, country: "Samoa" },
    { id: 149, country: "San Marino" },
    { id: 150, country: "Sao Tome and Principe" },
    { id: 151, country: "Saudi Arabia" },
    { id: 152, country: "Senegal" },
    { id: 153, country: "Serbia" },
    { id: 154, country: "Seychelles" },
    { id: 155, country: "Sierra Leone" },
    { id: 156, country: "Singapore" },
    { id: 157, country: "Slovakia" },
    { id: 158, country: "Slovenia" },
    { id: 159, country: "Solomon Islands" },
    { id: 160, country: "Somalia" },
    { id: 161, country: "South Africa" },
    { id: 162, country: "South Sudan" },
    { id: 163, country: "Spain" },
    { id: 164, country: "Sri Lanka" },
    { id: 165, country: "Sudan" },
    { id: 166, country: "Suriname" },
    { id: 167, country: "Sweden" },
    { id: 168, country: "Switzerland" },
    { id: 169, country: "Syria" },
    { id: 170, country: "Taiwan" },
    { id: 171, country: "Tajikistan" },
    { id: 172, country: "Tanzania" },
    { id: 173, country: "Thailand" },
    { id: 174, country: "Timor-Leste" },
    { id: 175, country: "Togo" },
    { id: 176, country: "Tonga" },
    { id: 177, country: "Trinidad and Tobago" },
    { id: 178, country: "Tunisia" },
    { id: 179, country: "Turkey" },
    { id: 180, country: "Turkmenistan" },
    { id: 181, country: "Tuvalu" },
    { id: 182, country: "Uganda" },
    { id: 183, country: "Ukraine" },
    { id: 184, country: "United Arab Emirates" },
    { id: 185, country: "United Kingdom" },
    { id: 186, country: "United States" },
    { id: 187, country: "Uruguay" },
    { id: 188, country: "Uzbekistan" },
    { id: 189, country: "Vanuatu" },
    { id: 190, country: "Vatican City" },
    { id: 191, country: "Venezuela" },
    { id: 192, country: "Vietnam" },
    { id: 193, country: "Yemen" },
    { id: 194, country: "Zambia" },
    { id: 195, country: "Zimbabwe" }
  ];

  //TODO: countryCodes should be coming from a class.
  private countryCodes = [
    { Id: 1, CountryCode: "+27" },
    { Id: 2, CountryCode: "+44" },
    { Id: 3, CountryCode: "+21" }
  ];

  constructor(props: IBeneficiaryDetailsProps) {
    super(props);
  }

  public render() {
    return (
      <div className='add-new-beneficiaries'>
        <div className='center-form'>
          <Neo.GridLayout>
            <Neo.FormGroup className='form-text' href='Firstname' bind={this.props.currentBeneficiary.meta.firstName} placeholder={"Enter name"}
              label={
                <div>
                  First name
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <Neo.FormGroup className='form-text' href='Lastname' bind={this.props.currentBeneficiary.meta.lastName} placeholder={"Enter last name"}
              label={
                <div>
                  Last name
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <div className="align-communication-method">
              <label className='identification-title-text'> Identification type </label>
              <Neo.RadioList className='radio-option'
                bind={this.props.currentBeneficiary.meta.isIDNumber}
                radioList={{ items: [{ id: true, text: "RSA ID number" }, { id: false, text: "Passport number" }], inline: true }}
              />
            </div>
            {!this.props.currentBeneficiary.isIDNumber &&
              <Neo.FormGroup className='form-text' href='RSA ID number' bind={this.props.currentBeneficiary.meta.passportNo} placeholder={"Enter number"}
                label={
                  <div>
                    Passport number
                    <span className='display-asterisk'>*</span>
                  </div>
                } />
            }
            {!this.props.currentBeneficiary.isIDNumber &&
              <div className='province-dropdown'>
                <label className='custom-label'> Country of issue</label>
                <Neo.AutoCompleteDropDown
                  bind={this.props.currentBeneficiary.meta.countryOfIssueValueId}
                  items={this.countries}
                  placeholder="Select country of issue" />
              </div>
            }
            {this.props.currentBeneficiary.isIDNumber &&
              <Neo.FormGroup className='form-text' href='RSA ID number' bind={this.props.currentBeneficiary.meta.nationalIdNo} placeholder={"Enter number"}
                label={
                  <div>
                    RSA ID number
                    <span className='display-asterisk'>*</span>
                  </div>
                } />
            }
            <Neo.FormGroup className='form-text' href='BirtDate' bind={this.props.currentBeneficiary.meta.birthDate} placeholder={"Enter Date of birth"}
              label={
                <div>
                  Date of birth
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <Neo.FormGroup className='form-text' href='Relationship' bind={this.props.currentBeneficiary.meta.relationship} placeholder={"Enter relationship"}
              label={
                <div>
                  Relationship
                  <span className='display-asterisk'>*</span>
                </div>
              } />
            <div className='cell-number-row'>
              <label className='custom-label'>Cell number</label>
              <div className='row '>
                <div className='col-4'>
                  <Neo.AutoCompleteDropDown
                    bind={this.props.currentBeneficiary.meta.countryCodeId}
                    bindDisplay={this.props.currentBeneficiary.meta.countryCode}
                    items={this.countryCodes}
                  />
                </div>
                <div className='col-8 '>
                  <Neo.FormGroup className='form-text'
                    bind={this.props.currentBeneficiary.meta.telephoneNo}
                    placeholder="Enter number"
                    suppressLabel={true} />
                </div>
              </div>
            </div>
            <Neo.FormGroup className='form-text' href='Email address' bind={this.props.currentBeneficiary.meta.emailAddress} placeholder={"Enter address"} />
            <Neo.FormGroup className='form-text' href='Address line 1' bind={this.props.currentBeneficiary.meta.addressLine1} placeholder={"Enter address"} />
            <Neo.FormGroup className='form-text' href='Address line 2' bind={this.props.currentBeneficiary.meta.addressLine2} placeholder={"Optional"} />
            <Neo.FormGroup className='form-text' href='Suburb' bind={this.props.currentBeneficiary.meta.suburb} placeholder={"Enter suburb"} />
            <div className="province-dropdown">
              <label className='custom-label'> Province/State </label>
              <Neo.AutoCompleteDropDown className='mt-0 mb-1'
                bind={this.props.currentBeneficiary.meta.provinceValueId}
                items={this.provinces}
                placeholder="Select province/state" />
            </div>
            <div className="province-dropdown">
              <label className='custom-label'> Country </label>
              <Neo.AutoCompleteDropDown className='mt-0 mb-1'
                bind={this.props.currentBeneficiary.meta.countryValueId}
                items={this.props.viewModel.countries}
                placeholder="Select Country"
              />
            </div>
            <Neo.FormGroup className='form-text' href='Postal code' bind={this.props.currentBeneficiary.meta.postalCode} placeholder={"Eg: 2199"} />
          </Neo.GridLayout>
        </div >
      </div >
    );
  }
}