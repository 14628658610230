import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../MemberDashboard/MemberDashboardTypes';
import FADashboardSearchResultsLookup from '../Models/Lookups/FADashboardSearchResultsLookup';
import FASearchCriteria from '../Models/Lookups/FASearchCriteria';

export interface IFADashboardApiClient {

  /** 
   * Returns the FaDashboardSearchResultsLookupListPaged.
   * @returns Paged List Of FaDashboardSearchResults.
   */
  getFaDashboardSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<FASearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<FADashboardSearchResultsLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class FADashboardApiClient extends Data.ApiClientBase implements IFADashboardApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/FADashboard`);
  }

  public getFaDashboardSearchResults(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<FASearchCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<FADashboardSearchResultsLookup>>> {
    return this.axios.get(`${this.apiPath}/fa-dashboard-search-results-lookup-list-paged?${Utils.getQueryString(request)}`);
  }

  // Client only properties / methods
}