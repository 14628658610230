import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import Underwriting from '../../Models/RiskBenefits/Underwriting';

export interface IRiskBenefitsCommandApiClient {

    /** 
     * Updates Underwriting Template of RiskBenefits
     * @returns Underwriting
     */
    updateUnderwritingTemplate(underwritingTemplate: Model.PartialPlainObject<Underwriting>): AxiosPromise<Model.PlainTrackedObject<Underwriting>>;

    // Client only properties / methods
}

@injectable()
export default class RiskBenefitsCommandApiClient extends Data.ApiClientBase implements IRiskBenefitsCommandApiClient {

    constructor (config = AppService.get(Types.App.Config)) {
        super(`${config.apiPath}/risk-benefits`);
    }

    public updateUnderwritingTemplate(underwritingTemplate: Model.PartialPlainObject<Underwriting>): AxiosPromise<Model.PlainTrackedObject<Underwriting>> {
        return this.axios.put(`${this.apiPath}/underwriting-template`, underwritingTemplate);
    }

    // Client only properties / methods
}