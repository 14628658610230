import { EnumHelper } from '@singularsystems/neo-core';

export enum ClaimTypeCategory {
    None = 0,
    Retirement = 1,
    Preserve = 2,
    PreserveAndCash = 3,
}

EnumHelper.decorateEnum(ClaimTypeCategory, decorator => {
    decorator.describe(ClaimTypeCategory.Retirement, "Retirement");
    decorator.describe(ClaimTypeCategory.Preserve, "Preserve");
    decorator.describe(ClaimTypeCategory.PreserveAndCash, "Preserve & cash ");
});