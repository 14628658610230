import React from 'react';
import { observer } from 'mobx-react';
import MemberIcon from './MemberIcon';
import '../../Styles/Components/InformationCard.scss';

interface IInformationCardProps {
  firstName?: string,
  lastName?: string,
  headingText: string,
  available: string,
  telHeading: string,
  tel: string,
  additionalTelHeading: string,
  additionalTel: string,
  faxHeading: string,
  fax: string,
  emailHeading: string,
  email: string,
  additionalEmailHeading: string,
  additionalEmail: string,
  postalAddressHeading: string,
  postalAddressName: string,
  postalAddressPOBox: string,
  postalAddressCity: string,
  postalAddressCityCode: string,
}

@observer
export default class InformationCard extends React.Component<IInformationCardProps> {

  constructor(props: IInformationCardProps) {
    super(props);
  }

  public render() {
    return (
      <div className='information-cards-cu'>
        <div className='cards-top-section-cu'>
          <MemberIcon firstName={this.props.firstName ?? 'c'} lastName={this.props.lastName ?? ''} className='icon-cu'></MemberIcon>
          <div className='information-heading-cards-cu'>{this.props.headingText}</div>
        </div>
        <div className='information-available-cards-cu'>{this.props.available} </div>
        <div className='line-cu bigger-card' />
        <div className='information-description-cards-cu'>{this.props.telHeading}</div>
        <div className={this.props.additionalTelHeading != '' ? 'information-detail-cards-cu remove-margin' : 'information-detail-cards-cu'}><a href={`tel:{this.props.tel}`}>{this.props.tel}</a></div>
        <div className='additional-tel-heading'>{this.props.additionalTelHeading}
          <a href={`tel:{this.props.tel}`}>{this.props.additionalTel}</a></div>
        <div className='information-description-cards-cu'>{this.props.faxHeading}</div>
        <div className='information-detail-cards-cu'><a href={`tel:{this.props.fax}`}>{this.props.fax}</a></div>
        <div className='information-description-cards-cu'>{this.props.emailHeading}</div>
        {(this.props.additionalEmailHeading !== '') &&
          <div className='information-description-cards-cu additional-email-heading mt-2'>Complaints:</div>
        }
        <div className='information-detail-cards-cu'><a href={`mailto:${this.props.email}`}>{this.props.email}</a>
        </div>
        <div className='information-description-cards-cu additional-email-heading'>{this.props.additionalEmailHeading}</div>
        <div className='information-detail-cards-cu'><a href={`mailto:${this.props.email}`}>{this.props.additionalEmail}</a>
        </div>
        <div>
          <div className='information-description-cards-cu'>{this.props.postalAddressHeading}</div>
          {(this.props.postalAddressHeading !== '') &&
            <p className='information-detail-adress-cards-cu'>
              {this.props.postalAddressName} <br></br>
              {this.props.postalAddressPOBox} <br></br>
              {this.props.postalAddressCity}  <br></br>
              {this.props.postalAddressCityCode}
            </p>
          }
        </div>
      </div>
    );
  }
}