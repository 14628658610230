import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import EducationalInformationTemplate from '../../Domain/Models/EducationalInformation/EducationalInformationTemplate';
import { AppService, Types } from '../Services/AppService';
import KnowledgeAndFormsView from './KnowledgeAndFormsView';


@NeoModel
export default class UsefulLinksVM extends Views.ViewModelBase {

  public isAdmin = false;
  public showEditTemplateModel = false;
  public educationalInformationTemplates = new List(EducationalInformationTemplate);
  public usefulLinkTemplate = new EducationalInformationTemplate();

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private educationalInformationCommandApiClient = AppService.get(Types.Domain.ApiClients.EducationalInformationCommandApiClient),
    private educationalInformationQueryApiClient = AppService.get(Types.Domain.ApiClients.EducationalInformationQueryApiClient)) {

    super(taskRunner);
  }

  public async initialise() {
    await this.getEducationalInformationTemplates();
    await this.refreshUsefulLinksTemplates();
  }

  public hideTemplateModel() {
    this.refreshUsefulLinksTemplates();
    this.showEditTemplateModel = false;
  }

  public getEducationalInformationTemplates = async () => {
    const response = await this.educationalInformationQueryApiClient.getEducationalInformationTemplates();
    this.educationalInformationTemplates.set(response.data);
  }

  public getUsefulLinksTemplate = async () => {
    const response = await this.educationalInformationQueryApiClient.getEducationalInformationTemplate(1);
    this.usefulLinkTemplate.set(response.data);
  }

  public async refreshUsefulLinksTemplates() {
    const response = await this.educationalInformationQueryApiClient.getEducationalInformationTemplate(1);
    this.usefulLinkTemplate.set(response.data);
  }

  public updateUsefulLinksContent = async () => {
    this.taskRunner.run(async () => {
      const converted = this.usefulLinkTemplate.toJSObject({ includeClean: true, suppressTrackingState: false, suppressChildren: false });
      let result: any = null;
      result = await this.educationalInformationCommandApiClient.updateEducationalInformationTemplate(converted);
      this.refreshUsefulLinksTemplates();
      this.showEditTemplateModel = !this.showEditTemplateModel;
    })
  }

  public homeClicked(): void {
    this.navigation.navigateToView(KnowledgeAndFormsView);
  }
}