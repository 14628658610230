import { AppServices } from '@singularsystems/neo-core';
import EmployerReportsAndDocumentsApiClient from './ApiClients/EmployerReportsAndDocumentsApiClient';
import EmployerComplaintsApiClient from './ApiClients/EmployerComplaintsApiClient';
import DocumentsApiClient from './ApiClients/DocumentsApiClient';
import { DashboardTypes } from './DashboardTypes';
import EmployerDashboardApiClient from './ApiClients/EmployerDashboardApiClient';
import { FADashboardTypes } from '../FADashboard/FADashboardTypes';
import FADashboardApiClient from '../FADashboard/ApiClients/FaDashboardApiClient';
import { StaffDashboardTypes } from '../StaffDashboard/StaffDashboardTypes';
import StaffDashboardApiClient from '../StaffDashboard/ApiClients/StaffDashboardApiClient';
import { MemberDashboardTypes } from '../MemberDashboard/MemberDashboardTypes';
import MemberDashboardApiClient from '../MemberDashboard/ApiClients/MemberDashboardApiClient';
import CallsAndNotificationsApiClient from '../MemberDashboard/ApiClients/CallsAndNotificationsApiClient';
import CallsAndInstructionsApiClient from '../MemberDashboard/ApiClients/CallsAndInstructionsApiClient';
import { LegacyMemberDashboardTypes } from '../LegacyMemberDashboard/LegacyMemberDashboardTypes';
import LegacyMemberDashboardApiClient from '../LegacyMemberDashboard/ApiClients/LegacyMemberDashboardApiClient';

export const DashboardModule = new AppServices.Module("Dashboard", container => {
  // Api Clients
  container.bind(DashboardTypes.ApiClients.EmployerReportsAndDocumentsApiClient).to(EmployerReportsAndDocumentsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.EmployerComplaintsApiClient).to(EmployerComplaintsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.DocumentsApiClient).to(DocumentsApiClient).inSingletonScope();
  container.bind(DashboardTypes.ApiClients.EmployerDashboardApiClient).to(EmployerDashboardApiClient).inSingletonScope();

  container.bind(MemberDashboardTypes.ApiClients.MemberDashboardApiClient).to(MemberDashboardApiClient).inSingletonScope();
  container.bind(MemberDashboardTypes.ApiClients.CallsAndNotificationsApiClient).to(CallsAndNotificationsApiClient).inSingletonScope();
  container.bind(MemberDashboardTypes.ApiClients.CallsAndInstructionsApiClient).to(CallsAndInstructionsApiClient).inSingletonScope();

  container.bind(StaffDashboardTypes.ApiClients.StaffDashboardApiClient).to(StaffDashboardApiClient).inSingletonScope();

  container.bind(FADashboardTypes.ApiClients.FADashboardApiClient).to(FADashboardApiClient).inSingletonScope();

  container.bind(LegacyMemberDashboardTypes.ApiClients.LegacyMemberDashboardApiClient).to(LegacyMemberDashboardApiClient).inSingletonScope();

  // Services
});