import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberComplaintLookup from '../../Models/LookUps/MemberQueries/MemberComplaintLookup';

export interface IMemberComplaintsCommandApiClient {

  /**
   * Inserts or updates a member complaint.
   * @param memberComplaint The member complaint to update.
   * @returns The updated member complaint.
   */
  insertUpdateMemberComplaint(memberComplaint: Model.PartialPlainObject<MemberComplaintLookup>): AxiosPromise<Model.PlainObject<MemberComplaintLookup>>;

  /**
   * Deletes a member complaint.
   * @param memberId The member id for the complaint to delete.
   * @param sourceId The source id
   */
  deleteMemberComplaint(memberId: number, sourceId: string): AxiosPromise;

  /** 
   * Deletes member complaint file.
   * @param memberId The member Id
   * @param sourceId The source id
   * @param fileName The file name for the file needed to get deleted.
   * @returns A message if the file was not deleted
   */
  deleteMemberComplaintFile(memberId: number, sourceId: string, fileName: string): AxiosPromise<string>;

  /**
   * Uploads a secure document
   * Note: This method returns the url of the api endpoint. It performs no logic.
   * @returns A member complaint
   */
  getUploadComplaintSecureDocumentUrl(): string;

  /**
   * Gets the stream of a secure file
   * @returns A  representing the result of the asynchronous operation.
   */
  getComplaintSecureDocument(fileDescriptorId: number, memberId: number, sourceId: string, fileName: string): AxiosPromise<Blob>;

  /**
   * Submits a member complaint.
   * @param memberComplaint The member complaint to submit.
   * @returns The member complaint.
   */
  submitMemberComplaint(memberComplaint: Model.PartialPlainObject<MemberComplaintLookup>): AxiosPromise<Model.PlainObject<MemberComplaintLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberComplaintsCommandApiClient extends Data.ApiClientBase implements IMemberComplaintsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-complaints`);
  }

  public insertUpdateMemberComplaint(memberComplaint: Model.PartialPlainObject<MemberComplaintLookup>): AxiosPromise<Model.PlainObject<MemberComplaintLookup>> {
    return this.axios.post(`${this.apiPath}/save-complaint`, memberComplaint);
  }

  public deleteMemberComplaint(memberId: number, sourceId: string): AxiosPromise {
    return this.axios.delete(`${this.apiPath}/complaint/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public deleteMemberComplaintFile(memberId: number, sourceId: string, fileName: string): AxiosPromise<string> {
    return this.axios.delete(`${this.apiPath}/file/${memberId}/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}`);
  }

  public getUploadComplaintSecureDocumentUrl(): string {
    return `${this.apiPath}/upload-document`;
  }

  public getComplaintSecureDocument(fileDescriptorId: number, memberId: number, sourceId: string, fileName: string): AxiosPromise<Blob> {
    return this.axios.get(`${this.apiPath}/document/${fileDescriptorId}/${memberId}/${encodeURIComponent(sourceId)}/${encodeURIComponent(fileName)}`, { responseType: "blob" });
  }

  public submitMemberComplaint(memberComplaint: Model.PartialPlainObject<MemberComplaintLookup>): AxiosPromise<Model.PlainObject<MemberComplaintLookup>> {
    return this.axios.post(`${this.apiPath}/submit-complaint`, memberComplaint);
  }

  // Client only properties / methods
}