import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import MemberWebInterfaceOptionLookup from '../../Models/WebInterfaceOptions/MemberWebInterfaceOptionsLookup';

export interface IMemberWebInterfaceOptionsQueryApiClient {

  /** 
   * Gets the first Interface Option.
   * @returns Returns the first WebInterface Options
   */
  getWebInterfaceOption(): AxiosPromise<Model.PlainTrackedObject<MemberWebInterfaceOptionLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberWebInterfaceOptionsQueryApiClient extends Data.ApiClientBase implements IMemberWebInterfaceOptionsQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/WebInterfaceOptions`);
  }

  public getWebInterfaceOption(): AxiosPromise<Model.PlainTrackedObject<MemberWebInterfaceOptionLookup>> {
    return this.axios.get(`${this.apiPath}/memberwebinterfaceoption`);
  }

  // Client only properties / methods
}