import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import MemberBenefitsLookup from '../../Models/LookUps/MemberBenefits/MemberBenefitsLookup';
import MemberDependentBenefitsLookup from '../../Models/LookUps/MemberDependentsBenefits/MemberDependentBenefitsLookup';

export interface IMemberBenefitsApiClient {

  /** 
   * Retrieves member benefits Lookup.
   * @param memberId Id
   * @returns MemberBenefitsLookup.
   */
  getMemberBenefits(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberBenefitsLookup>>;

  /** 
   * Retrieves member dependent benefits Lookup.
   * @param memberId Id
   * @returns MemberDependentsBenefitsLookup.
   */
  getMemberDependentsBenefits(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberDependentBenefitsLookup>>;

  /** 
   * GetIfFuneralExists
   */
  hasFuneralBenefits(): AxiosPromise<boolean>;

  // Client only properties / methods
}

@injectable()
export default class MemberBenefitsApiClient extends Data.ApiClientBase implements IMemberBenefitsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-benefits`);
  }

  public getMemberBenefits(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberBenefitsLookup>> {
    return this.axios.get(`${this.apiPath}/lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public getMemberDependentsBenefits(memberId: number, sourceId: string): AxiosPromise<Model.PlainObject<MemberDependentBenefitsLookup>> {
    return this.axios.get(`${this.apiPath}/dependents-lookup/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public hasFuneralBenefits(): AxiosPromise<boolean> {
    return this.axios.get(`${this.apiPath}/has-funeral-benefits`);
  }

  // Client only properties / methods
}