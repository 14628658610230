import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';

export interface ISystemInformationQueryApiClient {

  /** 
   * Gets the Assembly Version Lookup.
   * @returns A AssemblyVersionLookup.
   */
  getAssemblyVersionLookup(): AxiosPromise<Model.PlainObject<{ semVer: string, majorMinorPatch: string }>>;

  // Client only properties / methods
}

@injectable()
export default class SystemInformationQueryApiClient extends Data.ApiClientBase implements ISystemInformationQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/system-information`);
  }

  public getAssemblyVersionLookup(): AxiosPromise<Model.PlainObject<{ semVer: string, majorMinorPatch: string }>> {
    return this.axios.get(`${this.apiPath}/assembly-version-lookup`);
  }

  // Client only properties / methods
}