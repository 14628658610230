import React from 'react';
import { observer } from 'mobx-react';
import BeneficiariesVM from '../../Views/Beneficiaries/BeneficiariesVM';
import { Neo } from '@singularsystems/neo-react';
import warningYellow from '../../../App/assets/img/warning-yellow.png';
import edit from '../../../App/assets/img/edit.png';
import bin from '../../../App/assets/img/bin.png';
import valid from '../../../App/assets/img/img-valid.png';
import group from '../../../App/assets/img/group.png';
import greyPlus from '../../../MemberDashboard/Assets/img/greyPlus.png';
import NoBeneficiaries from './NoBeneficiaries';
import information_icon from '../../../App/assets/img/information-small.png';
import switch_icon from '../../../App/assets/img/switch.png';
import MemberIcon from '../../../App/Views/Components/MemberIcon';
import FileUploadBeneficiaries from './FileUploadBeneficiaries';
import ContainerFooterComponent from '../../../App/Views/Components/ContainerFooterComponent';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';

interface IBeneficiariesFuneralBenefitProps {
  viewModel: BeneficiariesVM
}

@observer
export default class BeneficiariesFuneralBenefit extends React.Component<IBeneficiariesFuneralBenefitProps> {

  constructor(props: IBeneficiariesFuneralBenefitProps) {
    super(props);
  }

  public render() {
    return (
      <div className='beneficiaries-approved-death-benefits'>
        <div className={this.props.viewModel.viewOnlyBeneficiary == true ? 'adding-margin-to-the-sides adding-margin-bottom' : 'adding-margin-to-the-sides'}>
          <div className='row alert-margin'>
            <div className='col-md-3'>
              <div className='benefit-info-link' onClick={() => this.props.viewModel.showFuneralBenefitInfoModal = true}>
                <img className='alert-approved-badb' src={information_icon} /> What is funeral benefits?
              </div>
            </div>
            <div className='col-md-9'>
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Beneficiaries outstanding
                </div>
              }
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 1 &&
                <div className='details-outstanding yellow-status-text'>
                  <img className='alert-approved-badb' src={warningYellow} /> Beneficiary 2 is outstanding
                </div>
              }
              {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 &&
                <div className='details-outstanding green-status-text'>
                  <img className='alert-approved-badb' src={valid} /> Up to date
                </div>
              }
            </div>
          </div>
          <BlueRibbon
            text='Your first beneficiary (Beneficiary 1) is the person you appoint to claim and receive your funeral policy benefits after
                          your death. He/She must be 18 years or older. If beneficiary 1 predeceases you,
                          the benefit will be payable to beneficiary 2. If for any reason both beneficiaries predecease you,
                          the benefit will be paid to your estate.'
          />
          <div className='col-md-12'>
            <Neo.Alert variant='success' className='alert-text-badb' bindShow={this.props.viewModel.meta.showApprovedAddedAlert} autoCloseTimeout={3000} >
              <div className='row'>
                <div className='col-1 alert-image-padding'>
                  <img className='alert-approved-badb' src={valid} />
                </div>
                <div className='col-10'>
                  <div className='alert-text-headging-badb'>Existing beneficiary added </div>
                  <div className='alert-text-headging-badb sub-heading'>The beneficiary has been added.</div>
                </div>
              </div>
            </Neo.Alert>
          </div>
          {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length === 0 &&
            <NoBeneficiaries viewModel={this.props.viewModel} />
          }
          {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length !== 0 &&
            <span>
              <div className='show-on-web-screen'>
                <div className='row table-heading-badb'>
                  <span className='col-3 card-heading-text funeral-full-name'>Full name</span>
                  <span className='col-2 card-heading-text '>ID/Passport Number</span>
                  <span className='col-2 card-heading-text '>Relationship</span>
                </div>
                {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).sortBy((item) => {
                  return item.sequence;
                }).map((item) => (
                  <Neo.Card className='approved-benefit-card-items' key={item.beneficiaryId} >
                    <div className='row'>
                      <span className='col-3 padding-beneficiary-number'>
                        <MemberIcon firstName={item.firstName} lastName={item.lastName} className='icon-cu-beneficiaries-table'></MemberIcon>
                        <span className='table-card-name'>{item.firstName} {item.lastName} - <span className='beneficiary-number'> Beneficiary {item.sequence}</span></span>
                      </span>
                      <span className='col-2 card-items-padding id-number'>{item.nationalIdNoOrPassportNo}</span>
                      <span className='col-4 card-items-padding relationship'>{item.relationshipType}</span>
                      {!this.props.viewModel.viewOnlyBeneficiary &&
                        <>
                          <span className='col-1 card-items-padding'>
                            <img src={edit} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => this.props.viewModel.editBeneficiary(item)}>
                              Edit
                            </Neo.Link>
                          </span>
                          <span className='col-2 card-items-padding delete'>
                            <img src={bin} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                var funeralBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);

                                if (funeralBeneficiary) {
                                  this.props.viewModel.selectedFuneralBenefitBeneficiary = funeralBeneficiary;
                                  this.props.viewModel.showFuneralDeletedModal = true;
                                }
                              }}>
                              Remove
                            </Neo.Link>
                          </span>
                        </>
                      }
                    </div>
                  </Neo.Card>
                ))}
              </div>
              <div className='show-on-phone-screen'>
                {this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).sortBy((item) => {
                  return item.sequence;
                }).map((item) => (
                  <Neo.Card className='approved-benefit-card-items funeral-benefit-height' key={item.beneficiaryId}>
                    <div className='row'>
                      <span className='col-5 card-text-phone-screen'>Fullname </span>
                      <span className='col-7 padding-phone table-card-name'>{item.firstName} {item.lastName} - <span className='beneficiary-number'> Beneficiary {item.sequence}</span></span>
                      <span className='col-5 card-text-phone-screen'>ID number</span><span className='col-7 padding-phone'>{item.nationalIdNoOrPassportNo}</span>
                      <span className='col-5 card-text-phone-screen'>Relationship</span><span className='col-7 padding-phone'>{item.relationshipType}</span>
                      {!this.props.viewModel.viewOnlyBeneficiary &&
                        <>
                          <div className='line-badb funeral-beneficiaries-line-padding' />
                          <span className='col-4'>
                            <img src={edit} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => this.props.viewModel.editBeneficiary(item)}>
                              Edit
                            </Neo.Link>
                          </span>
                          <span className='col-5 bin-image-phone'>
                            <img src={bin} className='edit-image-size' />
                            <Neo.Link className='link-format'
                              onClick={() => {
                                var funeralBeneficiary = this.props.viewModel.knownBeneficiaries.find(x => x.beneficiaryId == item.beneficiaryId);

                                if (funeralBeneficiary) {
                                  this.props.viewModel.selectedFuneralBenefitBeneficiary = funeralBeneficiary;
                                  this.props.viewModel.showFuneralDeletedModal = true;
                                }
                              }
                              }>
                              Remove
                            </Neo.Link>
                          </span>
                        </>
                      }
                    </div>
                  </Neo.Card>
                ))}
              </div>
              {!this.props.viewModel.viewOnlyBeneficiary &&
                <div className={this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 ? 'greyed-out-block' : 'blue-block'}>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Neo.Link className='blue-block-text'
                        onClick={() => this.props.viewModel.selectExistingBeneficiary()} >
                        Select existing beneficiary
                      </Neo.Link>
                      <span className='line-break'><br></br></span>
                      <span className='or-text'> or</span>
                      <span className='line-break'><br></br></span>
                      <img src={this.props.viewModel.funeralBeneficiaryGrouping.nominationData.filter(nomination => nomination.isDeleted == false).length > 1 ? greyPlus : group} className="edit-image-size padding" />
                      <Neo.Link
                        onClick={() => this.props.viewModel.initiateAddNewBeneficiary()}
                        className='add-text'>
                        Add new beneficiary
                      </Neo.Link>
                    </div>
                  </div>
                </div>
              }
            </span>
          }
          <div className={this.props.viewModel.viewOnlyBeneficiary == true ? 'switch-beneficiaries adding-margin-top' : 'switch-beneficiaries'}>
            <Neo.Button className='switch-beneficiaries-button' onClick={() => this.props.viewModel.switchBeneficiariesAround()}>
              <img src={switch_icon} /> Switch beneficiaries
            </Neo.Button>
          </div>
        </div>
        {!this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <FileUploadBeneficiaries viewModel={this.props.viewModel} />
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedFuneral.Role + ' - ' + this.props.viewModel.lastModifiedFuneral.UserName}</span>
            </div>
            {this.props.viewModel.funeralBeneficiaryGrouping.isActiveInterim &&
              <div className='align-changes-text'>
                The values on this screen are incomplete and unsubmitted
              </div>
            }
          </div>
        }
        {this.props.viewModel.viewOnlyBeneficiary &&
          <div>
            <div className='last-updated-by'>
              Last updated by: &nbsp; <span className='employer'>{this.props.viewModel.lastModifiedFuneral.Role + ' - ' + this.props.viewModel.lastModifiedFuneral.UserName}</span>
            </div>
          </div>
        }
        <ContainerFooterComponent
          showPrimaryButton={true}
          showSecondaryButton={true}
          backLinkText='Save & exit'
          primaryButtonText='Update'
          primaryButtonCallback={() => this.props.viewModel.verifyUpdatedNominations()}
          disablePrimaryButton={this.props.viewModel.funeralBeneficiaryGrouping.nominationData.length === 0 ? true : false}
        />
        <Neo.Modal title='What is funeral death benefits?'
          acceptButton={{ text: "Okay", variant: "light", onClick: () => this.props.viewModel.showFuneralBenefitInfoModal = false }}
          closeButton={false}
          bind={this.props.viewModel.meta.showFuneralBenefitInfoModal}>
          This is a benefit payable in the event of death as defined in the group policy. These benefits are paid according to the signed nomination of beneficiary
          form that is on record with your employer. If a completed nomination of beneficiary form is not on record, the funeral benefit is payable to your estate in terms of the policy terms and conditions.
        </Neo.Modal>
        <Neo.Modal
          bind={this.props.viewModel.meta.showFuneralDeletedModal}
          title='Remove beneficiary'
          acceptButton={{ text: "Yes", variant: "primary", onClick: () => this.props.viewModel.deleteFuneralBeneficiary(this.props.viewModel.selectedFuneralBenefitBeneficiary) }}
          closeButton={{ text: "No", variant: "danger", onClick: () => this.props.viewModel.showFuneralDeletedModal = false }}>
          By removing beneficiary 1, you are agreeing that beneficiary 2 will be appointed to claim and receive your funeral policy benefits after your death.
        </Neo.Modal>
      </div>
    );
  }
}