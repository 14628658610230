import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class MemberContributionDetailsContributionGetMemberBillingLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  @Attributes.Nullable()
  @Attributes.Float()
  public amount: number | null = null;

  public groupName: string | null = null;

  // Client only properties / methods

  public order: number = 0;
}