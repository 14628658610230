import { ModalUtils, Routing as NeoRouting } from '@singularsystems/neo-core';
import { Neo, Routing } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import NotFound from '../Components/404NotFound';
import OidcLoginRedirect from '../Views/Security/OidcLoginRedirect';
import { AppConfig } from '../Models/AppConfig';
import InstructionsView from '../Views/InstructionsView';
import img_Calculator from "../assets/img/calculator.png";
import img_Dashboard from "../assets/img/primary-dashboard.png";
import img_Clipboard from "../assets/img/clipboard-instructions.png";
import img_Formfill from "../assets/img/form-fill.png";
import img_Reports from "../assets/img/generic.png";
import img_Phone from "../assets/img/phone-1.png";
import img_Queries from '../assets/img/icn-questionmark-circle-updated.png';
import img_Mail from '../assets/img/mail-small.png';
import img_Outline from '../assets/img/outline.png';
import QueriesView from '../Views/QueriesAndComplimentsView';
import ComplaintsView from '../Views/ComplaintsView';
import ContactUsView from '../Views/ContactUsView';
import ProfileView from '../Views/MyPortfolio/ProfileView';
import ValueAddedServicesView from '../Views/ValueAddedServicesView';
import ProductsView from '../../MemberDashboard/Views/ProductsView';
import ReportsAndDocumentsView from '../Views/ReportsAndDocumentsView';
import NotificationsView from '../Views/NotificationsView';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import FADashboardView from '../../FADashboard/Views/FADashboardView';
import StaffDashboardView from '../../StaffDashboard/Views/StaffDashboardView';
import MemberDashboardView from '../../MemberDashboard/Views/MemberDashboardView';
import KnowledgeAndFormsView from '../Views/KnowledgeAndFormsView';
import TAndCView from '../Views/TAndCView';
import TAndCMaintenanceView from '../Views/Maintenance/TAndCMaintenanceView';
import KnowledgeCentreMaintenanceView from '../Views/Maintenance/KnowledgeCentreMaintenanceView';
import FundFactSheetMaintenanceView from '../Views/Maintenance/FundFactSheetMaintenanceView';
import EmployerDashboardView from '../../Dashboard/Views/EmployerDashboardView';
import ContactUsMaintenance from '../Views/Maintenance/ContactUsMaintenanceView';
import EmployerSchemeView from '../../Dashboard/Views/EmployerSchemeView';
import BillingView from '../../Dashboard/Views/EmployerBillingView';
import EmployerReportsAndDocumentsView from '../../Dashboard/Views/EmployerReportsAndDocumentsView';
import EmployerProfileView from '../../Dashboard/Views/EmployerProfileView';
import UsefulLinksView from '../Views/UsefulLinksView';
import EducationalMaterialView from '../Views/EducationalMaterialView';
import EmployerComplaintsView from '../../Dashboard/Views/EmployerComplaintsView';
import EmployerQueriesComplimentsView from '../../Dashboard/Views/EmployerQueriesComplimentsView';
import EmployerDashboardNewView from '../../Dashboard/Views/EmployerDashboardNewView';
import LegacyMemberDashboardView from '../../LegacyMemberDashboard/Views/LegacyMemberDashboardView';
import MyGoalsDetailsView from '../../MemberDashboard/Views/MyGoalsDetailsView';
import img_star from '../assets/img/star.png';
import img_logout from '../assets/img/logout.png';
import UnderWritingView from '../Views/MyPortfolio/UnderWritingView';
import NonEditableProfileView from '../Views/MyPortfolio/NonEditableProfileView';
import IModalOptions from "@singularsystems/neo-core/dist/Components/Modal/ModalOptions";
import '../Styles/RouteService.scss'
import * as IdentityRoutes from '../../Identity/IdentityRoutes';
import SavingsWithdrawalClaimMaintenanceView from '../Views/Maintenance/SavingsWithdrawalClaimMaintenanceView';
import MemberWebInterfaceMaintenanceView from '../Views/Maintenance/MemberWebInterfaceMaintenanceView';
import SavingsWithdrawalClaimView from '../../Party/Views/SavingsWithdrawalClaimView';
import SavingsWithdrawalClaimOverviewView from '../../Party/Views/SavingsWithdrawalClaimOverviewView';
import ClaimsDeclarationsMaintenanceView from '../Views/Maintenance/ClaimDeclarationMaintenanceView';
import RiskBenefitsMaintenanceVM from '../Views/Maintenance/RiskBenefitsMaintenanceVM';
import RiskBenefitsMaintenanceView from '../Views/Maintenance/RiskBenefitsMaintenanceView';
import ValueAddedServiceMaintenanceVM from '../Views/Maintenance/ValueAddedServiceMaintenanceVM';
import ValueAddedServiceMaintenanceView from '../Views/Maintenance/ValueAddedServiceMaintenanceView';
import EmployerMemberProfileView from '../../Dashboard/Views/EmployerMemberProfileView';

interface ICommonAppRouteProps {
  /** TODO: Add any custom route props here, like userType. */
}

export interface IAppRoute extends NeoRouting.IRoute, ICommonAppRouteProps {

}
export interface IAppMenuItem extends NeoRouting.IMenuRoute, ICommonAppRouteProps {
  onClick?: () => void
}

@injectable()
export class RouteService {

  private routeProvider: Routing.RouteProvider;

  constructor(private config = AppService.get(Types.App.Config),
    private authService = AppService.get(Types.App.Services.AuthenticationService)) {

    this.routeProvider = new Routing.RouteProvider(
      this.getMenuRoutes(),
      this.getPureRoutes(),
      NotFound,
    )
  }

  /**
   * Gets the routes provider
   */
  public get routes(): Routing.RouteProvider {
    return this.routeProvider;
  }

  public async showModal() {
    const modalOptions: Partial<IModalOptions> = {}
    modalOptions.closeButton = false;

    ModalUtils.showYesNo("Are you sure you want to log out?",
      <p className="body-text">By logging out you will be redirected to the inital login page.</p>, (() => this.goToLogOutPage())
    );
  }

  private goToLogOutPage() {
    this.authService.beginSignOut();
  }

  private getMenuRoutes(): IAppMenuItem[] {
    return [
      ...this.getMainMenuRoutes(),
      ...this.getSupportRoutes(),
      ...this.getSettingRoutes(),
      ...this.getEmployerRoutes(),
      ...this.getEmployerSupportRoutes(),
      ...this.getFARoutes(),
      ...this.getStaffRoutes(),
      ...this.getLegacyMemberRoutes()
    ]
  }

  private getPureRoutes(): IAppRoute[] {
    const pureRoutes =
      [
        {
          path: AppConfig.loginRedirectRoute, component: OidcLoginRedirect, allowAnonymous: true
        },
        {
          path: "/Notifications", component: NotificationsView, allowAnonymous: true
        },
        {
          path: "/Reports", component: ReportsAndDocumentsView, allowAnonymous: true
        },
        {
          path: "/TAndC", component: TAndCView, allowAnonymous: true
        },
        // TODO: uncomment for R2.2
        // {
        //   path: "/MyBeneficiaries", component: BeneficiariesView
        // },
        {
          path: "/UsefulLinks", component: UsefulLinksView, allowAnonymous: true
        },
        {
          path: "/EducationalMaterial", component: EducationalMaterialView, allowAnonymous: true
        },
        {
          path: "/MyGoalsDetail", component: MyGoalsDetailsView, allowAnonymous: true
        },
        {
          path: "/SavingsWithdrawalClaim", component: SavingsWithdrawalClaimView, allowAnonymous: true
        },
        {
          path: "/SavingsWithdrawalClaimOverview", component: SavingsWithdrawalClaimOverviewView, allowAnonymous: true
        },
        {
          path: "/NotFound", component: NotFound, allowAnonymous: true
        },
        //TODO: uncomment for R2.2
        // {
        //   path: "/EmployerMemberProfile", component: EmployerMemberProfileView, allowAnonymous: true
        // }
        // end of this comment section

        // ...DomainRoutes.PureRoutes,
        // ...ReportingRoutes.PureRoutes,
      ];

    return pureRoutes;
  }

  public getMainMenuRoutes(): IAppMenuItem[] {
    return [
      {
        name: "My dashboard", path: '/MyPortfolio', icon: img_Dashboard, component: MemberDashboardView, children: [
          { name: "My Profile", path: "/MyProfile", component: ProfileView },
          // { name: "My Beneficiaries", path: "/MyBeneficiaries", component: BeneficiariesView }, //TODO: uncomment for R2.2
          { name: "My Underwriting", path: "/MyUnderwriting", component: UnderWritingView },
          { name: "My Products", path: "/MyProducts", component: ProductsView },
        ]
      },
      {
        name: "Retirement calculator", path: '/RetirementCalculator', component: TAndCView, icon: img_Calculator, exact: true, allowAnonymous: false
      },
      {
        name: "Instructions", path: '/Instructions', component: InstructionsView, icon: img_Clipboard
      },
      {
        name: "Knowledge centre", path: "/KnowledgeCentre", icon: img_Formfill, component: KnowledgeAndFormsView
      },
      {
        name: "Reports", path: "/Reports", component: ReportsAndDocumentsView, icon: img_Reports
      },
    ]
  }

  public getSupportRoutes(): IAppMenuItem[] {
    return [
      {
        name: "Contact us", path: '/ContactUs', component: ContactUsView, icon: img_Phone, exact: true, allowAnonymous: true
      },
      {
        name: "Queries & compliments", path: '/QueriesAndCompliments', component: QueriesView, exact: true, icon: img_Queries, allowAnonymous: true
      },
      {
        name: "Complaints", path: '/Complaints', component: ComplaintsView, exact: true, icon: img_Mail, allowAnonymous: true
      },
      {
        name: "Value-added services", path: '/ValueAddedServices', component: ValueAddedServicesView, exact: true, allowAnonymous: true, icon: img_star
      },
      // {
      //   name: "Maintenance", exact: true, allowAnonymous: true, icon: img_Outline, children: [
      //     { name: "VAS", path: "/Maintenance/VAS", component: ValueAddedServiceMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "Fund Fact Sheets", path: "/Maintenance/FundFactSheet", component: FundFactSheetMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "KC Maintenance", path: "/Maintenance/KC", component: KnowledgeCentreMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "T&C Maintenance", path: "/Maintenance/TAndCMaintenance", component: TAndCMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "Contact Us", path: "/Maintenance/ContactUs", component: ContactUsMaintenance, allowAnonymous: true, icon: img_Outline },
      //     { name: "Web Interface Options Maintenance", path: "/Maintenance/WebInterfaceOptions", component: MemberWebInterfaceMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "Savings Withdrawal Claims", path: "/Maintenance/SavingsWithdrawalClaimMaintenanceView", component: SavingsWithdrawalClaimMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "Claims Declarations", path: "/Maintenance/ClaimsDeclarationsMaintenanceView", component: ClaimsDeclarationsMaintenanceView, allowAnonymous: true, icon: img_Outline },
      //     { name: "Risk Benefits", path: "/Maintenance/RiskBenefits", component: RiskBenefitsMaintenanceView, allowAnonymous: true, icon: img_Outline }
      //   ]
      // },
    ]
  }

  public getSettingRoutes(): IAppMenuItem[] {
    let settingsRoutes = [
      {
        name: "Logout", icon: img_logout, onClick: () => { this.showModal() }
      },
      ...IdentityRoutes.IdentityMenuRoutes
    ];
    if (this.config.isDevelopment) {
      settingsRoutes.push(IdentityRoutes.SecurityConfigRoute);
    }
    return settingsRoutes;
  }

  public getEmployerRoutes(): IAppMenuItem[] {
    return [
      //TODO: uncomment for R2.2
      // {
      //   name: "Dashboard", path: '/EmployerDashboard', component: EmployerDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Dashboard New", path: '/EmployerDashboardNew', component: EmployerDashboardNewView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Scheme", path: '/EmployerScheme', component: EmployerSchemeView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Billing", path: '/Billing', component: BillingView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Profile", path: '/EmployerProfile', component: EmployerProfileView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Reports & documents", path: '/EmployerReportsAndDocuments', component: EmployerReportsAndDocumentsView, icon: img_Reports, exact: true, allowAnonymous: true
      // },
    ]
  }

  public getEmployerSupportRoutes(): IAppMenuItem[] {
    return [
      //TODO: uncomment for R2.2
      // {
      //   name: "Queries & Compliments", path: '/EmployerQueriesAndCompliments', component: EmployerQueriesComplimentsView, icon: img_Queries, exact: true, allowAnonymous: true
      // },
      // {
      //   name: "Complaints", path: '/EmployerComplaints', component: EmployerComplaintsView, icon: img_Mail, exact: true, allowAnonymous: true
      // },
    ]
  }

  public getFARoutes(): IAppMenuItem[] {
    return [
      //TODO: uncomment for R2.2
      // {
      //   name: "Dashboard", path: '/FADashboard', component: FADashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
    ]
  }

  public getStaffRoutes(): IAppMenuItem[] {
    return [
      //TODO: uncomment for R2.2
      // {
      //   name: "Dashboard", path: '/StaffDashboard', component: StaffDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true
      // },
    ]
  }

  public getLegacyMemberRoutes(): IAppMenuItem[] {
    return [
      {
        name: "My dashboard", path: '/', component: LegacyMemberDashboardView, icon: img_Dashboard, exact: true, allowAnonymous: true, children: [
          { name: "My profile", path: "/MyProfile", component: NonEditableProfileView },
          { name: "My products", path: "/MyProducts", component: ProductsView },
          //{ name: "My beneficiaries", path: "/MyBeneficiaries", component: BeneficiariesView }, //TODO: uncomment for R2.2
        ]
      },
      {
        name: "Knowledge centre", path: "/KnowledgeCentre", icon: img_Formfill, component: KnowledgeAndFormsView
      },
      {
        name: "Statements & reports", path: "/StatementsAndReports", component: ReportsAndDocumentsView, icon: img_Reports
      }
    ]
  }
}