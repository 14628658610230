import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import KCTemplate from '../../Models/KnowledgeCentre/KCTemplate';
import KCTemplateLookup from '../../Models/KnowledgeCentre/KCTemplateLookup';

export interface IKCQueryApiClient {

  /**
   * Gets a KC template.
   * @returns A KC template.
   */
  getKCTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<KCTemplate>>;

  /**
   * Gets the KC templateLookup.
   * @returns A KCTemplateLookup.
   */
  getKCTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<KCTemplateLookup>>;

  /**
   * Gets the KC templates.
   * @returns A list of KC templates.
   */
  getKCTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<KCTemplate>>>;

  /**
   * Gets the KC templates.
   * @returns A list of KC templates.
   */
  getKCTemplateLookups(): AxiosPromise<Array<Model.PlainObject<KCTemplateLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class KCQueryApiClient extends Data.ApiClientBase implements IKCQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/kc`);
  }

  public getKCTemplate(templateId: number): AxiosPromise<Model.PlainTrackedObject<KCTemplate>> {
    return this.axios.get(`${this.apiPath}/kc-template/${templateId}`);
  }

  public getKCTemplateLookup(templateId: number): AxiosPromise<Model.PlainObject<KCTemplateLookup>> {
    return this.axios.get(`${this.apiPath}/kc-template-lookup/${templateId}`);
  }

  public getKCTemplates(): AxiosPromise<Array<Model.PlainTrackedObject<KCTemplate>>> {
    return this.axios.get(`${this.apiPath}/kc-templates`);
  }

  public getKCTemplateLookups(): AxiosPromise<Array<Model.PlainObject<KCTemplateLookup>>> {
    return this.axios.get(`${this.apiPath}/kc-template-lookups`);
  }

  // Client only properties / methods
}