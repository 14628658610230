import React from 'react';
import { observer } from 'mobx-react';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import RiskBenefitCard from './RiskBenefitCard';
import { Model } from '@singularsystems/neo-core';
import MemberDependentsBenefitDetailsLookup from '../../../Party/Models/LookUps/MemberDependentsBenefits/MemberDependentsBenefitDetailsLookup';
import { Neo } from '@singularsystems/neo-react';
import BenefitType from '../../../Party/Models/Enums/BenefitType';

interface IDeathOfSpouseBenefitsProps {
  spouseDependentBenefitDetail: MemberDependentsBenefitDetailsLookup | undefined,
  spouseBenefitGroup: BenefitGroup | undefined,
}

@observer
export default class DeathOfSpouseBenefits extends React.Component<IDeathOfSpouseBenefitsProps> {

  constructor(props: IDeathOfSpouseBenefitsProps) {
    super(props);
  }

  public CreateSpouseDependentBenefitGroup(): BenefitGroup | undefined {
    let dependentBenefitGroup: BenefitGroup | undefined;
    if (this.props.spouseDependentBenefitDetail) {
      dependentBenefitGroup = new BenefitGroup("Funeral benefit your spouse's cover", 0)
      dependentBenefitGroup.benefitTotal = this.props.spouseDependentBenefitDetail?.benefitAmount ?? 0;
      dependentBenefitGroup.tableText = "An amount payable towards the deceased spouse's funeral expenses of";
      dependentBenefitGroup.disclaimer = "If your nominated spouse passes away while you are still employed, the following lump sum will become payable to you";
      dependentBenefitGroup.isExpanded = new Model.ObservableValue(false);
      return dependentBenefitGroup;
    }
    else {
      return dependentBenefitGroup;
    }
  }

  public CreateSpouseBenefitGroup(): BenefitGroup | undefined {
    let benefitGroup: BenefitGroup | undefined;
    if (this.props.spouseBenefitGroup) {
      benefitGroup = new BenefitGroup(BenefitType.spouseDeathBenefit, 0)
      benefitGroup.benefitTotal = this.props.spouseBenefitGroup?.benefitTotal ?? 0;
      benefitGroup.tableText = "Spouse's death benefit amount of";
      benefitGroup.disclaimer = "If your nominated spouse passes away while you are still employed, the following lump sum will become payable to you";
      benefitGroup.isExpanded = new Model.ObservableValue(false);
      return benefitGroup;
    }
    else {
      return benefitGroup;
    }
  }

  public render() {
    let memberBenefitCategory = new BenefitGroup("Risk benefits payable on the death of a nominated spouse", 0);
    let spouseDependentBenefitGroup = this.CreateSpouseDependentBenefitGroup();
    let memberBenefitGroup = this.CreateSpouseBenefitGroup();
    return (
      <div>
        {
          <div>
            {
              <Neo.Card
                title={memberBenefitCategory.benefitType}
                className="cards-risk cards-headings-risk"
                isExpanded={memberBenefitCategory.isExpanded}
              >
                <div className='grey-box-risk'>
                  These are benefits that may become payable should your spouse pass away while you are still employed.
                </div>
                <br />
                {
                  spouseDependentBenefitGroup && spouseDependentBenefitGroup.benefitTotal !== null && spouseDependentBenefitGroup.benefitTotal > 0 &&
                  <RiskBenefitCard
                    benefitGroup={spouseDependentBenefitGroup}
                    disclaimer={spouseDependentBenefitGroup.disclaimer}
                    tableText={spouseDependentBenefitGroup.tableText}
                  />
                }
                {
                  memberBenefitGroup && memberBenefitGroup.benefitTotal !== null && memberBenefitGroup.benefitTotal > 0 &&
                  <RiskBenefitCard
                    benefitGroup={memberBenefitGroup}
                    disclaimer={memberBenefitGroup.disclaimer}
                    tableText={memberBenefitGroup.tableText}
                  />
                }
              </Neo.Card>
            }
          </div>
        }
      </div>
    );
  }
}