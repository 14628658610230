import { AppServices } from '@singularsystems/neo-core';
import EmployerDashboardApiClient from './ApiClients/EmployerDashboardApiClient';
import EmployerReportsAndDocumentsApiClient from './ApiClients/EmployerReportsAndDocumentsApiClient';
import DocumentsApiClient from './ApiClients/DocumentsApiClient';
import EmployerComplaintsApiClient from './ApiClients/EmployerComplaintsApiClient';

export const DashboardExportedTypes = {
  ApiClients: {
    EmployerDashboardApiClient: new AppServices.ServiceIdentifier<EmployerDashboardApiClient>("Dashboard.ApiClient.EmployerDashboardApiClient"),
    EmployerComplaintsApiClient: new AppServices.ServiceIdentifier<EmployerComplaintsApiClient>("Dashboard.ApiClient.EmployerComplaintsApiClient"),
    DocumentsApiClient: new AppServices.ServiceIdentifier<DocumentsApiClient>("Dashboard.ApiClient.DocumentsApiClient"),
    EmployerReportsAndDocumentsApiClient: new AppServices.ServiceIdentifier<EmployerReportsAndDocumentsApiClient>("Dashboard.ApiClient.EmployerReportsAndDocumentsApiClient"),
  },
  Services: {
    // Add Services here
  },
};