import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import EducationalMaterialVM from './EducationalMaterialVM';
import { observer } from 'mobx-react';
import RichTextEditor from './Components/RichTextEditor';
import '../Styles/Components/EducationalMaterial.scss';
import { UserType } from '../../Enums/UserType.enum';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class EducationalMaterialView extends Views.ViewBase<EducationalMaterialVM> {

  constructor(props: unknown) {
    super("Educational material", EducationalMaterialVM, props);
  }

  public render() {
    return (
      <div className='educational-material-container'>
        <div className='container'>
          {(this.viewModel.isAdmin || this.viewModel.userType == UserType.Member) &&
            <div className='mt-4 educational-material-card'>
              <div className='row'>
                <div className='form-group align-header col-12 col-md-12'>
                  <div className='card-header-ul'> Educational material</div>
                  {this.viewModel.isAdmin &&
                    <Neo.Button text="Edit" className='align-edit-button' onClick={() => this.viewModel.showEditTemplateModel = true} />
                  }
                </div>
              </div>
              <div className='col-md-12'>
                <div className='line-ul'></div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <Neo.Card className='educational-links-card'>
                    <div className='row' spellCheck={false}>
                      <RichTextEditor height='100%' autoFocus={false} readOnly hideToolbar bind={this.viewModel.educationalMaterialTemplateMember.meta.templateContent}></RichTextEditor>
                    </div>
                  </Neo.Card>
                </div>
              </div>
              {!this.viewModel.isAdmin &&
                <ContainerFooterComponent
                  backLinkText='Back'
                  backLinkCallback={() => this.viewModel.homeClicked()}
                />
              }
            </div>
          }
          {(this.viewModel.isAdmin || this.viewModel.userType !== UserType.Member) &&
            <div className='mt-4 educational-material-card'>
              <div className='row'>
                <div className='form-group align-header col-12 col-md-12'>
                  <div className='card-header-ul'> Educational material Employer</div>
                  {this.viewModel.isAdmin &&
                    <Neo.Button text="Edit" className='align-edit-button' onClick={() => this.viewModel.showEmployerEditTemplateModel = true} />
                  }
                </div>
              </div>
              <div className='col-md-12'>
                <div className='line-ul'></div>
              </div>

              <div className='row'>
                <div className='col-12 col-md-12'>
                  <Neo.Card className='educational-links-card'>
                    <div className='row' spellCheck={false}>
                      <RichTextEditor height='100%' autoFocus={false} readOnly hideToolbar bind={this.viewModel.educationalMaterialTemplateEmployer.meta.templateContent}></RichTextEditor>
                    </div>
                  </Neo.Card>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <div className='line-ul'></div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <ContainerFooterComponent
                    backLinkText='Back'
                    backLinkCallback={() => this.viewModel.homeClicked()}
                  />
                </div>
              </div>
            </div>
          }

        </div>
        <Neo.Modal title="Educational Material Template Editor"
          size="xl"
          show={this.viewModel.showEditTemplateModel}
          onClose={() => this.viewModel.hideTemplateModel()}
          className="tandc-template-modal"
          footer={
            <div className='pull-right'>
              <Neo.Button
                variant='primary'
                icon={"fa-save"}
                onClick={() => this.viewModel.updateEducationalMaterialMemberContent()}
              >
                Save
              </Neo.Button>
            </div>
          }>
          <Neo.Loader task={this.viewModel.taskRunner}>
            <div className='row'>
              <div className='col-12'>
                <RichTextEditor bind={this.viewModel.educationalMaterialTemplateMember.meta.templateContent}></RichTextEditor>
              </div>
            </div>
          </Neo.Loader>
        </Neo.Modal>

        <Neo.Modal title="Educational Material Template Employer Editor"
          size="xl"
          show={this.viewModel.showEmployerEditTemplateModel}
          onClose={() => this.viewModel.hideTemplateEmployerModel()}
          className="tandc-template-modal"
          footer={
            <div className='pull-right'>
              <Neo.Button
                variant='primary'
                icon={"fa-save"}
                onClick={() => this.viewModel.updateEducationalMaterialEmployerContent()}
              >
                Save
              </Neo.Button>
            </div>
          }>
          <Neo.Loader task={this.viewModel.taskRunner}>
            <div className='row'>
              <div className='col-12'>
                <RichTextEditor bind={this.viewModel.educationalMaterialTemplateEmployer.meta.templateContent}></RichTextEditor>
              </div>
            </div>
          </Neo.Loader>
        </Neo.Modal>
      </div>
    );
  }
}