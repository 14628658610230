import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import SavingsClaimDataCaptureLookup from '../../Models/LookUps/MemberClaims/SavingsClaimDataCaptureLookup';
import MemberCriteriaLookup from '../../Models/LookUps/MemberClaims/MemberCriteriaLookup';

export interface ISavingsClaimQueryApiClient {

    /** 
     * Gets a members savings claim.
     * @param memberCriteriaLookup The member criteria lookup
     * @returns A member query.
     */
    getMemberSavingsClaim(memberCriteriaLookup: Model.PartialPlainObject<MemberCriteriaLookup>): AxiosPromise<Model.PlainObject<SavingsClaimDataCaptureLookup>>;

    /** 
     * Gets a the active status of a savings claim
     * @param memberCriteriaLookup The member criteria lookup
     * @returns A member query.
     */
    isMemberSavingsClaimActive(memberCriteriaLookup: Model.PartialPlainObject<MemberCriteriaLookup>): AxiosPromise<boolean>;
  }

@injectable()
export default class SavingsClaimQueryApiClient extends Data.ApiClientBase implements ISavingsClaimQueryApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-savings-claim-queries`);
  }

    public getMemberSavingsClaim(memberCriteriaLookup: Model.PartialPlainObject<MemberCriteriaLookup>): AxiosPromise<Model.PlainObject<SavingsClaimDataCaptureLookup>> {
        return this.axios.get(`${this.apiPath}/get?${Utils.getQueryString(memberCriteriaLookup)}`);
    }

    public isMemberSavingsClaimActive(memberCriteriaLookup: Model.PartialPlainObject<MemberCriteriaLookup>): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/get/isActive?${Utils.getQueryString(memberCriteriaLookup)}`);
    }

  // Client only properties / methods
}