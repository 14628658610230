import { LookupBase, ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class InstructionsSearchResultsLookup extends ModelBase {

  public instructionId: number = 0;

  public status: string = "";

  public name: string = "";

  public instructionNumber: string = "";

  public instructionType: string = '';

  public schemeName: string = "";

  public schemeNumber: string = "";

  public instruction: string = "";

  public numberOfMembers: number = 0;

  // Client only properties / methods
}