import React from 'react';
import { observer } from 'mobx-react';
import InstructionsVM from '../InstructionsVM';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import LibertyPager from '../../../Dashboard/Components/LibertyPager';
import InstructionsSearchResultsLookup from '../../../Instructions/Models/Lookups/InstructionsSearchResultsLookUp';
import add_circle from '../../../App/assets/img/add_circle.png';
import MemberIcon from './MemberIcon';
import ContainerFooterComponent from './ContainerFooterComponent';

interface IInstructionStartNewProps {
  viewModel: InstructionsVM
}

@observer
export default class InstructionStartNew extends React.Component<IInstructionStartNewProps> {

  constructor(props: IInstructionStartNewProps) {
    super(props);
  }

  private tabCodes = [
    { Id: 1, tabCodes: "Approved Death Benefits" },
    { Id: 2, tabCodes: "Unapproved Death Benefits" },
    { Id: 3, tabCodes: "Funeral Benefits" },
    { Id: 4, tabCodes: "Trusted Contact Person" }
  ];

  public render() {
    return (
      <div className='start-new-instruction'>
        <div className='instruction-background'>
          <div className='card-heading-new-instructions'>Start a new instruction
            <div className='card-sub-heading-new-instructions'>
              Please choose a member or a scheme to start a new instruction.
            </div>
          </div>
          <div className='container-new-instruction'>
            <Neo.TabContainer variant="flat" className='new-instruction-tab-container' selectedTab={this.props.viewModel.meta.tabValueNewInstruction}>
              <Neo.Tab header="Members" name="Members">
                <div className='row search-container new-instructions-search-bar'>
                  <div className='col-md-10'>
                    <Neo.FormGroup
                      bind={this.props.viewModel.criteria.meta.searchTerm} suppressLabel
                      placeholder="Search by..."
                      className='search-box'
                      onChange={
                        () => {
                          this.props.viewModel.updateNewInstructionGrid(this.props.viewModel.tabValueNewInstruction);
                        }
                      } />
                  </div>
                  <div className='col-md-2 search-div'>
                    <Neo.Button text="Search" className='btn-search' />
                  </div>
                </div>
                <LibertyPager
                  maxPagesToShow={2}
                  pageManager={this.props.viewModel.pageManagerMember}
                  pageControlProps={{
                    pageSizeLabel: " ",
                    lastText: " ",
                    totalRecords: this.props.viewModel.pageManagerMember.totalRecords,
                  }}>
                  <NeoGrid.Grid<InstructionsSearchResultsLookup> className='new-instruction-table' initialSort="name">
                    {(item, meta) => (
                      <NeoGrid.Row >
                        <NeoGrid.Column name='Name' label='Member name' className='name-column' sort={meta.name} id="name" >
                          <MemberIcon firstName={item.name} lastName={''} className='profile-icon' />
                          {item.name}
                        </NeoGrid.Column>
                        <NeoGrid.Column label='Scheme' bind={meta.schemeName} readOnly />
                        <NeoGrid.Column label='Member status' bind={meta.status} readOnly />
                        <NeoGrid.Column label='ID number' bind={meta.instructionNumber} readOnly />
                        <NeoGrid.Column label='Member number' bind={meta.numberOfMembers} readOnly />
                        <NeoGrid.Column label=''><img src={add_circle} onClick={() => this.props.viewModel.showStartInstruction = true} className='add-circle-image' /></NeoGrid.Column>
                      </NeoGrid.Row>
                    )}
                  </NeoGrid.Grid>
                </LibertyPager>
              </Neo.Tab>
              <Neo.Tab header="Schemes" name="Schemes">
                <div className='row search-container new-instructions-search-bar'>
                  <div className='col-md-10'>
                    <Neo.FormGroup
                      bind={this.props.viewModel.criteria.meta.searchTerm} suppressLabel
                      placeholder="Search by..."
                      className='search-box'
                      onChange={
                        () => {
                          if (this.props.viewModel.pageManagerScheme.data.length > 0) {
                            this.props.viewModel.updateNewInstructionGrid(this.props.viewModel.tabValueNewInstruction);
                          }
                        }
                      } />
                  </div>
                  <div className='col-md-2 search-div'>
                    <Neo.Button text="Search" className='btn-search' />
                  </div>
                </div>
                <LibertyPager
                  maxPagesToShow={2}
                  pageManager={this.props.viewModel.pageManagerScheme}
                  pageControlProps={{
                    pageSizeLabel: " ",
                    lastText: " ",
                    totalRecords: this.props.viewModel.pageManagerScheme.totalRecords
                  }}>
                  <NeoGrid.Grid<InstructionsSearchResultsLookup> className='new-instruction-table' initialSort='schemeName'>
                    {(item, meta) => (
                      <NeoGrid.Row >
                        <NeoGrid.Column label='Name' className='name-column' bind={meta.schemeName} sort={meta.schemeName} readOnly />
                        <NeoGrid.Column label='Number' bind={meta.schemeNumber} readOnly />
                        <NeoGrid.Column label='No. of members' bind={meta.numberOfMembers} readOnly />
                        <NeoGrid.Column label='Status' bind={meta.status} readOnly />
                        <NeoGrid.Column label=''><img src={add_circle} onClick={() => this.props.viewModel.showStartInstruction = true} className='add-circle-image' /></NeoGrid.Column>
                      </NeoGrid.Row>
                    )}
                  </NeoGrid.Grid>
                </LibertyPager>
              </Neo.Tab>
            </Neo.TabContainer>
          </div>
          <ContainerFooterComponent />
        </div>
        <Neo.Modal
          title=""
          bind={this.props.viewModel.meta.showStartInstruction}
          acceptButton={{ text: "Continue", variant: "primary", onClick: () => this.props.viewModel.navigateToWithdrawalClaimView() }}
          closeButton={{ text: "Cancel", variant: "light" }}
          size='lg'
          className='help-my-modal'>
          <span className='instruction-text'>
            Start a new instruction - &nbsp;
            <span className='instruction-text-member'>
              {this.props.viewModel.memberNameAndCode}
            </span>
          </span>
          <div className='row'>
            <div className="divider"></div>
          </div>
          <div className='modal-content'>
            <p className='modal-text'>Please provide further details in order to start a new instruction</p>
          </div>
          <div className='mb-3 instructions-card '>
            <span className='drop-down'>Instruction type</span>
            <Neo.AutoCompleteDropDown
              className='member-profile-card'
              placeholder="Instruction type"
              isClearable={true}
              bind={this.props.viewModel.meta.instructionTypeId}
              bindDisplay={this.props.viewModel.meta.instructionTypeValue}
              items={this.props.viewModel.instructionTypeValues}
            />
          </div>
          <span className='drop-down'>Related Scheme</span>
          <div className='mb-3 instructions-card '>
            <Neo.AutoCompleteDropDown
              bind={this.props.viewModel.meta.schemeTypeId}
              placeholder="Select Scheme"
              className='member-profile-card'
              isClearable={true}
              typeToSearchPrompt="Select Scheme"
              items={this.props.viewModel.schemeTypeValues} />
          </div>
        </Neo.Modal>
      </div >
    );
  }
}