import { Attributes, EnumHelper, List, ModelBase, Validation } from '@singularsystems/neo-core';
import { ClaimType } from './Enums/ClaimType.enum';
import RoleTypeGroupTemplateLookup from './RoleTypeTemplateLookup';

export default class ClaimDeclarationGroupTemplateLookup extends ModelBase {
    static typeName = "ClaimDeclarationGroupTemplateLookup";

    constructor() {
        super();
        this.makeObservable();
    }

    public claimType: ClaimType = ClaimType.SavingsClaim;

    public roleTypeGroups = new List(RoleTypeGroupTemplateLookup);

    // Client only properties / methods

    @Attributes.NoTracking()
    public isExpanded : boolean = false;

    @Attributes.Display("Claim Type")
    public get claimTypeDisplay() {
        return EnumHelper.getItemMetadata(ClaimType, this.claimType).display;    
    }
    
    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New claim declaration group template lookup";
        } else {
            return "Claim Declaration Group Template Lookup";
        }
    }
}