import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import NotificationsVM from '../NotificationsVM';
import "../../../App/Styles/Components/Notifications.scss";

interface IActionableNotificationViewProps {
  viewModel: NotificationsVM
}

@observer
export default class ActionableNotificationView extends React.Component<IActionableNotificationViewProps> {

  constructor(props: IActionableNotificationViewProps) {
    super(props);
  }

  public render() {
    return (
      <div >
        <div className='row show-notification'>
          <Neo.Card className='show-notification-card'>
            <div className='align-text-member-info'>
              <div>
                <span className='notification-text-heading'>{this.props.viewModel.selectedItem.notificationText}</span>
              </div>
              <div className='pe-4'>
                {this.props.viewModel.selectedItem.notificationMemberInformation != "" &&
                  <span className='notification-sub-heading'>{this.props.viewModel.selectedItem.notificationMemberInformation} <span className='display-caret'> | </span> </span>}
                <span className='notification-sub-heading'>{this.props.viewModel.selectedItem.notificationInformation}</span>
              </div>
            </div>
            <div className='line-notifications-info-card'></div>
            <p className='notification-date'>{this.props.viewModel.selectedItem.notificationDate}</p>
            <div className='notification-information mt-2'>
              {this.props.viewModel.selectedItem.notificationDescription}
            </div>
            <div className='align-btns'>
              <Neo.Button text="Back to notifications" className='col-12 btn btn-notifications' onClick={() => this.props.viewModel.onBackSubmit()} />
              <Neo.Button text="Instructions" className='col-12 btn btn-instructions' onClick={() => this.props.viewModel.navigateToInstructions()} />
            </div>
          </Neo.Card>
        </div>
      </div>
    );
  }
}