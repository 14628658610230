import { AppServices } from '@singularsystems/neo-core';
import { AppService, Types as AppTypes } from '../App/Services/AppService';
import SchemeDetailsApiClient from './ApiClients/SchemeDetails/SchemeDetailsApiClient';
import { PartiesExportedTypes } from './PartiesExportedTypes';

// Symbols specific to this module.
const PartiesTypes = {
  ApiClients: {
    ...PartiesExportedTypes.ApiClients,
    SchemeDetailsApiClient: new AppServices.ServiceIdentifier<SchemeDetailsApiClient>("Party.ApiClients.SchemeDetailsApiClient"),
  },
  Services: {
    ...PartiesExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  Party: PartiesTypes
}

export { AppService, Types, PartiesTypes }