import { Rules, StringUtils } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import AddressInfoLookup from '../../Models/LookUps/AddressInfoLookup';
import EmailInfoLookup from '../../Models/LookUps/EmailInfoLookup';
import GetPersonContactDetailLookup from '../../Models/LookUps/GetPersonContactDetailLookup';
import PersonContactDetailsAddressCommand from '../../Models/LookUps/MemberProfileUpdates/PersonContactDetailsAddressCommand';
import UpdatePersonContactDetailsCommand from '../../Models/LookUps/MemberProfileUpdates/UpdatePersonContactDetailsCommand';
import TelephoneInfoLookup from '../../Models/LookUps/TelephoneInfoLookup';
import { AppService, Types } from '../../PartiesTypes';

export default class PersonContactDetailsComponentVM extends Views.ViewModelBase {

  public countryCodeValueId: number = 148;
  @Rules.Required("Country is required")
  public countryValueId: number | null = null;
  @Rules.Required("Province/state is required")
  public provinceValueId: number | null = null;
  public personContactDetailsLookup = new GetPersonContactDetailLookup();
  public telephoneInfo = new TelephoneInfoLookup();
  public addressInfo = new AddressInfoLookup();
  public emailInfo = new EmailInfoLookup();
  public updatePersonContactDetailsCommand = new UpdatePersonContactDetailsCommand();
  public allVerified: boolean = false;

  public emailOriginal: string = "";
  public phoneOriginal: string = "";
  public originalAddressLine1: string = "";
  public originalAddressLine2: string = "";
  public originalAddressLine3: string = "";
  public originalProvince: string | null = null;
  public originalCountry: string | null = null;
  public originalPostalCode: string = "";
  public originalCity: string = "";
  public originalIsEmailAddressVerified = false;
  public originalIsPhoneAddressVerified = false;
  public originalIsAddressVerified = false;
  public originalContactNumberBase: string = "";
  public originalCountryValue: string = "";

  public showUpdateModal: boolean = false;
  public hidePhoneCheckbox: boolean = false;
  public hideEmailCheckbox: boolean = false;
  public hideAddressCheckbox: boolean = false;

  //TODO: Provinces should be coming from MasterDataService
  public provinces = [
    { id: 1, province: "Eastern Cape" },
    { id: 2, province: "Free State" },
    { id: 3, province: "Gauteng" },
    { id: 4, province: "KwaZulu-Natal" },
    { id: 5, province: "Limpopo" },
    { id: 6, province: "Mpumalanga" },
    { id: 7, province: "Northern Cape" },
    { id: 8, province: "North West" },
    { id: 9, province: "Western Cape" }
  ];

  //TODO: Countries should be coming from MasterDataService
  public countries = [
    { id: 161, country: "South Africa" },
    { id: 1, country: "Afghanistan" },
    { id: 2, country: "Albania" },
    { id: 3, country: "Algeria" },
    { id: 4, country: "Andorra" },
    { id: 5, country: "Angola" },
    { id: 6, country: "Antigua and Barbuda" },
    { id: 7, country: "Argentina" },
    { id: 8, country: "Armenia" },
    { id: 9, country: "Australia" },
    { id: 10, country: "Austria" },
    { id: 11, country: "Azerbaijan" },
    { id: 12, country: "Bahamas" },
    { id: 13, country: "Bahrain" },
    { id: 14, country: "Bangladesh" },
    { id: 15, country: "Barbados" },
    { id: 16, country: "Belarus" },
    { id: 17, country: "Belgium" },
    { id: 18, country: "Belize" },
    { id: 19, country: "Benin" },
    { id: 20, country: "Bhutan" },
    { id: 21, country: "Bolivia" },
    { id: 22, country: "Bosnia and Herzegovina" },
    { id: 23, country: "Botswana" },
    { id: 24, country: "Brazil" },
    { id: 25, country: "Brunei" },
    { id: 26, country: "Bulgaria" },
    { id: 27, country: "Burkina Faso" },
    { id: 28, country: "Burundi" },
    { id: 29, country: "Cabo Verde" },
    { id: 30, country: "Cambodia" },
    { id: 31, country: "Cameroon" },
    { id: 32, country: "Canada" },
    { id: 33, country: "Central African Republic" },
    { id: 34, country: "Chad" },
    { id: 35, country: "Chile" },
    { id: 36, country: "China" },
    { id: 37, country: "Colombia" },
    { id: 38, country: "Comoros" },
    { id: 39, country: "Congo, Democratic Republic of the" },
    { id: 40, country: "Congo, Republic of the" },
    { id: 41, country: "Costa Rica" },
    { id: 42, country: "Croatia" },
    { id: 43, country: "Cuba" },
    { id: 44, country: "Cyprus" },
    { id: 45, country: "Czech Republic" },
    { id: 46, country: "Denmark" },
    { id: 47, country: "Djibouti" },
    { id: 48, country: "Dominica" },
    { id: 49, country: "Dominican Republic" },
    { id: 50, country: "Ecuador" },
    { id: 51, country: "Egypt" },
    { id: 52, country: "El Salvador" },
    { id: 53, country: "Equatorial Guinea" },
    { id: 54, country: "Eritrea" },
    { id: 55, country: "Estonia" },
    { id: 56, country: "Eswatini" },
    { id: 57, country: "Ethiopia" },
    { id: 58, country: "Fiji" },
    { id: 59, country: "Finland" },
    { id: 60, country: "France" },
    { id: 61, country: "Gabon" },
    { id: 62, country: "Gambia" },
    { id: 63, country: "Georgia" },
    { id: 64, country: "Germany" },
    { id: 65, country: "Ghana" },
    { id: 66, country: "Greece" },
    { id: 67, country: "Grenada" },
    { id: 68, country: "Guatemala" },
    { id: 69, country: "Guinea" },
    { id: 70, country: "Guinea-Bissau" },
    { id: 71, country: "Guyana" },
    { id: 72, country: "Haiti" },
    { id: 73, country: "Honduras" },
    { id: 74, country: "Hungary" },
    { id: 75, country: "Iceland" },
    { id: 76, country: "India" },
    { id: 77, country: "Indonesia" },
    { id: 78, country: "Iran" },
    { id: 79, country: "Iraq" },
    { id: 80, country: "Ireland" },
    { id: 81, country: "Israel" },
    { id: 82, country: "Italy" },
    { id: 83, country: "Jamaica" },
    { id: 84, country: "Japan" },
    { id: 85, country: "Jordan" },
    { id: 86, country: "Kazakhstan" },
    { id: 87, country: "Kenya" },
    { id: 88, country: "Kiribati" },
    { id: 89, country: "Korea, North" },
    { id: 90, country: "Korea, South" },
    { id: 91, country: "Kosovo" },
    { id: 92, country: "Kuwait" },
    { id: 93, country: "Kyrgyzstan" },
    { id: 94, country: "Laos" },
    { id: 95, country: "Latvia" },
    { id: 96, country: "Lebanon" },
    { id: 97, country: "Lesotho" },
    { id: 98, country: "Liberia" },
    { id: 99, country: "Libya" },
    { id: 100, country: "Liechtenstein" },
    { id: 101, country: "Lithuania" },
    { id: 102, country: "Luxembourg" },
    { id: 103, country: "Madagascar" },
    { id: 104, country: "Malawi" },
    { id: 105, country: "Malaysia" },
    { id: 106, country: "Maldives" },
    { id: 107, country: "Mali" },
    { id: 108, country: "Malta" },
    { id: 109, country: "Marshall Islands" },
    { id: 110, country: "Mauritania" },
    { id: 111, country: "Mauritius" },
    { id: 112, country: "Mexico" },
    { id: 113, country: "Micronesia" },
    { id: 114, country: "Moldova" },
    { id: 115, country: "Monaco" },
    { id: 116, country: "Mongolia" },
    { id: 117, country: "Montenegro" },
    { id: 118, country: "Morocco" },
    { id: 119, country: "Mozambique" },
    { id: 120, country: "Myanmar" },
    { id: 121, country: "Namibia" },
    { id: 122, country: "Nauru" },
    { id: 123, country: "Nepal" },
    { id: 124, country: "Netherlands" },
    { id: 125, country: "New Zealand" },
    { id: 126, country: "Nicaragua" },
    { id: 127, country: "Niger" },
    { id: 128, country: "Nigeria" },
    { id: 129, country: "North Macedonia" },
    { id: 130, country: "Norway" },
    { id: 131, country: "Oman" },
    { id: 132, country: "Pakistan" },
    { id: 133, country: "Palau" },
    { id: 134, country: "Panama" },
    { id: 135, country: "Papua New Guinea" },
    { id: 136, country: "Paraguay" },
    { id: 137, country: "Peru" },
    { id: 138, country: "Philippines" },
    { id: 139, country: "Poland" },
    { id: 140, country: "Portugal" },
    { id: 141, country: "Qatar" },
    { id: 142, country: "Romania" },
    { id: 143, country: "Russia" },
    { id: 144, country: "Rwanda" },
    { id: 145, country: "Saint Kitts and Nevis" },
    { id: 146, country: "Saint Lucia" },
    { id: 147, country: "Saint Vincent and the Grenadines" },
    { id: 148, country: "Samoa" },
    { id: 149, country: "San Marino" },
    { id: 150, country: "Sao Tome and Principe" },
    { id: 151, country: "Saudi Arabia" },
    { id: 152, country: "Senegal" },
    { id: 153, country: "Serbia" },
    { id: 154, country: "Seychelles" },
    { id: 155, country: "Sierra Leone" },
    { id: 156, country: "Singapore" },
    { id: 157, country: "Slovakia" },
    { id: 158, country: "Slovenia" },
    { id: 159, country: "Solomon Islands" },
    { id: 160, country: "Somalia" },
    { id: 162, country: "South Sudan" },
    { id: 163, country: "Spain" },
    { id: 164, country: "Sri Lanka" },
    { id: 165, country: "Sudan" },
    { id: 166, country: "Suriname" },
    { id: 167, country: "Sweden" },
    { id: 168, country: "Switzerland" },
    { id: 169, country: "Syria" },
    { id: 170, country: "Taiwan" },
    { id: 171, country: "Tajikistan" },
    { id: 172, country: "Tanzania" },
    { id: 173, country: "Thailand" },
    { id: 174, country: "Timor-Leste" },
    { id: 175, country: "Togo" },
    { id: 176, country: "Tonga" },
    { id: 177, country: "Trinidad and Tobago" },
    { id: 178, country: "Tunisia" },
    { id: 179, country: "Turkey" },
    { id: 180, country: "Turkmenistan" },
    { id: 181, country: "Tuvalu" },
    { id: 182, country: "Uganda" },
    { id: 183, country: "Ukraine" },
    { id: 184, country: "United Arab Emirates" },
    { id: 185, country: "United Kingdom" },
    { id: 186, country: "United States" },
    { id: 187, country: "Uruguay" },
    { id: 188, country: "Uzbekistan" },
    { id: 189, country: "Vanuatu" },
    { id: 190, country: "Vatican City" },
    { id: 191, country: "Venezuela" },
    { id: 192, country: "Vietnam" },
    { id: 193, country: "Yemen" },
    { id: 194, country: "Zambia" },
    { id: 195, country: "Zimbabwe" }
  ];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    public helpersService = AppService.get(Types.App.Services.HelpersService),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private personContactDetailsCommandApiClient = AppService.get(Types.Parties.ApiClients.PersonContactDetailsCommandApiClient),
  ) {
    super(taskRunner);
    this.makeObservable();
  }

  public async initialise() {
    await this.getPersonContactDetails();
  }

  public async getPersonContactDetails() {
    await this.taskRunner.run(async () => {
      this.personContactDetailsLookup.mapFrom(this.userRoleService.personContactDetailsLookup);
      this.telephoneInfo = this.personContactDetailsLookup.telephoneInfo[0] ? this.personContactDetailsLookup.telephoneInfo[0] : new TelephoneInfoLookup();
      this.emailInfo = this.personContactDetailsLookup.emailInfo[0] ? this.personContactDetailsLookup.emailInfo[0] : new EmailInfoLookup();
      this.addressInfo = this.personContactDetailsLookup.addressInfo[0] ? this.personContactDetailsLookup.addressInfo[0] : new AddressInfoLookup();
      this.setCountryValueForProfile(this.telephoneInfo.countryValue);
      this.addressInfo.country = this.getCountry(this.addressInfo.country ?? "");
      this.addressInfo.province = this.getProvince(this.addressInfo.province ?? "");
      this.populateOriginalValues();
      this.checkAllVerified();
    });
  }

  public getProvince(province: string | null): string {
    if (province) {
      if (this.countryValueId != 161 && StringUtils.isNullOrWhitespace(province)) {
        return "";
      }
      if (this.countryValueId == 161 && StringUtils.isNullOrWhitespace(province)) {
        this.provinceValueId = 1;
        return this.getProvinceById(this.provinceValueId);
      }
      if (province.toLowerCase() == "kwazulu natal") {
        this.provinceValueId = 4;
        return this.getProvinceById(this.provinceValueId);
      }
      let result = this.provinces.find(x => x.province.toLowerCase() === province.toLowerCase());
      if (result) {
        this.provinceValueId = result.id;
        return result.province
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getProvinceById(id: number | null): string {
    if (id) {
      let result = this.provinces.find(province => province.id == id);
      if (result) {
        return result?.province;
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountry(country: string | null): string {
    if (country) {
      if (country.toLowerCase() == "sa" || country.toLowerCase() == "southafrica" || StringUtils.isNullOrWhitespace(country)) {
        this.countryValueId = 161;
        return this.getCountryById(this.countryValueId);
      }
      if (country.toLowerCase() == "za") {
        this.countryValueId = 195;
        return this.getCountryById(this.countryValueId);
      }

      let result = this.countries.find(s => s.country.toLowerCase() === country.toLowerCase());
      if (result) {
        this.countryValueId = result.id;
        return result.country
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  public getCountryById(id: number | null): string {
    if (id) {
      let result = this.countries.find(country => country.id == id);
      if (result) {
        return result?.country;
      }
      else {
        return "";
      }
    } else {
      return "";
    }
  }

  public populateOriginalValues() {
    this.hideEmailCheckbox = this.emailInfo.isVerified;
    this.hideAddressCheckbox = this.addressInfo.isVerified;
    this.hidePhoneCheckbox = this.telephoneInfo.isVerified;
    this.emailOriginal = this.emailInfo.emailAddress;
    this.phoneOriginal = this.telephoneInfo.telePhoneNo;
    this.originalAddressLine1 = this.addressInfo.addressLine1;
    this.originalAddressLine2 = this.addressInfo.addressLine2;
    this.originalAddressLine3 = this.addressInfo.addressLine3;
    this.originalPostalCode = this.addressInfo.postalCode;
    this.originalCity = this.addressInfo.city;
    this.originalProvince = this.getProvince(this.addressInfo.province);
    this.originalCountry = this.getCountry(this.addressInfo.country);
    this.originalCountryValue = this.telephoneInfo.countryValue;
    this.originalContactNumberBase = this.telephoneInfo.contactNumberBase;
    this.originalIsAddressVerified = this.addressInfo.isVerified;
    this.originalIsEmailAddressVerified = this.emailInfo.isVerified;
    this.originalIsPhoneAddressVerified = this.telephoneInfo.isVerified;
  }

  public checkAllVerified() {
    if (this.telephoneInfo.isVerified &&
      this.emailInfo.isVerified &&
      this.addressInfo.isVerified) {
      this.allVerified = true;
    }
    else {
      this.allVerified = false;
    }
  }

  public checkIfEmailChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.emailInfo.emailAddress == this.emailOriginal && this.emailInfo.isValid && this.originalIsEmailAddressVerified && !this.hideEmailCheckbox) {
      this.hideEmailCheckbox = true;
    }
    if (this.emailOriginal == this.emailInfo.emailAddress || this.emailInfo.emailAddress == "" || !this.emailInfo.isValid) {
      return;
    }
    if ((this.emailOriginal !== this.emailInfo.emailAddress)
      && this.emailInfo.isValid) {
      this.hideEmailCheckbox = false;
      this.emailInfo.isVerified = true;
    } else {
      this.hideEmailCheckbox = true;
      this.emailInfo.isVerified = false;
    }
  }

  public checkIfNumberChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.originalContactNumberBase == this.telephoneInfo.contactNumberBase && this.originalCountryValue == this.telephoneInfo.countryValue && this.originalIsPhoneAddressVerified && this.telephoneInfo.isValid && !this.hidePhoneCheckbox) {
      this.hidePhoneCheckbox = true;
    }
    if ((this.originalContactNumberBase == this.telephoneInfo.contactNumberBase && this.telephoneInfo.countryValue == this.originalCountryValue) || !this.telephoneInfo.isValid) {
      return;
    }
    if (((this.telephoneInfo.contactNumberBase !== this.originalContactNumberBase || this.telephoneInfo.countryValue !== this.originalCountryValue) && this.telephoneInfo.isValid)) {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = true;
    } else {
      this.hidePhoneCheckbox = true;
      this.telephoneInfo.isVerified = false;
    }
  }

  public onTelephoneChange(e: any) {
    const targetElement = e.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    this.hidePhoneCheckbox = false;
    this.telephoneInfo.isVerified = false
  }

  public onEmailChange(e: any) {
    const targetElement = e.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    this.hideEmailCheckbox = false;
    this.emailInfo.isVerified = false
  }

  public onProvinceSelected(item: string) {
    this.addressInfo.province = item;
    if (this.originalProvince!.toLowerCase() === item.toLowerCase()) {
      this.hideAddressCheckbox = true;
      return;
    }
    else {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
    }
  }

  public onCountrySelected(item: string) {
    this.addressInfo.country = item;
    if (item !== "South Africa") {
      this.addressInfo.province = ""
    }
    if (this.originalCountry!.toLowerCase() === item.toLowerCase() || this.originalCountry!.toLowerCase() == "sa" && item.toLowerCase() == "south africa" ||
      this.originalCountry!.toLowerCase() == "za" && item.toLowerCase() == "zimbabwe") {
      this.hideAddressCheckbox = true;
      return;
    }
    else {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
    }
  }

  public onCountryCodeSelected() {
    if (this.telephoneInfo.countryValue == this.originalCountryValue && this.telephoneInfo.contactNumberBase == this.originalContactNumberBase && this.originalIsPhoneAddressVerified && this.telephoneInfo.isValid) {
      this.hidePhoneCheckbox = true;
      this.telephoneInfo.isVerified = true;
      return;
    }
    if (!this.telephoneInfo.isValid) {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = false;
    }
    else {
      this.hidePhoneCheckbox = false;
      this.telephoneInfo.isVerified = true;
    }
  }

  public checkIfAddressChanged(event: any) {
    const targetElement = event.target;
    if (targetElement.type === 'checkbox') {
      return;
    }
    if (this.originalAddressLine1 == this.addressInfo.addressLine1 && this.originalAddressLine2 == this.addressInfo.addressLine2 &&
      this.originalCity == this.addressInfo.city && this.originalAddressLine3 == this.addressInfo.addressLine3 &&
      this.originalPostalCode == this.addressInfo.postalCode && this.originalProvince == this.addressInfo.province) {
      return;
    }
    if (this.originalAddressLine1 !== this.addressInfo.addressLine1 || this.originalAddressLine2 !== this.addressInfo.addressLine2 ||
      this.originalCity !== this.addressInfo.city || this.originalAddressLine3 !== this.addressInfo.addressLine3 ||
      this.originalPostalCode !== this.addressInfo.postalCode || this.originalProvince !== this.addressInfo.province) {
      this.hideAddressCheckbox = false;
      this.addressInfo.isVerified = false;
    }
    else {
      this.hideAddressCheckbox = true;
      this.addressInfo.isVerified = false;
    }
  }

  public checkIfAddressClicked(event: any) {
    this.addressInfo.isVerified = event.target.checked;
  }

  public async updateAllDetails(): Promise<void> {
    this.checkAllVerified();

    const emailChanged = this.emailOriginal !== this.emailInfo.emailAddress &&
      this.emailInfo.isValid &&
      this.emailInfo.isVerified &&
      !this.hideEmailCheckbox;

    const phoneChanged = (this.originalContactNumberBase !== this.telephoneInfo.contactNumberBase ||
      this.originalCountryValue !== this.telephoneInfo.countryValue) &&
      this.telephoneInfo.isValid &&
      this.telephoneInfo.isVerified &&
      !this.hidePhoneCheckbox;

    const addressChanged = (this.originalAddressLine1 !== this.addressInfo.addressLine1 ||
      this.originalAddressLine2 !== this.addressInfo.addressLine2 ||
      this.originalCity !== this.addressInfo.city ||
      this.originalAddressLine3 !== this.addressInfo.addressLine3 ||
      (this.countryValueId === 161
        ? this.originalProvince?.toLowerCase() !== this.getProvinceById(this.provinceValueId).toLowerCase()
        : this.originalProvince?.toLowerCase() !== this.addressInfo.province?.toLowerCase()) ||
      this.originalPostalCode !== this.addressInfo.postalCode ||
      this.originalCountry?.toLowerCase() !== this.getCountryById(this.countryValueId).toLowerCase()) &&
      this.addressInfo.isValid &&
      this.addressInfo.isVerified &&
      !this.hideAddressCheckbox;

    const updateEmail = emailChanged || (this.emailInfo.isVerified && this.hideEmailCheckbox !== this.emailInfo.isVerified);
    const updatePhone = phoneChanged || (this.telephoneInfo.isVerified && this.hidePhoneCheckbox !== this.telephoneInfo.isVerified);
    const updateAddress = addressChanged || (this.addressInfo.isVerified && this.hideAddressCheckbox !== this.addressInfo.isVerified);

    this.taskRunner.run(async () => {
      if (updateEmail) {
        this.updatePersonContactDetailsCommand.emailAddress = this.emailInfo.emailAddress;
      }

      if (updatePhone) {
        this.updatePersonContactDetailsCommand.telephoneNo = `${this.telephoneInfo.countryValue} ${this.telephoneInfo.contactNumberBase}`;
      }

      if (updateAddress) {
        const addressCommand = new PersonContactDetailsAddressCommand();
        addressCommand.addressLine1 = this.addressInfo.addressLine1;
        addressCommand.addressLine2 = this.addressInfo.addressLine2;
        addressCommand.addressLine3 = this.addressInfo.addressLine3;
        addressCommand.postalCode = this.addressInfo.postalCode;
        addressCommand.suburb = "";
        addressCommand.city = this.addressInfo.city;
        addressCommand.province = this.countryValueId === 161
          ? this.getProvinceById(this.provinceValueId)
          : this.addressInfo.province;
        addressCommand.country = this.getCountryById(this.countryValueId);
        this.updatePersonContactDetailsCommand.addressInfo = addressCommand;
      }

      if (updateEmail || updatePhone || updateAddress) {
        this.updatePersonContactDetailsCommand.sourceId = this.personContactDetailsLookup.sourceId;
        this.updatePersonContactDetailsCommand.nameId = this.personContactDetailsLookup.nameId;

        const result = await this.personContactDetailsCommandApiClient.updatePersonContactDetails(
          this.updatePersonContactDetailsCommand.toJSObject()
        );
        if (result.data) {
          this.userRoleService.getPersonContactDetails();
          this.showUpdateModal = true;
        }
      }
    });
  }

  public getMemberProfileCompleteness() {
    this.checkAllVerified();

    let percentageComplete = 0;
    const oneThird = 33.33;
    const oneSixth = 16.67;

    if (this.allVerified) {
      percentageComplete = 100;
      return percentageComplete;
    }

    if (this.addressInfo.isVerified) {
      percentageComplete += oneThird;
    } else if (!this.addressInfo.isVerified && this.addressInfo !== null && (!StringUtils.isNullOrWhitespace(this.addressInfo.addressLine1))) {
      percentageComplete += oneSixth;
    }

    if (this.telephoneInfo.isVerified) {
      if (this.telephoneInfo.telephoneType === "Member Personal Cell Phone" || this.telephoneInfo.telephoneType.includes("Cell Phone")
        && (!StringUtils.isNullOrWhitespace(this.telephoneInfo.telePhoneNo))) {
        percentageComplete += oneThird;
      }
    } else if (!this.telephoneInfo.isVerified && this.telephoneInfo != null && (!StringUtils.isNullOrWhitespace(this.telephoneInfo.telePhoneNo))) {
      percentageComplete += oneSixth;
    }

    if (this.emailInfo.isVerified) {
      percentageComplete += oneThird;
    } else if (!this.emailInfo.isVerified && this.emailInfo != null && (!StringUtils.isNullOrWhitespace(this.emailInfo.emailAddress))) {
      percentageComplete += oneSixth;
    }
    return percentageComplete;
  }

  public async cancelChanges() {
    this.emailInfo.emailAddress = this.emailOriginal;
    this.telephoneInfo.telePhoneNo = this.phoneOriginal;
    this.telephoneInfo.contactNumberBase = this.originalContactNumberBase;
    this.setCountryValueForProfile(this.originalCountryValue);
    this.addressInfo.addressLine1 = this.originalAddressLine1;
    this.addressInfo.addressLine2 = this.originalAddressLine2;
    this.addressInfo.addressLine3 = this.originalAddressLine3;
    this.addressInfo.postalCode = this.originalPostalCode;
    this.addressInfo.city = this.originalCity;
    this.addressInfo.province = this.originalProvince;
    this.addressInfo.country = this.originalCountry;
    this.getCountry(this.originalCountry);
    this.getProvince(this.originalProvince);
    this.emailInfo.isVerified = this.originalIsEmailAddressVerified;
    this.telephoneInfo.isVerified = this.originalIsPhoneAddressVerified;
    this.addressInfo.isVerified = this.originalIsAddressVerified;

    this.checkAllVerified();
    if (this.addressInfo.isVerified == true) {
      this.hideAddressCheckbox = true;
    }
    if (this.telephoneInfo.isVerified == true) {
      this.hidePhoneCheckbox = true;
    }
    if (this.emailInfo.isVerified == true) {
      this.hideEmailCheckbox = true;
    }
  }

  public setCountryValueForProfile(countryCode: string) {
    let result = this.helpersService.countryCodes.find(province => province.CountryCode == countryCode);
    if (result) {
      this.countryCodeValueId = result.Id;
      this.telephoneInfo.countryValue = result.CountryCode;
    }
    else {
      this.countryCodeValueId = 148;
      this.telephoneInfo.countryValue = '+27';
    }
  }
}