import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberContactDetailsLookup from '../../Models/LookUps/MemberContacts/MemberContactDetailsLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberContactDetailsApiClient {

  /** 
   * GetMemberAdminContactsLookup
   * @returns List of MemberContactDetailsLookup
   */
  getMemberAdminContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>>;

  /** 
   * GetMemberEmployerContactsLookups
   * @returns List of MemberContactDetailsLookups
   */
  getMemberEmployerContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>>;

  /** 
   * GetMemberFAContactsLookups
   * @returns List of FA MemberContactDetailsLookups
   */
  getMemberFAContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class MemberContactDetailsApiClient extends Data.ApiClientBase implements IMemberContactDetailsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-contacts`);
  }

  public getMemberAdminContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>> {
    return this.axios.get(`${this.apiPath}/admin-contact-lookups/${schemeId}/${encodeURIComponent(sourceId)}`);
  }

  public getMemberEmployerContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>> {
    return this.axios.get(`${this.apiPath}/employer-contact-lookups/${schemeId}/${encodeURIComponent(sourceId)}`);
  }

  public getMemberFAContactsLookups(schemeId: number, sourceId: string): AxiosPromise<Array<Model.PlainObject<MemberContactDetailsLookup>>> {
    return this.axios.get(`${this.apiPath}/fa-contact-lookups/${schemeId}/${encodeURIComponent(sourceId)}`);
  }

  // Client only properties / methods
}