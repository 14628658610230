import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import "../../App/Styles/Components/ValueAddedServices.scss";
import icn_download from '../../App/assets/img/download_white.png';
import qr_code from '../../App/assets/img/liberty-qrcode.png';
import icn_location from '../../App/assets/img/location.png';
import icn_call_centre from '../../App/assets/img/call-centre.png';
import ValueAddedServicesVM from './ValueAddedServicesVM';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

@observer
export default class ValueAddedServicesView extends Views.ViewBase<ValueAddedServicesVM> {

  constructor(props: unknown) {
    super("Value-added services", ValueAddedServicesVM, props);
  }

  public render() {
    return (
      <div>
        <div className='background-card-vas'>
          <div className='row'>
            <div className='card-heading'>
              Emergency and assistance services
              <p>The following emergency and assistance services are available to your members, their spouse and dependent children under the age of 21.</p>
            </div>
          </div>
          {this.viewModel.vasTemplate.vasTemplateCards.map((item) => (
            <div className='col-12'>
              <Neo.Card key={item.vasTemplateCardId} title={
                <div className='align-title'>
                  <Neo.Button className="fa-2x cards-img-vas" variant='link' icon={item.icon}></Neo.Button>
                  {item.title}
                </div>}
                isExpanded={item.isExpanded}
                className='cards-cu cards-headings-cu-vas'>
                <div className='col-md-12'>
                  <p>{item.description}</p>
                </div>
              </Neo.Card >
            </div>
          ))}
        </div>
        {this.viewModel.showFuneralServices &&
          <div className='background-card-vas mt-4'>
            <div className='row'>
              <div className='card-heading'>
                Funeral assistance services
                <p>The following funeral assistance services are available to your members and their family members who are covered under the Funeral Benefit.</p>
              </div>
            </div>
            <div className='col-12'>
              <Neo.Card title={
                <div className='align-title'>
                  <div><img className='img-class' src={icn_location}></img></div>
                  <div>{"Repatriation of mortal remains"}</div>
                </div>}
                isExpanded={this.viewModel.isFuneralExpanded} className='cards-cu cards-headings-cu-vas'>
                <div className='col-md-12'>
                  <p>This service provides for a single trip, one-way for the deceased to reach the place where the funeral service will be taking place, provided that this trip is more than 100km’s away from where the deceased’s body is being kept in a secure and refrigerated storage.
                    The territories include transportation of the deceased within South Africa, and to/back to South Africa from Botswana, Lesotho, Mozambique, Namibia, Swaziland or Zimbabwe.
                    Embalming and refrigerated transportation is not part of the service offered.</p>
                </div>
              </Neo.Card >
            </div>
            <div className='col-12'>
              <Neo.Card title={
                <div className='align-title'>
                  <div> <img className='img-class' src={icn_call_centre}></img></div>
                  <div>{"Telephonic assistance and referrals (for funeral-related arrangements)"}</div></div>}
                isExpanded={this.viewModel.isFuneralTelephoneAssistanceExpanded} className='cards-cu cards-headings-cu-vas'>
                <div className='col-md-12'>
                  <p>The 24-Hour Contact Centre can provide telephonic assistance and referrals in terms of funeral-related arrangements
                    (including, but not limited to, guidance in where and how to obtain medical certificates, death certificates and burial orders and referral to reputable funeral homes).
                    Please note that all costs associated with the delivery of all services will be for the member’s or their family’s own account.</p>
                </div>
              </Neo.Card >
            </div>
          </div>
        }
        <div className='background-card-vas mt-4 remove-padding'>
          <div className='row'>
            <div className='card-heading'>
              Accessing the above services
              <p>The above services are available 24 hours per day and 365 days per year, by reaching out to the 24-Hour Contact Centre.</p>
              <p className='align-liberty-link'>To find out more, please visit <a target={'_blank'} href='https://www.liberty.co.za/umbrella-fund-solutions'>Liberty.co.za</a></p>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-3 negate-col-information-financial-advisers-card-margin'>
              <div className='information-financial-advisers-cards-cu '>
                <div className='cards-top-section-cu'>
                  <div className='icon-cu'>C</div>
                  <div className='information-heading-financial-advisers-cards-cu-vas financial-advisers-cards'>
                    24-Hour Contact Centre (telephonic access)
                  </div>
                </div>
                <div className='occupation-cu'></div>
                <div className='line-cu' />
                <div className='information-description-cards-cu'>If calling from within South Africa:</div>
                <div className='information-detail-cards-cu'><a href={"tel:" + this.viewModel.vasTemplate.contactNumberLocal}>{this.viewModel.vasTemplate.getFormattedContactNumberLocal()}</a></div>
                <div className='information-description-cards-cu'>If calling from outside of South Africa:</div>
                <div className='information-detail-cards-cu'><a href={"tel:" + this.viewModel.vasTemplate.contactNumberInternational}>{this.viewModel.vasTemplate.getFormattedContactNumberInternational()}</a></div>
              </div>
            </div>
            <div className='col-md-3 negate-col-information-financial-advisers-card-margin'>
              <div className='information-financial-advisers-cards-cu '>
                <div className='cards-top-section-cu'>
                  <div className='icon-cu'>C</div>
                  <div className='information-heading-financial-advisers-cards-cu-vas financial-advisers-cards'>24-Hour Contact Centre (digital messaging access)</div>
                </div>
                <div className='occupation-cu'></div>
                <div className='line-cu' />
                <div className='information-description-cards-cu'>Digital messaging platform:</div>
                <div className='information-detail-cards-cu'><a target="_blank" href={"https://" + this.viewModel.vasTemplate.websiteURL}>
                  {this.viewModel.vasTemplate.websiteURL}
                </a></div>
                <div className='information-description-cards-cu'>QR code:</div>
                <div className='row'>
                  <div className='col-md-12'>
                    <img src={qr_code} className="qr-code-vas" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='add-margin-for-vas-btn'>
              <Neo.Button
                onClick={() => this.viewModel.downloadVCardFile(this.viewModel.vasTemplate)}
                data-tip-class="ui-tooltip"
                data-tip-pos={"right"}
                tooltip={"The contact details are available for yourself, spouse and dependents."}
                variant='primary'
                className="vas-download-btn"
              >
                <img src={icn_download} className="download-icon-vas" />
                Download contact details
              </Neo.Button>
            </div>
          </div>
          <div className='col-12'>
            <div className='notes-disclaimer-block'>
              <div className='notes-disclaimer-block-text-heading-vas'>Notes and disclosures:</div>
              <ul>
                <li className='notes-disclaimer-block-text-vas'>These services are provided by Cims South Africa (Pty) Ltd in partnership with Liberty.</li>
                <li className='notes-disclaimer-block-text-vas'>For more information on these services, please contact your Fund Financial Adviser or Liberty.</li>
                <li className='notes-disclaimer-block-text-vas'>Please note that terms and conditions apply to each of these services.</li>
              </ul>
            </div>
          </div>
          <ContainerFooterComponent />
        </div>
      </div>
    );
  }
}