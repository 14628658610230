import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import '../Styles/Claims.scss'
import { MemberWithdrawalClaimStep } from '../Components/WithdrawalClaim/MemberWithdrawalClaimStep.enum';
import MemberDetails from '../Components/WithdrawalClaim/MemberDetails';
import HowItWorks from '../Components/WithdrawalClaim/HowItWorks';
import Deductions from '../Components/WithdrawalClaim/Deductions';
import Documentation from '../Components/WithdrawalClaim/Documentation';
import MemberDeclaration from '../Components/WithdrawalClaim/MemberDeclaration';
import ReviewSubmit from '../Components/WithdrawalClaim/ReviewSubmit';
import SavingsWithdrawalClaimVM from './SavingsWithdrawalClaimVM';
import Selection from '../Components/WithdrawalClaim/Selection';
import checked from '../../App/assets/img/checked.png';
import unchecked from '../../App/assets/img/unchecked.png';
import StepsContainerComponent from '../../Domain/Components/StepsContainerComponent';
import Step from '../../Domain/Models/Steps/Step';
import doneCircle from '../../App/assets/img/done-stepper.png'
import invalidCircle from '../../App/assets/img/invalid-stepper.png';
import ContainerFooterComponent from '../../App/Views/Components/ContainerFooterComponent';
import BlueRibbon from '../../App/Views/Components/BlueRibbon';

@observer
export default class SavingsWithdrawalClaimView extends Views.ViewBase<SavingsWithdrawalClaimVM> {

  constructor(props: unknown) {
    super("Savings claim", SavingsWithdrawalClaimVM, props);
  }

  public render() {
    return (
      <div>
        <Neo.Modal
          bind={this.viewModel.meta.showCancelClaimModal}
          title='Are you sure you want to cancel?'
          acceptButton={{
            text: "Yes", variant: "primary", onClick: async () => {
              await this.viewModel.deleteSavingsClaim();
              this.viewModel.navigateToDashboard();
            }
          }}
          closeButton={{ text: "No", variant: "secondary" }}>
          Any changes you have made will not be saved.
        </Neo.Modal>
        <Neo.Modal
          bind={this.viewModel.meta.showSaveExitModal}
          title='Save & exit?'
          acceptButton={{ text: "Continue", variant: "primary", onClick: () => this.viewModel.navigateToDashboard() }}
          closeButton={{ text: "Cancel", variant: "secondary" }}>
          <span className='center-text'>
            Please note: This claim has not been submitted. The information captured will be retained for 30 days.
            Please return to this screen to complete and submit.
          </span>
        </Neo.Modal>
        <Neo.Modal
          title='File upload limit exceeded'
          acceptButton={{ text: "Okay", variant: "primary" }}
          closeButton={false}
          bind={this.viewModel.meta.showFileSizeModal}>
          <p >Please note you may upload a maximum of 2 files, not exceeding 5mb per file.</p>
        </Neo.Modal>

        <div className='claims'>

          {this.viewModel.withdrawalClaimStepsVM.currentStep?.stepId === 0 && !this.viewModel.taskRunner.isBusy &&
            <div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='header-background-card'>
                    <div className='header-background-card-title'>
                      Welcome!
                    </div>
                    {!this.viewModel.isEmployerView &&
                      <div className='header-background-card-text'>
                        Welcome to the Online Claims portal. By clicking proceed, you will initiate an online claims process for a savings withdrawal benefit.
                      </div>
                    }
                    {this.viewModel.isEmployerView &&
                      <div className='header-background-card-text'>
                        Welcome to the Online Claims portal. By clicking proceed, you will initiate an online claims process for a savings withdrawal benefit on
                        a behalf of member <span className='bold'>Michael Clifford (M547869) </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className='background-card withdrawal-claim-view'>
                <div className='p-2'>
                  {!this.viewModel.isEmployerView &&
                    <BlueRibbon
                      width='98%'
                      text='Please note: You will only be allowed to make a savings withdrawal claim if you meet the necessary requirements.'
                    />
                  }
                  {this.viewModel.isEmployerView &&
                    <BlueRibbon
                      width='98%'
                      text='Please note: The savings withdrawal claim will only be accepted if all the necessary requirements are met, and the information provided is complete.'
                    />
                  }
                  <div className='p-4'>
                    {!this.viewModel.isEmployerView &&
                      <div className='claims-title'>
                        Please select the fund that you are withdrawing from:
                      </div>
                    }
                    {this.viewModel.isEmployerView &&
                      <div className='claims-title'>
                        Select the scheme that the member is withdrawing from:
                      </div>
                    }
                    <div className='col-md-5 mt-4'>
                      <label className='scheme-dropdown-label'> Scheme name</label>
                      <Neo.AutoCompleteDropDown
                        className='auto-complete-dropdown'
                        bind={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeId}
                        bindDisplay={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeName}
                        isClearable={false}
                        items={this.viewModel.userMemberSchemeService.namesArray}
                        onItemSelected={(item) => this.viewModel.selectSchemeId(item?.schemeId ?? 0)}
                        placeholder="Select a scheme"
                      />
                    </div>
                    {!this.viewModel.isEmployerView &&
                      <div>
                        <div className='claims-title mt-4'>
                          Important information:
                        </div>
                        <div className='row'>
                          <div className='header-background-card-text mt-4 mb-3'>
                            Once Liberty commences with processing the claim payment, the claim cannot be cancelled.
                          </div>
                        </div>
                      </div>
                    }
                    {this.viewModel.isEmployerView &&
                      <div>
                        <div className='claims-title'>
                          Form required
                        </div>
                        <div className='row'>
                          <div className='header-background-card-text mt-4'>
                            Please confirm that you have a fully completed and signed savings withdrawal form as this is required to complete the claim submission.
                          </div>
                        </div>
                        <div className='steps-progress'>
                          <Neo.Button
                            className='checkbox-table-bak employer-view sub-select'
                            onClick={() => this.viewModel.employerDeclarationIAgree = this.viewModel.employerDeclarationIAgree ? false : true} >
                            {this.viewModel.employerDeclarationIAgree ? <img src={checked} /> : <img src={unchecked} />} &nbsp; &nbsp; I agree
                          </Neo.Button>
                        </div>
                        <BlueRibbon text='By clicking "I confirm.", you confirm that you have a fully completed and signed savings withdrawal claim form.' />
                      </div>
                    }
                  </div>
                </div>
                {/* TODO: This is will be used in the PreservationAndTransfer process once we get to those process - 08/09/2023 Benji*/}
                {/* <div className='row'>
                <div className='col-4'>
                  <div className='align-buttons pdf-button-start'>
                    <Neo.Button text="Download claim PDF" className='btn-continue pdf-extend-button' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='header-background-card-text margin-text-two'>
                  <span>Please note:</span> This claim document is pre-populated with the members personal details,
                  please ensure that all populated information is correct before the document is filled
                  and completed.
                </div>
              </div> */}
                <ContainerFooterComponent
                  showPrimaryButton={true}
                  primaryButtonText="Proceed"
                  primaryButtonCallback={() => this.viewModel.proceedToClaim()}
                  looksDisablePrimaryButton={!this.viewModel.eligibilityData.isEligible}
                  backLinkText='Cancel'
                  backLinkCallback={() =>
                    this.viewModel.navigateToSavingsClaim()
                  }
                />
              </div>
            </div>
          }
          {this.viewModel.withdrawalClaimStepsVM.currentStep?.stepId > 0 &&
            <div className='steps-progress'>
              <div className='mt-2'>
                <div className='step-background-card p-4'>
                  <div className='steps-header'>
                    <div className='steps-header-text'>
                      Savings Claim
                    </div>
                    <Neo.Button text="Cancel claim"
                      onClick={() => {
                        this.viewModel.showCancelClaimModal = true;
                      }}
                      variant="secondary"
                    />
                  </div>
                  <div className='steps-sub-header'>
                    Complete the following steps to complete your submission.
                  </div>
                  <StepsContainerComponent
                    viewModel={this.viewModel.withdrawalClaimStepsVM}
                    detailContainerIcon={(step: Step) => {
                      if (step.isCompleted) {
                        switch (step.stepId) {
                          case MemberWithdrawalClaimStep.MemberDetails:
                            return this.viewModel.validationCheckMemberDetails() ? doneCircle : invalidCircle;
                          case MemberWithdrawalClaimStep.HowItWorks:
                            return doneCircle;
                          case MemberWithdrawalClaimStep.Selection:
                            return (this.viewModel.bankingValidationResponse.passed != "Not Verified" &&
                              (this.viewModel.savingsWithdrawalClaimCapture.bankValidationsCheck() &&
                                this.viewModel.savingsWithdrawalClaimCapture.selectionValidationsCheck() &&
                                this.viewModel.savingWithdrawalAmountValidation())) ? doneCircle : invalidCircle;
                          case MemberWithdrawalClaimStep.Deductions:
                            return this.viewModel.savingsWithdrawalClaimCapture.deductionsValidationsCheck() ? doneCircle : invalidCircle;
                          case MemberWithdrawalClaimStep.Documentation:
                            return (this.viewModel.fileValidationCheck()) ? doneCircle : invalidCircle;
                          case MemberWithdrawalClaimStep.ReviewAndSubmit:
                            return (this.viewModel.savingsWithdrawalClaimCapture.bankValidation == "Not Verified" ||
                              !this.viewModel.reviewAndSubmitValidation()) ? doneCircle : invalidCircle;
                          case MemberWithdrawalClaimStep.MemberDeclaration:
                            return doneCircle;
                        }
                      }
                      return invalidCircle;
                    }}
                    iconHidden={(step: Step) => {
                      switch (step.stepId) {
                        case MemberWithdrawalClaimStep.MemberDetails:
                        case MemberWithdrawalClaimStep.HowItWorks:
                        case MemberWithdrawalClaimStep.Selection:
                        case MemberWithdrawalClaimStep.Deductions:
                        case MemberWithdrawalClaimStep.Documentation:
                        case MemberWithdrawalClaimStep.ReviewAndSubmit:
                        case MemberWithdrawalClaimStep.MemberDeclaration:
                          return step.isActive || !step.isCompleted;
                        default:
                          return step.isActive;
                      }
                    }}
                    iconOnClick={(step: Step) => {
                      this.viewModel.beforeStepChanged()
                      this.viewModel.withdrawalClaimStepsVM.currentStep.isActive = false;
                      this.viewModel.withdrawalClaimStepsVM.currentStep = step;
                      this.viewModel.withdrawalClaimStepsVM.currentStep.isActive = true;
                    }}
                  />
                </div>
              </div>
              <div>
                <div className='step-background-card'>
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.MemberDetails &&
                    <MemberDetails viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.HowItWorks &&
                    <HowItWorks viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Selection &&
                    <Selection viewModel={this.viewModel} />
                  }
                  {/* TODO: This is will be used in the PreservationAndTransfer process once we get to those process - 08/09/2023 Benji*/}
                  {/*{this.viewModel.currentStep.stepId === MemberWithdrawalClaimStep.PreservationAndTransfer &&
                  <PreservationAndTransfer viewModel={this.viewModel} />
                }
                 {this.viewModel.currentStep.stepId === MemberWithdrawalClaimStep.Beneficiaries && this.viewModel.beneficiariesVM.showBeneficiariesApprovedDeathBenefit === true &&
                  <BeneficiariesApprovedDeathBenefits viewModel={this.viewModel.beneficiariesVM} currentStep={this.viewModel.currentStep} />
                }
                {this.viewModel.beneficiariesVM.showBeneficiariesAddKnown &&
                  <BeneficiariesAddKnown viewModel={this.viewModel.beneficiariesVM} />
                }
                {this.viewModel.beneficiariesVM.showAddApprovedBeneficiariesForm &&
                  <AddBeneficiaries viewModel={this.viewModel.beneficiariesVM} onAddClicked={() => this.viewModel.beneficiariesVM.addNewApprovedBeneficiary()}
                    onCancelClicked={() => this.viewModel.beneficiariesVM.changeToBeneficiariesApprovedDeathBenefit()}
                    homeClicked={() => this.viewModel.beneficiariesVM.changeToBeneficiariesView()}
                    currentBeneficiary={this.viewModel.beneficiariesVM.newBeneficiary}
                    currentFuneralBenefit={this.viewModel.beneficiariesVM.newFuneralBenefit} />
                }
                {this.viewModel.beneficiariesVM.showBeneficiariesEdit &&
                  <BeneficiariesEdit viewModel={this.viewModel.beneficiariesVM} />
                } */}
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Deductions &&
                    <Deductions viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.Documentation &&
                    <Documentation viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.ReviewAndSubmit &&
                    <ReviewSubmit viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.MemberDeclaration &&
                    <MemberDeclaration viewModel={this.viewModel} />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId !== MemberWithdrawalClaimStep.MemberDeclaration &&
                    <ContainerFooterComponent
                      showPrimaryButton={true}
                      primaryButtonText="Next"
                      primaryButtonCallback={() => {
                        this.viewModel.savingsClaimNextButtonLogic()
                      }}
                      looksDisablePrimaryButton={this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.ReviewAndSubmit
                        && (this.viewModel.savingsWithdrawalClaimCapture.bankValidation == "Not Verified"
                          || this.viewModel.reviewAndSubmitValidation())}
                      showSecondaryButton={true}
                      secondaryButtonText="Save & Exit"
                      secondaryButtonCallback={() => {
                        this.viewModel.showSaveExitModal = true;
                      }}
                      hideBackLink={this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.MemberDetails}
                      backLinkText='Back'
                      backLinkCallback={() => {
                        this.viewModel.withdrawalClaimStepsVM.moveToPreviousStep();
                        this.viewModel.saveSavingsClaimData();
                      }}
                    />
                  }
                  {this.viewModel.withdrawalClaimStepsVM.currentStep.stepId === MemberWithdrawalClaimStep.MemberDeclaration &&
                    <ContainerFooterComponent
                      showPrimaryButton={true}
                      primaryButtonText="Submit"
                      primaryButtonCallback={() =>
                        this.viewModel.showFinalWarningPopup = true
                      }
                      disablePrimaryButton={!this.viewModel.memberDeclarationIAgree}
                      showSecondaryButton={true}
                      secondaryButtonText="Save & Exit"
                      secondaryButtonCallback={() => {
                        this.viewModel.showSaveExitModal = true;
                      }}
                      backLinkText='Back'
                      backLinkCallback={() =>
                        this.viewModel.withdrawalClaimStepsVM.moveToPreviousStep()
                      }
                    />
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}