import { List, ModelBase, Rules } from '@singularsystems/neo-core';
import SavingsClaimDataCaptureLookup from './SavingsClaimDataCaptureLookup';

export default class PostCaseLookup extends ModelBase {

    constructor() {
        super();
        this.makeBindable();
    }

    @Rules.Required()
    @Rules.StringLength(30)
    public nameId: number = 0;

    @Rules.Required()
    @Rules.StringLength(30)
    public memberId: number = 0;

    @Rules.Required()
    @Rules.StringLength(3)
    public sourceId: string = "";

    public savingsClaimDataCaptureLookups = new List(SavingsClaimDataCaptureLookup);

    // Client only properties / methods
}