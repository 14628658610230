import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class NotificationsSearchResultsLookup extends LookupBase {
  constructor() {
    super();
  }

  public notificationId: number = 0;

  public status: string = "";

  public notificationText: string = "";

  public notificationInformation: string = "";

  public notificationDate: string = "";

  public notificationType: NotificationType = NotificationType.None;

  public notificationStatus: string = "";

  public notificationDescription: string = "";

  public notificationMemberInformation: string = "";

  // Client only properties / methods
}

export enum NotificationType {
  None = 0,
  Member = 1,
  Scheme = 2,
  Broadcast = 3,
}