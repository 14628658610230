import { Attributes, ModelBase, Rules, StringUtils, Validation } from '@singularsystems/neo-core';

export default class MemberBeneficiaryLookup extends ModelBase {
  constructor() {
    super();
    this.makeBindable();
  }

  public beneficiaryId: string = "";

  @Attributes.Integer()
  public titleCode: number = 0;

  @Rules.Required()
  @Attributes.Display("First name")
  @Rules.StringLength(0, 80, "First name cannot be more than 80 characters in length")
  public firstName: string = "";

  @Rules.StringLength(0, 80, "Middle name cannot be more than 80 characters in length")
  public middleName: string = "";

  @Rules.Required()
  @Attributes.Display("Last name")
  @Rules.StringLength(0, 80, "Last name cannot be more than 80 characters in length")
  public lastName: string = "";

  public title: string = "";

  @Attributes.Display("RSA ID/Passport Number")
  public nationalIdNoOrPassportNo: string = "";

  @Attributes.Display("RSA ID Number")
  public nationalIdNo: string | null = null;

  @Attributes.Display("Passport number")
  public passportNo: string | null = null;

  @Attributes.Date()
  @Attributes.Display("Date of birth")
  @Rules.Required()
  public birthDate: Date | null = null;

  @Attributes.Display("Email address")
  @Rules.EmailAddress("Email address is invalid")
  @Rules.StringLength(0, 80, "Email address cannot be more than 80 characters in length")
  public emailAddress: string = "";

  @Attributes.Date()
  public expiryDate: Date | null = null;

  public signedByMember: boolean = false;

  public lastModifiedUser: string = "";

  public lastModifiedByRole: string = "";

  @Rules.Required()
  @Rules.StringLength(0, 80, "Relationship cannot be more than 80 characters in length")
  public relationship: string = "";

  public trustedPerson: boolean = false;

  @Attributes.Display("Country of issue")
  public passportCountryOfIssue: string | null = null;

  public trustNo: string = "";

  public trustName: string = "";

  @Attributes.Display("Cell number")
  public telephoneNo: string = "";

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);

    rules.failWhen(c => c.isIDNumber && StringUtils.isNullOrWhitespace(c.nationalIdNo), "RSA ID Number is required").onProperties(c => c.nationalIdNo);
    rules.failWhen(c => !c.isIDNumber && StringUtils.isNullOrWhitespace(c.passportNo), "Passport Number is required").onProperties(c => c.passportNo);
    // TODO: get the validation text to show up on the screen without hovering over the dropdown
    rules.add(this.validPassportCountryOfIssue).onProperties(c => [c.passportCountryOfIssue, c.countryOfIssueValueId]);
    rules.add(this.telephoneValidationRules).onProperties(c => c.telephoneNo);
  }

  public isSelected: boolean = false;

  public canChangeSelection: boolean = false;

  @Attributes.Display("Profile Picture")
  public profilePicture: string = '';

  @Attributes.Display("Full Name")
  public fullName: string = this.firstName + " " + this.lastName;

  public isIDNumber: boolean = true;

  public passportNumber: string = '';

  @Attributes.Observable()
  public countryOfIssueValueId: number = 1;

  @Attributes.Display("Country code")
  public countryCodeId: number | null = 1;

  public countryCode: string = '+27';

  @Attributes.Display("Address line 1")
  @Rules.StringLength(0, 80, "Address line 1 cannot be more than 80 characters in length")
  public addressLine1: string = '';

  @Attributes.Display("Address line 2")
  @Rules.StringLength(0, 80, "Address line 2 cannot be more than 80 characters in length")
  public addressLine2: string = '';

  public suburb: string = '';

  public provinceValueId: number = 1;

  public province: string = '';

  public countryValueId: number = 1;

  public country: string = '';

  @Attributes.Display("Postal code")
  @Rules.StringLength(0, 20, "Postal code cannot be more than 20 characters in length")
  public postalCode: string = '';

  @Attributes.Float()
  @Attributes.Display("Share allocation")
  public share: number = 0;

  public isApproved: boolean = true;

  public beneficiaryNumber: number = 0;

  public orderOfBeneficiaries: number = 99;

  public getFullName(): string {
    const trimmedFirstName = this.firstName.trim();
    const trimmedLastName = this.lastName.trim();
    const fullName = trimmedFirstName === '' ? trimmedLastName : trimmedLastName === '' ? trimmedFirstName : trimmedFirstName + ' ' + trimmedLastName;

    return fullName;
  }

  @Attributes.Display("Relationship")
  public get trustedPersonRelationship() {
    return this.relationship;
  }

  public set trustedPersonRelationship(value: string) {
    this.relationship = value;
  }

  private validPassportCountryOfIssue(context: Validation.IRuleContext) {
    if (!this.isIDNumber && this.countryOfIssueValueId == 1) {
      context.addError("Country of Issue is required");
    }
  }

  private isValidateTelephone() {
    if (this.countryCode === "+27") {
      // This test for that if a user uses spaces in the number the format
      // will be xx xxx xxxx or xxx xxx xxxx where the spaces are optional and letters are not allowed
      // not allowing for more than 10 characters.
      const cellNumberRegex = /^\(?(\d{2,3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      var valid = cellNumberRegex.test(this.telephoneNo)
      if (!valid || (this.telephoneNo.length >= 10 && this.telephoneNo.charAt(0) !== "0" && !this.telephoneNo.includes(" "))) {
        return false
      }
    } else {
      const foreignNumber = /^[0-9]*$/;
      var foreignNumberValid = foreignNumber.test(this.telephoneNo)
      if (this.telephoneNo.length > 15 || !foreignNumberValid) {
        return false;
      }
    }
    return true;
  }

  private telephoneValidationRules(context: Validation.IRuleContext) {
    if (this.trustedPerson && StringUtils.isNullOrWhitespace(this.telephoneNo)) {
      context.addError("Cell Number is required");
    }

    if (this.telephoneNo.length > 0) {
      if (!this.isValidateTelephone()) {
        context.addError("Cell number is invalid");
      }
    }
  }

  public isValidTrustedPerson() {
    if (this.trustedPerson &&
      !StringUtils.isNullOrWhitespace(this.firstName) &&
      !StringUtils.isNullOrWhitespace(this.lastName) &&
      !StringUtils.isNullOrWhitespace(this.telephoneNo) && this.isValidateTelephone()) {
      return true;
    }
    return false
  }
}