import { EnumHelper } from "@singularsystems/neo-core";

export enum AccountOwnershipType {
  None = 0,
  MembersAccount,
  BeneficiariesAccount,
  JointAccount,
  ThirdPartyAccount,
  EstateAccount,
  MasterOfCourtAccount
}

EnumHelper.decorateEnum(AccountOwnershipType, decorator => {
  decorator.describe(AccountOwnershipType.MembersAccount, "Member's account");
  decorator.describe(AccountOwnershipType.BeneficiariesAccount, "Beneficiary’s account");
  decorator.describe(AccountOwnershipType.JointAccount, "Joint account");
  decorator.describe(AccountOwnershipType.ThirdPartyAccount, "Third party account");
  decorator.describe(AccountOwnershipType.EstateAccount, "Estate account");
  decorator.describe(AccountOwnershipType.MasterOfCourtAccount, "Master of court account");
});