import { Attributes, EnumHelper, LookupBase } from '@singularsystems/neo-core';
import { SchemeStatus } from '../../Enums/Employer/SchemeStatus.enum';

export default class EmployerDashboardSchemeSearchResultsLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public name: string = "";

  @Attributes.Integer()
  public number: string = "";

  @Attributes.Integer()
  public numberOfMembers: number = 0;

  public schemeStatus: SchemeStatus | null = null;

  // Client only properties / methods

  public get schemeStatusName(): string {
    if (this.schemeStatus) {
      return EnumHelper.getItemMetadata(SchemeStatus, this.schemeStatus!).name
    }
    return "";
  }
}