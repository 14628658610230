import { ModelBase } from "@singularsystems/neo-core"
import { injectable } from "inversify";

@injectable()
export default class MemberDataService extends ModelBase {
  public estimatedMonthlyIncome: number = 20000;
  public showDashboard: string = "";
  private hasInitialised = false;

  constructor() {
    super();
    this.makeObservable();
  }

  public initialise() {
    if (!this.hasInitialised) {
      this.hasInitialised = true;
    }
  }

}
