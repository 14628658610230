import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';
import UpdateMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateMemberNominationCommand';
import UpdateMemberNominationResponse from '../../Models/LookUps/MemberNominations/UpdateMemberNominationResponse';
import UpdateInterimMemberNominationCommand from '../../Models/LookUps/MemberNominations/UpdateInterimMemberNominationCommand';

export interface IMemberNominationsCommandApiClient {

  /**
   * Updates a member's nomination(s)
   * @returns A string to indicate whether or not the request was successful
   */
  postMemberNominations(memberNominationsCommand: Model.PartialPlainObject<UpdateMemberNominationCommand>): AxiosPromise<Array<Model.PlainObject<UpdateMemberNominationResponse>>>;

  /**
   * EditMemberInterimMemberNominationLookupAsync
   */
  editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup: Model.PartialPlainObject<UpdateInterimMemberNominationCommand>): AxiosPromise;

  /**
   * DeleteInterimMemberNomination
   */
  deleteInterimMemberNomination(memberId: number, nominationType: string, sourceId: string): AxiosPromise;

  /**
   * ResetInterimNominations
   */
  resetInterimNominations(updateInterimMemberNominationCommands: Array<Model.PartialPlainObject<UpdateInterimMemberNominationCommand>>): AxiosPromise;


  // Client only properties / methods
}

@injectable()
export default class MemberNominationsCommandApiClient extends Data.ApiClientBase implements IMemberNominationsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-nominations`);
  }

  public postMemberNominations(memberNominationsCommand: Model.PartialPlainObject<UpdateMemberNominationCommand>): AxiosPromise<Array<Model.PlainObject<UpdateMemberNominationResponse>>> {
    return this.axios.post(`${this.apiPath}/submit-updated`, memberNominationsCommand);
  }

  public editMemberInterimMemberNominationLookupAsync(interimBeneficiaryNominationRequestLookup: Model.PartialPlainObject<UpdateInterimMemberNominationCommand>): AxiosPromise {
    return this.axios.put(`${this.apiPath}/interim-nomination-insert-update`, interimBeneficiaryNominationRequestLookup);
  }

  public deleteInterimMemberNomination(memberId: number, nominationType: string, sourceId: string): AxiosPromise {
    return this.axios.delete(`${this.apiPath}/interim-nomination/${memberId}/${encodeURIComponent(nominationType)}/${encodeURIComponent(sourceId)}`);
  }

  public resetInterimNominations(updateInterimMemberNominationCommands: Array<Model.PartialPlainObject<UpdateInterimMemberNominationCommand>>): AxiosPromise {
    return this.axios.put(`${this.apiPath}/interim-beneficairy-nomination-update`, updateInterimMemberNominationCommands);
  }

  // Client only properties / methods
}