import { LookupBase, NeoModel } from '@singularsystems/neo-core';
import { KCTemplateType } from './Enums/KCTemplateType.enum';

@NeoModel
export default class KCTemplateLookup extends LookupBase {

  public kcTemplateId: number = 0;

  public title: string = "";

  public description: string = "";

  public url: string = "";

  public kcTemplateType: KCTemplateType = KCTemplateType.None;

  // Client only properties / methods
}