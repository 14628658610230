import AppTypes from '../App/AppTypes';
import { AppService } from '../App/Services/AppService';
import { LegacyMemberDashboardExportedTypes } from './LegacyMemberDashboardExportedTypes';

const LegacyMemberDashboardTypes = {
  ApiClients: {
    ...LegacyMemberDashboardExportedTypes.ApiClients
  },
  Services: {
    ...LegacyMemberDashboardExportedTypes.Services,
  }
}

// Merged symbols from app for convenience.
const Types = {
  ...AppTypes,
  LegacyMemberDashboard: LegacyMemberDashboardTypes
}

export { AppService, Types, LegacyMemberDashboardTypes }