import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { MouseEvent } from 'react';
import { AppService, Types } from '../../App/Services/AppService';
import SchemeStatusGroup from '../../MasterData/Models/SchemeStatusGroup';
import BeneficiariesView from '../../Party/Views/Beneficiaries/BeneficiariesView';
import StaffDashboardSearchResultsLookup from '../Models/Lookups/StaffDashboardSearchResultsLookup';
import StaffSearchCriteria from '../Models/Lookups/StaffSearchCriteria';

@NeoModel
export default class StaffDashboardVM extends Views.ViewModelBase {

  public searchResults = new List(StaffDashboardSearchResultsLookup);
  public searchByScheme: string = "";
  public schemeId: number | null = null;
  public searchId: number | null = null;
  public schemeStatusId: number | null = null;
  public emptyGridCssClass: string = 'empty-grid-container';
  public hideReportGridCssClass: string = 'hide-grid';
  public showCardCssClass: string = 'hide-grid';
  public schemeStatuses: SchemeStatusGroup[] = [];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private staffDashboardApiClient = AppService.get(Types.StaffDashboard.ApiClients.StaffDashboardApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService)) {

    super(taskRunner);
  }

  private criteria = new StaffSearchCriteria();
  public pageManager = new Data.PageManager(this.criteria, StaffDashboardSearchResultsLookup,
    this.staffDashboardApiClient.getStaffDashboardSearchResults,
    {
      pageSize: 10,
      pageSizeOptions: [],
      sortBy: "idNumber",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public async initialise() {
    this.schemeStatuses = this.masterDataService.schemeStatusGroups;
  }

  changeToBenefeciariesView(item: StaffDashboardSearchResultsLookup): void {
    this.navigation.navigateToView(BeneficiariesView, { memberName: item.firstName + " " + item.lastName })
  }



}