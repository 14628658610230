import React from 'react';
import '../Styles/Components/NotFound.scss';
import img_Page_Not_Found from "../assets/img/page-not-found.png";
import img_Over_Worked_Employee from "../assets/img/overworked-employee.png";
import { Neo, Views } from '@singularsystems/neo-react';
import { EmptyViewModel } from '@singularsystems/neo-react/dist/Views';
import { AppService, Types } from '../Services/AppService';

export default class NotFound extends Views.ViewBase {

  private userRoleService = AppService.get(Types.App.Services.UserRoleService);

  constructor(props: unknown) {
    super("Page Not Found", EmptyViewModel, props);
  }

  public render() {
    return (
      <div className='page-not-found-container'>
        <div className='page-not-found'>
          <div className='message-container'>
            <div className='error-row'>
              <div className='content-div'>
                <div className='img_container'>
                  <img src={img_Page_Not_Found} className="img_404_display" />
                </div>
                <div className='page-not-found-header'>
                  <p>
                    Page not found
                  </p>
                </div>
                <div className='page-not-found-content'>
                  <p>
                    Sorry, we cannot seem to find the page you are looking for.
                  </p>
                </div>
                <div className='return-to-home'>
                  <Neo.Link
                    onClick={() => this.homeClicked()}
                  >
                    Return to dashboard
                  </Neo.Link>
                </div>
              </div>
              <div className='employee-img-container'>
                <img src={img_Over_Worked_Employee} className="employee-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  public homeClicked(): void {
    this.userRoleService.navigateToDashboard();
  }
}