import { Attributes, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import ComplaintsView from '../../App/Views/ComplaintsView';
import ContactUsView from '../../App/Views/ContactUsView';
import NonEditableProfileView from '../../App/Views/MyPortfolio/NonEditableProfileView';
import ProfileVM from '../../App/Views/MyPortfolio/ProfileVM';
import QueriesView from '../../App/Views/QueriesAndComplimentsView';
import ValueAddedServicesView from '../../App/Views/ValueAddedServicesView';
import { AppService, Types } from '../../MemberDashboard/MemberDashboardTypes';
import ProfileSummaryLookup from '../Models/Lookups/Summary/ProfileSummaryLookup';
import MyGoalsDetailsView from './MyGoalsDetailsView';

@NeoModel
export default class MemberDashboardVM extends Views.ViewModelBase {

  public estimatedMonthlyIncome: number = 0;
  public profileVM = this.registerViewModel(ProfileVM);

  public get calculateProjectedSavingsPercentage() {
    let total = 50000;
    let percentage: number = 0;
    percentage = (this.estimatedMonthlyIncome / total) * 100;
    return percentage;
  }

  public profileSummary = new ProfileSummaryLookup();
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private dashboardApiClient = AppService.get(Types.MemberDashboard.ApiClients.MemberDashboardApiClient),
    private memberService = AppService.get(Types.App.Services.MemberDataService),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper)) {

    super(taskRunner);
    this.getProfileSummary();
  }

  public async initialise() {
    this.profileVM.personContactDetailsComponentVM.getPersonContactDetails();
    this.memberService.showDashboard = "Member";
  }

  public getProfileSummary = async () => {
    const response = await this.dashboardApiClient.getProfileSummary();
    this.profileSummary.set(response.data);
    this.profileSummary.value = response.data.value;
    this.profileSummary.summaryText = response.data.summaryText;
  }

  public navigateToProfileView(): void {
    this.navigation.navigateToView(NonEditableProfileView);
  }

  public navigateToContactUsView(): void {
    this.navigation.navigateToView(ContactUsView);
  }

  public navigateToVASView(): void {
    this.navigation.navigateToView(ValueAddedServicesView);
  }

  public navigateToComplaintsView(): void {
    this.navigation.navigateToView(ComplaintsView);
  }

  public navigateToQueriesView(): void {
    this.navigation.navigateToView(QueriesView);
  }

  public navigateToMyGoalsDetail() {
    this.navigation.navigateToView(MyGoalsDetailsView, { monthlyIncome: this.estimatedMonthlyIncome });
  }
}

