import MemberWebInterfaceOptionLookup from "../../Domain/Models/WebInterfaceOptions/MemberWebInterfaceOptionsLookup";

export default class WalkMeScriptHelper {

  public static injectScript(memberWebInterfaceOptionLookup: MemberWebInterfaceOptionLookup) {
    if (memberWebInterfaceOptionLookup.walkMeHeaderEnabled && memberWebInterfaceOptionLookup.walkmeUrl.length > 0) {
      const head = document.querySelector("head");
      const walkmeScript = document.createElement("script");
      walkmeScript.type = 'text/javascript';
      var url = 'https://cdn.walkme.com/users/' + memberWebInterfaceOptionLookup.walkmeUrl;
      walkmeScript.setAttribute("src", url);
      walkmeScript.async = true;
      walkmeScript.onload = function () { (window as any)._walkmeConfig = { smartLoad: true }; };
      var firstScript = document.getElementsByTagName('script')[0];
      if (firstScript && firstScript.parentNode) {
        firstScript.parentNode.insertBefore(walkmeScript, firstScript);
        (window as any)._tmp = walkmeScript;
      } else {
        console.log("FirstScript is null");
      }
    }
  }
}