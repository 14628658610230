import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ReportsAndDocumentsVM, { GridDocumentItem } from './ReportsAndDocumentsVM';
import { observer } from 'mobx-react';
import '../Styles/Components/ReportsAndDocuments.scss';
import img_info from '../../App/assets/img/information.png';
import img_view from '../assets/img/view.png';
import { ReportType } from '../../Enums/ReportType.enum';
import { DateUtils, EnumHelper } from '@singularsystems/neo-core';
import { DocumentType } from '../../Enums/DocumentType.enum';
import LibertyPager from '../../Dashboard/Components/LibertyPager';
import ContainerFooterComponent from './Components/ContainerFooterComponent';

class ReportsAndDocumentsParams {
  public reportType = { required: false, isQuery: true };
}

@observer
export default class ReportsAndDocumentsView extends Views.ViewBase<ReportsAndDocumentsVM, ReportsAndDocumentsParams> {
  public static params = new ReportsAndDocumentsParams();
  private reports = EnumHelper.asDatasource(ReportType);
  private documents = EnumHelper.asDatasource(DocumentType);
  public maxDate = new Date();

  public showGrid() {
    if (this.viewModel.reportType == ReportType.MemberInvestmentStatement) {
      if (this.viewModel.fromDate! > this.viewModel.toDate!) {
        this.viewModel.showIncompatibleDatesModal = true;
        return;
      }
    }

    this.viewModel.reportList.set([
      {
        report: (this.viewModel.reportType == ReportType.MemberBenefitStatement) ? EnumHelper.getItemMetadata(ReportType, ReportType.MemberBenefitStatement).display :
          (this.viewModel.reportType == ReportType.MemberInvestmentStatement) ? EnumHelper.getItemMetadata(ReportType, ReportType.MemberInvestmentStatement).display :
            (this.viewModel.reportType == ReportType.PaidupMemberCertificate) ? EnumHelper.getItemMetadata(ReportType, ReportType.PaidupMemberCertificate).display :
              (this.viewModel.reportType == ReportType.PaidupMemberInvestmentStatement) ? EnumHelper.getItemMetadata(ReportType, ReportType.PaidupMemberInvestmentStatement).display : "",
        dateValue: ((this.viewModel.reportType == ReportType.MemberBenefitStatement ||
          this.viewModel.reportType == ReportType.PaidupMemberCertificate ||
          this.viewModel.reportType == ReportType.PaidupMemberInvestmentStatement) ? DateUtils.format(new Date(this.viewModel.atDate ?? ""), "dd MMM yyyy") : DateUtils.format(new Date(this.viewModel.fromDate ?? ""), "dd MMM yyyy") + " - " + DateUtils.format(new Date(this.viewModel.toDate ?? ""), "dd MMM yyyy")),
        schemeName: this.viewModel.schemeName ?? ""
      }
    ]);

    this.viewModel.hideReportGrid = 'report-grid';
    this.viewModel.emptyGridCssClass = 'hide-grid';
    this.viewModel.mobileGridCssClass = 'show-grid-mobile';
  }

  constructor(props: unknown) {
    super("Statements & reports", ReportsAndDocumentsVM, props);
  }

  public viewParamsUpdated() {
    if (this.viewParams.reportType) {
      this.viewModel.selectReport(Number.parseInt(this.viewParams.reportType.value as string))
    }
  }

  public render() {
    return (
      <div>
        {/* TODO - uncomment in R2.2 */}
        {/* <div className='reports-and-documents-card tab-header-card p-3'>
          <div className='row'>
            <div className='col-12 col-md-12'>
              <p className='reports-header'>Please select the option that you would like to view.</p>
            </div>
          </div>
          <div className='row btn-row'>
            <div className='align-report-tab-buttons mt-2 col-6 col-md-6'>
              <Neo.Button text="Reports" onClick={(e) => this.viewModel.showReports(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-complain' : 'col-6 btn btn-contact'} />
              <Neo.Button text="Documents" onClick={(e) => this.viewModel.showDocuments(e)} className={this.viewModel.showBlue ? 'col-6 btn btn-contact' : 'col-6 btn btn-complain'} />
            </div>
          </div>
        </div> */}
        <div className='reports-and-documents-card body'>
          {!this.viewModel.displayDocuments &&
            <div className='p-3 reports-container'>
              <div className='reports-header'>
                Select which statement or report you would like to view
              </div>
              <div className='reports-card'>
                <div className='p-3'>
                  <div className='row'>
                    <div className='col-md-3 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> Scheme </label>
                        <Neo.AutoCompleteDropDown
                          bind={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeId}
                          bindDisplay={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeName}
                          items={this.viewModel.userMemberSchemeService.namesArray}
                          onItemSelected={async (item) => {
                            await this.viewModel.selectSchemeId(item?.schemeId ?? 0);
                            await this.viewModel.updateReportList(item?.schemeId ?? 0);
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-3 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> Statement/Report type </label>
                        <Neo.AutoCompleteDropDown
                          bind={this.viewModel.meta.reportType}
                          items={this.viewModel.reports}
                          placeholder="Report type"
                          onItemSelected={(item) => {
                            if (item) {
                              this.viewModel.selectReport(item.id as number)
                            }
                          }} />
                      </div>
                    </div>
                    <div hidden={(this.viewModel.reportType === ReportType.MemberBenefitStatement ||
                      this.viewModel.reportType === ReportType.PaidupMemberCertificate ||
                      this.viewModel.reportType == ReportType.PaidupMemberInvestmentStatement)} className='col-md-3 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> From </label>
                        <Neo.DatePicker placeholder="DD/MM/YY"
                          dateProps={{ formatString: "dd/MM/yyyy", maxDate: this.maxDate }}
                          bind={this.viewModel.meta.fromDate} />
                      </div>
                    </div>
                    <div hidden={(this.viewModel.reportType === ReportType.MemberBenefitStatement ||
                      this.viewModel.reportType === ReportType.PaidupMemberCertificate ||
                      this.viewModel.reportType == ReportType.PaidupMemberInvestmentStatement)} className='col-md-3 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> To </label>
                        <Neo.DatePicker placeholder="DD/MM/YY"
                          disabled={this.viewModel.meta.fromDate.value == null}
                          dateProps={{ formatString: "dd/MM/yyyy", maxDate: this.maxDate, minDate: this.viewModel.meta.fromDate.value }}
                          bind={this.viewModel.meta.toDate} />
                      </div>
                    </div>
                    <div hidden={(this.viewModel.reportType === ReportType.MemberInvestmentStatement)} className='col-md-6 col-12 at-class'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> At </label>
                        <Neo.DatePicker placeholder="DD/MM/YY"
                          required
                          dateProps={{ formatString: "dd/MM/yyyy", popupAlignRight: true, maxDate: this.maxDate }}
                          bind={this.viewModel.meta.atDate} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='align-generate-button col-12 col-md-12'>
                  <Neo.Button
                    text="Generate"
                    disabled={this.viewModel.atDate !== null || (this.viewModel.toDate !== null && this.viewModel.fromDate !== null) ? false : true}
                    onClick={() => { this.showGrid() }}
                    variant='primary'
                    className='btn-generate' />
                </div>
              </div>
              <div className='line-reports'></div>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <div className={this.viewModel.emptyGridCssClass}>
                    <i> <img src={img_info}></img></i>
                    <div className='grid-info'>Reports will appear once you have made your selections</div>
                  </div>
                  <div className={this.viewModel.hideReportGrid}>
                    <NeoGrid.Grid items={this.viewModel.reportList}>
                      {(item, meta) => (
                        <NeoGrid.Row>
                          <NeoGrid.Column label='Report' className='col-md-3 col-12' display={meta.report} />
                          <NeoGrid.Column label='Scheme' className='col-md-3 col-12 display-date-column' display={meta.schemeName} />
                          <NeoGrid.Column label='Date' className='col-md-3 col-12 display-date-column' display={meta.dateValue} />
                          <NeoGrid.ButtonColumn label='Action' className='col-md-6'>
                            <Neo.Link
                              className="display-view-link"
                              onClick={() => { this.viewModel.getReport() }}>
                              <i> <img src={img_view}></img></i>  View
                            </Neo.Link>
                          </NeoGrid.ButtonColumn>
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </div>
                </div>
                <div className={this.viewModel.mobileGridCssClass}>
                  <div className='row'>
                    {this.viewModel.reportList.map((item) => (
                      <Neo.Card
                        className='mt-3 reports-grid-card'
                      >
                        <div>
                          <span className='col-6 pe-2'>Report:</span>
                          <span className='col-6 report-grid-value align-report-value'>{item.report}</span>
                        </div>
                        <div>
                          <span className='col-6 pe-2'>Scheme:</span>
                          <span className='col-6 report-grid-value align-report-value'>{item.schemeName}</span>
                        </div>
                        <div>
                          <span className='col-6 pe-2'>Date:</span>
                          <span className='col-6 ps-3 report-grid-value'>{item.dateValue}</span>
                        </div>
                        <div>
                          <span>
                            <Neo.Button
                              className="btn-sm ps-1"
                              variant='link'
                              onClick={() => { this.viewModel.getReport() }}>
                              <i> <img src={img_view}></img></i>  View
                            </Neo.Button>
                          </span>
                        </div>
                      </Neo.Card>
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {this.viewModel.displayDocuments &&
            <div className='p-3 reports-container'>
              <div className='reports-header'>
                Select which document you would like to view
              </div>
              <div className='reports-card add-padding-bottom'>
                <div className='p-3'>
                  <div className='row'>
                    <div className='col-md-6 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> Scheme </label>
                        <Neo.AutoCompleteDropDown
                          bind={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeId}
                          bindDisplay={this.viewModel.userMemberSchemeService.currentSchemeSelected.meta.schemeName}
                          items={this.viewModel.userMemberSchemeService.namesArray}
                          onItemSelected={(item) => this.viewModel.selectSchemeId(item?.schemeId ?? 0)}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-12'>
                      <div className="align-reports-dropdown">
                        <label className='reports-label'> Document</label>
                        <Neo.AutoCompleteDropDown
                          //This will bind to documentType once we have the backend code.
                          bind={this.viewModel.meta.documentType}
                          items={this.documents}
                          placeholder="IRP5"
                          onItemSelected={(item) => {
                            if (item) {
                              this.viewModel.selectDocument(item.id as number)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='align-generate-button col-12 col-md-12'>
                    <Neo.Button
                      text="Search"
                      className='btn btn-generate search' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  {this.viewModel.documentList.length == 0 &&
                    <div className="empty-grid-container" >
                      <i><img src={img_info}></img></i>
                      <div className='grid-info'>You have no documents at this time.</div>
                    </div>
                  }
                  {this.viewModel.documentList.length > 0 &&
                    <div className="report-grid document">
                      <LibertyPager
                        maxPagesToShow={2}
                        pageManager={this.viewModel.documentPageManager}
                        pageControlProps={{
                          pageSizeLabel: " ",
                          lastText: " ",
                          totalRecords: 10
                        }}>
                        <NeoGrid.Grid<GridDocumentItem>>
                          {(item, meta) => (
                            <NeoGrid.Row>
                              <NeoGrid.Column label='Document' className='col-md-3 col-12' display={meta.document} />
                              <NeoGrid.Column label='Date' className='col-md-3 col-12 ' >{DateUtils.format(item.effectiveDate, "dd MMM yyyy")}</NeoGrid.Column>
                              <NeoGrid.ButtonColumn label='Action'>
                                <Neo.Button
                                  className="btn-sm"
                                  variant='link'>
                                  <i><img src={img_view} /></i>  View
                                </Neo.Button>
                              </NeoGrid.ButtonColumn>
                            </NeoGrid.Row>
                          )}
                        </NeoGrid.Grid>
                      </LibertyPager>
                    </div>
                  }
                </div>
                <div className={this.viewModel.mobileDocumentsGridCssClass}>
                  <LibertyPager
                    maxPagesToShow={2}
                    pageManager={this.viewModel.documentPageManager}
                    pageControlProps={{
                      pageSizeLabel: " ",
                      lastText: " ",
                      totalRecords: 10
                    }}>
                    <NeoGrid.Grid<GridDocumentItem>>
                      {(item) => (
                        <Neo.Card className='mt-3 reports-grid-card documents' >
                          <div>
                            <span className='col-6 pe-2'>Document</span>
                            <span className='col-6 report-grid-value align-report-value'>{item.document}</span>
                          </div>
                          <div>
                            <span className='col-6 pe-2'>Date</span>
                            <span className='col-6 ps-3 report-grid-value'>{DateUtils.format(item.effectiveDate, "dd MMM yyyy")}</span>
                          </div>
                          <div>
                            <span>
                              <Neo.Button
                                className="btn-sm ps-1"
                                variant='link'>
                                <i> <img src={img_view}></img></i>  View
                              </Neo.Button>
                            </span>
                          </div>
                        </Neo.Card>
                      )}
                    </NeoGrid.Grid>
                  </LibertyPager>
                </div>
              </div>
            </div>
          }
          <ContainerFooterComponent />
        </div >

        <Neo.Modal
          bind={this.viewModel.meta.showIncompatibleDatesModal}
          title='Please take note:'
          closeButton={false}
          acceptButton={{ text: "Ok", variant: "primary" }}>
          Please ensure the "From" date comes before the "To" date.
        </Neo.Modal>
      </div >
    );
  }
}