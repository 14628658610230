import { AppServices } from '@singularsystems/neo-core';
import { MasterDataTypes, Types } from './MasterDataTypes';
import TitleQueryApiClient from './ApiClients/Title/TitleQueryApiClient';
import BankQueryApiClient from './ApiClients/Bank/BankQueryApiClient';
import SchemeStatusGroupQueryApiClient from './ApiClients/SchemeStatus/SchemeStatusGroupQueryApiClient';
import CountryQueryApiClient from './ApiClients/Country/CountryQueryApiClient';
import MemberStatusGroupQueryApiClient from './ApiClients/MemberStatus/MemberStatusGroupQueryApiClient';

export const MasterDataModule = new AppServices.Module("MasterData", container => {

  // ApiClients
  container.bind(MasterDataTypes.ApiClients.TitleQueryApiClient).to(TitleQueryApiClient).inSingletonScope();
  container.bind(MasterDataTypes.ApiClients.BankQueryApiClient).to(BankQueryApiClient).inSingletonScope();
  container.bind(MasterDataTypes.ApiClients.SchemeStatusGroupQueryApiClient).to(SchemeStatusGroupQueryApiClient).inSingletonScope();
  container.bind(MasterDataTypes.ApiClients.CountryQueryApiClient).to(CountryQueryApiClient).inSingletonScope();
  container.bind(MasterDataTypes.ApiClients.MemberStatusGroupQueryApiClient).to(MemberStatusGroupQueryApiClient).inSingletonScope();
});

export const MasterDataTestModule = new AppServices.Module("MasterData", container => {
  // bind any test types here
});