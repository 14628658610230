import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';
import { ClaimType } from './Enums/ClaimType.enum';
import { RoleType } from './Enums/RoleType.enum';
import { ClaimTypeCategory } from './Enums/ClaimTypeCategory.enum';
import { ClaimTypeSubCategory } from './Enums/ClaimTypeSubCategory.enum';

export default class ClaimDeclarationTemplate extends ModelBase {
    static typeName = "ClaimDeclarationTemplate";

    constructor() {
        super();
        this.makeObservable();
    }

    public claimDeclarationTemplateId: number = 0;

    @Attributes.Display("Body")
    public claimDeclarationBody: string = "";

    public claimType: ClaimType = ClaimType.SavingsClaim;

    public roleType: RoleType = RoleType.MemberDeclaration;

    public claimTypeCategory: ClaimTypeCategory = ClaimTypeCategory.None;

    public claimTypeSubCategory: ClaimTypeSubCategory = ClaimTypeSubCategory.None;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.claimDeclarationBody) {
            return "New claim declaration template";
        } else {
            return this.claimDeclarationBody;
        }
    }
}