import { ModelBase } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import Bank from "../../MasterData/Models/Bank";
import Country from "../../MasterData/Models/Country";
import MemberStatusGroup from "../../MasterData/Models/MemberStatusGroup";
import SchemeStatusGroup from "../../MasterData/Models/SchemeStatusGroup";
import Title from "../../MasterData/Models/Title";
import { AppService, Types } from "./AppService";

@injectable()
export default class MasterDataService extends ModelBase {
  private hasInitialised = false;

  public titles: Title[] = [];
  public banks: Bank[] = [];
  public schemeStatusGroups: SchemeStatusGroup[] = [];
  public countries: Country[] = [];
  public memberStatusGroups: MemberStatusGroup[] = [];

  constructor(private titleQueryApiClient = AppService.get(Types.MasterData.ApiClients.TitleQueryApiClient),
    private bankQueryApiClient = AppService.get(Types.MasterData.ApiClients.BankQueryApiClient),
    private schemeStatusGroupQueryApiClient = AppService.get(Types.MasterData.ApiClients.SchemeStatusGroupQueryApiClient),
    private countryApiClient = AppService.get(Types.MasterData.ApiClients.CountryQueryApiClient),
    private memberStatusGroupQueryApiClient = AppService.get(Types.MasterData.ApiClients.MemberStatusGroupQueryApiClient)) {
    super();
    this.makeObservable();
  }

  public async populateMasterData() {
    if (!this.hasInitialised) {
      await this.getTitles();
      await this.getBanks();
      await this.getSchemeStatusGroups();
      await this.getCountries();
      await this.getMemberStatusGroups();
      this.hasInitialised = true;
    }
  }

  public async getTitles() {
    if (this.titles.length === 0) {
      var result = await this.titleQueryApiClient.getTitles();
      const titles = result.data as Title[]

      if (result.data) {
        this.titles = titles.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getBanks() {
    if (this.banks.length === 0) {
      var result = await this.bankQueryApiClient.getBanks();
      const banks = result.data as Bank[]

      if (result.data) {
        this.banks = banks.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getSchemeStatusGroups() {
    if (this.schemeStatusGroups.length === 0) {
      var result = await this.schemeStatusGroupQueryApiClient.getSchemeStatusGroups();
      const schemeStatusGroups = result.data as SchemeStatusGroup[]

      if (result.data) {
        this.schemeStatusGroups = schemeStatusGroups.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getCountries() {
    if (this.countries.length === 0) {
      var result = await this.countryApiClient.getCountries();
      const countries = result.data as Country[]

      if (result.data) {
        this.countries = countries.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public async getMemberStatusGroups() {
    if (this.memberStatusGroups.length === 0) {
      var result = await this.memberStatusGroupQueryApiClient.getMemberStatusGroups();
      const memberStatusGroups = result.data as MemberStatusGroup[]

      if (result.data) {
        this.memberStatusGroups = memberStatusGroups.filter(({ expiry }) => expiry == null);
      }
    }
  }

  public getHasInitialised() {
    return this.hasInitialised;
  }
}