export enum UserManagement {
  ActivateUser = "Identity.User Management.Activate User",
  DeactivateUser = "Identity.User Management.Deactivate User",
  Access = "Identity.User Management.Access",
  EmailVerificationLinkResend = "Identity.User Management.Email Verification Link Resend",
  EnableMFA = "Identity.User Management.Enable MFA",
  DisableMFA = "Identity.User Management.Disable MFA",
  ResetMFA = "Identity.User Management.Reset MFA",
  ClearLockout = "Identity.User Management.Clear Lockout",
}

export enum IdentityProviders {
  Setup = "Identity.Identity Providers.Setup",
}