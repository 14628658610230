import React from 'react';
import { observer } from 'mobx-react';
import Step from '../Models/Steps/Step';
import StepsContainerComponentVM from './StepsContainerComponentVM';

interface IStepComponentProps {
  viewModel: StepsContainerComponentVM;
  step: Step;
  detailContainerIcon: (step: Step) => string;
  iconHidden: (step: Step) => boolean;
  iconOnClick: (step: Step) => void;
}

@observer
export default class StepComponent extends React.Component<IStepComponentProps> {

  constructor(props: IStepComponentProps) {
    super(props);
  }

  public render() {
    const props = this.props;
    const item = props.step;

    return (
      <div className={item.cssClass}>
        <div className='step-details'>
          <div className='progress-white-space' hidden={!item.isFirstStep}>
            <div className='the-line' />
          </div>
          <div className='progress-line' hidden={item.isFirstStep}>
            <div className='the-line' />
          </div>
          <div className='detail-container'>
            <div className='icon' hidden={props.iconHidden(item)}>
              <img src={props.detailContainerIcon(item)}
                onClick={() => props.iconOnClick(item)}
              />
            </div>
            <div className='step-number inactive-step-number' hidden={item.isCompleted || item.isActive} />
            <div className='step-number active-step-number' hidden={!item.isActive || !props.iconHidden(item)} />
          </div>
          <div className='progress-line' hidden={item.isLastStep}>
            <div className='the-line' />
          </div>
          <div className='progress-white-space' hidden={!item.isLastStep}>
            <div className='the-line' />
          </div>
        </div>
        <div className='step-text' hidden={item.isCompleted || item.isActive}>
          {item.stepName}
        </div>
        <div className='step-text-phone' hidden={!item.isActive}>
          {item.stepName}
        </div>
        <div className='completed-step-text' hidden={!item.isCompleted && !item.isActive}>
          {item.stepName}
        </div>
      </div>
    );
  }
}