import { Data, List, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../App/Services/AppService';
import EmployerComplaintsResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerComplaintsResultsLookup';
import EmployerComplaintsSchemeResultsLookup from '../Models/Lookups/EmployerDashboard/EmployerComplaintsSchemeResultsLookup';

export interface IEmployerComplaintsApiClient {

  /**
   * Returns the EmployerComplaintsResultsLookup .
   * @returns Paged List Of EmployerComplaintsResults.
   */
  getEmployerComplaintsSearchResults(keyword: string): Promise<EmployerComplaintsResultsLookup[]>;

  /**
   * Returns the EmployerComplaintsResultsLookup .
   * @returns Paged List Of EmployerComplaintsSchemeResults.
   */
  getEmployerComplaintsSchemeSearchResults(keyword: string): Promise<EmployerComplaintsSchemeResultsLookup[]>;

  // Client only properties / methods
}

@injectable()
export default class EmployerComplaintsApiClient extends Data.ApiClientBase implements IEmployerComplaintsApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/EmployerComplaints`);
  }

  public async getEmployerComplaintsSearchResults(keyword: string): Promise<EmployerComplaintsResultsLookup[]> {
    const result = await this.axios.get(`${this.apiPath}/employer-complaints-search-results?keyWord=${encodeURIComponent(keyword)}`);
    return List.fromJSArray(EmployerComplaintsResultsLookup, result.data);
  }

  public async getEmployerComplaintsSchemeSearchResults(keyword: string): Promise<EmployerComplaintsSchemeResultsLookup[]> {
    const result = await this.axios.get(`${this.apiPath}/employer-complaints-scheme-search-results?keyWord=${encodeURIComponent(keyword)}`);
    return List.fromJSArray(EmployerComplaintsSchemeResultsLookup, result.data);
  }


  // Client only properties / methods
}