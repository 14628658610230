import { Attributes, EnumHelper, LookupBase } from '@singularsystems/neo-core';
import { MemberStatus } from '../../Enums/Employer/MemberStatus.enum';
import { SchemeStatus } from '../../Enums/Employer/SchemeStatus.enum';

export default class EmployerDashboardSearchResultsLookup extends LookupBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public firstName: string = "";

  public lastName: string = "";

  public scheme: string = "";

  @Attributes.Integer()
  public idNumber: string = "";

  public memberNumber: string = "";

  public memberStatus: MemberStatus | null = null;

  public schemeStatus: SchemeStatus | null = null;

  public keyword: string = "";

  // Client only properties / methods
  public get memberstatusName(): string {
    if (this.memberStatus) {
      return EnumHelper.getItemMetadata(MemberStatus, this.memberStatus!).name
    }
    return "";
  }
}