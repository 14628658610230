import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';
import TAndCTemplateLookup from '../../Domain/Models/TAndC/TAndCTemplateLookup';
import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import '../../App/Styles/Components/TermsAndConditions.scss';
import RichTextEditor from '../Views/Components/RichTextEditor';

interface IFooterProps { }
@observer
export default class Footer extends React.Component<IFooterProps> {
  private taskRunner = AppService.get(Types.Neo.TaskRunner);
  private authService = AppService.get(Types.App.Services.AuthenticationService);
  private tAndcQueryApiClient = AppService.get(Types.Domain.ApiClients.TAndCQueryApiClient);
  private modelState = new FooterState();

  public async getLatestTAndCTemplate() {
    const response = await this.tAndcQueryApiClient.getLatestTAndCTemplate();
    const latestTAndCTemplate = response.data;
    this.modelState.tAndCTemplate = TAndCTemplateLookup.fromJSObject<TAndCTemplateLookup>(latestTAndCTemplate);
  }

  private async downloadPdf() {
    const response = await this.tAndcQueryApiClient.getTermsAndConditionsPdf();
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = downloadUrl;
    link.setAttribute('download', "TermsAndConditions.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public downloadTermsAndConditions() {
    this.taskRunner.run(async () => {
      await this.downloadPdf();
    });
  }

  constructor(props: IFooterProps) {
    super(props);
  }

  public async displayModal() {
    await this.taskRunner.waitFor(this.getLatestTAndCTemplate());
    this.modelState.showTAndCModal = true;
  }


  public render() {
    const user = this.authService.user;
    return (
      user &&
      [
        <div className="app-footer" id="footer-panel">
          <div className='row'>
            <div className='col-md-12 footer-text'>
              <div className='divider'></div>
              <span> Liberty Group Limited - a Licensed Life Insurer and an Authorised Financial Services Provider (Licence no 2409)</span>
              <div className='small-corp'>&copy; Liberty Ltd. All rights reserved.</div>
              <div className='back-to-t-and-c'>
                <Neo.Link
                  onClick={() => this.displayModal()}>
                  Terms and conditions
                </Neo.Link>
              </div>
            </div>
          </div>
        </div>,
        <Neo.Modal
          size="lg"
          title='Liberty Corporate Terms & Conditions:'
          bind={this.modelState.meta.showTAndCModal}
          buttons={[
            { text: "Download T&C's", className: 'btn-download', onClick: () => this.downloadTermsAndConditions() },
            { text: "Done", variant: "success", onClick: () => this.modelState.showTAndCModal = false },
          ]}
          className='terms-conditions-footer-modal'
          showCancelButton={false}>
          <div className='modal-content'>
            <div className='modal-text' spellCheck={false}>
              <RichTextEditor height='100%' autoFocus={false} disable readOnly hideToolbar bind={this.modelState.tAndCTemplate.meta.templateContent} />
            </div>
          </div>
        </Neo.Modal>
      ]);
  }
}

@NeoModel
class FooterState extends ModelBase {
  public showTAndCModal = false;
  public tAndCTemplate = new TAndCTemplateLookup();
}