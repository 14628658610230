import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import Country from '../../MasterData/Models/Country';
import { AppService, Types } from '../DashboardTypes';

@NeoModel
export class MemberProfile extends ModelBase {
  public title: string = 'Mr';
  public surName: string = 'Doe';
  public name: string = 'John';

  @Attributes.Display('SA ID or Passport Number')
  public passportOrNationalIdNumber: string = '80907358435';

  public dateOfBirth: string = '21 August 1989';

  @Attributes.Display('Country of issue')
  public passportCountryOfIssue: string = 'N/A';

  @Attributes.Display('Street name & number')
  public streetName: string = '1 Liberty street';

  @Attributes.Display('Unit/apartment number & complex/building name')
  public buildingName: string = 'Optional';

  @Attributes.Display('City/Town')
  public city: string = 'Johannesburg';

  public suburb: string = 'Sandton';
  public postalCode: string = 'Eg: 2199';
}

export default class EmployerMemberProfileVM extends Views.ViewModelBase {
  public schemeId: number | null = 3;
  public schemeName: string = 'The ABC Company(PTY)LTD-123';
  public profileSectionId: number | null = 1;
  public profileSectionName: string = 'Member Profile';
  public memberProfileDetails = new MemberProfile();
  public provinceId: number | null = 3;
  public provinceName: string = 'Gauteng';
  public countryId: number | null = 1;
  public showMemberProfile: boolean = true;
  public showMemberBeneficiary: boolean = false;
  public countries: Country[] = [];

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private userRoleService = AppService.get(Types.App.Services.UserRoleService),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private masterDataService = AppService.get(Types.App.Services.MasterDataService)) {

    super(taskRunner);
    this.makeObservable();
  }

  public async initialise() {
    this.countries = this.masterDataService.countries;
  }

  public navigateToDashboard(): void {
    this.userRoleService.navigateToDashboard();
  }

  public profileSectionSelected(profileSection: number): void {
    if (profileSection == 1) {
      this.showMemberProfile = true;
      this.showMemberBeneficiary = false;
      this.userRoleService.viewOnlyBeneficiary = false;
    }
    if (profileSection == 2) {
      this.showMemberProfile = false;
      this.showMemberBeneficiary = true;
      this.userRoleService.viewOnlyBeneficiary = true;
    }
  }
}