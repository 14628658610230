import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import MemberComplimentLookup from '../../Models/LookUps/MemberQueries/MemberComplimentLookup';
import { AppService, Types } from '../../PartiesTypes';

export interface IMemberComplimentsCommandApiClient {

  /** 
   * Inserts or updates a member compliment.
   * @param memberCompliment The member compliment to update.
   * @returns The updated member compliment.
   */
  insertUpdateMemberCompliment(memberCompliment: Model.PartialPlainObject<MemberComplimentLookup>): AxiosPromise<Model.PlainObject<MemberComplimentLookup>>;

  /** 
   * Deletes a member compliment.
   * @param memberId The member id for the compliment to delete.
   */
  deleteMemberCompliment(memberId: number, sourceId: string): AxiosPromise;

  /** 
   * Submits a member compliment.
   * @param memberCompliment The member compliment to submit.
   * @returns The member compliment.
   */
  submitMemberCompliment(memberCompliment: Model.PartialPlainObject<MemberComplimentLookup>): AxiosPromise<Model.PlainObject<MemberComplimentLookup>>;

  // Client only properties / methods
}

@injectable()
export default class MemberComplimentsCommandApiClient extends Data.ApiClientBase implements IMemberComplimentsCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/member-compliments`);
  }

  public insertUpdateMemberCompliment(memberCompliment: Model.PartialPlainObject<MemberComplimentLookup>): AxiosPromise<Model.PlainObject<MemberComplimentLookup>> {
    return this.axios.post(`${this.apiPath}/save-compliment`, memberCompliment);
  }

  public deleteMemberCompliment(memberId: number, sourceId: string): AxiosPromise {
    return this.axios.delete(`${this.apiPath}/compliment/${memberId}/${encodeURIComponent(sourceId)}`);
  }

  public submitMemberCompliment(memberCompliment: Model.PartialPlainObject<MemberComplimentLookup>): AxiosPromise<Model.PlainObject<MemberComplimentLookup>> {
    return this.axios.post(`${this.apiPath}/submit-compliment`, memberCompliment);
  }

  // Client only properties / methods
}