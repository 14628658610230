import { Attributes, List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import MemberCurrentInvestmentValuesLookup from './MemberInvestmentValues/MemberCurrentInvestmentValuesLookup';

export default class MemberInvestmentValuesLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public memberId: number = 0;

  public currentInvestmentValues = new List(MemberCurrentInvestmentValuesLookup);

  @Attributes.Nullable()
  @Attributes.Float()
  public totalInvestmentFundValueForScheme: number | null = 0.00;

  @Attributes.Date()
  public retirementDate: Date | null = null;

  @Attributes.Integer()
  public yearstoRetirement: number = 0;

  @Attributes.Float()
  public monthlyContributionSalary: number = 0;

  @Attributes.Nullable()
  @Attributes.Float()
  public bestProjectedRetirementBenefit: number | null = null;

  @Attributes.Nullable()
  @Attributes.Float()
  public bestEstimatedMonthlyIncome: number | null = null;

  @Attributes.Nullable()
  @Attributes.Float()
  public bestNetReplacementRatio: number | null = null;

  @Attributes.Float()
  public totalCurrentBalance: number = 0;

  @Attributes.Float()
  public savingsComponent: number = 0;

  @Attributes.Float()
  public vestedComponent: number = 0;

  @Attributes.Float()
  public vestedBenefit: number = 0;

  @Attributes.Float()
  public nonVestedBenefit: number = 0;

  @Attributes.Float()
  public retirementComponent: number = 0;


  // Client only properties / methods

  public get totalInvestmentFundValueForSchemeSafe() {
    return this.totalInvestmentFundValueForScheme ?? 0;
  }

  public get bestNetReplacementRatioSafe() {
    return this.bestNetReplacementRatio ?? 0;
  }
}