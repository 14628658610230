import React from 'react';
import { observer } from 'mobx-react';
//TODO need to be exported properly from domain.
import { IValueByPortfolio } from '../../../../Domain/Models/Portfolios/ValueByPortfolio';
import { chartColors } from '../../../../Domain/Models/Portfolios/ChartColors';
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { chart } from 'highcharts';

interface IPieChartProps {
  graphData: IValueByPortfolio[];
  animate: boolean;
  backgroundColor: string;
  children: React.ReactNode;
}

export default class PieChart extends React.Component<IPieChartProps> {

  public render() {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.getPieChartOptions(this.props.graphData)} />
      </div>
    );
  }

  private getPieChartOptions(data: IValueByPortfolio[]) {
    const symbol = "%";

    return {
      chart: {
        type: "pie",
        backgroundColor: this.props.backgroundColor,
        height: 380,
      },
      plotOptions: {
        pie: {
          innerSize: "48%",
          borderWidth: 0,
          allowPointSelect: true,
          cursor: 'pointer',
          size: 310,
        }
      },
      title: { text: undefined },
      series: [{
        animation: this.props.animate,
        tooltip: { valueDecimals: 2, pointFormat: "<b>" + "{point.y:.2f}</b>" + symbol },
        type: "pie",
        name: "Value",
        data: data.map(c => [c.portfolioName, c.value]),
        colors: chartColors,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f} %',
          distance: -40,
          color: '#FFFFFF',
          style: {
            fontWeight: '700',
            fontSize: '15px',
            fontFamily: 'NotoSans-Bold',
            textOutline: 'none',
          }
        },

      }],
      credits: { enabled: false },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 850
            },
            chartOptions: {
              chart: {
                height: 540,
              },
              plotOptions: {
                pie: {
                  size: 282,
                }
              },
              legend: {
                useHTML: true,
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                itemMarginTop: 10,
                itemMarginBottom: 15,
                symbolRadius: 0,
                width: 300,
                symbolPadding: 10,
                symbolWidth: 40,
                symbolHeight: 22,
                squareSymbol: false,
                itemStyle: {
                  fontSize: '11px',
                  fontFamily: 'NotoSans-Medium',
                  fontWeight: '500',
                  color: '#0d1325',
                  lineHeight: "2px",
                  whiteSpace: 'nowrap',
                },
              },
            }
          },]
      },
      legend: {
        useHTML: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 10,
        itemMarginBottom: 25,
        symbolRadius: 0,
        width: '50%',
        symbolPadding: 10,
        symbolWidth: 70,
        symbolHeight: 30,
        squareSymbol: false,
        y: 20,
        itemStyle: {
          fontSize: '16px',
          fontFamily: 'NotoSans-Medium',
          fontWeight: '500',
          color: '#0d1325',
          lineHeight: "2px",
          display: 'inline-block',
          textOverflow: 'none',
          overflow: 'justify',
        }
      },
    } as Highcharts.Options;
  }
}