import { List, Validation } from '@singularsystems/neo-core';
import MemberQueryLookupBase from './MemberQueryLookupBase';
import PartyFileDescriptorLookup from '../Files/PartyFileDescriptorLookup';

export default class MemberQueryLookup extends MemberQueryLookupBase {

  constructor() {
    super();
    this.makeObservable();
  }
  // Client only properties / methods

  public files = new List(PartyFileDescriptorLookup);

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New member query lookup";
    } else {
      return "Member Query Lookup";
    }
  }

  public getfilesCount(): number {
    return this.files.length;
  }
}