import { Attributes, List, ModelBase, Rules } from '@singularsystems/neo-core';
import AddressInfoLookup from './AddressInfoLookup';
import TelephoneInfoLookup from './TelephoneInfoLookup';
import EmailInfoLookup from './EmailInfoLookup';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';

export default class GetPersonContactDetailLookup extends ModelBase {

  constructor() {
    super();
    this.makeObservable();
  }

  public addressInfo = new List(AddressInfoLookup);

  @Display("Date of birth")
  @Attributes.Date()
  public birthDate: Date | null = null;

  public birthDateStringFormat: string = "";

  public telephoneInfo = new List(TelephoneInfoLookup);

  public emailInfo = new List(EmailInfoLookup);

  @Attributes.Display("Name")
  public firstName: string = "";

  @Attributes.Display("Surname")
  public lastName: string = "";

  public middleName: string = "";

  @Attributes.Integer()
  public nameId: number = 0;

  public partyId: string = "";;

  public sourceId: string = "";

  @Attributes.Display("SA ID or passport number")
  public nationalIdNumber: string | null = null;

  public title: string = "";

  public passportNumber: string | null = null;

  @Attributes.Display("Country of issue")
  public passportCountryOfIssue: string | null = null;

  public gender: string | null = null;

  public maritalStatus: string | null = null;

  public preferredLanguage: string | null = null;

  @Attributes.Date()
  public taxNumberLoadDate: Date | null = null;

  @Rules.Required("Tax number is required")
  public taxNo: string | null = null;

  public taxNumberStatus: string | null = null;

  // Client only properties / methods

  public idOrPassport: any;

  public personFullName: string = "";
}