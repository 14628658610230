import React from 'react';
import { observer } from 'mobx-react';
import '../../Domain/Styles/StepsContainerComponent.scss';
import Step from '../Models/Steps/Step';
import StepComponent from './StepComponent';
import StepsContainerComponentVM from './StepsContainerComponentVM';

interface IStepsContainerComponentProps {
  viewModel: StepsContainerComponentVM;
  detailContainerIcon: (step: Step) => string;
  iconHidden: (step: Step) => boolean;
  iconOnClick: (step: Step) => void;
}

@observer
export default class StepsContainerComponent extends React.Component<IStepsContainerComponentProps> {
  constructor(props: IStepsContainerComponentProps) {
    super(props);
  }

  public render() {
    const viewModel = this.props.viewModel;

    return (
      <div className='steps-container-component'>
        {viewModel.stepList.sortBy((step) => step.stepNumber)
          .map((item) => (
            <StepComponent
              viewModel={viewModel}
              step={item}
              detailContainerIcon={this.props.detailContainerIcon}
              iconHidden={this.props.iconHidden}
              iconOnClick={this.props.iconOnClick}
            />
          ))}
      </div>
    );
  }
}