import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../PartiesTypes';

export interface IBackgroundDataApiClient {

  /** 
   * Fetches member data for caching
   */
  fetchMemberBackgroundData(): AxiosPromise;

  // Client only properties / methods
}

@injectable()
export default class BackgroundDataApiClient extends Data.ApiClientBase implements IBackgroundDataApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/background-data`);
  }

  public fetchMemberBackgroundData(): AxiosPromise {
    return this.axios.get(`${this.apiPath}/member`);
  }

  // Client only properties / methods
}