import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import ProductsVM from '../../Views/ProductsVM';
import ProductDeathBenefits from '../ProductDeathBenefits';
import ProductChildDeathBenefit from '../ProductChildDeathBenefit';
import DeathOfSpouseBenefits from './DeathOfSpouseBenefits';
import { Model } from '@singularsystems/neo-core';
import { BenefitGroup } from '../../../Party/Models/LookUps/MemberBenefits/BenefitGroup';
import MemberBenefitsData from '../../../Party/Models/LookUps/MemberBenefits/MemberBenefitsData';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';
import SchemeInformationCards from '../../../App/Views/Components/MyPortfolio/SchemeInformationCards';

interface IRiskBenefitsTabProps {
  viewModel: ProductsVM
}

@observer
export default class RiskBenefitsTab extends React.Component<IRiskBenefitsTabProps> {

  constructor(props: IRiskBenefitsTabProps) {
    super(props);
  }

  public viewModel = this.props.viewModel;

  public render() {
    return (
      <Neo.Tab header={<div className='card-tab-text-products '>Risk benefits</div>}
        name="Risk Benefits"
        onDisplayed={() => {
          this.viewModel.showRiskBenefitModal = true;
          this.viewModel.isLumpSumTitleShown = false;
          this.viewModel.isMonthlyTitleShown = false;
        }
        }
        id="Risk Benefits">
        <div className='line-products' />
        <BlueRibbon
          text='Below is a summary of your Risk benefits. For a detailed view please view your Member benefit statement.'
        />
        <div className='card-heading-products'>
          <p>Scheme information</p>
        </div>
        <div className='scheme-information-cards-container'>
          <SchemeInformationCards
            schemeName={this.viewModel.schemeName}
            schemeNumber={this.viewModel.schemeNumber}
            memberName={this.viewModel.memberName}
            memberNumber={this.viewModel.memberNo}
            lastContributionAmount={this.viewModel.contributionBillingData.totalAmountOfContributions
              ? this.viewModel.contributionBillingData.totalAmountOfContributions.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })
              : ""}
            contributionDate={this.viewModel.contributionBillingData.billDueDateFormatted}
          />
        </div>
        <br />
        <ProductDeathBenefits
          viewModel={this.props.viewModel} />
        {

          (this.viewModel.spouseBenefitGroup || this.viewModel.spouseBenefitDetail) &&
          <DeathOfSpouseBenefits
            spouseDependentBenefitDetail={this.viewModel.spouseBenefitDetail}
            spouseBenefitGroup={this.viewModel.spouseBenefitGroup}
          />
        }
        {
          this.viewModel.showDependentsCard == true &&
          <ProductChildDeathBenefit viewModel={this.props.viewModel} memberDependentsBenefitsLookup={this.viewModel.memberDependentBenefitsLookup} />
        }
      </Neo.Tab>
    );
  }
}