import { Attributes, ModelBase } from '@singularsystems/neo-core';

export default class MemberDetailsLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sourceId: string = "";

  public memberId: number = 0;

  public payrollNo: string = "";

  public normalRetirementDate: string = "";

  public schemeJoinDate: string = "";

  public employmentDate: string = "";

  @Attributes.Integer()
  public retirementAge: number = 0;

  public memberGroupName: string | null = null;

  public paypoint: string = "";

  @Attributes.Float()
  public totalAnnualSalary: number = 0;

  @Attributes.Float()
  public monthlySalary: number = 0;

  public memberNo: string = "";

  public schemeNo: string = "";

  public employerName: string | null = null;

  // Client only properties / methods
}