import React from 'react';
import { observer } from 'mobx-react';
import InstructionsVM from '../InstructionsVM';
import { Neo } from '@singularsystems/neo-react';

interface IInstructionUpdateBeneficiaryViewIncompleteProps {
  viewModel: InstructionsVM
}

@observer
export default class InstructionUpdateBeneficiaryViewIncomplete extends React.Component<IInstructionUpdateBeneficiaryViewIncompleteProps> {

  constructor(props: IInstructionUpdateBeneficiaryViewIncompleteProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='instructon-view-message' >Please see the message below and proceed to your beneficiary page to complete the instruction</p>
          </div>
        </div>
        <div className='container'>
          <Neo.Button text="Continue" className='center btn btn-submit btn-lg' onClick={() => this.props.viewModel.navigateToBeneficiaries()} />
        </div>
      </div>
    );
  }
}