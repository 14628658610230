import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../DomainTypes';
import KCTemplate from '../../Models/KnowledgeCentre/KCTemplate';

export interface IKCCommandApiClient {

  /** 
   * Updates a KC template.
   * @param kcTemplate The KC template to update.
   * @returns The updated template.
   */
  updateKCTemplate(kcTemplate: Model.PartialPlainObject<KCTemplate>): AxiosPromise<Model.PlainTrackedObject<KCTemplate>>;

  // Client only properties / methods
}

@injectable()
export default class KCCommandApiClient extends Data.ApiClientBase implements IKCCommandApiClient {

  constructor(config = AppService.get(Types.App.Config)) {
    super(`${config.apiPath}/kc`);
  }

  public updateKCTemplate(kcTemplate: Model.PartialPlainObject<KCTemplate>): AxiosPromise<Model.PlainTrackedObject<KCTemplate>> {
    return this.axios.put(`${this.apiPath}/kc-template`, kcTemplate);
  }

  // Client only properties / methods
}