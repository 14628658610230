import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import UserStatus from './UserStatus';
import { observer } from 'mobx-react';
import { AppService, Types } from '../Services/AppService';
import img_Menu from '../../App/assets/img/menu.png';
import img_Cross from '../../App/assets/img/cross.png';
import img_MiniLogo from '../../App/assets/img/white-mini-logo.png';
import img_Line from "../assets/img/line-39.png";
import { NotificationsView } from '@singularsystems/neo-notifications';
import { UserType } from '../../Enums/UserType.enum';

@observer
export default class HeaderPanel extends React.Component {

  private navigation = AppService.get(Types.Neo.Routing.NavigationHelper);

  public changeIcon() {
    let displayIcon = document.getElementById('toggle-Menu-Icon') as HTMLImageElement;

    if (displayIcon.src.match(img_Menu)) {
      displayIcon.src = img_Cross;
    }
    else {
      displayIcon.src = img_Menu;
    }
  }

  public authService = AppService.get(Types.App.Services.AuthenticationService);
  public routeService = AppService.get(Types.App.Services.RouteService);
  public userRoleService = AppService.get(Types.App.Services.UserRoleService);

  public determineRouteName(loggedInUserName: string): string {
    let name;

    if (Views.ViewBase.currentView) {
      name = Views.ViewBase.currentView.viewName;
      if (name == "My dashboard" || name == 'Employer New Dashboard' || name == 'Legacy Member Dashboard') {
        name = 'Welcome, ' + loggedInUserName;
      }
    }
    else {
      name = 'Page not found';
    }

    return name;
  }

  private selectedRole(userType: UserType) {
    this.userRoleService.setRole(userType);
    this.userRoleService.navigateToDashboard();
  }

  private appLayout = AppService.get(Types.App.Services.AppLayout);

  public render() {
    const user = this.authService.user;
    const layout = this.appLayout;
    const globalTask = Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined;

    let headerClassName = "app-header-panel row";

    if (!layout.isScrollTop) {
      headerClassName += " scrolled";
    }

    return (
      user && <div className={headerClassName} id="header-panel">
        {globalTask && globalTask.isBusy &&
          <Neo.ProgressBar className="page-progress" progressState={globalTask.progressState} variant={globalTask.options.variant} type="animated" />}
        <div className="app-header">
          <div className='menu-anchor-container'>
            <div id="menu-anchor" className="app-hamburger-container" onClick={layout.menuToggle}>
              <i><img id="toggle-Menu-Icon" src={img_Menu} onClick={this.changeIcon} /></i>
            </div>
            <img src={img_Line} className="mini-logo-display" />
          </div>
          <div className='display-header-panel-content'>
            <div className='logo-and-user-container'>
              <div className='liberty-logo-container'>
                <img src={img_MiniLogo} className="mini-logo-display liberty-icon-change-height" />
              </div>
              <h1 className='welcome-tag'>
                {
                  this.determineRouteName(user.userData.profile.given_name)
                }
              </h1>
            </div>
            <div className='show-on-desktop'>
              <div className='bell'>
                {this.userRoleService.loggedInUser.userType != UserType.Legacy &&
                  <a href="" onClick={() => this.navigation.navigateToView(NotificationsView)}>
                    <i className="fas fa-bell fa-2x icon"></i>
                    <span className="position-absolute badge rounded-pill badge-notification notification-bubble">129</span>
                  </a>
                }
              </div>
              <div className="" >
                <UserStatus />
              </div>
            </div>
          </div>
          <div className='user-roles-image-container'>
            <div className='user-roles-image'>
              <Neo.Button
                menuAlignment="right"
                onClick={() => alert("This won't appear")}
                menuItems={
                  [{
                    text: "Liberty member",
                    onClick: () => {
                      this.selectedRole(UserType.Legacy);
                    }
                  },
                    //TODO: uncomment for R2.2
                    // {
                    //   text: "Employer",
                    //   onClick: () => {
                    //     this.selectedRole(UserType.Employer);
                    //   }
                    // },
                    // {
                    //   text: "Financial adviser",
                    //   onClick: () => {
                    //     this.selectedRole(UserType.FA);
                    //   }
                    // },
                    // {
                    //   text: "Change default role",
                    // },
                  ]}>
              </Neo.Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}