import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import NotificationsVM from '../NotificationsVM';
import "../../../App/Styles/Components/Notifications.scss";

interface IGeneralNotificationViewProps {
  viewModel: NotificationsVM
}

@observer
export default class GeneralNotificationView extends React.Component<IGeneralNotificationViewProps> {

  constructor(props: IGeneralNotificationViewProps) {
    super(props);
  }

  public render() {
    return (
      <div >
        <div className='row show-notification'>
          <Neo.Card className='show-notification-card'>
            <div className='align-text-member-info'>
              <div>
                <span className='notification-text-heading'>{this.props.viewModel.selectedItem.notificationText} - <b>{this.props.viewModel.selectedItem.notificationStatus}</b></span>
              </div>
              <div className='pe-4'>
                {this.props.viewModel.selectedItem.notificationMemberInformation != "" &&
                  <span className='notification-sub-heading'>{this.props.viewModel.selectedItem.notificationMemberInformation} <span className='display-caret'> | </span> </span>}
                <span className='notification-sub-heading'>{this.props.viewModel.selectedItem.notificationInformation}</span>
              </div>
            </div>
            <div className='line-notifications-info-card'></div>
            <p className='notification-date'>{this.props.viewModel.selectedItem.notificationDate}</p>
            <div className='notification-information mt-2'>
              {this.props.viewModel.selectedItem.notificationDescription}
            </div>
            <div className='notification-information mt-2'>
              Thank you!
            </div>
            <div className='align-btns'>
              <Neo.Button text="Back to notifications" className='btn btn-instructions' onClick={() => this.props.viewModel.onGeneralBack()} />
            </div>
          </Neo.Card>
        </div>
      </div>
    );
  }
}