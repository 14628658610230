import { Attributes, ModelBase, Validation } from '@singularsystems/neo-core';

export default abstract class DashboardSummaryModelBase extends ModelBase {

  public summaryText: string = "";

  @Attributes.Float()
  public value: number = 0.0;

  // Client only properties / methods

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }
}