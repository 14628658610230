import { List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import { BeneficiaryGroupingType } from '../BeneficiaryGroupingType.enum';
import MemberNominationDataLookup from './MemberNominationDataLookup';

export default class MemberBeneficiaryGroupingDataLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public sspSourceId: string = "";

  public beneficiaryGroupingDescription: string = "";

  public beneficiaryGroupingCode: BeneficiaryGroupingType = BeneficiaryGroupingType.ApprovedBenefits;

  public isActiveInterim: boolean = false;

  public nominationData = new List(MemberNominationDataLookup);

  // Client only properties / methods
}