import { Attributes, List, LookupBase, ModelBase } from '@singularsystems/neo-core';
import ContactUsTemplateCardLookup from './ContactUsTemplateCardLookup';
import { ContactUsTemplateType } from './Enums/ContactUsTemplateType.enum';

export default class ContactUsTemplateLookup extends ModelBase {

  constructor() {
    super();
    this.makeBindable();
  }

  public contactUsTemplateId: number = 0;

  @Attributes.Display("Heading")
  public heading: string = "";

  public contactUsTemplateCards = new List(ContactUsTemplateCardLookup);

  public contactUsTemplateType: ContactUsTemplateType = ContactUsTemplateType.None;

  // Client only properties / methods
}