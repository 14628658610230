import { Data, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import NotificationsSearchCriteria from '../../Notifications/Models/Lookups/NotificationsSearchCriteria';
import NotificationsSearchResultsLookup, { NotificationType } from '../../Notifications/Models/Lookups/NotificationsSearchResultsLookup';
import { AppService, Types } from '../Services/AppService';
import InstructionsView from './InstructionsView';

@NeoModel
export default class NotificationsVM extends Views.ViewModelBase {

  public tabValue: string = 'Actions';
  public search: string = ""
  public showNotificationInformation: boolean = false;
  public showGeneralInformation: boolean = false;
  public showGeneralCards: boolean = true;
  public showNotificationCards: boolean = true;
  public isLibertyNewsletter: boolean = false;
  public selectedItem: NotificationsSearchResultsLookup = new NotificationsSearchResultsLookup();

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    private callsAndNotificationApiClient = AppService.get(Types.MemberDashboard.ApiClients.CallsAndNotificationsApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

    super(taskRunner);
  }

  private criteria = new NotificationsSearchCriteria();

  public pageManager = new Data.PageManager(this.criteria, NotificationsSearchResultsLookup,
    this.callsAndNotificationApiClient.getNotificationsSearchResults,
    {
      pageSize: 4,
      pageSizeOptions: [4, 10],
      sortBy: "notificationId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public pageManagerGeneral = new Data.PageManager(this.criteria, NotificationsSearchResultsLookup,
    this.callsAndNotificationApiClient.getGeneralSearchResults,
    {
      pageSize: 4,
      pageSizeOptions: [4, 10],
      sortBy: "notificationId",
      sortAscending: false,
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
    });

  public async initialise() {

  }

  public onSubmit(item: NotificationsSearchResultsLookup) {
    this.selectedItem = item;
    this.showNotificationInformation = !this.showNotificationInformation;
    this.showNotificationCards = !this.showNotificationCards;
  }

  public onSubmitGeneral(item: NotificationsSearchResultsLookup) {
    this.showGeneralCards = false;
    this.selectedItem = item;

    if (item.notificationType == NotificationType.Broadcast) {

      if (item.status == "Unseen") {
        item.status = "Seen";
      }

      this.showGeneralInformation = false;
      this.isLibertyNewsletter = true;
    }

    else {
      if (item.status == "Unseen") {
        item.status = "Seen";
      }

      this.isLibertyNewsletter = false;
      this.showGeneralInformation = true;
    }
  }

  public onGeneralBack() {
    this.showGeneralCards = true;
    this.isLibertyNewsletter = false;
    this.showGeneralInformation = false;
    this.tabValue = 'General';
  }

  public onBackSubmit() {
    this.pageManager.data.map((i) => {
      if (i.notificationId == this.selectedItem.notificationId && i.status == "Unseen") {
        i.status = "Seen";
      }

      return i
    });

    this.showNotificationInformation = !this.showNotificationInformation;
    this.showNotificationCards = !this.showNotificationCards;
    this.tabValue = 'Actions';
  }

  public navigateToInstructions() {
    this.navigation.navigateToView(InstructionsView);
  }

}